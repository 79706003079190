import IconButton from '@mui/material/IconButton'
import Iconify from '@/components/iconify'

import Typography from '@mui/material/Typography'
import { trans } from '@/utils/translation'
import { Card, CardHeader, Stack } from '@mui/material'
import { usePage } from '@inertiajs/react'
import { formatDate } from '@/utils/format'
import { CancelOutlined, CheckCircleOutlined } from '@mui/icons-material'
import CustomLabelValue from '../../components/custom-label-value'
import PropertyEditBillingSettingsModal from '@/sections/property/property-edit-billing-settings-modal'
import { useModalContext } from '@/context/use-modal-context'

export default function PropertyBillingSetting() {
  const { property } = usePage().props
  const { openModal } = useModalContext()

  const features = [
    {
      value: property.billingSettings.enableHeatingCost,
      label: trans('property.billing_setting.charged_costs.heating')
    },
    {
      value: property.billingSettings.enableWarmWaterCost,
      label: trans('property.billing_setting.charged_costs.warm_water')
    },
    {
      value: property.billingSettings.enableColdWaterCost,
      label: trans('property.billing_setting.charged_costs.cold_water')
    },
    {
      value: property.billingSettings.enableAdditionalCost,
      label: trans('property.billing_setting.charged_costs.additional_cost')
    }
  ]

  return (
    <Card>
      <CardHeader
        title={trans('property.billing_setting.title')}
        action={
          <IconButton
            onClick={() =>
              openModal('propertyEditBillingSettings', <PropertyEditBillingSettingsModal property={property} />)
            }
          >
            <Iconify icon="solar:pen-bold" />
          </IconButton>
        }
      />
      <Stack direction={'column'} spacing={1} sx={{ p: 3, typography: 'body2' }}>
        <Typography variant={'subtitle2'}> {trans('property.billing_setting.charged_costs.title')}</Typography>
        <Stack direction={{ md: 'row', sm: 'column' }} spacing={2}>
          {features.map((feature) => (
            <Stack direction={'row'} flexWrap={'wrap'} spacing={1} key={feature.label}>
              {feature.value ? <CheckCircleOutlined color={'success'} /> : <CancelOutlined />}
              <Typography variant={'body2'}>{feature.label}</Typography>
            </Stack>
          ))}
        </Stack>

        <Typography sx={{ mt: 2 }} variant={'subtitle2'}>
          {trans('property.billing_setting.heating_costs')}
        </Typography>
        <CustomLabelValue
          label={trans('property.billing_setting.heating_cost_split')}
          value={`${property.billingSettings.heatingBaseCostPercentage}/${property.billingSettings.heatingUsageCostPercentage}`}
        />
        <CustomLabelValue
          label={trans('property.billing_setting.basic_heating_cost_levy')}
          value={property.billingSettings.heatingBaseCostLevy.label}
        />

        <Typography sx={{ mt: 2 }} variant={'subtitle2'}>
          {' '}
          {trans('property.billing_setting.warm_water_costs')}
        </Typography>
        <CustomLabelValue
          label={trans('property.billing_setting.warm_water_cost_split')}
          value={`${property.billingSettings.warmWaterBaseCostPercentage}/${property.billingSettings.warmWaterUsageCostPercentage}`}
        />
        <CustomLabelValue
          label={trans('property.billing_setting.basic_warm_water_cost_levy')}
          value={property.billingSettings.warmWaterBaseCostLevy.label}
        />
        <CustomLabelValue
          label={trans('property.billing_setting.usage_heating_cost_levy')}
          value={property.billingSettings.warmWaterUsageCostLevy?.label}
        />

        <Typography sx={{ mt: 2 }} variant={'subtitle2'}>
          {' '}
          {trans('property.billing_setting.deadline.title')}
        </Typography>
        <CustomLabelValue
          label={trans('property.billing_setting.deadline.billing_date')}
          value={formatDate(property.billingSettings.reportDate, 'DD.MM')}
        />
      </Stack>
    </Card>
  )
}
