import DashboardLayout from '@/layouts/dashboard'
import { trans } from '@/utils/translation'
import { Head } from '@inertiajs/react'

const DocumentDetailPage = () => {
  return (
    <>
      <Head>
        <title>{trans('document.title')}</title>
      </Head>
      {/*<DocumentDetailView />*/}
    </>
  )
}
DocumentDetailPage.layout = (page) => <DashboardLayout>{page}</DashboardLayout>
export default DocumentDetailPage
