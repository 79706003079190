import { LinearProgress, Stack } from '@mui/material'
import Typography from '@mui/material/Typography'
import { formatDeviceValue } from '@/utils/format'
import DeviceTypes from '@/constants/DeviceTypes'
import PropTypes from 'prop-types'

export default function ProgressItem({ progress, value, color, ...other }) {
  return (
    <Stack direction={'row'} spacing={1} {...other}>
      <LinearProgress
        variant="determinate"
        value={progress}
        sx={{
          height: 16,
          backgroundColor: 'white',
          width: '80%',
          '& .MuiLinearProgress-bar': {
            borderRadius: 3,
            background: color
          }
        }}
      />
      <Typography variant={'subtitle2'} sx={{ whiteSpace: 'noWrap' }}>
        {formatDeviceValue(value, DeviceTypes.HEAT)}
      </Typography>
    </Stack>
  )
}

ProgressItem.propTypes = {
  progress: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired
}
