import { Card, CardHeader, Grid, Stack } from '@mui/material'
import { trans } from '@/utils/translation'
import IconButton from '@mui/material/IconButton'
import Iconify from '@/components/iconify'
import Typography from '@mui/material/Typography'
import CustomLabelValue from '@/components/custom-label-value'
import { usePage } from '@inertiajs/react'
import { formatDate, formatFloor, formatNumber } from '@/utils/format'
import Avatar from '@mui/material/Avatar'
import { avatar, hasRole } from '@/utils/misc'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import ApartmentChangeListTable from '@/sections/apartment/apartment-change-list-table'
import ApartmentsTenantsListTable from '@/sections/apartment/period/apartment-tenants-list-table'
import ApartmentsOwnersListTable from '@/sections/apartment/period/apartment-owners-list-table'
import ApartmentCreateModal from '@/sections/apartment/apartment-create-modal'
import { useModalContext } from '@/context/use-modal-context'
import AddressModal from '@/components/modal/adress-modal'
import Roles from '@/constants/Roles'
import ApartmentAdminFunction from '@/sections/apartment/apartment-admin-function'

const ApartmentDetailData = () => {
  const { apartment, auth } = usePage().props
  const { openModal } = useModalContext()

  const renderApartmentData = (
    <Card>
      <CardHeader
        title={trans('apartment.object_data.title')}
        action={
          <IconButton
            onClick={() =>
              openModal(
                'apartmentCreate',
                <ApartmentCreateModal apartment={apartment} propertyId={apartment.property.id} />
              )
            }
          >
            <Iconify icon="solar:pen-bold" />
          </IconButton>
        }
      />
      <Stack direction={'column'} spacing={1} sx={{ p: 3, typography: 'body2' }}>
        <Typography variant={'subtitle2'}> {trans('apartment.object_data.basic_data.title')}</Typography>
        <CustomLabelValue label={trans('apartment.object_data.basic_data.number')} value={apartment.number} />
        <CustomLabelValue label={trans('field.apartment.label')} value={apartment.label} />
        <CustomLabelValue label={trans('field.apartment.location')} value={apartment.location || '-'} />
        <CustomLabelValue label={trans('general.from')} value={formatDate(apartment.begin)} />
        <CustomLabelValue label={trans('general.to')} value={apartment.end ? formatDate(apartment.end) : '-'} />
        <CustomLabelValue label={trans('field.apartment.floor')} value={formatFloor(apartment.floor)} />

        <Typography sx={{ mt: 2 }} variant={'subtitle2'}>
          {trans('apartment.object_data.sizes.title')}
        </Typography>
        <CustomLabelValue
          label={trans('apartment.object_data.sizes.constructed_area')}
          value={apartment.setting?.size ? `${formatNumber(apartment.setting?.size)} m²` : '-'}
        />
        <CustomLabelValue
          label={trans('apartment.object_data.sizes.heating')}
          value={apartment.setting?.heatingSize ? `${formatNumber(apartment.setting?.heatingSize)} m²` : '-'}
        />
        <CustomLabelValue
          label={trans('apartment.object_data.sizes.wwater')}
          value={apartment.setting?.warmWaterSize ? `${formatNumber(apartment.setting?.warmWaterSize)} m²` : '-'}
        />
        <CustomLabelValue
          label={trans('apartment.object_data.sizes.additional_cost')}
          value={
            apartment.setting?.additionalCostSize ? `${formatNumber(apartment.setting?.additionalCostSize)} m²` : '-'
          }
        />
        <CustomLabelValue
          label={trans('apartment.object_data.sizes.commercial')}
          value={apartment.setting?.commercialSize ? `${formatNumber(apartment.setting?.commercialSize)} m²` : '-'}
        />
      </Stack>
    </Card>
  )

  const renderApartmentAddress = (
    <>
      <CardHeader
        title={trans('apartment.address.title')}
        action={
          <IconButton onClick={() => openModal('addressEdit', <AddressModal address={apartment.address} />)}>
            <Iconify icon="solar:pen-bold" />
          </IconButton>
        }
      />
      <Stack direction={'column'} spacing={1} sx={{ p: 3 }}>
        {apartment.address.name && <Typography variant="body2">{apartment.address.name}</Typography>}
        <Typography variant="body2">
          {apartment.address.street} {apartment.address.streetNumber}
        </Typography>
        <Typography variant="body2">
          {apartment.address.postcode} {apartment.address.city}
        </Typography>
      </Stack>
    </>
  )

  const renderCurrentOwner = (
    <>
      <CardHeader title={trans('apartment.current_owner')} />
      <Stack direction="row" sx={{ p: 3, flexWrap: 'wrap' }}>
        <Avatar src={avatar(apartment.owner?.user)} sx={{ width: 48, height: 48, mr: 2 }} />
        <Stack spacing={0.5} alignItems="flex-start" sx={{ typography: 'body2' }}>
          <Typography variant="subtitle2">{apartment.owner?.user?.name ?? 'Kein Eigentümer'}</Typography>
          <Box sx={{ color: 'text.secondary', overflowWrap: 'anywhere' }}>{apartment.owner?.user?.email}</Box>
          <Box sx={{ color: 'text.secondary' }}>{apartment.owner?.user?.phone}</Box>
        </Stack>
      </Stack>
    </>
  )

  const renderCurrentTenant = (
    <>
      <CardHeader title={trans('apartment.current_tenant')} />
      <Stack direction="row" sx={{ p: 3 }}>
        <Avatar src={avatar(apartment.tenant?.user)} sx={{ width: 48, height: 48, mr: 2 }} />

        <Stack spacing={0.5} alignItems="flex-start" sx={{ typography: 'body2' }}>
          <Typography variant="subtitle2">{apartment.tenant?.user?.name || trans('apartment.no_tenant')}</Typography>
          <Box sx={{ color: 'text.secondary', overflowWrap: 'anywhere' }}>{apartment.tenant?.user?.email}</Box>
          <Box sx={{ color: 'text.secondary' }}>{apartment.tenant?.user?.phone}</Box>
        </Stack>
      </Stack>
    </>
  )

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Stack direction={{ md: 'column' }} gap={3}>
            {renderApartmentData}

            <Card>
              {renderApartmentAddress}
              {apartment.property.isWEG && (
                <>
                  <Divider sx={{ borderStyle: 'dashed' }} />
                  {renderCurrentOwner}
                </>
              )}
              <Divider sx={{ borderStyle: 'dashed' }} />
              {renderCurrentTenant}
            </Card>

            {hasRole(auth.user, Roles.ADMIN) && <ApartmentAdminFunction />}
          </Stack>
        </Grid>

        <Grid item xs={12} md={8}>
          <Stack direction={{ md: 'column' }} gap={3}>
            <ApartmentsTenantsListTable />
            {hasRole(auth.user, Roles.ROLE_PROPERTY_ADMIN) && apartment.property.isWEG && <ApartmentsOwnersListTable />}
            <ApartmentChangeListTable />
          </Stack>
        </Grid>
      </Grid>
    </>
  )
}

export default ApartmentDetailData
