import { PropertyCompleteView } from '@/sections/property-complete/view'
import SimpleLayout from '@/layouts/header-simple'
import { Head } from '@inertiajs/react'
import { trans } from '@/utils/translation'

export default function PropertyCompletePage() {
  return (
    <>
      <Head>
        <title>{trans('initial_setup.title')}</title>
      </Head>
      <PropertyCompleteView />
    </>
  )
}

PropertyCompletePage.layout = (page) => <SimpleLayout>{page}</SimpleLayout>