import { Grid, Stack } from '@mui/material'
import Device from '@/sections/device/device'
import DeviceData from '@/sections/device/device-data'
import DeviceInfo from '@/sections/device/device-info'
import { usePage } from '@inertiajs/react'
import DeviceValueListTable from '../../device-value/device-value-list-table'
import DeviceDataErrorTable from '@/sections/device/device-data-error-table'

export default function DeviceDetailView() {
  const { device } = usePage().props

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Stack direction={{ md: 'column' }} gap={3}>
            <Device />
            <DeviceData />
            <DeviceInfo />
          </Stack>
        </Grid>
        <Grid item xs={12} md={8}>
          <Stack direction={{ md: 'column' }} gap={3}>
            <DeviceValueListTable defaultFilter={{ device: device.id }} />
            <DeviceDataErrorTable />
          </Stack>
        </Grid>
      </Grid>
    </>
  )
}
