import { useMemo, useState } from 'react'
import { trans } from '@/utils/translation'
import useRemoteTable from '../../hooks/useRemoteTable'
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table'
import { defaultTableOptions } from '@/utils/misc'
import {
  Card,
  Checkbox,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'
import Iconify from '../../components/iconify'
import { router } from '@inertiajs/react'
import { useTheme } from '@mui/material/styles'
import { formatDate } from '@/utils/format'
import IconButton from '@mui/material/IconButton'
import { RemoveOutlined } from '@mui/icons-material'
import Paper from '@mui/material/Paper'
import { useBoolean } from '@/hooks/use-boolean'
import { COLUMNS } from './table/manager-table-definitions'
import ManagerTableToolbar from './table/manager-table-toolbar'
import PropTypes from 'prop-types'
import TableRowAction from '@/components/table/table-row-action'
import TableConfirmDelete from '@/components/table/table-confirm-delete'

export default function ManagerListTable({ defaultFilter, defaultSorting }) {
  const path = '/managers/list'
  const theme = useTheme()
  const confirmRows = useBoolean()
  const [manager, setManager] = useState(null)

  const columns = useMemo(() => COLUMNS, [])
  const methods = useRemoteTable(path, ['name'], defaultFilter, defaultSorting)
  const {
    globalFilter,
    columnSorting,
    setColumnSorting,
    pagination,
    setPagination,
    data,
    isError,
    isLoading,
    rowCount,
    rowSelection,
    setRowSelection,
    refresh,
    setRefresh
  } = methods

  const table = useMaterialReactTable({
    ...defaultTableOptions,
    enableRowSelection: false,
    enableMultiRowSelection: true,
    enableRowActions: true,
    renderRowActions: ({ row }) => (
      <TableRowAction
        row={row}
        items={[
          {
            content: (
              <>
                <Iconify icon={'eva:trash-2-outline'} color={theme.palette.error.main} />
                <Typography variant={'body'}>{trans('button.delete')}</Typography>
              </>
            ),
            callback: () => {
              setManager(row.original)
              confirmRows.onTrue()
            }
          }
        ]}
      />
    ),
    renderTopToolbar: () => <ManagerTableToolbar {...methods} />,
    renderDetailPanel: ({ row }) => {
      if (!row.original?.managedProperties?.length) return

      return (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell width="40%">{trans('general.address')}</TableCell>
                <TableCell width="20%">{trans('general.property')}</TableCell>
                <TableCell width="20%">{trans('general.from')}</TableCell>
                <TableCell width="20%">{trans('general.to')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {row.original.managedProperties.map((row, index) => (
                <TableRow
                  key={index}
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()

                    router.get(`/properties/${row.property.id}/detail`)
                  }}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                    '&:hover': { background: (theme) => theme.palette.grey[200] }
                  }}
                >
                  <TableCell>
                    {row?.property?.address?.street} {row?.property?.address?.streetNumber},{' '}
                    {row?.property?.address?.postcode} {row?.property?.address?.city}
                  </TableCell>
                  <TableCell>{row?.property?.label}</TableCell>
                  <TableCell>{row.begin ? formatDate(row.begin) : '-'}</TableCell>
                  <TableCell>{row.end ? formatDate(row.end) : '-'}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )
    },
    positionToolbarAlertBanner: 'head-overlay',
    muiToolbarAlertBannerProps: isError ? { severity: 'error' } : { severity: 'warning' },
    renderToolbarAlertBannerContent: () => {
      if (isError) {
        return trans('table.data_load_error')
      }

      return (
        <Stack direction={'row'} sx={{ px: 1 }} alignItems={'center'} justifyContent={'space-between'}>
          <Stack direction={'row'} alignItems={'center'}>
            <Checkbox
              sx={{ mr: 3 }}
              checkedIcon={
                <RemoveOutlined
                  sx={{
                    background: theme.palette.primary.main,
                    color: theme.palette.common.white,
                    borderRadius: '5px'
                  }}
                />
              }
              defaultChecked
              onClick={() => setRowSelection([])}
            />
            <Typography fontSize={'small'}>
              {Object.values(rowSelection).length} {trans('table.selected')}
            </Typography>
          </Stack>
          <IconButton onClick={confirmRows.onTrue}>
            <Iconify icon={'eva:trash-2-outline'} color={theme.palette.error.main} />
          </IconButton>
        </Stack>
      )
    },
    muiTablePaperProps: {
      elevation: 0,
      sx: {
        borderRadius: '0'
      }
    },
    muiTableBodyRowProps: ({ row }) => ({
      onClick: (e) => {
        if (!e.target.classList.contains('MuiBackdrop-root')) {
          router.get(`/managers/${row.original.id}/detail`)
        }
      },
      sx: { cursor: 'pointer' }
    }),
    columns,
    data,
    rowCount: rowCount,
    onPaginationChange: setPagination,
    onSortingChange: setColumnSorting,
    onRowSelectionChange: setRowSelection,
    state: {
      rowSelection,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isLoading,
      showSkeletons: isLoading,
      sorting: columnSorting,
      refresh
    }
  })

  return (
    <>
      <Card>
        <MaterialReactTable table={table} />
      </Card>

      <TableConfirmDelete
        url={`/api/users/${manager?.id}`}
        confirmRows={confirmRows}
        setValue={setManager}
        setRefresh={setRefresh}
      />
    </>
  )
}

ManagerListTable.propTypes = {
  defaultFilter: PropTypes.object,
  defaultSorting: PropTypes.array
}
