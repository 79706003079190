import { Head } from '@inertiajs/react'
import { trans } from '@/utils/translation'
import DashboardLayout from '../../layouts/dashboard'
import { DeviceListView } from '../../sections/device/view'

const DeviceListPage = () => {
  return (
    <>
      <Head>
        <title>{trans('device.title')}</title>
      </Head>
      <DeviceListView />
    </>
  )
}
DeviceListPage.layout = (page) => <DashboardLayout>{page}</DashboardLayout>

export default DeviceListPage
