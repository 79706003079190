import { Checkbox, Chip, FormControl, InputLabel, OutlinedInput, Select, Stack } from '@mui/material'
import { trans } from '@/utils/translation'
import Box from '@mui/material/Box'
import MenuItem from '@mui/material/MenuItem'
import Roles from '../../../constants/Roles'
import PropTypes from 'prop-types'
import ListFilterTextfield from '@/components/form/list-filter-textfield'

export default function UserTableToolbar({ columnFilters, setColumnFilters, globalFilter, setGlobalFilter }) {
  return (
    <Stack>
      <Stack
        direction={{ xs: 'column', md: 'row' }}
        alignItems={'center'}
        spacing={2}
        flexGrow={1}
        sx={{
          py: 2
        }}
      >
        <FormControl
          sx={{
            flexShrink: 0,
            width: { xs: 1, md: 200 }
          }}
        >
          <InputLabel>{trans('field.role')}</InputLabel>
          <Select
            multiple={true}
            value={columnFilters?.roles}
            onChange={(e) =>
              setColumnFilters({
                ...columnFilters,
                roles: e.target.value
              })
            }
            input={<OutlinedInput label={trans('field.role')} />}
            renderValue={(selected) => (
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  gap: 0.5
                }}
              >
                {selected.map((role) => (
                  <Chip key={role} label={trans(`role.${role}`)} variant="light" color="primary" size="small" />
                ))}
              </Box>
            )}
          >
            {Object.values(Roles).map((role) => (
              <MenuItem key={role} value={role}>
                <Checkbox disableRipple size="small" checked={columnFilters?.roles?.includes(role)} />
                {trans(`role.${role}`)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <ListFilterTextfield
          placeholder={trans('impersonate.search_placeholder')}
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
      </Stack>
    </Stack>
  )
}

UserTableToolbar.propTypes = {
  columnFilters: PropTypes.object,
  setColumnFilters: PropTypes.func,
  globalFilter: PropTypes.string,
  setGlobalFilter: PropTypes.func
}
