import { DialogActions, DialogTitle } from '@mui/material'
import DialogContent from '@mui/material/DialogContent'
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table'
import { useMemo } from 'react'
import { useModalContext } from '@/context/use-modal-context'
import { LoadingButton } from '@mui/lab'
import { trans } from '@/utils/translation'
import { ToastCtl } from '@/utils/toast-ctl'
import { defaultTableOptions } from '@/utils/misc'
import useRemoteTable from '../../hooks/useRemoteTable'
import { COLUMNS } from './table/user-table-definitions'
import UserTableToolbar from './table/user-table-toolbar'
import CustomButton from '@/components/custom-button'
import PropTypes from 'prop-types'

export default function UserSelectModal({ callback }) {
  const { closeModal } = useModalContext()

  const columns = useMemo(() => COLUMNS, [])

  const methods = useRemoteTable('/users/list', ['email', 'name', 'identifier'], { roles: [] })
  const {
    globalFilter,
    columnSorting,
    setColumnSorting,
    pagination,
    setPagination,
    data,
    isError,
    isLoading,
    rowCount
  } = methods

  const table = useMaterialReactTable({
    ...defaultTableOptions,
    enableMultiRowSelection: false,
    enableRowSelection: true,
    enableSelectAll: true,
    renderTopToolbar: () => <UserTableToolbar {...methods} />,
    muiTablePaperProps: {
      elevation: 0,
      sx: {
        borderRadius: '0'
      }
    },
    muiTableBodyRowProps: ({ row }) => ({
      onClick: row.getToggleSelectedHandler(),
      sx: { cursor: 'pointer' }
    }),
    muiToolbarAlertBannerProps: isError ? { severity: 'error', children: trans('table.data_load_error') } : undefined,
    columns,
    data,
    rowCount: rowCount,
    onPaginationChange: setPagination,
    onSortingChange: setColumnSorting,
    state: {
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isLoading,
      showSkeletons: isLoading,
      sorting: columnSorting
    }
  })

  return (
    <>
      <DialogTitle>{trans('user.select')}</DialogTitle>
      <DialogContent dividers sx={{ py: 1 }}>
        <MaterialReactTable table={table} />
      </DialogContent>
      <DialogActions>
        <CustomButton onClick={() => closeModal('userSelect')}>{trans('button.cancel')}</CustomButton>
        <LoadingButton
          variant={'contained'}
          onClick={() => {
            const selected = table.getSelectedRowModel().flatRows[0]?.original
            if (!selected) {
              ToastCtl.show(trans('impersonate.validation.user_missing'), 'warning')
              return
            }

            const response = callback(selected)
            if (response) {
              closeModal('userSelect')
            }
          }}
        >
          {trans('button.select')}
        </LoadingButton>
      </DialogActions>
    </>
  )
}

UserSelectModal.propTypes = {
  callback: PropTypes.func
}
