import IconButton from '@mui/material/IconButton'
import { Card, CardHeader, Stack } from '@mui/material'
import Iconify from '@/components/iconify'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'

import Avatar from '@mui/material/Avatar'
import { trans } from '@/utils/translation'
import { usePage } from '@inertiajs/react'
import { avatar } from '@/utils/misc'
import { useModalContext } from '@/context/use-modal-context'
import AddressModal from '@/components/modal/adress-modal'
import ContactModal from '@/components/modal/contact-modal'

export default function PropertyAddressData() {
  const { property } = usePage().props
  const { openModal } = useModalContext()

  const renderPropertyAddress = (
    <>
      <CardHeader
        title={trans('property.property_address.title')}
        action={
          <IconButton onClick={() => openModal('addressEdit', <AddressModal address={property.address} />)}>
            <Iconify icon="solar:pen-bold" />
          </IconButton>
        }
      />
      <Stack direction={'column'} spacing={1} sx={{ p: 3 }}>
        {property.address.name && <Typography variant="body2">{property.address.name}</Typography>}
        <Typography variant="body2">
          {property.address.street} {property.address.streetNumber}
        </Typography>
        <Typography variant="body2">
          {property.address.postcode} {property.address.city}
        </Typography>
      </Stack>
    </>
  )

  const renderBillAddress = (
    <>
      <CardHeader
        title={trans('property.bill_address.title')}
        action={
          <IconButton
            onClick={() =>
              openModal(
                'addressEdit',
                <AddressModal address={{ isBillingAddress: true, ...property.billingAddress }} />
              )
            }
          >
            <Iconify icon="solar:pen-bold" />
          </IconButton>
        }
      />
      <Stack direction={'column'} spacing={1} sx={{ p: 3 }}>
        {property.billingAddress.company && <Typography variant="body2">{property.billingAddress.company}</Typography>}
        {property.billingAddress.name && <Typography variant="body2">{property.billingAddress.name}</Typography>}
        <Typography variant="body2">
          {property.billingAddress.street} {property.billingAddress.streetNumber}
        </Typography>
        <Typography variant="body2">
          {property.billingAddress.postcode} {property.billingAddress.city}
        </Typography>
      </Stack>
    </>
  )

  const renderContractPerson = (
    <>
      <CardHeader title={trans('property.contract_partner')} />
      <Stack direction="row" sx={{ p: 3, flexWrap: 'wrap' }}>
        <Avatar src={avatar(property.contractor?.user)} sx={{ width: 48, height: 48, mr: 2 }} />
        <Stack spacing={0.5} alignItems="flex-start" sx={{ typography: 'body2' }}>
          <Typography variant="subtitle2">
            {property.contractor?.user?.name} ({trans(`role.${property.contractor?.user?.roles[0]}`)})
          </Typography>
          <Box sx={{ color: 'text.secondary', overflowWrap: 'anywhere' }}>{property.contractor?.user?.email}</Box>
          <Box sx={{ color: 'text.secondary' }}>{property.contractor?.user?.phone}</Box>
        </Stack>
      </Stack>
    </>
  )

  const renderContactPerson = (
    <>
      <CardHeader
        title={trans('property.contact_person')}
        action={
          <IconButton onClick={() => openModal('contactEdit', <ContactModal contact={property.contactPerson} />)}>
            <Iconify icon="solar:pen-bold" />
          </IconButton>
        }
      />
      <Stack direction="row" sx={{ p: 3, flexWrap: 'wrap' }}>
        <Avatar src={avatar(property.contactPerson)} sx={{ width: 48, height: 48, mr: 2 }} />
        <Stack spacing={0.5} alignItems="flex-start" sx={{ typography: 'body2' }}>
          <Typography variant="subtitle2">{property.contactPerson.name}</Typography>
          {property.contactPerson?.email && (
            <Box sx={{ color: 'text.secondary', overflowWrap: 'anywhere' }}>{property.contactPerson.email}</Box>
          )}
          {property.contactPerson?.phone && <Box sx={{ color: 'text.secondary' }}>{property.contactPerson.phone}</Box>}
        </Stack>
      </Stack>
    </>
  )

  return (
    <Card>
      {renderPropertyAddress}
      <Divider sx={{ borderStyle: 'dashed' }} />
      {renderBillAddress}
      <Divider sx={{ borderStyle: 'dashed' }} />
      {property.contractor?.user && (
        <>
          {renderContractPerson}
          <Divider sx={{ borderStyle: 'dashed' }} />
        </>
      )}
      {renderContactPerson}
    </Card>
  )
}
