import { useForm as useInertiaForm } from '@inertiajs/react'
import { useState } from 'react'
import { get } from 'lodash'
import { set as setFp } from 'lodash/fp'
import * as yup from 'yup'
import { memoizeDebounce } from '@/utils/misc'

export const useLocalForm = (...args) => {
  let { defaultValues, validationSchema } = args[0]

  const [overrideFieldValues, setOverrideFieldValues] = useState(defaultValues)
  const form = useInertiaForm(defaultValues)

  const validate = async () => {
    return await validationSchema.validate(form.data, { context: form.data, abortEarly: false }).then(() => {
      form.clearErrors()
    })
  }

  const setData = (key, value) => {
    form.setData(function (data) {
      return setFp(key, value, data)
    })

    if (get(form.data, key) !== null && validationSchema) {
      try {
        yup
          .reach(validationSchema, key)
          .validate(value, { context: form.data })
          .then(() => {
            form.clearErrors(key)
          })
          .catch((e) => {
            form.setError(key, e.message)
          })
      } catch (e) {
        console.debug('ERROR', e)
      }
    }
  }

  const setDataDebounce = memoizeDebounce((key, value) => {
    setData(key, value)
  }, 150)

  return {
    ...form,
    validationSchema,
    validate,
    setData,
    setDataDebounce,
    setOverrideFieldValues,
    overrideFieldValues
  }
}
