import AuthLayout from '../../layouts/auth'
import CustomLink from '@/components/custom-link'
import { Head } from '@inertiajs/react'
import { trans } from '@/utils/translation'
import PropTypes from 'prop-types'

const ForgotPasswordSent = ({ expirationMessage }) => {
  return (
    <>
      <Head>
        <title>{trans('forgot_password.header')}</title>
      </Head>
      <p>Die Mail wurde versendet. Bitte sehen Sie in Ihren Posteingang.</p>
      <p>Der Link ist nur für {expirationMessage} gültig.</p>
      <p>
        Wenn Sie keine E-Mail erhalten, überprüfen Sie bitte Ihren Spam-Ordner oder{' '}
        <CustomLink href="/forgot-password">versuchen Sie es erneut</CustomLink>.
      </p>
    </>
  )
}

ForgotPasswordSent.propTypes = {
  expirationMessage: PropTypes.string
}

ForgotPasswordSent.layout = (page) => <AuthLayout>{page}</AuthLayout>

export default ForgotPasswordSent
