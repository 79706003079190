import background from '../../images/register_background.png'
import ThemeProvider from '@/theme'
import PropTypes from 'prop-types'
import { Card, Modal } from '@mui/material'

export default function RegisterLayout({ children }) {
  return (
    <ThemeProvider>
      <Modal
        open={true}
        sx={{
          backgroundImage: `url(${background})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        }}
      >
        <Card
          sx={{
            maxWidth: '500px',
            margin: 'auto',
            mt: 20,
            p: 4
          }}
        >
          {children}
        </Card>
      </Modal>
    </ThemeProvider>
  )
}

RegisterLayout.propTypes = {
  children: PropTypes.node
}
