import { trans } from '@/utils/translation'
import { formatDate } from '@/utils/format'
import PropertyState from '../../../constants/PropertyState'
import PropertyStateLabel from '../property-state-label'

export const COLUMNS = [
  {
    accessorFn: (row) => `${row.address.street} ${row.address.streetNumber}`,
    header: trans('field.street'),
    size: 250,
    id: 'street'
  },
  {
    accessorFn: (row) => `${row.address.postcode} ${row.address.city}`,
    header: trans('field.city'),
    size: 250,
    id: 'city'
  },
  {
    accessorKey: 'apartmentsCount',
    header: trans('field.property.apartments'),
    size: 150,
    id: 'apartmentsCount'
  },
  {
    accessorKey: 'billingSettings.reportDate',
    header: trans('field.property.billing_date'),
    Cell: ({ renderedCellValue }) => (renderedCellValue ? formatDate(renderedCellValue, 'DD.MM') : '-'),
    size: 150,
    id: 'reportDate'
  },
  {
    accessorKey: 'state',
    header: trans('field.state'),
    Cell: ({ renderedCellValue }) => <PropertyStateLabel state={renderedCellValue} />,
    size: 150,
    enableSorting: false
  }
]

export const STATUS_OPTIONS = {
  '': { color: 'default', label: trans('state.property.ALL') },
  [PropertyState.DONE]: {
    color: 'success',
    label: trans('state.property.DONE')
  },
  [PropertyState.OPEN]: {
    color: 'warning',
    label: trans('state.property.OPEN')
  },
  [PropertyState.PROGRESS]: {
    color: 'primary',
    label: trans('state.property.PROGRESS')
  },
  [PropertyState.CANCELED]: {
    color: 'error',
    label: trans('state.property.CANCELED')
  }
}
