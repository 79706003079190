import DashboardLayout from '@/layouts/dashboard'
import { trans } from '@/utils/translation'
import { OwnerDetailView } from '@/sections/owner/view'
import { Head } from '@inertiajs/react'

const OwnerDetailPage = () => {
  return (
    <>
      <Head>
        <title>{trans('general.owner')}</title>
      </Head>
      <OwnerDetailView />
    </>
  )
}
OwnerDetailPage.layout = (page) => <DashboardLayout>{page}</DashboardLayout>
export default OwnerDetailPage
