import { createInertiaApp } from '@inertiajs/react'
import { createRoot } from 'react-dom/client'
import '../styles/app.scss'
import { ErrorBoundary } from 'react-error-boundary'
import { log } from './utils/log'
import PropTypes from 'prop-types'

const Root = ({ children }) => {
  // Do not hide errors on development
  if (document.location.hostname === 'localhost') {
    return children
  }

  return (
    <ErrorBoundary
      fallback={<></>}
      onError={async (error, info) => {
        await log(`FRONTEND ERROR: ${error.name}: ${error.message}`, info)
        document.location.href = '/error_500'
      }}
    >
      {children}
    </ErrorBoundary>
  )
}

Root.propTypes = {
  children: PropTypes.node
}

createInertiaApp({
  title: (title) => `${title} - AssetApp`,
  resolve: (name) => require(`./pages/${name}`),
  setup({ el, App, props }) {
    createRoot(el).render(
      <Root>
        <App {...props} />
      </Root>
    )
  },
  progress: { color: '#4B5563', delay: 50, includeCSS: true, showSpinner: false }
}).catch((e) => console.debug(e))
