import { Typography } from '@mui/material'
import { trans } from '@/utils/translation'
import AppointmentListTable from '@/sections/appointment/appointment-list-table'

export default function AppointmentListView() {
  return (
    <>
      <Typography variant="h5" gutterBottom>
        {trans('appointment.header')}
      </Typography>
      <AppointmentListTable />
    </>
  )
}
