import { trans } from '@/utils/translation'
import { formatDate } from '@/utils/format'
import AppointmentState from '@/constants/AppointmentState'
import AppointmentStateLabel from '@/sections/appointment/appointment-state-label'

export const COLUMNS = [
  {
    accessorFn: (row) => trans(`appointment.type.${row.type}`),
    header: trans('field.type'),
    size: 250,
    id: 'type'
  },
  {
    accessorFn: (row) => row.assignment.property?.label,
    header: trans('general.property'),
    size: 250,
    id: 'property'
  },
  {
    accessorFn: (row) => formatDate(row.begin),
    header: trans('field.date'),
    size: 150,
    id: 'begin'
  },
  {
    accessorKey: 'state',
    header: trans('field.state'),
    // eslint-disable-next-line react/prop-types
    Cell: ({ renderedCellValue }) => <AppointmentStateLabel state={renderedCellValue} />,
    size: 150,
    enableSorting: false
  }
]

export const STATUS_OPTIONS = {
  '': { color: 'default', label: trans('state.user.ALL') },
  [AppointmentState.OPEN]: {
    color: 'primary',
    label: trans('state.appointment.OPEN')
  },
  [AppointmentState.CONFIRMED]: {
    color: 'success',
    label: trans('state.appointment.CONFIRMED')
  },
  [AppointmentState.AUTO_CONFIRMED]: {
    color: 'success',
    label: trans('state.appointment.AUTO_CONFIRMED')
  },
  [AppointmentState.CANCELED]: {
    color: 'error',
    label: trans('state.appointment.CANCELED')
  },
  [AppointmentState.DECLINED]: {
    color: 'info',
    label: trans('state.appointment.DECLINED')
  },
  [AppointmentState.DONE]: {
    color: 'success',
    label: trans('state.appointment.DONE')
  }
}
