import { m } from 'framer-motion'

import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Avatar from '@mui/material/Avatar'
import Divider from '@mui/material/Divider'
import { alpha } from '@mui/material/styles'
import MenuItem from '@mui/material/MenuItem'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'

import CustomPopover, { usePopover } from '@/components/custom-popover'
import { varHover } from '@/components/animate/variants/actions'
import { router, usePage } from '@inertiajs/react'
import { useModalContext } from '@/context/use-modal-context'
import { useMemo } from 'react'
import { trans } from '@/utils/translation'
import { avatar } from '@/utils/misc'
import UserSelectModal from '@/sections/user/user-select-modal'
import { ToastCtl } from '@/utils/toast-ctl'
import Iconify from '@/components/iconify'
import { ListItemIcon } from '@mui/material'
import ListItemText from '@mui/material/ListItemText'
import UserState from '@/constants/UserState'

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const { auth, originalAuth } = usePage().props
  const popover = usePopover()
  const { openModal } = useModalContext()

  const OPTIONS = useMemo(
    () => [
      {
        label: originalAuth ? trans('menu.impersonate_back') : trans('menu.impersonate'),
        icon: 'carbon:user-simulation',
        callback: () => {
          originalAuth
            ? router.get(
                '/?_switch_user=_exit',
                {},
                { onSuccess: () => ToastCtl.show(trans('impersonate.validation.user_exit'), 'success') }
              )
            : openModal(
                'userSelect',
                <UserSelectModal
                  callback={(selected) => {
                    if (selected.state !== UserState.ACTIVE) {
                      ToastCtl.show(trans('impersonate.validation.user_inactive'), 'error')
                      return
                    }

                    router.get(
                      `/?_switch_user=${selected.email}`,
                      {},
                      {
                        onSuccess: () => ToastCtl.show(trans('impersonate.validation.user_found'), 'success')
                      }
                    )

                    return true
                  }}
                />,
                {
                  maxWidth: 'lg'
                }
              )
        },
        ROLE: 'ROLE_ADMIN'
      },
      {
        label: trans('menu.settings'),
        icon: 'carbon:settings',
        callback: () => router.get('/user')
      }
    ],
    [auth]
  )

  return (
    <>
      <IconButton
        component={m.button}
        whileTap="tap"
        whileHover="hover"
        variants={varHover(1.05)}
        onClick={popover.onOpen}
        sx={{
          width: 40,
          height: 40,
          background: (theme) => alpha(theme.palette.grey[500], 0.08),
          ...(popover.open && {
            background: (theme) =>
              `linear-gradient(135deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`
          })
        }}
      >
        <Avatar
          src={avatar(auth.user)}
          sx={{
            width: 36,
            height: 36,
            border: (theme) => `solid 2px ${theme.palette.background.default}`
          }}
        ></Avatar>
      </IconButton>

      <CustomPopover open={popover.open} onClose={popover.onClose} sx={{ width: 220, p: 0 }}>
        <Box sx={{ p: 2, pb: 1.5 }}>
          <Typography variant="subtitle2" noWrap>
            {auth?.user?.name}
          </Typography>

          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {auth?.user.email}
          </Typography>
          {originalAuth && (
            <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
              {trans(`role.${auth?.user.roles[0]}`)}
            </Typography>
          )}
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          {OPTIONS.map((option) => {
            if (
              option?.ROLE &&
              !auth.user.roles.includes(option.ROLE) &&
              !originalAuth?.user?.roles?.includes(option.ROLE)
            )
              return

            return (
              <MenuItem
                key={option.label}
                onClick={() => {
                  option.callback()
                  popover.onClose()
                }}
              >
                <ListItemIcon sx={{ mr: 0 }}>
                  <Iconify icon={option.icon} />
                </ListItemIcon>
                <ListItemText>{option.label}</ListItemText>
              </MenuItem>
            )
          })}
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem
          onClick={() => router.get('/logout')}
          sx={{
            m: 1,
            fontWeight: 'fontWeightBold',
            color: 'error.main'
          }}
        >
          <ListItemIcon sx={{ mr: 0 }}>
            <Iconify icon="carbon:logout" />
          </ListItemIcon>
          <ListItemText>{trans('menu.logout')}</ListItemText>
        </MenuItem>
      </CustomPopover>
    </>
  )
}
