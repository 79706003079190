import { useCallback, useState } from 'react'
import Tabs, { tabsClasses } from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import LogListTable from '@/sections/log/log-list-table'
import MailListTable from '@/sections/log/mail-list-table'

const TABS = [
  {
    value: 'logs',
    label: 'Logs'
  },
  {
    value: 'mails',
    label: 'Mails'
  }
]

export default function LogListView(props) {
  const [currentTab, setCurrentTab] = useState('logs')

  const handleChangeTab = useCallback((event, newValue) => {
    setCurrentTab(newValue)
  }, [])

  return (
    <>
      <Tabs
        value={currentTab}
        onChange={handleChangeTab}
        sx={{
          width: 1,
          bottom: 0,
          zIndex: 9,
          px: 2,
          bgcolor: 'background.paper',
          [`& .${tabsClasses.flexContainer}`]: {
            pr: { md: 3 },
            justifyContent: {
              sm: 'center',
              md: 'flex-start'
            }
          }
        }}
      >
        {TABS.map((tab) => (
          <Tab key={tab.value} value={tab.value} icon={tab.icon} label={tab.label} />
        ))}
      </Tabs>

      {currentTab === 'logs' && <LogListTable {...props} />}
      {currentTab === 'mails' && <MailListTable {...props} />}
    </>
  )
}
