import { useState } from 'react'
import { Button } from '@mui/material'
import { UploadFile } from '@mui/icons-material'
import { useFormContext } from '@/context/use-form-context'
import PropTypes from 'prop-types'
import FilePreview from '@/components/file-thumbnail/file-preview'
import { trans } from '@/utils/translation'
import { ToastCtl } from '@/utils/toast-ctl'

const InertiaFileUpload = ({ name, input, filename, onRemove }) => {
  const { setData, clearErrors } = useFormContext()
  const { label, ...inputProps } = input
  const [file, setFile] = useState({ name: filename })

  const handleRemove = () => {
    setData(name, new File([], 'undefined'))
    setFile({ name: null })

    onRemove ? onRemove() : null
  }

  return (
    <label htmlFor={`upload-${name}`} style={{ display: 'flex', gap: '1rem', alignItems: 'flex-start' }}>
      <input
        style={{ display: 'none' }}
        id={`upload-${name}`}
        name={name}
        type="file"
        onChange={(e) => {
          if (inputProps?.accept && !inputProps.accept.includes(e.target.files[0].type)) {
            ToastCtl.show('Dateityp nicht erlaubt', 'error')
            return
          }

          setData(name, e.target.files[0])
          setFile(e.target.files[0])
          clearErrors(name)
        }}
        {...inputProps}
      />
      {file?.name && <FilePreview label={label} file={file} onRemove={handleRemove} />}
      {!file?.name && (
        <Button
          color="primary"
          variant="outlined"
          component="span"
          startIcon={<UploadFile />}
          style={{ whiteSpace: 'nowrap', height: '55px', width: '100%' }}
        >
          {label} {trans('button.upload')}
        </Button>
      )}
    </label>
  )
}

InertiaFileUpload.propTypes = {
  name: PropTypes.string,
  input: PropTypes.object,
  filename: PropTypes.string,
  onRemove: PropTypes.func
}

export default InertiaFileUpload
