import { Card, CardActions, CardContent, CardHeader, Stack, Typography } from '@mui/material'
import { trans } from '@/utils/translation'
import Button from '@mui/material/Button'
import { router } from '@inertiajs/react'
import PropTypes from 'prop-types'

export default function TaskHeader({ count, showDetail = false }) {
  const background =
    count === 0
      ? 'linear-gradient(135deg, rgba(127,191,134,1) 0%, rgba(55,139,65,1) 100%);'
      : 'linear-gradient(135deg, rgba(209,104,78,1) 0%, rgba(134,68,52,1) 100%);'

  return (
    <Card sx={{ background, height: '100%' }}>
      <CardHeader title={trans('dashboard.tasks.header')} titleTypographyProps={{ color: '#fff' }} />
      <CardContent>
        <Typography variant={'h3'} color={'#fff'}>
          {count}
        </Typography>
      </CardContent>
      {showDetail && (
        <CardActions>
          <Stack flexDirection={'row'} justifyContent={'flex-end'} sx={{ width: '100%' }}>
            <Button onClick={() => router.get('/tasks')} sx={{ color: '#fff' }}>
              {trans('dashboard.tasks.button')}
            </Button>
          </Stack>
        </CardActions>
      )}
    </Card>
  )
}

TaskHeader.propTypes = {
  count: PropTypes.number.isRequired,
  showDetail: PropTypes.bool
}
