import { useMemo, useState } from 'react'
import { trans } from '@/utils/translation'
import { formatDate, formatNumber } from '@/utils/format'
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table'
import { defaultTableOptions } from '@/utils/misc'
import { Card, Stack } from '@mui/material'
import Typography from '@mui/material/Typography'
import CustomButton from '@/components/custom-button'
import MenuItem from '@mui/material/MenuItem'
import { usePage } from '@inertiajs/react'
import Iconify from '@/components/iconify'
import ApartmentChangeModal from '@/sections/apartment/apartment-change-modal'
import { useModalContext } from '@/context/use-modal-context'
import TableConfirmDelete from '@/components/table/table-confirm-delete'
import { useBoolean } from '@/hooks/use-boolean'

export default function ApartmentChangeListTable() {
  const { apartment } = usePage().props
  const { openModal } = useModalContext()
  const confirmRow = useBoolean()
  const [apartmentChange, setApartmentChange] = useState(null)

  const columns = useMemo(
    () => [
      {
        accessorKey: 'fromDate',
        header: trans('apartment.change.from_date'),
        Cell: ({ renderedCellValue }) =>
          formatDate(renderedCellValue) === '01.01.1970' ? '-' : formatDate(renderedCellValue),
        enableSorting: false
      },
      {
        accessorKey: 'size',
        header: trans('apartment.change.size'),
        Cell: ({ renderedCellValue }) => `${formatNumber(renderedCellValue)} m²`,
        enableSorting: false
      },
      {
        accessorKey: 'heatingSize',
        header: trans('apartment.change.heating_size'),
        Cell: ({ renderedCellValue }) => `${formatNumber(renderedCellValue)} m²`,
        enableSorting: false
      },
      {
        accessorKey: 'warmWaterSize',
        header: trans('apartment.change.warm_water_size'),
        Cell: ({ renderedCellValue }) => `${formatNumber(renderedCellValue)} m²`,
        enableSorting: false
      },
      {
        accessorKey: 'additionalCostSize',
        header: trans('apartment.change.additional_cost_size'),
        Cell: ({ renderedCellValue }) => `${formatNumber(renderedCellValue)} m²`,
        enableSorting: false
      },
      {
        accessorKey: 'commercialSize',
        header: trans('apartment.change.commercial_size'),
        Cell: ({ renderedCellValue }) => `${formatNumber(renderedCellValue)} m²`,
        enableSorting: false
      },
      {
        accessorKey: 'ownershipShare',
        header: trans('apartment.change.ownership_share'),
        Cell: ({ renderedCellValue }) => renderedCellValue ?? '-',
        enableSorting: false
      },
      {
        accessorKey: 'personCount',
        header: trans('apartment.change.person_count'),
        Cell: ({ renderedCellValue }) => renderedCellValue ?? '-',
        enableSorting: false
      }
    ],
    []
  )

  const table = useMaterialReactTable({
    ...defaultTableOptions,
    renderTopToolbar: () => (
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} justifyContent={'space-between'} m={2}>
        <Typography variant="h6"> {trans('apartment.change.title')} </Typography>
        <CustomButton
          variant="contained"
          color="primary"
          onClick={() => {
            openModal(
              'apartmentSettingsCreate',
              <ApartmentChangeModal apartmentId={apartment.id} previousSettings={apartment.settings.at(-1)} />
            )
          }}
        >
          + {trans('apartment.change.create')}{' '}
        </CustomButton>
      </Stack>
    ),
    columns,
    defaultColumn: {
      size: 120
    },
    data: apartment.settings,
    enableRowActions: true,
    renderRowActionMenuItems: ({ row }) => [
      <MenuItem
        key="editDate"
        onClick={() => {
          openModal(
            'apartmentSettingsCreate',
            <ApartmentChangeModal apartmentId={apartment?.id} apartmentSettings={row.original} />
          )
        }}
      >
        <Iconify icon="solar:pen-bold" sx={{ mr: 1 }} />
        {trans('table_view.edit_time')}
      </MenuItem>,
      <MenuItem
        key="delete"
        sx={{ color: 'error.main' }}
        disabled={row.original.isLocked}
        onClick={() => {
          setApartmentChange(row.original)
          confirmRow.onTrue()
        }}
      >
        <Iconify icon="solar:trash-bin-trash-bold" sx={{ mr: 1 }} />
        {trans('table_view.delete_time')}
      </MenuItem>
    ]
  })

  return (
    <>
      <Card>
        <MaterialReactTable table={table} />
      </Card>

      <TableConfirmDelete
        url={`/api/apartment_settings/${apartmentChange?.id}`}
        confirmRows={confirmRow}
        setValue={setApartmentChange}
      />
    </>
  )
}
