import { Grid, Stack } from '@mui/material'
import UserData from '@/sections/user/user-data'
import UserAdminFunction from '@/sections/user/user-admin-function'
import UserRentedApartmentsListTable from '@/sections/user/period/user-rented-apartment-list-table'
import { hasRole } from '@/utils/misc'
import Roles from '@/constants/Roles'
import { usePage } from '@inertiajs/react'

export default function TenantDetailPage() {
  const { auth } = usePage().props

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Stack direction={{ md: 'column' }} gap={3}>
            <UserData />
            {hasRole(auth.user, Roles.ROLE_ADMIN) && <UserAdminFunction />}
          </Stack>
        </Grid>

        <Grid item xs={12} md={8}>
          <Stack direction={{ md: 'column' }} gap={3}>
            <UserRentedApartmentsListTable />
          </Stack>
        </Grid>
      </Grid>
    </>
  )
}
