import Typography from '@mui/material/Typography'
import { trans } from '@/utils/translation'
import { FormControlLabel, Radio, RadioGroup } from '@mui/material'
import { useFormContext } from '@/context/use-form-context'
import PropTypes from 'prop-types'

export default function InertiaToggleButton({ name, input, left = {}, right = {} }) {
  const { data, setData, errors } = useFormContext()
  const { label } = input

  return (
    <>
      <Typography variant="body2">{label}</Typography>
      <RadioGroup
        sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', justifyContent: 'flex-start', mt: 1 }}
      >
        <FormControlLabel
          sx={(theme) => ({
            flexGrow: 1,
            justifyContent: 'center',
            px: 4,
            py: 2,
            ml: 0,
            mr: 2,
            border: '1px solid rgba(33, 43, 54, 0.08)',
            borderRadius: '12px',
            ...(data[name] === true && { border: `1px solid ${theme.palette.primary.main}` })
          })}
          control={<Radio sx={{ display: 'none' }} />}
          label={trans('general.yes')}
          onClick={() => setData(name, true)}
          {...left}
        />
        <FormControlLabel
          sx={(theme) => ({
            flexGrow: 1,
            justifyContent: 'center',
            px: 4,
            py: 2,
            mx: 0,
            border: '1px solid rgba(33, 43, 54, 0.08)',
            borderRadius: '12px',
            ...(data[name] === false && { border: `1px solid ${theme.palette.primary.main}` })
          })}
          control={<Radio sx={{ display: 'none' }} />}
          label={trans('general.no')}
          onClick={() => setData(name, false)}
          {...right}
        />
      </RadioGroup>
      <span className="validation-error">{errors[name]}</span>
    </>
  )
}

InertiaToggleButton.propTypes = {
  name: PropTypes.string.isRequired,
  input: PropTypes.object.isRequired,
  left: PropTypes.object,
  right: PropTypes.object
}
