import { alpha, useTheme } from '@mui/material/styles'
import { trans } from '@/utils/translation'
import Typography from '@mui/material/Typography'
import { Card, Stack } from '@mui/material'
import { bgGradient } from '@/theme/css'
import Box from '@mui/material/Box'
import AppointmentStateLabel from '@/sections/appointment/appointment-state-label'
import AppointmentDetailPage from '@/sections/appointment/appointment-detail-page'
import { formatDate } from '@/utils/format'
import { usePage } from '@inertiajs/react'

export default function AppointmentDetailView() {
  const { appointment } = usePage().props
  const theme = useTheme()

  return (
    <>
      <Typography variant={'h5'} gutterBottom>
        {trans('appointment.header')}
      </Typography>

      <Card
        sx={{
          mb: 3,
          height: 240,
          flexGrow: 10
        }}
      >
        <Box
          sx={{
            height: 1,
            color: 'common.white',
            ...bgGradient({
              direction: 'to right',
              color: alpha(theme.palette.primary.lighter, 0.1),
              imgUrl: require('../../../../images/Cover/Liegenschaft.jpg'),
              startColor: alpha(theme.palette.primary.darker, 0.4)
            })
          }}
        >
          <Stack direction={'column'} justifyContent={'space-between'} height={'100%'}>
            <Stack direction={{ xs: 'column', md: 'row' }} alignItems={'end'} justifyContent={'space-between'} p={4}>
              <div></div>
              <Stack direction={'column'} spacing={1}>
                <AppointmentStateLabel state={appointment?.state || 'OPEN'} />
                <Typography variant={'body2'}>
                  {trans('appointment.when_date')} {formatDate(appointment?.stateDate)}
                </Typography>
              </Stack>
            </Stack>
            <Stack direction={'column'} px={4} sx={{ mb: 3 }}>
              <Typography variant={'h6'}>{trans('appointment.title')}</Typography>
            </Stack>
          </Stack>
        </Box>
      </Card>

      <AppointmentDetailPage />
    </>
  )
}
