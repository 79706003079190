import { useModalContext } from '@/context/use-modal-context'
import { DialogActions, DialogTitle, Stack } from '@mui/material'
import { trans } from '@/utils/translation'
import DialogContent from '@mui/material/DialogContent'
import { FormProvider } from '@/context/use-form-context'
import { useForm } from '@/hooks/use-form'
import { ToastCtl } from '@/utils/toast-ctl'
import { router } from '@inertiajs/react'
import PropTypes from 'prop-types'
import InertiaDatePicker from '@/components/form/inertia-date-picker'
import InertiaNumericField from '@/components/form/inertia-numeric-field'
import { LoadingButton } from '@mui/lab'
import CustomButton from '@/components/custom-button'
import InertiaCheckbox from '@/components/form/inertia-checkbox'

export default function TenantPrepaymentModal({ defaultValues }) {
  const { closeModal } = useModalContext()

  const methods = useForm({
    url: {
      method: defaultValues?.id ? 'PUT' : 'POST',
      location: defaultValues?.id
        ? `/api/apartment_tenants_prepayment/${defaultValues.id}/prepayment`
        : `/api/apartment_tenants_prepayment/prepayment`
    },
    defaultValues: {
      apartmentTenant: defaultValues.apartmentTenant,
      apartmentTenantPrepayment: defaultValues.id || null,
      amount: defaultValues.amount || 0,
      fromDate: defaultValues.fromDate || null,
      confirm: false
    }
  })

  const { submit, submitting } = methods

  const handleSubmit = () => {
    submit(() => {
      router.reload()
      ToastCtl.show(trans('general.save_success'), 'success')
      closeModal('tenantPrepayment')
    })
  }

  return (
    <>
      <DialogTitle>
        {defaultValues.id
          ? trans('tenant.rented_apartments.edit_prepayment')
          : trans('tenant.rented_apartments.new_prepayment')}
      </DialogTitle>
      <DialogContent dividers sx={{ py: 1 }}>
        <FormProvider {...methods} sx={{ px: 2, mx: 8 }}>
          <form onSubmit={handleSubmit}>
            <Stack direction={'column'} spacing={2}>
              <InertiaDatePicker
                name={'fromDate'}
                input={{
                  label: trans('tenant.rented_apartments.valid_from'),
                  required: true
                }}
              />
              <InertiaNumericField
                name={'amount'}
                input={{
                  label: trans('tenant.rented_apartments.amount')
                }}
              />
              <InertiaCheckbox
                name={'confirm'}
                input={{
                  label: trans('tenant.rented_apartments.confirm'),
                  required: true
                }}
              />
            </Stack>
          </form>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <CustomButton onClick={() => closeModal('tenantPrepayment')}>{trans('button.cancel')}</CustomButton>
        <LoadingButton
          loading={submitting}
          variant={'contained'}
          onClick={() => {
            handleSubmit()
          }}
        >
          {trans('button.done')}
        </LoadingButton>
      </DialogActions>
    </>
  )
}

TenantPrepaymentModal.propTypes = {
  defaultValues: PropTypes.object
}
