import { useEffect, useState } from 'react'
import useSingletonAxios from '@/hooks/use-singleton-axios'

export function useStateCounts(path, refresh) {
  const [counts, setCounts] = useState({})
  const request = useSingletonAxios()

  const fetchCounts = () =>
    request({
      method: 'GET',
      url: `https://${document.location.hostname}${path}`
    })
      .then((response) => {
        setCounts(response.data.counts)
      })
      .catch(() => setCounts({ ALL: 0 }))

  useEffect(() => {
    fetchCounts()
  }, [refresh])

  return counts
}
