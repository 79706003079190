import { Card, CardContent } from '@mui/material'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { alpha, useTheme } from '@mui/material/styles'
import Link from '@mui/material/Link'
import Carousel, { CarouselDots, useCarousel } from '@/components/carousel'
import Image from '@/components/image'
import { trans } from '@/utils/translation'

export default function DashboardInfo({ list, ...other }) {
  const carousel = useCarousel({
    speed: 800,
    autoplay: true,
    infinite: list.length > 1, //to avoid duplicating when there is only one item
    ...CarouselDots({
      sx: {
        right: 20,
        bottom: 20,
        position: 'absolute',
        color: 'primary.light'
      }
    })
  })

  return (
    <Card {...other}>
      <Carousel {...carousel.carouselSettings}>
        {list.map((item) => (
          <CarouselItem key={item.id} item={item} />
        ))}
      </Carousel>
    </Card>
  )
}

DashboardInfo.propTypes = {
  list: PropTypes.array
}

function CarouselItem({ item }) {
  const theme = useTheme()

  const { coverUrl, title, link, header } = item

  const renderImg = (
    <Image
      alt={title}
      src={coverUrl}
      overlay={`linear-gradient(to bottom, ${alpha(theme.palette.grey[900], 0)} 0%, ${theme.palette.grey[900]} 90%)`}
      sx={{
        width: 1,
        height: { xs: 280, xl: 320 }
      }}
    />
  )

  return (
    <Box sx={{ position: 'relative' }}>
      <CardContent
        sx={{
          left: 0,
          width: 1,
          bottom: 0,
          zIndex: 9,
          textAlign: 'left',
          position: 'absolute',
          color: 'common.white'
        }}
      >
        <Typography
          variant="overline"
          sx={{
            color: theme.palette.secondary.light,
            fontWeight: 600
          }}
        >
          {header}
        </Typography>

        <Typography noWrap variant="h5" sx={{ mt: 1, mb: 3 }}>
          {title}
        </Typography>

        <Link color="inherit" underline="none" href={link} target="_blank">
          <Typography variant="subtitle2" noWrap>
            {trans('dashboard.dashboard_info.link')}{' '}
          </Typography>
        </Link>
      </CardContent>

      {renderImg}
    </Box>
  )
}

CarouselItem.propTypes = {
  item: PropTypes.object
}
