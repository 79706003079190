import { trans } from '@/utils/translation'
import { Card, CardHeader, Grid, Stack } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import Box from '@mui/material/Box'
import { FormProvider } from '@/context/use-form-context'
import { router, usePage } from '@inertiajs/react'
import { useForm } from '@/hooks/use-form'
import { ToastCtl } from '@/utils/toast-ctl'
import InertiaTextField from '@/components/form/inertia-textfield'
import InertiaCheckbox from '@/components/form/inertia-checkbox'
import { useModalContext } from '@/context/use-modal-context'
import AssignmentChooseUser from '@/sections/assignment/assignment-choose-user'
import Roles from '@/constants/Roles'
import { hasRole } from '@/utils/misc'
import InertiaAutocomplete from '@/components/form/inertia-autocomplete'

export default function AssignmentProcessStep4() {
  const { assignment, auth } = usePage().props
  const { openModal } = useModalContext()

  const methods = useForm({
    url: {
      method: 'PUT',
      location: `/api/assignments/${assignment.id}/step-4`
    },
    defaultValues: {
      existingUserId: null,
      company: assignment?.user?.company ?? '',
      name: assignment?.user?.name ?? auth.user.name,
      email: assignment?.user?.email ?? auth.user.email,
      role: Roles.ROLE_PROPERTY_OWNER,
      address: {
        street: auth.user.address?.street,
        streetNumber: auth.user.address?.streetNumber,
        postcode: auth.user.address?.postcode,
        city: auth.user.address?.city
      },
      billingAddress: {
        name: '',
        company: '',
        street: '',
        streetNumber: '',
        postcode: '',
        city: ''
      }
    }
  })

  const { submit, submitting, data, setData, setOverrideFieldValues } = methods

  const handleSubmit = () => {
    submit((response) => {
      router.get(`/assignment/${response.data.id}/step-5`)
      ToastCtl.show(trans('general.save_success'), 'success')
    })
  }

  const handleUserSelect = (user) => {
    setData('existingUserId', user.id)
    setData('company', user.company)
    setData('name', user.name)
    setData('address.street', user.address?.street)
    setData('address.streetNumber', user.address?.streetNumber)
    setData('address.postcode', user.address?.postcode)
    setData('address.city', user.address?.city)
    setData('email', user.email)
    setOverrideFieldValues({
      company: user.company,
      name: user.name,
      address: {
        street: user?.address?.street,
        streetNumber: user?.address?.streetNumber,
        postcode: user?.address?.postcode,
        city: user?.address?.city
      },
      email: user.email
    })
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit}>
        <Box sx={{ maxWidth: '400px', mx: 'auto' }}>
          <Card>
            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'baseline'} sx={{ mt: 1 }}>
              <CardHeader title={trans('assignment.customize.your_data')} />
              {hasRole(auth.user, Roles.ROLE_ADMIN) && (
                <LoadingButton
                  variant={'contained'}
                  sx={{ mr: 3, my: 2 }}
                  onClick={() =>
                    openModal('chooseUser', <AssignmentChooseUser callback={handleUserSelect} />, { maxWidth: 'lg' })
                  }
                >
                  {trans('assignment.customize.choose_customer')}
                </LoadingButton>
              )}
            </Stack>
            <Stack direction={'column'} spacing={2} sx={{ mx: 3, mt: 1 }}>
              <InertiaTextField
                name={'company'}
                input={{
                  label: trans('field.company'),
                  disabled: data.existingUserId
                }}
              />
              <InertiaTextField
                name={'name'}
                input={{
                  label: trans('field.name'),
                  required: true,
                  disabled: data.existingUserId
                }}
              />
              <Grid container spacing={2}>
                <Grid item xs={12} md={7}>
                  <InertiaTextField
                    name={'address.street'}
                    input={{
                      label: trans('field.street'),
                      required: true,
                      disabled: data.existingUserId
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={5}>
                  <InertiaTextField
                    name={'address.streetNumber'}
                    input={{
                      label: trans('field.street_number'),
                      required: true,
                      disabled: data.existingUserId
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={5}>
                  <InertiaTextField
                    name={'address.postcode'}
                    input={{
                      label: trans('field.zip'),
                      required: true,
                      disabled: data.existingUserId
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={7}>
                  <InertiaTextField
                    name={'address.city'}
                    input={{
                      label: trans('field.city'),
                      required: true,
                      disabled: data.existingUserId
                    }}
                  />
                </Grid>
              </Grid>
              <InertiaTextField
                name={'email'}
                input={{
                  label: trans('field.email'),
                  required: true,
                  disabled: auth.user?.email && !hasRole(auth.user, Roles.ROLE_ADMIN)
                }}
              />
              {hasRole(auth.user, Roles.ROLE_ADMIN) && !data.existingUserId && (
                <InertiaAutocomplete
                  name={'role'}
                  input={{
                    label: trans('field.role')
                  }}
                  items={[
                    { id: Roles.ROLE_PROPERTY_OWNER, label: trans(`role.${Roles.ROLE_PROPERTY_OWNER}`) },
                    { id: Roles.ROLE_PROPERTY_ADMIN, label: trans(`role.${Roles.ROLE_PROPERTY_ADMIN}`) }
                  ]}
                />
              )}
              <InertiaCheckbox
                name={'secondAddress'}
                input={{
                  label: trans('assignment.customize.second_address')
                }}
                onChange={() => setData('secondAddress', !data.secondAddress)}
              />
              {data.secondAddress && (
                <>
                  <InertiaTextField
                    name={'billingAddress.company'}
                    input={{
                      label: trans('field.company')
                    }}
                  />
                  <InertiaTextField
                    name={'billingAddress.name'}
                    input={{
                      label: trans('field.name'),
                      required: true
                    }}
                  />
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={7}>
                      <InertiaTextField
                        name={'billingAddress.street'}
                        input={{
                          label: trans('field.street'),
                          required: true
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={5}>
                      <InertiaTextField
                        name={'billingAddress.streetNumber'}
                        input={{
                          label: trans('field.street_number'),
                          required: true
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={5}>
                      <InertiaTextField
                        name={'billingAddress.postcode'}
                        input={{
                          label: trans('field.zip'),
                          required: true
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={7}>
                      <InertiaTextField
                        name={'billingAddress.city'}
                        input={{
                          label: trans('field.city'),
                          required: true
                        }}
                      />
                    </Grid>
                  </Grid>
                </>
              )}
              <InertiaCheckbox
                name={'isConfirmed'}
                input={{
                  label: trans('assignment.customize.confirm')
                }}
                onChange={() => setData('isConfirmed', !data.isConfirmed)}
              />
            </Stack>
            <Box sx={{ display: 'flex', flexDirection: 'row', py: 2, mx: 3, justifyContent: 'space-between' }}>
              <LoadingButton variant={'outlined'} onClick={() => router.get(`/assignment/${assignment.id}/step-3`)}>
                {trans('button.back')}
              </LoadingButton>
              <LoadingButton loading={submitting} variant={'contained'} onClick={handleSubmit}>
                {trans('assignment.customize.submit')}
              </LoadingButton>
            </Box>
          </Card>
        </Box>
      </form>
    </FormProvider>
  )
}
