import React, { useCallback, useState } from 'react'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import Iconify from '@/components/iconify'
import Typography from '@mui/material/Typography'
import { trans } from '@/utils/translation'
import UserProfileForm from '../user-profile-form'
import { Alert, Card, Grid, Stack } from '@mui/material'
import UserProfileEmailForm from '../user-profile-email-form'
import UserProfilePasswordForm from '../user-profile-password-form'
import UserProfileNotificationForm from '../user-profile-notification-form'

// ----------------------------------------------------------------------

const TABS = [
  {
    value: 'general',
    label: trans('user.tabs.general'),
    icon: <Iconify icon="solar:user-id-bold" width={24} />
  },
  {
    value: 'accessData',
    label: trans('user.tabs.accessData'),
    icon: <Iconify icon="ic:round-vpn-key" width={24} />
  },
  {
    value: 'notifications',
    label: trans('user.tabs.notifications'),
    icon: <Iconify icon="solar:bell-bing-bold" width={24} />
  }
]

// ----------------------------------------------------------------------

export default function AccountView() {
  const [currentTab, setCurrentTab] = useState('general')

  const handleChangeTab = useCallback((event, newValue) => {
    setCurrentTab(newValue)
  }, [])

  return (
    <>
      <Typography variant={'h5'} gutterBottom>
        Benutzerdaten
      </Typography>
      <Tabs
        value={currentTab}
        onChange={handleChangeTab}
        sx={{
          mb: { xs: 3, md: 5 }
        }}
      >
        {TABS.map((tab) => (
          <Tab key={tab.value} label={tab.label} icon={tab.icon} value={tab.value} />
        ))}
      </Tabs>

      <Grid container>
        <Grid item xs={12} md={8}>
          {currentTab === 'general' && <UserProfileForm />}

          {currentTab === 'accessData' && (
            <Stack component={Card} spacing={3} sx={{ p: 3 }}>
              <Alert severity={'warning'}>{trans('user.logout_hint')}</Alert>
              <UserProfileEmailForm />
              <UserProfilePasswordForm />
            </Stack>
          )}

          {currentTab === 'notifications' && <UserProfileNotificationForm />}
        </Grid>
      </Grid>
    </>
  )
}
