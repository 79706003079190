import { useMemo } from 'react'
import { trans } from '@/utils/translation'
import { Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table'
import { defaultTableOptions, handleErrorResponse } from '@/utils/misc'
import Typography from '@mui/material/Typography'
import CustomButton from '@/components/custom-button'
import Iconify from '@/components/iconify'
import { router, usePage } from '@inertiajs/react'
import IconButton from '@mui/material/IconButton'
import { formatCurrency, formatDate } from '@/utils/format'
import { useModalContext } from '@/context/use-modal-context'
import BillingAdditionalCostModal from '@/sections/billing/billing-additional-cost-modal'
import CollapseCard from '@/components/collapse-card'
import OverlayCard from '@/sections/billing/components/overlay-card'
import useSingletonAxios from '@/hooks/use-singleton-axios'
import Paper from '@mui/material/Paper'
import PropTypes from 'prop-types'

export default function BillingAdditionalCostTable({ expanded, onExpand, editForbidden }) {
  const { billing } = usePage().props
  const { openModal } = useModalContext()
  const request = useSingletonAxios()

  const columns = useMemo(
    () => [
      {
        accessorFn: (row) => row.additionalCostType.label,
        header: trans('billing.detail.additional_cost.columns.cost_type'),
        size: 100,
        enableSorting: false
      },
      {
        accessorFn: (row) => row.alternativeCost ?? '-',
        header: trans('billing.detail.additional_cost.columns.alternative_cost'),
        size: 150,
        enableSorting: false,
        enableEditing: true
      },
      {
        accessorFn: (row) => formatDate(row.date),
        header: trans('billing.detail.additional_cost.columns.date'),
        size: 100,
        enableSorting: false
      },
      {
        accessorFn: (row) => row.levy?.label,
        header: trans('billing.detail.additional_cost.columns.levy'),
        size: 100,
        enableSorting: false
      },
      {
        accessorFn: (row) => formatCurrency(row.grossAmount),
        header: trans('billing.detail.additional_cost.columns.brutto'),
        size: 100,
        enableSorting: false
      }
    ],
    []
  )

  const table = useMaterialReactTable({
    ...defaultTableOptions,
    renderTopToolbar: () => <></>,
    columns,
    data: billing?.additionalCosts,
    enableRowActions: !editForbidden,
    renderRowActions: ({ row }) => (
      <Stack direction={{ md: 'row', sm: 'column' }} spacing={1}>
        <IconButton
          key={'editAdditionalCost'}
          onClick={() =>
            openModal(
              'billingAdditionalCostModal',
              <BillingAdditionalCostModal billingId={billing.id} billingAdditionalCost={row.original} />
            )
          }
        >
          <Iconify icon="solar:pen-bold" />
        </IconButton>
        <IconButton
          key={'delete'}
          sx={{ color: 'error.main' }}
          onClick={() => {
            request({
              method: 'DELETE',
              url: `/api/billing_additional_cost/${row.original.id}`
            })
              .then(() => {
                router.reload()
              })
              .catch((e) => handleErrorResponse(e))
          }}
        >
          <Iconify icon="solar:trash-bin-trash-bold" />
        </IconButton>
      </Stack>
    ),
    renderDetailPanel: ({ row }) => {
      return (
        <Stack spacing={2} alignItems={'start'}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: '9%' }}>{trans('billing.detail.additional_cost.modal.group')}</TableCell>
                  <TableCell sx={{ width: '9%' }}>{trans('billing.detail.additional_cost.modal.tax_rate')}</TableCell>

                  <TableCell sx={{ width: '9%' }}>{trans('billing.detail.additional_cost.modal.netto')}</TableCell>
                  {row.original.isWork && (
                    <TableCell sx={{ width: '9%' }}>
                      {trans('billing.detail.additional_cost.modal.work_amount')}
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell sx={{ width: '9%' }}>{row.original.additionalCostType?.costGroup}</TableCell>
                  <TableCell sx={{ width: '9%' }}>{row.original.taxRate}%</TableCell>
                  <TableCell sx={{ width: '9%' }}>{formatCurrency(row.original.netAmount)}</TableCell>
                  {row.original.isWork && (
                    <TableCell sx={{ width: '9%' }}>{formatCurrency(row.original.workAmount)}</TableCell>
                  )}
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
      )
    }
  })

  return (
    <>
      <OverlayCard>
        <CollapseCard
          title={trans('billing.detail.additional_cost.title')}
          header={
            <Stack direction={'row'} spacing={2} alignItems={'center'}>
              <Typography variant="body2">
                {formatCurrency(billing?.additionalCosts?.reduce((acc, curr) => acc + curr.grossAmount, 0))}{' '}
              </Typography>
              <CustomButton
                variant="contained"
                color="primary"
                disabled={editForbidden}
                onClick={() =>
                  openModal('billingAdditionalCostModal', <BillingAdditionalCostModal billingId={billing.id} />)
                }
              >
                + {trans('billing.detail.additional_cost.add')}
              </CustomButton>
            </Stack>
          }
          expand={expanded}
          onExpand={onExpand}
        >
          <MaterialReactTable table={table} />
        </CollapseCard>
      </OverlayCard>
    </>
  )
}

BillingAdditionalCostTable.propTypes = {
  expanded: PropTypes.bool,
  onExpand: PropTypes.func,
  editForbidden: PropTypes.bool
}
