import PropTypes from 'prop-types'

import Stack from '@mui/material/Stack'
import Container from '@mui/material/Container'
import ThemeProvider from '../theme'

// ----------------------------------------------------------------------

export default function CompactLayout({ children }) {
  return (
    <ThemeProvider>
      <Container component="main">
        <Stack
          sx={{
            m: 'auto',
            maxWidth: 400,
            minHeight: '90vh',
            textAlign: 'center',
            justifyContent: 'center'
          }}
        >
          {children}
        </Stack>
      </Container>
    </ThemeProvider>
  )
}

CompactLayout.propTypes = {
  children: PropTypes.node
}
