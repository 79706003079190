import DashboardLayout from '@/layouts/dashboard'
import { trans } from '@/utils/translation'
import { Head } from '@inertiajs/react'
import { BillingDetailView } from '@/sections/billing/view'

const BillingDetailPage = () => {
  return (
    <>
      <Head>
        <title>{trans('billing.title')}</title>
      </Head>
      <BillingDetailView />
    </>
  )
}
BillingDetailPage.layout = (page) => <DashboardLayout>{page}</DashboardLayout>
export default BillingDetailPage
