import { bgGradient } from '@/theme/css'
import { alpha, useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import { Card, Stack } from '@mui/material'
import Typography from '@mui/material/Typography'
import { usePage } from '@inertiajs/react'
import { trans } from '@/utils/translation'
import DeviceStateLabel from './device-state-label'

export default function Device() {
  const { device } = usePage().props
  const theme = useTheme()

  const detailImage = device.deviceImages?.find((image) => image.type === 'DETAIL')

  return (
    <>
      <Card sx={{ height: '250px' }}>
        <Box
          sx={{
            ...bgGradient({
              color: alpha(theme.palette.grey[700], 0.3),
              imgUrl: detailImage ? detailImage.imageUrl : require(`../../../images/devices/${device.type}.jpg`)
            }),
            height: '100%',
            color: 'common.white'
          }}
        >
          <Stack
            direction={{ xs: 'column', md: 'row' }}
            alignItems={'start'}
            justifyContent={'space-between'}
            p={2}
            mx={{ md: 2 }}
          >
            {/*<Iconify icon="solar:camera-add-bold" width={32} />*/}
            <div></div>
            <DeviceStateLabel variant={'filled'} state={device.state} />
          </Stack>
          <Stack direction={'column'} p={2} sx={{ position: 'absolute', bottom: 0 }}>
            <Typography variant={'subtitle2'}>{device.number}</Typography>
            <Typography variant={'caption'}>{trans(`device.type.${device.type}`)}</Typography>
          </Stack>
        </Box>
      </Card>
    </>
  )
}
