import PropTypes from 'prop-types'

import Box from '@mui/material/Box'
import { alpha } from '@mui/material/styles'
import { Typography } from '@mui/material'
import ListItemText from '@mui/material/ListItemText'
import { formatSize } from '@/utils/format'
import IconButton from '@mui/material/IconButton'
import Iconify from '@/components/iconify'
import FileThumbnail from '@/components/file-thumbnail/file-thumbnail'

export default function FilePreview({ file, label, onRemove }) {
  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        gap: '1rem',
        alignItems: 'center',
        justifyContent: 'space-between',
        py: 1,
        px: 1.5,
        width: '100%',
        borderRadius: 1,
        border: (theme) => `solid 1px ${alpha(theme.palette.grey[500], 0.16)}`
      }}
    >
      <Typography
        variant={'caption'}
        sx={(theme) => ({
          position: 'absolute',
          top: '-10px',
          background: '#fff',
          px: 1,
          fontWeight: 600,
          color: theme.palette.grey[600]
        })}
      >
        {label}
      </Typography>
      <FileThumbnail file={file} />
      <ListItemText
        primary={file?.name}
        secondary={formatSize(file?.size)}
        primaryTypographyProps={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap'
        }}
        secondaryTypographyProps={{
          component: 'span',
          typography: 'caption'
        }}
      />

      <IconButton size="small" onClick={onRemove}>
        <Iconify icon="mingcute:close-line" width={16} />
      </IconButton>
    </Box>
  )
}

FilePreview.propTypes = {
  file: PropTypes.object,
  label: PropTypes.string,
  onRemove: PropTypes.func
}
