import { Card, CardHeader } from '@mui/material'
import Box from '@mui/material/Box'
import { trans } from '@/utils/translation'
import Typography from '@mui/material/Typography'
import CustomButton from '@/components/custom-button'
import { LoadingButton } from '@mui/lab'
import { router, usePage } from '@inertiajs/react'
import { useForm } from '@/hooks/use-form'
import { ToastCtl } from '@/utils/toast-ctl'

export default function AppointmentStep3() {
  const { appointment } = usePage().props

  const methods = useForm({
    url: {
      method: 'PUT',
      location: `/api/appointments/${appointment.id}/accept`
    }
  })

  const { submit, submitting } = methods

  const handleSubmit = () => {
    submit(() => {
      router.get(`/appointments/${appointment.id}/detail`)
      ToastCtl.show(trans('general.save_success'), 'success')
    })
  }

  return (
    <Box sx={{ maxWidth: '400px', mx: 'auto' }}>
      <Card>
        <CardHeader title={trans('appointment.print.title')} />
        <Typography variant={'body2'} sx={{ mx: 3, my: 2 }}>
          {trans('appointment.print.note')}
        </Typography>
        <CustomButton
          variant={'contained'}
          sx={{ mb: 3, mx: 3 }}
          onClick={() => {
            window.open(`/documents/${appointment.document.id}/view`, '_blank')
          }}
        >
          {trans('appointment.print.button')}
        </CustomButton>

        <Box sx={{ display: 'flex', flexDirection: 'row', py: 2, mx: 3, mt: 6, justifyContent: 'end' }}>
          <LoadingButton
            variant={'outlined'}
            sx={{ mr: 1 }}
            onClick={() => router.get(`/appointments/${appointment.id}/step-2`)}
          >
            {trans('button.back')}
          </LoadingButton>
          <LoadingButton variant={'contained'} loading={submitting} onClick={handleSubmit}>
            {trans('appointment.confirm')}
          </LoadingButton>
        </Box>
      </Card>
    </Box>
  )
}
