import { Card, CardHeader, Stack } from '@mui/material'
import { trans } from '@/utils/translation'
import { usePage } from '@inertiajs/react'
import { useModalContext } from '@/context/use-modal-context'
import Button from '@mui/material/Button'
import ApartmentGenetrateUviModal from '@/sections/apartment/apartment-genetrate-uvi-modal'

export default function ApartmentAdminFunction() {
  const { apartment } = usePage().props
  const { openModal } = useModalContext()

  return (
    <>
      <Card>
        <CardHeader title={trans('apartment.admin_function.title')} />
        <Stack direction="column" justifyContent="space-between" spacing={2} sx={{ p: 3, typography: 'body2' }}>
          <Button
            variant={'contained'}
            onClick={() => openModal('apartmentUvi', <ApartmentGenetrateUviModal apartment={apartment} />)}
          >
            {trans('apartment.admin_function.uvi_generate')}
          </Button>
        </Stack>
      </Card>
    </>
  )
}
