import { useCallback, useRef } from 'react'
import axios from 'axios'
import PropTypes from 'prop-types'

export default function useSingletonAxios() {
  let abortController = useRef()
  const instance = axios.create()

  return useCallback(async (config) => {
    if (abortController.current) abortController.current.abort()

    abortController.current = new AbortController()
    return instance.request({ ...config, signal: abortController.current.signal, timeout: 7500 }).catch((e) => {
      if (axios.isCancel(e)) return

      throw e
    })
  }, [])
}

useSingletonAxios.propTypes = {
  config: PropTypes.object.isRequired
}
