import { useEffect, useMemo, useState } from 'react'
import TextField from '@mui/material/TextField'
import { Tooltip } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import { InfoOutlined, Lock } from '@mui/icons-material'
import { get } from 'lodash'
import { useFormContext } from '@/context/use-form-context'
import { NumericFormat } from 'react-number-format'

const InertiaNumericField = ({ input, name, callback }) => {
  const { data, setDataDebounce, overrideFieldValues, errors } = useFormContext()
  const { tooltip, required, decimalScale, suffix, allowNegative, disabled, maxLength } = input

  const [fieldValue, setFieldValue] = useState(get(data, name))

  useEffect(() => {
    const override = get(overrideFieldValues, name)
    if (typeof override !== 'undefined') {
      setFieldValue(override)
    }
  }, [get(overrideFieldValues, name)])

  return useMemo(() => {
    return (
      <NumericFormat
        isAllowed={(values) => {
          const { floatValue } = values
          if (!floatValue) return true
          return maxLength > 0 ? floatValue.toString().length <= maxLength : true
        }}
        name={name}
        value={fieldValue}
        onValueChange={(values) => {
          let inputValue = values.value
          if (maxLength && inputValue.length > maxLength) {
            inputValue = inputValue.slice(-maxLength)
          }

          const value = isNaN(parseFloat(inputValue)) ? undefined : parseFloat(inputValue)
          setFieldValue(value)
          setDataDebounce(name, value)
        }}
        onBlur={() => {
          callback ? callback(fieldValue) : null
        }}
        thousandSeparator={'.'}
        decimalSeparator={','}
        decimalScale={decimalScale || 2}
        suffix={suffix || ' €'}
        allowNegative={allowNegative || false}
        customInput={TextField}
        error={!!get(errors, name)}
        helperText={get(errors, name)}
        InputProps={{
          endAdornment: (
            <>
              {tooltip ? (
                <Tooltip title={tooltip}>
                  <IconButton>
                    <InfoOutlined />
                  </IconButton>
                </Tooltip>
              ) : (
                ''
              )}
              {disabled ? (
                <Tooltip title={'Bearbeitung deaktiviert'}>
                  <IconButton>
                    <Lock />
                  </IconButton>
                </Tooltip>
              ) : (
                ''
              )}
            </>
          )
        }}
        required={required}
        sx={{
          width: '100%',
          ...input.sx
        }}
        {...input}
      />
    )
  }, [get(errors, name), fieldValue, suffix, disabled])
}
export default InertiaNumericField
