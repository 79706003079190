import { InputAdornment } from '@mui/material'
import Iconify from '@/components/iconify'
import IconButton from '@mui/material/IconButton'
import { Clear } from '@mui/icons-material'
import TextField from '@mui/material/TextField'
import PropTypes from 'prop-types'

export default function ListFilterTextfield({ globalFilter, setGlobalFilter, placeholder }) {
  return (
    <TextField
      fullWidth
      value={globalFilter}
      onChange={(e) => setGlobalFilter(e.target.value)}
      placeholder={placeholder}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
          </InputAdornment>
        ),
        endAdornment: globalFilter?.length > 0 && (
          <IconButton
            size="small"
            onClick={() => {
              setGlobalFilter('')
            }}
          >
            <Clear />
          </IconButton>
        )
      }}
    />
  )
}

ListFilterTextfield.propTypes = {
  globalFilter: PropTypes.string.isRequired,
  setGlobalFilter: PropTypes.func.isRequired,
  placeholder: PropTypes.string
}
