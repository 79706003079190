import DeviceListTable from '../device-list-table'
import { Typography } from '@mui/material'
import { trans } from '@/utils/translation'

export default function DeviceListView(props) {
  return (
    <>
      <Typography variant="h5" gutterBottom>
        {trans('device.header')}
      </Typography>
      <DeviceListTable {...props} />
    </>
  )
}
