// src/theme/index.js

import PropTypes from 'prop-types'
import React, { useEffect, useMemo, useState } from 'react'
import CssBaseline from '@mui/material/CssBaseline'
import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material/styles'
import { palette } from './palette'
import { shadows } from './shadows'
import { typography } from './typography'
import { customShadows } from './custom-shadows'
import { componentsOverrides } from './overrides'
import { deDE } from '@mui/material/locale'
import { usePage } from '@inertiajs/react'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import 'dayjs/locale/de'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { ModalProvider } from '@/context/use-modal-context'
import { SnackbarProvider } from 'notistack'
import { ToastCtl } from '@/utils/toast-ctl'
import { Dialog } from '@mui/material'

export default function ThemeProvider({ children }) {
  const { flash } = usePage().props
  const [modals, setModals] = useState([])

  const modal = useMemo(
    () => ({
      openModal(key, node, options = {}) {
        const close = () => {
          setModals((modals) => {
            return modals.filter((modal) => modal.key !== key)
          })
        }

        const modal = (
          <Dialog fullWidth key={key} onClose={close} open={true} {...options}>
            {node}
          </Dialog>
        )
        setModals((modals) => [...modals, modal])
      },
      closeAllModals() {
        setModals([])
      },
      closeModal(key) {
        setModals((modals) => {
          return modals.filter((modal) => modal.key !== key)
        })
      }
    }),
    []
  )

  useEffect(() => {
    if (flash === undefined) return

    Object.entries(flash).map(([type, messages]) => {
      messages.map((message) => {
        ToastCtl.show(message, type)
      })
    })
  }, [flash])

  const memoizedValue = useMemo(
    () => ({
      palette: palette('light'), // or palette('dark')
      shadows: shadows('light'), // or shadows('dark')
      customShadows: customShadows('light'), // or customShadows('dark')
      shape: { borderRadius: 8 },
      typography
    }),
    []
  )

  const theme = createTheme(memoizedValue, deDE)
  theme.components = componentsOverrides(theme)

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <SnackbarProvider />
        <ModalProvider value={modal}>
          {modals}
          {children}
        </ModalProvider>
      </MuiThemeProvider>
    </LocalizationProvider>
  )
}

ThemeProvider.propTypes = {
  children: PropTypes.node
}
