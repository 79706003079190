import { Head } from '@inertiajs/react'
import { trans } from '@/utils/translation'
import DashboardLayout from '../../layouts/dashboard'
import { DocumentListView } from '../../sections/document/view'

const DocumentListPage = () => {
  return (
    <>
      <Head>
        <title>{trans('document.title')}</title>
      </Head>
      <DocumentListView />
    </>
  )
}
DocumentListPage.layout = (page) => <DashboardLayout>{page}</DashboardLayout>

export default DocumentListPage
