import SimpleLayout from '@/layouts/header-simple'
import { AppointmentStepsView } from '@/sections/appointment/view'
import { Head } from '@inertiajs/react'
import { trans } from '@/utils/translation'

const AppointmentSteps = () => {
  return (
    <>
      <Head>
        <title>{trans('appointment.title')}</title>
      </Head>
      <AppointmentStepsView />
    </>
  )
}

AppointmentSteps.layout = (page) => <SimpleLayout>{page}</SimpleLayout>

export default AppointmentSteps
