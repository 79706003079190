import { useTheme } from '@mui/material/styles'
import { formatPercent } from '@/utils/format'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { Card } from '@mui/material'
import CardHeader from '@mui/material/CardHeader'
import Iconify from '@/components/iconify'
import PropTypes from 'prop-types'
import ProgressItem from '@/sections/dashboard-overview/components/progress-item'
import { trans } from '@/utils/translation'
import { normalizeValues } from '@/utils/chart'
import EmptyItem from '@/sections/dashboard-overview/components/empty-item'

export default function DashboardOverviewComparison({ title, value, empty, previousValue, ...other }) {
  const theme = useTheme()

  let percentageComparison = null

  const [valueNormalized, previousValueNormalized] = normalizeValues(value, previousValue)

  if (value !== null && previousValue !== null && value !== 0 && previousValue !== 0) {
    percentageComparison = (value - previousValue) / previousValue
  }

  return value >= 0 ? (
    <Card {...other}>
      <CardHeader title={title} />
      <Stack direction="row" alignItems="center" sx={{ mt: 2, mb: 1, mx: 3 }}>
        <Iconify
          width={24}
          icon={
            percentageComparison < 0
              ? 'solar:double-alt-arrow-down-bold-duotone'
              : 'solar:double-alt-arrow-up-bold-duotone'
          }
          sx={{
            mr: 1,
            color: 'success.main',
            ...(percentageComparison > 0 && {
              color: 'error.main'
            })
          }}
        />
        <Typography component="div" variant="subtitle2">
          {percentageComparison && (
            <>
              {percentageComparison > 0 && '+'}
              {formatPercent(percentageComparison, 1)}
            </>
          )}
          {!percentageComparison && '-'}
        </Typography>
      </Stack>
      <Stack spacing={1} sx={{ mx: 3, mb: 3 }}>
        {value !== null && (
          <ProgressItem
            progress={valueNormalized}
            value={value}
            color={theme.palette.warning.main}
            sx={{ display: 'flex', justifyContent: 'space-between' }}
          />
        )}
        {value === null && <Typography variant={'body2'}>{trans('dashboard.consumption.data_empty')}</Typography>}
        {previousValue !== null && (
          <ProgressItem
            progress={previousValueNormalized}
            value={previousValue}
            color={theme.palette.grey[400]}
            sx={{ display: 'flex', justifyContent: 'space-between' }}
          />
        )}
        {previousValue === null && (
          <Typography variant={'body2'}>{trans('dashboard.consumption.comparison_data_empty')}</Typography>
        )}
      </Stack>
    </Card>
  ) : (
    <EmptyItem message={empty} />
  )
}

DashboardOverviewComparison.propTypes = {
  percent: PropTypes.number,
  sx: PropTypes.object,
  title: PropTypes.string,
  value: PropTypes.number,
  empty: PropTypes.string,
  previousValue: PropTypes.number
}
