import { DialogActions, DialogTitle, Grid } from '@mui/material'
import { trans } from '@/utils/translation'
import DialogContent from '@mui/material/DialogContent'
import CustomButton from '@/components/custom-button'
import { LoadingButton } from '@mui/lab'
import { useModalContext } from '@/context/use-modal-context'
import { useForm } from '@/hooks/use-form'
import { router } from '@inertiajs/react'
import { ToastCtl } from '@/utils/toast-ctl'
import useSubmit from '@/hooks/use-submit'
import { FormProvider } from '@/context/use-form-context'
import InertiaNumericField from '@/components/form/inertia-numeric-field'
import PropTypes from 'prop-types'
import InertiaDatePicker from '@/components/form/inertia-date-picker'

export default function ApartmentChangeModal({ apartmentId, apartmentSettings, previousSettings }) {
  const { closeModal } = useModalContext()

  const methods = useForm({
    url: {
      method: apartmentSettings?.id ? 'PUT' : 'POST',
      location: apartmentSettings?.id
        ? `/api/apartment_settings/${apartmentSettings.id}/edit`
        : `/api/apartment_settings`
    },
    defaultValues: {
      apartment: apartmentId,
      fromDate: apartmentSettings?.fromDate || new Date(),
      size: apartmentSettings?.size || previousSettings?.size,
      heatingSize: apartmentSettings?.heatingSize || previousSettings?.heatingSize,
      warmWaterSize: apartmentSettings?.warmWaterSize || previousSettings?.warmWaterSize,
      additionalCostSize: apartmentSettings?.additionalCostSize || previousSettings?.additionalCostSize,
      commercialSize:
        apartmentSettings?.commercialSize >= 0 ? apartmentSettings.commercialSize : previousSettings?.commercialSize,
      ownershipShare:
        apartmentSettings?.ownershipShare >= 0 ? apartmentSettings.ownershipShare : previousSettings?.ownershipShare,
      personCount: apartmentSettings?.personCount >= 0 ? apartmentSettings?.personCount : previousSettings?.personCount
    }
  })

  const { setData, setOverrideFieldValues, submit, submitting } = methods

  const handleSubmit = () => {
    submit(() => {
      router.reload()
      ToastCtl.show(trans('general.save_success'), 'success')
      closeModal('apartmentSettingsCreate')
    })
  }

  useSubmit(() => handleSubmit())

  return (
    <>
      <DialogTitle>{trans(apartmentSettings ? 'apartment_change.edit' : 'apartment_change.create')}</DialogTitle>
      <DialogContent dividers sx={{ py: 1 }}>
        <FormProvider {...methods} sx={{ px: 2, mx: 8 }}>
          <form onSubmit={handleSubmit}>
            <Grid container direction={'row'} spacing={2}>
              <Grid item md={6} xs={12}>
                {!apartmentSettings?.isLocked && (
                  <InertiaDatePicker
                    name={'fromDate'}
                    input={{
                      label: trans('general.from'),
                      required: true
                    }}
                  />
                )}
              </Grid>
              <Grid item md={6} xs={12}>
                <InertiaNumericField
                  name={'ownershipShare'}
                  input={{
                    label: trans('field.apartment.ownership_share'),
                    required: true,
                    suffix: ' ',
                    decimalScale: 3
                  }}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <InertiaNumericField
                  name={'size'}
                  input={{
                    label: trans('field.apartment.size'),
                    required: true,
                    suffix: ' m²',
                    decimalScale: 3
                  }}
                  callback={(value) => {
                    if (!apartmentSettings?.id) {
                      setData('heatingSize', value)
                      setData('warmWaterSize', value)
                      setData('additionalCostSize', value)

                      setOverrideFieldValues({
                        heatingSize: value,
                        warmWaterSize: value,
                        additionalCostSize: value
                      })
                    }
                  }}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <InertiaNumericField
                  name={'heatingSize'}
                  input={{
                    label: trans('field.apartment.heating_size'),
                    required: true,
                    suffix: ' m²',
                    decimalScale: 3
                  }}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <InertiaNumericField
                  name={'warmWaterSize'}
                  input={{
                    label: trans('field.apartment.warm_water_size'),
                    required: true,
                    suffix: ' m²',
                    decimalScale: 3
                  }}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <InertiaNumericField
                  name={'additionalCostSize'}
                  input={{
                    label: trans('field.apartment.additional_cost_size'),
                    required: true,
                    suffix: ' m²',
                    decimalScale: 3
                  }}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <InertiaNumericField
                  name={'commercialSize'}
                  input={{
                    label: trans('field.apartment.commercial_size'),
                    required: true,
                    suffix: ' m²',
                    decimalScale: 3
                  }}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <InertiaNumericField
                  name={'personCount'}
                  input={{
                    label: trans('field.apartment.person_count'),
                    required: true,
                    suffix: '',
                    decimalScale: 0
                  }}
                />
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <CustomButton onClick={() => closeModal('apartmentSettingsCreate')}>{trans('button.cancel')}</CustomButton>
        <LoadingButton
          loading={submitting}
          variant={'contained'}
          onClick={() => {
            handleSubmit()
          }}
        >
          {trans('button.done')}
        </LoadingButton>
      </DialogActions>
    </>
  )
}

ApartmentChangeModal.propTypes = {
  apartmentId: PropTypes.string,
  apartmentSettings: PropTypes.object,
  previousSettings: PropTypes.object
}
