import DashboardLayout from '@/layouts/dashboard'
import PropertyDetailView from '@/sections/property/view/property-detail-view'
import { trans } from '@/utils/translation'
import { Head } from '@inertiajs/react'

const PropertyDetailPage = () => {
  return (
    <>
      <Head>
        <title>{trans('property.title')}</title>
      </Head>
      <PropertyDetailView />
    </>
  )
}
PropertyDetailPage.layout = (page) => <DashboardLayout>{page}</DashboardLayout>
export default PropertyDetailPage
