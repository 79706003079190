import { useEffect, useRef, useState } from 'react'
import { log } from '@/utils/log'
import { usePage } from '@inertiajs/react'
import axios from 'axios'
import PropTypes from 'prop-types'

export default function useRemoteTable(
  path,
  searchFields,
  defaultFilter = {},
  defaultSorting = [],
  defaultPagination = { pageIndex: 0, pageSize: 10 }
) {
  const { auth } = usePage().props

  let abortController = useRef()
  const isFirstRender = useRef(true)

  const name = `${document.location.pathname}_${path}_table`
  const [data, setData] = useState([])
  const [isError, setIsError] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [rowCount, setRowCount] = useState(50)

  const [columnFilters, setColumnFilters] = useState(defaultFilter)
  const [globalFilter, setGlobalFilter] = useState('')
  const [columnSorting, setColumnSorting] = useState(defaultSorting)
  const [rowSelection, setRowSelection] = useState([])
  const [refresh, setRefresh] = useState(null)
  const [pagination, setPagination] = useState(defaultPagination)
  const [url, setUrl] = useState(new URL(path, `https://${document.location.hostname}`))

  const fetchData = async () => {
    if (abortController.current) abortController.current.abort()

    abortController.current = new AbortController()

    url.searchParams.set('page', `${pagination.pageIndex + 1}`)
    url.searchParams.set('itemsPerPage', `${pagination.pageSize}`)
    url.searchParams.set('filters', JSON.stringify(columnFilters))
    url.searchParams.set('sorting', JSON.stringify(columnSorting))
    if (searchFields) {
      url.searchParams.set('globalFilter', globalFilter)
      url.searchParams.set('globalFilterFields', searchFields)
    }

    try {
      setUrl(url)
      setIsLoading(true)

      const response = await axios.get(url.href, {
        signal: abortController.current.signal
      })
      const json = await response.data

      setData(json?.['hydra:member'])
      setRowCount(json?.['hydra:totalItems'])
    } catch (error) {
      if (axios.isCancel(error)) return

      setIsError(true)
      setIsLoading(false)
      console.error('e', error)
      await log('Failed to table load data: ', { error, url, auth: auth }, 'error')
      return
    }

    setIsError(false)
    setIsLoading(false)
  }

  const resetAll = () => {
    setColumnFilters(defaultFilter)
    setGlobalFilter('')
    setColumnSorting(defaultSorting)
    setRowSelection([])
  }

  useEffect(() => {
    // Only fetch data after the first render, to avoid fetching data twice
    if (isFirstRender.current) return

    fetchData()
  }, [columnFilters, globalFilter, pagination.pageIndex, pagination.pageSize, columnSorting, refresh])

  useEffect(() => {
    const columnFilters = sessionStorage.getItem(`mrt_columnFilters_${name}`)
    if (columnFilters) {
      setColumnFilters(JSON.parse(columnFilters))
    }

    const globalFilter = sessionStorage.getItem(`mrt_globalFilter_${name}`)
    if (globalFilter) {
      setGlobalFilter(JSON.parse(globalFilter))
    }

    const sorting = sessionStorage.getItem(`mrt_sorting_${name}`)
    if (sorting) {
      setColumnSorting(JSON.parse(sorting))
    }

    const pagination = sessionStorage.getItem(`mrt_pagination_${name}`)
    if (pagination) {
      setPagination(JSON.parse(pagination))
    }

    // If nothing was saved in the session storage, fetch the data on initial render
    if (!columnFilters && !globalFilter && !sorting && !pagination) {
      fetchData()
    }

    isFirstRender.current = false
  }, [])

  useEffect(() => {
    if (isFirstRender.current) return
    sessionStorage.setItem(`mrt_columnFilters_${name}`, JSON.stringify(columnFilters))
  }, [columnFilters])

  useEffect(() => {
    if (isFirstRender.current) return
    sessionStorage.setItem(`mrt_globalFilter_${name}`, JSON.stringify(globalFilter ?? ''))
  }, [globalFilter])

  useEffect(() => {
    if (isFirstRender.current) return
    sessionStorage.setItem(`mrt_sorting_${name}`, JSON.stringify(columnSorting))
  }, [columnSorting])

  useEffect(() => {
    if (isFirstRender.current) return
    sessionStorage.setItem(`mrt_pagination_${name}`, JSON.stringify(pagination))
  }, [pagination])

  return {
    resetAll,
    url,
    data,
    setData,
    isError,
    setIsError,
    isLoading,
    refresh,
    setRefresh,
    setIsLoading,
    rowSelection,
    setRowSelection,
    rowCount,
    setRowCount,
    columnFilters,
    setColumnFilters,
    globalFilter,
    setGlobalFilter,
    columnSorting,
    setColumnSorting,
    pagination,
    setPagination
  }
}

useRemoteTable.propTypes = {
  path: PropTypes.string,
  searchFields: PropTypes.array,
  defaultFilter: PropTypes.object,
  defaultSorting: PropTypes.array
}
