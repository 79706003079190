import { Head } from '@inertiajs/react'
import { trans } from '@/utils/translation'
import DashboardLayout from '../../layouts/dashboard'
import DeviceDetailView from '@/sections/device/view/device-detail-view'

const DeviceListPage = () => {
  return (
    <>
      <Head>
        <title>{trans('device.title')}</title>
      </Head>
      <DeviceDetailView />
    </>
  )
}
DeviceListPage.layout = (page) => <DashboardLayout>{page}</DashboardLayout>

export default DeviceListPage
