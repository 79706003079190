import OwnerListTable from '../owner-list-table'
import { Typography } from '@mui/material'
import { trans } from '@/utils/translation'

export default function OwnerListView(props) {
  return (
    <>
      <Typography variant="h5" gutterBottom>
        {trans('owner.header')}
      </Typography>
      <OwnerListTable {...props} />
    </>
  )
}
