import { Card, CardContent } from '@mui/material'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { alpha, useTheme } from '@mui/material/styles'
import { bgGradient } from '@/theme/css'
import { usePage } from '@inertiajs/react'

export default function DashboardApartmentWidget() {
  const theme = useTheme()
  const { apartment, property } = usePage().props

  const item = apartment?.label ? apartment : property
  const image = apartment?.label ? 'Wohnung.jpg' : 'Liegenschaft.jpg'
  const address = apartment?.label
    ? `${apartment?.address?.street} ${apartment?.address?.streetNumber}, ${apartment?.address?.postcode} ${apartment?.address?.city}`
    : `${property?.address?.postcode} ${property?.address?.city}`

  return (
    <Card sx={{ height: '260px' }}>
      <Box
        sx={{
          position: 'relative',
          height: '100%',
          ...bgGradient({
            color: alpha(theme.palette.grey[700], 0.3),
            imgUrl: require(`../../../images/Cover/${image}`)
          })
        }}
      >
        <CardContent
          sx={{
            left: 0,
            width: 1,
            bottom: 0,
            zIndex: 9,
            textAlign: 'left',
            position: 'absolute',
            color: 'common.white'
          }}
        >
          <Typography variant="h5">{item?.label}</Typography>

          <Typography noWrap variant="overline" sx={{ mt: 1, mb: 3, fontWeight: 'normal', textTransform: 'none' }}>
            {address}
          </Typography>
        </CardContent>
      </Box>
    </Card>
  )
}
