import Typography from '@mui/material/Typography'
import { trans } from '@/utils/translation'
import { usePage } from '@inertiajs/react'
import Box from '@mui/material/Box'
import CustomLink from '@/components/custom-link'
import DocumentListTable from '@/sections/document/document-list-table'
import Divider from '@mui/material/Divider'

export default function DashboardDocumentTable() {
  const { documents } = usePage().props

  return (
    <DocumentListTable
      documents={documents}
      defaultPagination={{ pageSize: 5, pageIndex: 0 }}
      renderTopToolbar={() => (
        <Box m={2}>
          <Typography variant="h6">{trans('dashboard.document.header')}</Typography>
        </Box>
      )}
      renderBottomToolbar={() => (
        <>
          <Divider sx={{ borderStyle: 'dashed' }} />

          <Box m={2}>
            <CustomLink sx={{ float: 'right', textDecoration: 'none', mb: 2 }} href={'/documents'}>
              {trans('dashboard.document.footer')}
            </CustomLink>
          </Box>
        </>
      )}
      enableRowSelection={false}
    />
  )
}
