import { FormProvider } from '@/context/use-form-context'
import { Grid, InputAdornment, Stack } from '@mui/material'
import ListItemText from '@mui/material/ListItemText'
import InertiaTextField from '@/components/form/inertia-textfield'
import { trans } from '@/utils/translation'
import IconButton from '@mui/material/IconButton'
import Iconify from '@/components/iconify'
import { LoadingButton } from '@mui/lab'
import { useForm } from '@/hooks/use-form'
import { useBoolean } from '@/hooks/use-boolean'
import { router, usePage } from '@inertiajs/react'
import { ToastCtl } from '@/utils/toast-ctl'
import useSubmit from '@/hooks/use-submit'

const UserProfilePasswordForm = () => {
  const { auth } = usePage().props

  const password = useBoolean()
  const passwordMethods = useForm({
    url: {
      method: 'PUT',
      location: `/api/users/${auth.user.id}/edit-password`
    },
    defaultValues: {
      oldPassword: '',
      newPassword: '',
      confirmPassword: ''
    }
  })

  const { submit, submitting } = passwordMethods

  const handleSubmit = () => {
    submit(() => {
      router.get(
        '/login',
        {},
        {
          onSuccess: () => {
            setTimeout(() => {
              ToastCtl.show(trans('general.save_success'), 'success')
              ToastCtl.show(trans('general.login_again'), 'success')
            }, 500)
          }
        }
      )
    })
  }

  useSubmit(() => handleSubmit())

  return (
    <FormProvider {...passwordMethods} sx={{ px: 2, mx: 8 }}>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={3} sx={{ p: 3 }}>
          <Grid item xs={12} md={4}>
            <ListItemText
              primary={trans('user.change_password')}
              primaryTypographyProps={{
                typography: 'h6',
                mb: 0.5
              }}
              secondaryTypographyProps={{ component: 'span' }}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <Stack spacing={1} sx={{ p: 3 }}>
              <InertiaTextField
                name={'oldPassword'}
                input={{
                  label: trans('field.current_password'),
                  InputProps: {
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={password.onToggle} edge="end">
                          <Iconify icon={password.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'} />
                        </IconButton>
                      </InputAdornment>
                    )
                  },
                  type: password.value ? 'text' : 'password',
                  required: true
                }}
              />
              <InertiaTextField
                name={'newPassword'}
                input={{
                  label: trans('field.new_password'),
                  InputProps: {
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={password.onToggle} edge="end">
                          <Iconify icon={password.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'} />
                        </IconButton>
                      </InputAdornment>
                    )
                  },
                  helperText: (
                    <Stack component="span" direction="row" alignItems="start" spacing={1}>
                      <Iconify icon="eva:info-fill" width={16} sx={{ mr: 0.5 }} /> {trans('reset_password.description')}
                    </Stack>
                  ),
                  type: password.value ? 'text' : 'password',
                  required: true
                }}
              />
              <InertiaTextField
                name={'confirmPassword'}
                input={{
                  label: trans('field.password_confirm'),
                  InputProps: {
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={password.onToggle} edge="end">
                          <Iconify icon={password.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'} />
                        </IconButton>
                      </InputAdornment>
                    )
                  },
                  type: password.value ? 'text' : 'password',
                  required: true
                }}
              />
            </Stack>
          </Grid>
          <LoadingButton
            variant={'contained'}
            loading={submitting}
            onClick={() => handleSubmit()}
            sx={{ float: 'right', ml: 'auto', mr: 3 }}
          >
            {trans('button.save')}
          </LoadingButton>
        </Grid>
      </form>
    </FormProvider>
  )
}

export default UserProfilePasswordForm
