import { Card, CardHeader, Stack } from '@mui/material'

import IconButton from '@mui/material/IconButton'
import Iconify from '@/components/iconify'
import { trans } from '@/utils/translation'
import PropertyEditMonthlyModal from '@/sections/property/property-edit-monthly-modal'
import { usePage } from '@inertiajs/react'
import CustomLabelValue from '@/components/custom-label-value'
import { useModalContext } from '@/context/use-modal-context'
import { boolToString } from '@/utils/misc'

export default function PropertyConsumptionInfo() {
  const { property } = usePage().props
  const { openModal } = useModalContext()

  return (
    <Card>
      <CardHeader
        title={trans('property.consumption.title')}
        action={
          <IconButton
            onClick={() => openModal('propertyEditMonthly', <PropertyEditMonthlyModal property={property} />)}
          >
            <Iconify icon="solar:pen-bold" />
          </IconButton>
        }
        sx={{ flexWrap: 'wrap' }}
      />
      <Stack direction={'column'} spacing={1} sx={{ p: 3 }}>
        <CustomLabelValue
          label={trans('field.property.generate_monthly_consumption_information')}
          value={boolToString(property.settings.generateMonthlyConsumptionInformation)}
        />
        <CustomLabelValue
          label={trans('field.property.send_monthly_consumption_information_via_post')}
          value={boolToString(property.settings.sendMonthlyConsumptionInformationViaPost)}
        />
        <CustomLabelValue
          label={trans('field.property.send_monthly_consumption_information_via_email')}
          value={boolToString(property.settings.sendMonthlyConsumptionInformationViaEmail)}
        />
      </Stack>
    </Card>
  )
}
