import ManagerListTable from '../manager-list-table'
import { Typography } from '@mui/material'
import { trans } from '@/utils/translation'

export default function ManagerListView(props) {
  return (
    <>
      <Typography variant="h5" gutterBottom>
        {trans('manager.header')}
      </Typography>
      <ManagerListTable {...props} />
    </>
  )
}
