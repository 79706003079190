import { useEffect } from 'react'

const useSubmit = (callback) => {
  useEffect(() => {
    const keyDown = (event) => {
      if (event.key === 'Enter' && event.target.nodeName !== 'TEXTAREA') {
        event.preventDefault()
        callback()
      }
    }

    document.addEventListener('keydown', keyDown)

    return () => {
      document.removeEventListener('keydown', keyDown)
    }
  })
}
export default useSubmit
