import { router, usePage } from '@inertiajs/react'
import { trans } from '@/utils/translation'
import { Card, CardHeader } from '@mui/material'
import Box from '@mui/material/Box'
import { LoadingButton } from '@mui/lab'
import AppointmentData from '@/sections/appointment/appointment-data'

export default function AppointmentStep1() {
  const { appointment } = usePage().props

  return (
    <Box sx={{ maxWidth: '400px', mx: 'auto' }}>
      <Card>
        <CardHeader title={trans('appointment.title')} />

        <AppointmentData cancel={true} />

        <Box sx={{ display: 'flex', flexDirection: 'row', py: 2, mx: 3, justifyContent: 'end' }}>
          <LoadingButton variant={'outlined'} sx={{ mr: 1 }} onClick={() => router.get(`/tasks`)}>
            {trans('button.back')}
          </LoadingButton>
          <LoadingButton variant={'contained'} onClick={() => router.get(`/appointments/${appointment.id}/step-2`)}>
            {trans('button.next')}
          </LoadingButton>
        </Box>
      </Card>
    </Box>
  )
}
