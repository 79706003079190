import { Card } from '@mui/material'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import PropTypes from 'prop-types'

export default function EmptyItem({ message }) {
  return (
    <Card sx={{ height: '100%', backgroundColor: 'background.neutral' }}>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '150px' }}>
        <Typography variant="subtitle1" sx={{ textAlign: 'center' }}>
          {message}
        </Typography>
      </Box>
    </Card>
  )
}

EmptyItem.propTypes = {
  message: PropTypes.string.isRequired
}
