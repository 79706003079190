import { DialogActions, DialogTitle, Grid } from '@mui/material'
import { trans } from '@/utils/translation'
import DialogContent from '@mui/material/DialogContent'
import CustomButton from '@/components/custom-button'
import { LoadingButton } from '@mui/lab'
import { useModalContext } from '@/context/use-modal-context'
import { useForm } from '@/hooks/use-form'
import { router } from '@inertiajs/react'
import { ToastCtl } from '@/utils/toast-ctl'
import useSubmit from '@/hooks/use-submit'
import { FormProvider } from '@/context/use-form-context'
import PropTypes from 'prop-types'
import InertiaSwitch from '@/components/form/inertia-switch'

export default function PropertyEditMonthlyModal({ property }) {
  const { closeModal } = useModalContext()

  const methods = useForm({
    url: {
      method: 'PUT',
      location: `/api/properties/${property.id}/edit`
    },
    defaultValues: {
      generateMonthlyConsumptionInformation: property?.settings?.generateMonthlyConsumptionInformation,
      sendMonthlyConsumptionInformationViaPost: property?.settings?.sendMonthlyConsumptionInformationViaPost,
      sendMonthlyConsumptionInformationViaEmail: property?.settings?.sendMonthlyConsumptionInformationViaEmail
    }
  })

  const { submit, submitting } = methods

  const handleSubmit = () => {
    submit(() => {
      router.reload()
      ToastCtl.show(trans('general.save_success'), 'success')
      closeModal('propertyEditMonthly')
    })
  }

  useSubmit(() => handleSubmit())

  return (
    <>
      <DialogTitle>{trans('property.edit')}</DialogTitle>
      <DialogContent dividers sx={{ py: 1 }}>
        <FormProvider {...methods} sx={{ px: 2, mx: 8 }}>
          <form onSubmit={handleSubmit}>
            <Grid container direction={'row'} spacing={2}>
              <Grid item xs={12}>
                <InertiaSwitch
                  name={'generateMonthlyConsumptionInformation'}
                  input={{
                    label: trans('field.property.generate_monthly_consumption_information')
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <InertiaSwitch
                  name={'sendMonthlyConsumptionInformationViaPost'}
                  input={{
                    label: trans('field.property.send_monthly_consumption_information_via_post')
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <InertiaSwitch
                  name={'sendMonthlyConsumptionInformationViaEmail'}
                  input={{
                    label: trans('field.property.send_monthly_consumption_information_via_email')
                  }}
                />
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <CustomButton onClick={() => closeModal('propertyEditMonthly')}>{trans('button.cancel')}</CustomButton>
        <LoadingButton
          loading={submitting}
          variant={'contained'}
          onClick={() => {
            handleSubmit()
          }}
        >
          {trans('button.done')}
        </LoadingButton>
      </DialogActions>
    </>
  )
}

PropertyEditMonthlyModal.propTypes = {
  property: PropTypes.object
}
