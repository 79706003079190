import { useEffect, useMemo, useState } from 'react'
import { useFormContext } from '@/context/use-form-context'
import { Stack } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import { get } from 'lodash'
import dayjs from 'dayjs'

const InertiaDatePicker = ({ name, input, minDate, callback, initialValue }) => {
  const { setDataDebounce, overrideFieldValues, errors } = useFormContext()
  const { label, format, required, disabled, views } = input

  const [fieldValue, setFieldValue] = useState(null)

  const fixDateTimezoneOffset = (date) => {
    // Backend (23:00 UTC) => Frontend (21:00 UTC) => Backend (21:00 UTC)
    // Dayjs thinks the date is in local timezone and removes the offset, but in reality it's already in UTC, so we add if back
    return dayjs(date).add(date.getTimezoneOffset() * -1, 'minutes')
  }

  useEffect(() => {
    if (initialValue) {
      const initialDate = dayjs(initialValue)
      if (initialDate.isValid()) {
        setFieldValue(initialDate)
        setDataDebounce(name, initialDate.toDate())
      }
    }

    const override = get(overrideFieldValues, name)
    if (typeof override !== 'undefined' && override !== '' && dayjs(override).isValid()) {
      setFieldValue(override ? dayjs(override) : override)
      setDataDebounce(name, fixDateTimezoneOffset(dayjs(override).toDate()), false)
    }
  }, [get(overrideFieldValues, name)])

  return useMemo(() => {
    return (
      <Stack direction={'column'} sx={{ width: '100%' }}>
        <DatePicker
          label={label + (required ? ' *' : '')}
          format={format}
          minDate={minDate}
          onChange={(date) => {
            if (date && !Number.isNaN(date.toDate().getTime())) {
              setFieldValue(dayjs(date))
              setDataDebounce(name, fixDateTimezoneOffset(date.toDate()))
              callback ? callback(date) : null
            }
          }}
          value={initialValue || fieldValue}
          slotProps={{
            textField: {
              error: !!get(errors, name),
              helperText: get(errors, name)
            },
            field: {
              clearable: !required,
              onClear: () => {
                setFieldValue(null)
                setDataDebounce(name, null)
                callback ? callback() : null
              }
            }
          }}
          disabled={disabled ?? false}
          views={views ?? ['year', 'month', 'day']}
        />
      </Stack>
    )
  }, [get(errors, name), fieldValue, minDate, errors])
}
export default InertiaDatePicker
