import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Drawer from '@mui/material/Drawer'
import { useResponsive } from '@/hooks/use-responsive'
import Scrollbar from '@/components/scrollbar'
import { NAV } from '../config-layout'
import { useNavData } from './config-navigation'
import { NavSectionVertical } from '@/components/nav-section'
import Link from '@mui/material/Link'
import assetAppLogo from '../../../images/logos/logo_small_colored.svg'
import Button from '@mui/material/Button'
import { isDemo } from '@/utils/misc'
import { trans } from '@/utils/translation'

export default function NavVertical({ openNav, onCloseNav }) {
  const lgUp = useResponsive('up', 'lg')

  const navData = useNavData()

  const logo = (
    <Box
      component="img"
      src={assetAppLogo}
      sx={{
        width: 40,
        height: 40,
        display: 'inline-flex'
      }}
    />
  )

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column'
        }
      }}
    >
      <Link href="/" sx={{ mt: 3, ml: 4, mb: 1 }}>
        {logo}
      </Link>
      <NavSectionVertical data={navData} open={openNav} />

      {isDemo() && (
        <Stack direction={'row'} justifyContent={'center'} sx={{ mt: 2 }}>
          <Button
            color={'primary'}
            variant={'contained'}
            onClick={() => (document.location.href = 'https://assetapp.de/register')}
          >
            {trans('menu.request_offer')}
          </Button>
        </Stack>
      )}

      <Box sx={{ flexGrow: 1 }} />
    </Scrollbar>
  )

  return (
    <Box
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV.W_VERTICAL }
      }}
    >
      {lgUp ? (
        <Stack
          sx={{
            height: 1,
            position: 'fixed',
            width: NAV.W_VERTICAL,
            borderRight: (theme) => `dashed 1px ${theme.palette.divider}`
          }}
        >
          {renderContent}
        </Stack>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          onClick={onCloseNav}
          PaperProps={{
            sx: {
              width: NAV.W_VERTICAL
            }
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  )
}

NavVertical.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func
}
