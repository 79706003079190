import BillingListTable from '../billing-list-table'
import { Typography } from '@mui/material'
import { trans } from '@/utils/translation'

export default function BillingListView() {
  return (
    <>
      <Typography variant="h5" gutterBottom>
        {trans('billing.header')}
      </Typography>
      <BillingListTable />
    </>
  )
}
