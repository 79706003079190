import { DialogActions, DialogTitle, Stack } from '@mui/material'
import { trans } from '@/utils/translation'
import DialogContent from '@mui/material/DialogContent'
import CustomButton from '@/components/custom-button'
import { LoadingButton } from '@mui/lab'
import { useModalContext } from '@/context/use-modal-context'
import { useForm } from '@/hooks/use-form'
import { router } from '@inertiajs/react'
import { ToastCtl } from '@/utils/toast-ctl'
import useSubmit from '@/hooks/use-submit'
import { FormProvider } from '@/context/use-form-context'
import PropTypes from 'prop-types'
import InertiaSwitch from '@/components/form/inertia-switch'

export default function UserEditPermissionModal({ user }) {
  const { closeModal } = useModalContext()

  const methods = useForm({
    url: {
      method: 'PUT',
      location: `/api/users/${user.id}/edit-permission`
    },
    defaultValues: {
      canManageTenants: user.settings.canManageTenants,
      canManageDocuments: user.settings.canManageDocuments,
      canManageBilling: user.settings.canManageBilling
    }
  })

  const { submit, submitting } = methods

  const handleSubmit = () => {
    submit(() => {
      router.reload()
      ToastCtl.show(trans('general.save_success'), 'success')
      closeModal('userPermissionEdit')
    })
  }

  useSubmit(() => handleSubmit())

  return (
    <>
      <DialogTitle>{trans(`user.edit`)}</DialogTitle>
      <DialogContent dividers sx={{ py: 1 }}>
        <FormProvider {...methods} sx={{ px: 2, mx: 8 }}>
          <form onSubmit={handleSubmit}>
            <Stack direction={'column'} spacing={2}>
              <InertiaSwitch
                name={'canManageTenants'}
                input={{
                  label: trans('owner.authorization.tenant_authorization')
                }}
              />
              <InertiaSwitch
                name={'canManageDocuments'}
                input={{
                  label: trans('owner.authorization.document_authorization')
                }}
              />
              <InertiaSwitch
                name={'canManageBilling'}
                input={{
                  label: trans('owner.authorization.cost_authorization')
                }}
              />
            </Stack>
          </form>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <CustomButton onClick={() => closeModal('userPermissionEdit')}>{trans('button.cancel')}</CustomButton>
        <LoadingButton
          loading={submitting}
          variant={'contained'}
          onClick={() => {
            handleSubmit()
          }}
        >
          {trans('button.done')}
        </LoadingButton>
      </DialogActions>
    </>
  )
}

UserEditPermissionModal.propTypes = {
  user: PropTypes.object
}
