import { Card, CardContent, Grid, Stack } from '@mui/material'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import { trans } from '@/utils/translation'
import { router } from '@inertiajs/react'

export default function TaskList({ tasks }) {
  return (
    <Stack spacing={2}>
      {tasks.map((task) => (
        <Card key={task.id}>
          <CardContent sx={{ p: '16px !important' }}>
            <Grid container>
              <Grid item xs={12} md={5}>
                <Stack justifyContent={'center'} sx={{ height: '100%' }}>
                  {task.translatedTodo}
                </Stack>
              </Grid>
              <Grid item xs={12} md={4}>
                <Stack justifyContent={'center'} sx={{ height: '100%' }}>
                  {task.property.label}
                </Stack>
              </Grid>
              <Grid item xs={12} md={3}>
                <Stack direction={'row'} sx={{ justifyContent: 'flex-end' }}>
                  {console.log(task)}
                  <Button variant={'contained'} onClick={() => router.get(task.link)}>
                    {trans('task.button')}
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      ))}
    </Stack>
  )
}

TaskList.propTypes = {
  tasks: PropTypes.array.isRequired
}
