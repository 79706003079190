import DashboardLayout from '@/layouts/dashboard'
import { AppointmentListView } from '@/sections/appointment/view'
import { Head } from '@inertiajs/react'
import { trans } from '@/utils/translation'

const AppointmentDetailPage = () => {
  return (
    <>
      <Head>
        <title>{trans('appointment.title')}</title>
      </Head>
      <AppointmentListView />
    </>
  )
}

AppointmentDetailPage.layout = (page) => <DashboardLayout>{page}</DashboardLayout>
export default AppointmentDetailPage
