import PropTypes from 'prop-types'
import { ConfirmDialog } from '@/components/custom-dialog'
import { trans } from '@/utils/translation'
import Button from '@mui/material/Button'
import axios from 'axios'
import { ToastCtl } from '@/utils/toast-ctl'

export default function TableConfirmBatchDelete({ url, data, confirmRows, setRowSelection, setRefresh }) {
  return (
    <>
      <ConfirmDialog
        open={confirmRows.value}
        onClose={confirmRows.onFalse}
        title={trans('button.delete')}
        content={<>{data?.length === 1 ? trans('general.confirm_delete') : trans('general.confirm_batch_delete')}</>}
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              axios
                .post(url, data)
                .then(() => {
                  confirmRows.onFalse()
                  ToastCtl.show(trans('general.delete_success'), 'success')

                  setRowSelection([])
                  setRefresh(Date.now())
                })
                .catch((e) => {
                  confirmRows.onFalse()

                  if (e?.response?.data?.violations?.[0]?.message) {
                    ToastCtl.show(e.response.data.violations[0].message, 'error')
                  } else {
                    ToastCtl.show(trans('general.delete_error'), 'error')
                  }
                })
            }}
          >
            {trans('button.delete')}
          </Button>
        }
      />
    </>
  )
}

TableConfirmBatchDelete.propTypes = {
  url: PropTypes.string,
  data: PropTypes.array,
  confirmRows: PropTypes.object,
  setRowSelection: PropTypes.func,
  setRefresh: PropTypes.func
}
