import { trans } from '@/utils/translation'
import { Stack } from '@mui/material'
import Avatar from '@mui/material/Avatar'
import { formatDate, formatDateAnonymized } from '@/utils/format'
import UserState from '../../../constants/UserState'
import UserStateLabel from '../../user/user-state-label'
import { avatar } from '@/utils/misc'

export const COLUMNS = [
  {
    accessorKey: 'name',
    header: trans('field.name'),
    Cell: ({ renderedCellValue, row }) => (
      <Stack flexDirection={'row'} alignItems={'center'} gap={2}>
        <Avatar
          src={avatar(row.original)}
          sx={{
            width: 24,
            height: 24,
            border: (theme) => `solid 2px ${theme.palette.common.white}`
          }}
        />
        <span>{renderedCellValue}</span>
      </Stack>
    ),
    size: 250,
    id: 'name'
  },
  {
    accessorKey: 'inhabitedApartmentsCount',
    header: trans('field.property.apartments'),
    size: 100,
    id: 'inhabitedApartmentsCount'
  },
  {
    accessorKey: 'invitedAt',
    header: trans('field.user.invited_at'),
    accessorFn: (row) => (row.invitedAt ? formatDate(row.invitedAt) : trans('user.not_invited_yet')),
    size: 150,
    id: 'invitedAt'
  },
  {
    accessorKey: 'lastLogin',
    header: trans('field.user.last_login'),
    accessorFn: (row) => (row.lastLogin ? formatDateAnonymized(row.lastLogin) : trans('user.not_logged_in_yet')),
    size: 150,
    id: 'lastLogin'
  },
  {
    accessorKey: 'state',
    header: trans('field.state'),
    Cell: ({ renderedCellValue }) => <UserStateLabel state={renderedCellValue} />,
    size: 150,
    enableSorting: false
  }
]

export const STATUS_OPTIONS = {
  '': { color: 'default', label: trans('state.user.ALL') },
  [UserState.ACTIVE]: { color: 'success', label: trans('state.user.ACTIVE') },
  [UserState.INVITED]: {
    color: 'warning',
    label: trans('state.user.INVITED')
  },
  [UserState.INACTIVE]: {
    color: 'primary',
    label: trans('state.user.INACTIVE')
  },
  [UserState.DISABLED]: {
    color: 'error',
    label: trans('state.user.DISABLED')
  }
}
