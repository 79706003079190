import { FormProvider } from '@/context/use-form-context'
import { Grid, Stack } from '@mui/material'
import ListItemText from '@mui/material/ListItemText'
import InertiaTextField from '@/components/form/inertia-textfield'
import { trans } from '@/utils/translation'
import InertiaCheckbox from '@/components/form/inertia-checkbox'
import { LoadingButton } from '@mui/lab'
import { useForm } from '@/hooks/use-form'
import { router, usePage } from '@inertiajs/react'
import { ToastCtl } from '@/utils/toast-ctl'
import useSubmit from '@/hooks/use-submit'

const UserProfileEmailForm = () => {
  const { auth } = usePage().props
  const emailMethods = useForm({
    url: {
      method: 'PUT',
      location: `/api/users/${auth.user.id}/edit-email-address`
    },
    defaultValues: {
      email: auth.user.email,
      newEmail: '',
      confirm: false
    }
  })

  const { submit, submitting } = emailMethods

  const handleSubmit = () => {
    submit(() => {
      router.get(
        '/login',
        {},
        {
          onSuccess: () => {
            setTimeout(() => {
              ToastCtl.show(trans('field.confirm_email'), 'info')
            }, 500)
          }
        }
      )
    })
  }

  useSubmit(() => handleSubmit())

  return (
    <FormProvider {...emailMethods} sx={{ px: 2, mx: 8 }}>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={3} sx={{ p: 3 }}>
          <Grid item xs={12} md={4}>
            <ListItemText
              primary={trans('user.change_email')}
              primaryTypographyProps={{
                typography: 'h6',
                mb: 0.5
              }}
              secondaryTypographyProps={{ component: 'span' }}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <Stack spacing={1} sx={{ p: 3 }}>
              <InertiaTextField
                name={'email'}
                input={{
                  label: trans('field.current_email'),
                  required: true,
                  disabled: true
                }}
              />
              <InertiaTextField
                name={'newEmail'}
                input={{
                  label: trans('field.new_email'),
                  required: true
                }}
              />
              <InertiaCheckbox
                name={'confirm'}
                input={{
                  label: trans('field.confirm'),
                  required: true
                }}
              />
            </Stack>
          </Grid>
          <LoadingButton
            variant={'contained'}
            loading={submitting}
            onClick={() => handleSubmit()}
            sx={{ float: 'right', ml: 'auto', mr: 3 }}
          >
            {trans('button.save')}
          </LoadingButton>
        </Grid>
      </form>
    </FormProvider>
  )
}

export default UserProfileEmailForm
