import { useMemo } from 'react'
import { trans } from '@/utils/translation'
import { Card, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table'
import { defaultTableOptions, handleErrorResponse } from '@/utils/misc'
import Typography from '@mui/material/Typography'
import CustomButton from '@/components/custom-button'
import MenuItem from '@mui/material/MenuItem'
import Iconify from '@/components/iconify'
import CreateModal from '@/sections/apartment-tenant/create-modal'
import { useModalContext } from '@/context/use-modal-context'
import { router, usePage } from '@inertiajs/react'
import { formatCurrency, formatDate } from '@/utils/format'
import Paper from '@mui/material/Paper'
import TenantPrepaymentModal from '@/sections/apartment-tenant/tenant-prepayment-modal'
import IconButton from '@mui/material/IconButton'
import useSingletonAxios from '@/hooks/use-singleton-axios'

export default function UserRentedApartmentsListTable() {
  const { user } = usePage().props
  const { openModal } = useModalContext()
  const request = useSingletonAxios()

  const columns = useMemo(
    () => [
      {
        accessorKey: 'apartment.property.label',
        header: trans('tenant.rented_apartments.columns.property'),
        size: 250,
        enableSorting: false,
        muiTableBodyCellProps: ({ row }) => ({
          onClick: () => router.get(`/properties/${row.original.apartment?.property?.id}/detail`),
          sx: {
            cursor: 'pointer',
            '&:hover': {
              textDecoration: 'underline'
            }
          }
        })
      },
      {
        accessorKey: 'apartment.label',
        header: trans('tenant.rented_apartments.columns.unit'),
        size: 100,
        enableSorting: false,
        muiTableBodyCellProps: ({ row }) => ({
          onClick: () => router.get(`/apartments/${row.original.apartment?.id}/detail`),
          sx: {
            cursor: 'pointer',
            '&:hover': {
              textDecoration: 'underline'
            }
          }
        })
      },
      {
        accessorKey: 'begin',
        header: trans('tenant.rented_apartments.columns.from'),
        Cell: ({ renderedCellValue }) => formatDate(renderedCellValue),
        size: 50,
        enableSorting: false
      },
      {
        accessorKey: 'end',
        header: trans('tenant.rented_apartments.columns.to'),
        Cell: ({ renderedCellValue }) => (renderedCellValue ? formatDate(renderedCellValue) : '-'),
        size: 50,
        enableSorting: false
      }
    ],
    []
  )

  const table = useMaterialReactTable({
    ...defaultTableOptions,
    manualFiltering: false,
    manualPagination: false,
    manualSorting: false,
    renderTopToolbar: () => (
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} justifyContent={'space-between'} m={2}>
        <Typography variant="h6"> {trans('tenant.rented_apartments.title')} </Typography>
        <CustomButton
          variant="contained"
          color="primary"
          onClick={() => openModal('apartmentTenantCreate', <CreateModal defaultValues={{ user: user.id }} />)}
        >
          + {trans('apartment_tenant.create')}{' '}
        </CustomButton>
      </Stack>
    ),
    columns,
    data: user.inhabitedApartments,
    enableRowActions: true,
    renderRowActionMenuItems: ({ row }) => [
      <MenuItem key="editApartment" onClick={() => router.get(`/apartments/${row.original.apartment.id}/detail`)}>
        <Iconify icon="solar:pen-bold" sx={{ mr: 1 }} />
        {trans('table_view.to_apartment')}
      </MenuItem>,
      <MenuItem
        key="editDate"
        onClick={() =>
          openModal(
            'apartmentTenantCreate',
            <CreateModal
              defaultValues={{
                id: row.original.id,
                user: user.id,
                apartments: [row.original.apartment.id],
                property: row.original.apartment.property.id,
                begin: row.original.begin,
                end: row.original.end
              }}
            />
          )
        }
      >
        <Iconify icon="solar:pen-bold" sx={{ mr: 1 }} />
        {trans('table_view.edit_time')}
      </MenuItem>,
      <MenuItem
        key="delete"
        sx={{ color: 'error.main' }}
        onClick={() => {
          request({
            method: 'DELETE',
            url: `/api/apartment_tenants/${row.original.id}`
          })
            .then(() => {
              router.reload()
            })
            .catch((e) => handleErrorResponse(e))
        }}
      >
        <Iconify icon="solar:trash-bin-trash-bold" sx={{ mr: 1 }} />
        {trans('table_view.delete_time')}
      </MenuItem>
    ],
    renderDetailPanel: ({ row }) => {
      if (!row.original) return

      return (
        <Stack spacing={2}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} justifyContent={'space-between'} m={2}>
            <Typography variant="h6"> {trans('tenant.rented_apartments.prepayments')} </Typography>
            <CustomButton
              variant="contained"
              color="primary"
              onClick={() => {
                openModal(
                  'tenantPrepayment',
                  <TenantPrepaymentModal defaultValues={{ apartmentTenant: row.original.id }} />
                )
              }}
            >
              + {trans('tenant.rented_apartments.new_prepayment')}{' '}
            </CustomButton>
          </Stack>
          {row.original.prepayments && (
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>{trans('general.from')}</TableCell>
                    <TableCell>{trans('tenant.rented_apartments.amount')}</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.original.prepayments.map((prepayment, index) => (
                    <TableRow key={index}>
                      <TableCell>{formatDate(prepayment.fromDate)}</TableCell>
                      <TableCell>{formatCurrency(prepayment.amount)}</TableCell>
                      <TableCell sx={{ width: '9%' }}>
                        <Stack direction={'row'} spacing={1} sx={{ mr: 1 }}>
                          <IconButton
                            key={'editPrepayment'}
                            onClick={() =>
                              openModal(
                                'tenantPrepayment',
                                <TenantPrepaymentModal
                                  defaultValues={{
                                    ...prepayment,
                                    apartmentTenant: row.original.id
                                  }}
                                />
                              )
                            }
                          >
                            <Iconify icon="solar:pen-bold" />
                          </IconButton>
                          <IconButton
                            key={'delete'}
                            sx={{ color: 'error.main' }}
                            onClick={() => {
                              request({
                                method: 'DELETE',
                                url: `/api/apartment_tenants_prepayment/${prepayment.id}`
                              })
                                .then(() => {
                                  router.reload()
                                })
                                .catch((e) => handleErrorResponse(e))
                            }}
                          >
                            <Iconify icon="solar:trash-bin-trash-bold" />
                          </IconButton>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Stack>
      )
    }
  })

  return (
    <>
      <Card>
        <MaterialReactTable table={table} />
      </Card>
    </>
  )
}
