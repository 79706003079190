import PropTypes from 'prop-types'
import { useCallback, useState } from 'react'
import NavItem from './nav-item'

// ----------------------------------------------------------------------

export default function NavList({ data, depth, slotProps }) {
  const active = true
  const [openMenu, setOpenMenu] = useState(active)

  const handleToggleMenu = useCallback(() => {
    if (data.children) {
      setOpenMenu((prev) => !prev)
    }
  }, [data.children])

  useCallback(() => {
    setOpenMenu(false)
  }, [])

  return (
    <>
      <NavItem
        open={openMenu}
        onClick={handleToggleMenu}
        //
        title={data.title}
        path={data.path}
        icon={data.icon}
        disabled={data.disabled}
        active={data.active}
        className={active ? 'active' : ''}
        sx={{
          mb: `${slotProps?.gap}px`,
          ...(depth === 1 ? slotProps?.rootItem : slotProps?.subItem)
        }}
      />
    </>
  )
}
NavList.propTypes = {
  data: PropTypes.object,
  depth: PropTypes.number,
  slotProps: PropTypes.object
}
