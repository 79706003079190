import { Card, CardHeader, Stack } from '@mui/material'

import IconButton from '@mui/material/IconButton'
import Iconify from '@/components/iconify'
import Typography from '@mui/material/Typography'
import { trans } from '@/utils/translation'
import { usePage } from '@inertiajs/react'
import { useModalContext } from '@/context/use-modal-context'
import UserEditPermissionModal from '@/sections/user/user-edit-permission-modal'

export default function OwnerAuthorization() {
  const { user } = usePage().props
  const { openModal } = useModalContext()

  return (
    <Card>
      <CardHeader
        title={trans('owner.authorization.title')}
        action={
          <IconButton onClick={() => openModal('userPermissionEdit', <UserEditPermissionModal user={user} />)}>
            <Iconify icon="solar:pen-bold" />
          </IconButton>
        }
      />
      <Stack direction={'column'} spacing={1} sx={{ p: 3 }}>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="body2">{trans('owner.authorization.tenant_authorization')}</Typography>
          <Typography variant="body2">
            {user.settings.canManageTenants ? trans('general.yes') : trans('general.no')}
          </Typography>
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="body2">{trans('owner.authorization.document_authorization')}</Typography>
          <Typography variant="body2">
            {user.settings.canManageDocuments ? trans('general.yes') : trans('general.no')}
          </Typography>
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="body2">{trans('owner.authorization.cost_authorization')}</Typography>
          <Typography variant="body2">
            {user.settings.canManageBilling ? trans('general.yes') : trans('general.no')}
          </Typography>
        </Stack>
      </Stack>
    </Card>
  )
}
