import { Card, CardHeader, Stack } from '@mui/material'
import { trans } from '@/utils/translation'
import Typography from '@mui/material/Typography'
import { FormProvider } from '@/context/use-form-context'
import { useForm } from '@/hooks/use-form'
import { LoadingButton } from '@mui/lab'
import useSubmit from '@/hooks/use-submit'
import { router, usePage } from '@inertiajs/react'
import { ToastCtl } from '@/utils/toast-ctl'
import InertiaNumericField from '@/components/form/inertia-numeric-field'
import Box from '@mui/material/Box'
import { formatNumber } from '@/utils/format'

export default function PropertyCompleteStep2() {
  const { property } = usePage().props

  const methods = useForm({
    url: {
      method: 'PUT',
      location: `/api/properties/${property.id}/step-2`
    },
    defaultValues: {
      apartments: property.apartments.map((apartment) => ({
        id: apartment.id,
        size: apartment.setting?.size > 0 ? apartment.setting?.size : null,
        heatingSize: apartment.setting?.heatingSize > 0 ? apartment.setting?.heatingSize : null,
        warmWaterSize: apartment.setting?.warmWaterSize > 0 ? apartment.setting?.warmWaterSize : null,
        additionalCostSize: apartment.setting?.additionalCostSize > 0 ? apartment.setting?.additionalCostSize : null
      }))
    }
  })

  const { submit, submitting, data, setData, setOverrideFieldValues } = methods

  const handleSubmit = () => {
    submit((response) => {
      router.get(`/properties/${response.data.id}/complete/step-3`)
      ToastCtl.show(trans('general.save_success'), 'success')
    })
  }

  useSubmit(() => handleSubmit())

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit}>
        <Card sx={{ maxWidth: '750px', mx: 'auto' }}>
          <CardHeader title={trans('initial_setup.apartment_area.apartment')} sx={{ mb: 2 }} />

          {property.apartments.map((apartment, index) => (
            <Box key={index}>
              <Typography variant={'body2'} sx={{ ml: 3, mt: 3, mb: 1 }}>
                {apartment.label}
              </Typography>

              <Stack direction={{ md: 'row', sm: 'column' }} spacing={{ xs: 2 }} sx={{ mx: 3 }}>
                <InertiaNumericField
                  name={`apartments.${index}.size`}
                  input={{
                    label: trans('initial_setup.apartment_area.constructed_area'),
                    required: true,
                    suffix: ' m²',
                    allowNegative: false,
                    decimalScale: 3
                  }}
                  callback={(value) => {
                    if (value > 0) {
                      setData(`apartments.${index}.size`, value)
                      setData(`apartments.${index}.heatingSize`, value)
                      setData(`apartments.${index}.warmWaterSize`, value)
                      setData(`apartments.${index}.additionalCostSize`, value)
                      setOverrideFieldValues({
                        [`apartments.${index}.size`]: value,
                        [`apartments.${index}.heatingSize`]: value,
                        [`apartments.${index}.warmWaterSize`]: value,
                        [`apartments.${index}.additionalCostSize`]: value
                      })
                    }
                  }}
                />

                <InertiaNumericField
                  name={`apartments.${index}.heatingSize`}
                  input={{
                    label: trans('initial_setup.apartment_area.heating'),
                    suffix: ' m²',
                    allowNegative: false,
                    decimalScale: 3
                  }}
                />

                <InertiaNumericField
                  name={`apartments.${index}.warmWaterSize`}
                  input={{
                    label: trans('initial_setup.apartment_area.wwater'),
                    suffix: ' m²',
                    allowNegative: false,
                    decimalScale: 3
                  }}
                />

                <InertiaNumericField
                  name={`apartments.${index}.additionalCostSize`}
                  input={{
                    label: trans('initial_setup.apartment_area.additional_cost'),
                    suffix: ' m²',
                    allowNegative: false,
                    decimalScale: 3
                  }}
                />
              </Stack>
            </Box>
          ))}

          <Stack direction={{ md: 'row', sm: 'column' }} spacing={2} sx={{ mx: 3 }} justifyContent={'space-between'}>
            <Typography variant={'body2'} sx={{ mt: 3, mb: 1 }} width={'164px'}>
              {formatNumber(
                data.apartments.reduce((acc, apartment) => acc + apartment.size || 0, 0),
                3
              )}{' '}
              m²
            </Typography>
            <Typography variant={'body2'} sx={{ mt: 3, mb: 1 }} width={'164px'}>
              {formatNumber(
                data.apartments.reduce((acc, apartment) => acc + apartment.heatingSize || 0, 0),
                3
              )}{' '}
              m²
            </Typography>
            <Typography variant={'body2'} sx={{ mt: 3, mb: 1 }} width={'164px'}>
              {formatNumber(
                data.apartments.reduce((acc, apartment) => acc + apartment.warmWaterSize || 0, 0),
                3
              )}{' '}
              m²
            </Typography>
            <Typography variant={'body2'} sx={{ mt: 3, mb: 1 }} width={'164px'}>
              {formatNumber(
                data.apartments.reduce((acc, apartment) => acc + apartment.additionalCostSize || 0, 0),
                3
              )}{' '}
              m²
            </Typography>
          </Stack>

          <Stack direction={'row'} spacing={2} sx={{ justifyContent: 'flex-end', mx: 3, my: 3 }}>
            <LoadingButton
              variant={'outlined'}
              onClick={() => router.get(`/properties/${property.id}/complete/step-1`)}
            >
              {trans('button.back')}
            </LoadingButton>
            <LoadingButton variant={'contained'} loading={submitting} onClick={handleSubmit}>
              {trans('button.next')}
            </LoadingButton>
          </Stack>
        </Card>
      </form>
    </FormProvider>
  )
}
