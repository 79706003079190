import DialogContent from '@mui/material/DialogContent'
import { useModalContext } from '@/context/use-modal-context'
import CustomVideo from '@/components/custom-video'
import IconButton from '@mui/material/IconButton'
import Iconify from '@/components/iconify'

export default function VideoModal(props) {
  const { closeModal } = useModalContext()
  return (
    <>
      <IconButton
        aria-label="close"
        onClick={() => closeModal('videoModal')}
        sx={(theme) => ({
          position: 'absolute',
          right: 8,
          top: 8,
          color: theme.palette.grey[500]
        })}
      >
        <Iconify icon={'solar:close-circle-bold'} width={24} height={24} />
      </IconButton>
      <DialogContent dividers sx={{ py: 1 }}>
        <CustomVideo {...props} />
      </DialogContent>
    </>
  )
}
