import { Card, CardHeader } from '@mui/material'
import Box from '@mui/material/Box'
import { trans } from '@/utils/translation'
import Typography from '@mui/material/Typography'
import CustomButton from '@/components/custom-button'
import { LoadingButton } from '@mui/lab'
import { router, usePage } from '@inertiajs/react'
import AppointmentMailTemplate from '@/sections/appointment/appointment-mail-template'

export default function AppointmentStep2() {
  const { appointment } = usePage().props

  const mailTemplate = AppointmentMailTemplate(appointment)

  return (
    <Box sx={{ maxWidth: '400px', mx: 'auto' }}>
      <Card>
        <CardHeader title={trans('appointment.send_email.title')} />
        <Typography variant={'body2'} sx={{ mx: 3, my: 2 }}>
          {trans('appointment.send_email.note')}
        </Typography>
        <CustomButton variant={'contained'} sx={{ mb: 3, mx: 3 }} href={mailTemplate}>
          {trans('appointment.send_email.button')}
        </CustomButton>

        <Box sx={{ display: 'flex', flexDirection: 'row', py: 2, mx: 3, mt: 6, justifyContent: 'end' }}>
          <LoadingButton
            variant={'outlined'}
            sx={{ mr: 1 }}
            onClick={() => router.get(`/appointments/${appointment.id}/step-1`)}
          >
            {trans('button.back')}
          </LoadingButton>
          <LoadingButton variant={'contained'} onClick={() => router.get(`/appointments/${appointment.id}/step-3`)}>
            {trans('button.next')}
          </LoadingButton>
        </Box>
      </Card>
    </Box>
  )
}
