import DocumentListTable from '../document-list-table'
import { Typography } from '@mui/material'
import { trans } from '@/utils/translation'

export default function DocumentListView(props) {
  return (
    <>
      <Typography variant="h5" gutterBottom>
        {trans('document.header')}
      </Typography>
      <DocumentListTable {...props} />
    </>
  )
}
