import PropTypes from 'prop-types'

import Stack from '@mui/material/Stack'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import { useTheme } from '@mui/material/styles'
import IconButton from '@mui/material/IconButton'

import { useOffSetTop } from '@/hooks/use-off-set-top'

import { bgBlur } from '@/theme/css'
import { useResponsive } from '@/hooks/use-responsive'
import { HEADER, NAV } from '@/layouts/config-layout'
import AccountPopover from '@/layouts/common/account-popover'
import Iconify from '@/components/iconify'

// ----------------------------------------------------------------------

export default function Header({ onOpenNav }) {
  const theme = useTheme()

  const lgUp = useResponsive('up', 'lg')

  const offsetTop = useOffSetTop(HEADER.H_DESKTOP)

  const renderContent = (
    <>
      {!lgUp && (
        <IconButton onClick={onOpenNav}>
          <Iconify icon={'carbon:menu'} />
        </IconButton>
      )}
      {/*<Searchbar />*/}

      <Stack flexGrow={1} direction="row" alignItems="center" justifyContent="flex-end" spacing={{ xs: 0.5, sm: 1 }}>
        {/*<NotificationsPopover />*/}

        <AccountPopover />
      </Stack>
    </>
  )

  return (
    <AppBar
      sx={{
        height: HEADER.H_MOBILE,
        zIndex: theme.zIndex.appBar + 1,
        ...bgBlur({
          color: theme.palette.background.default
        }),
        transition: theme.transitions.create(['height'], {
          duration: theme.transitions.duration.shorter
        }),
        ...(lgUp && {
          width: `calc(100% - ${NAV.W_VERTICAL + 1}px)`,
          height: HEADER.H_DESKTOP,
          ...(offsetTop && {
            height: HEADER.H_DESKTOP_OFFSET
          })
        })
      }}
    >
      <Toolbar
        sx={{
          height: 1,
          px: { lg: 5 }
        }}
      >
        {renderContent}
      </Toolbar>
    </AppBar>
  )
}

Header.propTypes = {
  onOpenNav: PropTypes.func
}
