import Box from '@mui/material/Box'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepButton from '@mui/material/StepButton'
import { LoadingButton } from '@mui/lab'
import { trans } from '@/utils/translation'
import { Card, Stack } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { router, usePage } from '@inertiajs/react'
import Typography from '@mui/material/Typography'
import PropertyCompleteStep1 from '@/sections/property-complete/property-complete-step-1'
import PropertyCompleteStep2 from '@/sections/property-complete/property-complete-step-2'
import PropertyCompleteStep3 from '@/sections/property-complete/property-complete-step-3'
import PropertyCompleteStep4 from '@/sections/property-complete/property-complete-step-4'

const steps = [1, 2, 3, 4]

export default function PropertyCompleteView() {
  const { property, step } = usePage().props
  const theme = useTheme()

  const renderOverview = (
    <Card sx={{ maxWidth: '400px', mx: 'auto' }}>
      <Box sx={{ my: 2, py: 1, mx: 3 }}>
        <Typography variant={'h5'}>{trans('initial_setup.title')}</Typography>
        <Box sx={{ my: 1 }}>
          <Typography variant={'h6'}>{trans('initial_setup.property')}</Typography>
          <Typography variant={'subtitle2'}>
            {property?.address?.street} {property?.address?.streetNumber}
          </Typography>
          <Typography variant={'subtitle2'}>
            {property?.address?.postcode} {property?.address?.city}
          </Typography>
        </Box>

        <Box sx={{ my: 2 }}>
          <Typography variant={'h6'} sx={{ my: 1 }}>
            {trans('initial_setup.importance_title')}
          </Typography>
          <Typography variant={'body2'}>{trans('initial_setup.importance_description')}</Typography>
        </Box>

        <Stack direction={'row'} spacing={2} sx={{ mt: 3, justifyContent: 'flex-end' }}>
          <LoadingButton variant={'outlined'} onClick={() => router.get(`/properties/${property.id}/detail`)}>
            {trans('button.back')}
          </LoadingButton>
          <LoadingButton variant={'contained'} onClick={() => router.get(`/properties/${property.id}/complete/step-1`)}>
            {trans('initial_setup.start')}
          </LoadingButton>
        </Stack>
      </Box>
    </Card>
  )

  return (
    <Box>
      <Stepper
        nonLinear
        activeStep={Number(step) - 1}
        sx={{
          justifyContent: 'space-between',
          mb: 3,
          maxWidth: '400px',
          mx: 'auto',
          '& .MuiStepLabel-root .Mui-completed': {
            color: theme.palette.success.main // circle color (COMPLETED)
          }
        }}
      >
        {steps.map((label, index) => (
          <Step
            key={label}
            completed={index < Number(step) - 1}
            disabled={index > Number(step) - 1}
            onClick={() => {
              if (index > Number(step) - 1) return

              router.get(`/properties/${property.id}/complete/step-${label}`)
            }}
          >
            <StepButton color="inherit"></StepButton>
          </Step>
        ))}
      </Stepper>
      <Box>
        {
          <Box sx={{ mt: 2, mb: 1, py: 1 }}>
            {parseInt(step) === 0 && renderOverview}
            {parseInt(step) === 1 && <PropertyCompleteStep1 />}
            {parseInt(step) === 2 && <PropertyCompleteStep2 />}
            {parseInt(step) === 3 && <PropertyCompleteStep3 />}
            {parseInt(step) === 4 && <PropertyCompleteStep4 />}
          </Box>
        }
      </Box>
    </Box>
  )
}
