import CustomPopover, { usePopover } from '../../../components/custom-popover'
import { Stack } from '@mui/material'
import { trans } from '@/utils/translation'
import Iconify from '../../../components/iconify'
import IconButton from '@mui/material/IconButton'
import MenuItem from '@mui/material/MenuItem'
import { alpha } from '@mui/material/styles'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'

import { DatePicker } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import PropTypes from 'prop-types'
import DeviceValueCreateModal from '@/sections/device-value/device-value-create-modal'
import { useModalContext } from '@/context/use-modal-context'
import DeviceExportModal from '@/sections/device/device-export-modal'
import DeviceTypes from '@/constants/DeviceTypes'
import { usePage } from '@inertiajs/react'

export default function DeviceValueTableToolbar({ columnFilters, setColumnFilters, setRefresh, resetAll, url }) {
  const popover = usePopover()
  const { openModal } = useModalContext()
  const { device } = usePage().props

  return (
    <>
      <Stack
        flexDirection={'row'}
        flexWrap={'wrap'}
        alignItems={'center'}
        justifyContent={'space-between'}
        sx={{
          boxShadow: (theme) => `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`
        }}
      >
        <Typography sx={{ p: 3 }} variant="h6">
          {trans('device_value.title')}
        </Typography>
        {device.type !== DeviceTypes.SMOKE && (
          <Button
            variant="contained"
            color={'primary'}
            sx={{ m: 1, mr: 4 }}
            onClick={() =>
              openModal(
                'deviceValueCreate',
                <DeviceValueCreateModal device={device} callback={() => setRefresh(new Date())} />
              )
            }
          >
            + {trans('device_value.create')}
          </Button>
        )}
      </Stack>
      <Stack>
        <Stack
          direction={'row'}
          alignItems={'center'}
          justifyContent={'space-between'}
          flexWrap={'wrap'}
          spacing={2}
          flexGrow={1}
          sx={{
            p: 2
          }}
        >
          <Stack direction={'row'} spacing={2}>
            <DatePicker
              label="Datum von"
              format={'DD.MM.YYYY'}
              value={columnFilters.begin ? dayjs(columnFilters.begin) : null}
              onChange={(value) =>
                setColumnFilters({
                  ...columnFilters,
                  begin: value
                })
              }
              slotProps={{
                field: {
                  clearable: true,
                  onClear: () => {
                    setColumnFilters({ ...columnFilters, begin: null })
                  }
                }
              }}
              sx={{
                maxWidth: { md: 200 }
              }}
            />
            <DatePicker
              label="Datum bis"
              format={'DD.MM.YYYY'}
              value={columnFilters.end ? dayjs(columnFilters.end) : null}
              onChange={(value) =>
                setColumnFilters({
                  ...columnFilters,
                  end: value
                })
              }
              slotProps={{
                field: {
                  clearable: true,
                  onClear: () => {
                    setColumnFilters({ ...columnFilters, end: null })
                  }
                }
              }}
              sx={{
                maxWidth: { md: 200 }
              }}
            />
          </Stack>
          <IconButton onClick={popover.onOpen}>
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>
        </Stack>

        <CustomPopover open={popover.open} onClose={popover.onClose} arrow="right-top" sx={{ width: 300 }}>
          <MenuItem
            onClick={() => {
              resetAll()
              popover.onClose()
            }}
          >
            <Iconify icon={'carbon:reset'} sx={{ width: 24, height: 24, mr: '16px' }} />
            {trans('table.reset_filter')}
          </MenuItem>
          {device.type !== DeviceTypes.SMOKE && (
            <MenuItem
              onClick={() => {
                openModal(
                  'deviceValueCreate',
                  <DeviceValueCreateModal device={device} callback={() => setRefresh(new Date())} />
                )
                popover.onClose()
              }}
            >
              <Iconify icon={'carbon:meter'} sx={{ width: 24, height: 24, mr: '16px' }} />
              {trans('device_value.create')}
            </MenuItem>
          )}

          <MenuItem
            onClick={() => {
              openModal('deviceExport', <DeviceExportModal url={url} defaultValues={{ exportType: 'specific' }} />)

              popover.onClose()
            }}
          >
            <Iconify icon={'carbon:download'} sx={{ width: 24, height: 24, mr: '16px' }} />
            {trans('button.csv_export')}
          </MenuItem>
        </CustomPopover>
      </Stack>
    </>
  )
}

DeviceValueTableToolbar.propTypes = {
  columnFilters: PropTypes.object,
  setColumnFilters: PropTypes.func,
  resetAll: PropTypes.func,
  url: PropTypes.object,
  setRefresh: PropTypes.func
}
