import { Stack } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import Collapse from '@mui/material/Collapse'
import { useState } from 'react'
import PropTypes from 'prop-types'
import Paper from '@mui/material/Paper'

export default function DeviceCard({ children, header, ...options }) {
  const [expanded, setExpanded] = useState(false)

  return (
    <Paper elevation={0} {...options}>
      <Stack
        direction={'row'}
        spacing={2}
        justifyContent={'space-between'}
        alignItems={'center'}
        sx={{ p: 1, mr: 1, cursor: 'pointer' }}
        onClick={(e) => {
          if (e.target.nodeName !== 'BUTTON') {
            setExpanded(!expanded)
          }
        }}
      >
        <Stack direction={'row'} alignItems={'center'}>
          <IconButton>{expanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}</IconButton>
          {header}
        </Stack>
      </Stack>
      <Collapse in={expanded}>{children}</Collapse>
    </Paper>
  )
}

DeviceCard.propTypes = {
  children: PropTypes.node.isRequired,
  header: PropTypes.node.isRequired
}
