import ApartmentListTable from '../apartment-list-table'
import { Typography } from '@mui/material'
import { trans } from '@/utils/translation'

export default function ApartmentListView(props) {
  return (
    <>
      <Typography variant="h5" gutterBottom>
        {trans('apartment.header')}
      </Typography>
      <ApartmentListTable {...props} />
    </>
  )
}
