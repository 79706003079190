import { trans } from '@/utils/translation'

export const COLUMNS = [
  {
    accessorFn: (row) => row.title,
    header: trans('news.field.title'),
    size: 250,
    id: 'title'
  },
  {
    accessorFn: (row) => row.header,
    header: trans('news.field.header'),
    size: 250,
    id: 'header'
  },
  {
    accessorFn: (row) => row.link,
    header: trans('news.field.link'),
    size: 150,
    id: 'link'
  },
  {
    accessorFn: (row) => row.image?.originalFileName,
    header: trans('news.field.image'),
    size: 150,
    id: 'file'
  }
]
