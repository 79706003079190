import { Head } from '@inertiajs/react'
import { trans } from '@/utils/translation'
import DashboardLayout from '../../layouts/dashboard'
import ManagerListView from '../../sections/manager/view/manager-list-view'

const ManagerListPage = () => {
  return (
    <>
      <Head>
        <title>{trans('general.manager')}</title>
      </Head>
      <ManagerListView defaultFilter={{ type: 'CURRENT_MANAGER' }} />
    </>
  )
}
ManagerListPage.layout = (page) => <DashboardLayout>{page}</DashboardLayout>

export default ManagerListPage
