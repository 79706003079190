import { trans } from '@/utils/translation'
import { Card, CardHeader, Stack } from '@mui/material'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import useSubmit from '@/hooks/use-submit'
import { FormProvider } from '@/context/use-form-context'
import { useForm } from '@/hooks/use-form'
import { LoadingButton } from '@mui/lab'
import { router, usePage } from '@inertiajs/react'
import InertiaAutocomplete from '@/components/form/inertia-autocomplete'
import InertiaFileUpload from '@/components/form/inertia-file-upload'
import { fileTypes } from '@/sections/assignment/steps/assignment-process-step-3'

export default function PropertyCompleteStep4() {
  const { property } = usePage().props
  const assignment = property.assignments?.[0]

  const methods = useForm({
    url: {
      method: 'PUT',
      location: `/api/properties/${property.id}/step-4`,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    },
    defaultValues: {
      activeContracting: assignment?.activeContracting ?? false,
      lastHeatingBillDocument: new File([], assignment?.lastHeatingBillDocument?.name),
      deviceContractDocument: new File([], assignment?.deviceContractDocument?.name),
      lastMeterProtocolDocument: new File([], assignment?.lastMeterProtocolDocument?.name),
      declarationOfPartitionDocument: new File([], assignment?.declarationOfPartitionDocument?.name),
      floorPlanDocument: new File([], assignment?.floorPlanDocument?.name),
      tenantListDocument: new File([], assignment?.tenantListDocument?.name),
      otherDocument: new File([], assignment?.otherDocument?.name)
    }
  })
  const { data, submit, submitting } = methods

  const handleSubmit = () => {
    submit(() => {
      router.get(`/properties/${property.id}/detail?flash[type]=success&flash[message]=${trans('initial_setup.done')}`)
    })
  }

  useSubmit(() => handleSubmit())

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit}>
        <Card sx={{ maxWidth: '400px', mx: 'auto' }}>
          <CardHeader title={trans('initial_setup.document.title')} />
          <Stack direction={'column'} spacing={2} sx={{ mx: 3, mt: 1 }}>
            <Typography variant={'body2'} sx={{ my: 1 }}>
              {trans('initial_setup.document.description')}
            </Typography>

            <InertiaAutocomplete
              name={'fileSelection'}
              items={fileTypes}
              input={{
                label: trans('assignment.customize.refine_request.document.upload')
              }}
              callback={(value) => {
                if (value) {
                  document.getElementById(`upload-${value}`).click()
                }
              }}
            />
            <Stack spacing={2}>
              {fileTypes.map((fileType) => (
                <Box key={fileType.id} sx={{ display: data[fileType.id]?.name !== 'undefined' ? 'block' : 'none' }}>
                  <InertiaFileUpload
                    name={fileType.id}
                    input={{ label: fileType.label, accept: 'image/png,image/jpeg,image/gif,application/pdf' }}
                    filename={data[fileType.id]?.name}
                  />
                </Box>
              ))}
            </Stack>
          </Stack>
          <Stack direction={'row'} spacing={2} sx={{ justifyContent: 'flex-end', mx: 3, my: 3 }}>
            <LoadingButton
              variant={'outlined'}
              onClick={() => router.get(`/properties/${property.id}/complete/step-3`)}
            >
              {trans('button.back')}
            </LoadingButton>
            <LoadingButton variant={'contained'} loading={submitting} onClick={handleSubmit}>
              {trans('initial_setup.document.finished')}
            </LoadingButton>
          </Stack>
        </Card>
      </form>
    </FormProvider>
  )
}
