import TaskHeader from '@/sections/task/task-header'
import { usePage } from '@inertiajs/react'
import TaskList from '@/sections/task/task-list'
import { Stack } from '@mui/material'

export default function TaskListView() {
  const { tasks } = usePage().props

  return (
    <>
      <Stack spacing={2}>
        <TaskHeader count={tasks?.length} />
        <TaskList tasks={tasks} />
      </Stack>
    </>
  )
}
