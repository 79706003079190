export default Object.freeze({
  ONBOARDING_OPEN: 'ONBOARDING_OPEN',
  ONBOARDING_STEP_1: 'ONBOARDING_STEP_1',
  ONBOARDING_STEP_2: 'ONBOARDING_STEP_2',
  ONBOARDING_STEP_3: 'ONBOARDING_STEP_3',
  ONBOARDING_DONE: 'ONBOARDING_DONE',

  PROPERTY_COMPLETE_STEP_1: 'PROPERTY_COMPLETE_1',
  PROPERTY_COMPLETE_STEP_2: 'PROPERTY_COMPLETE_2',
  PROPERTY_COMPLETE_STEP_3: 'PROPERTY_COMPLETE_3',
  PROPERTY_COMPLETE_DONE: 'PROPERTY_COMPLETE_DONE'
})
