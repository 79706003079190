import { Card, CardHeader, Grid, Stack } from '@mui/material'
import AssignmentProcessStep0 from '@/sections/assignment/steps/assignment-process-step-0'
import AssignmentCostOverview from '@/sections/assignment/assignment-cost-overview'
import { trans } from '@/utils/translation'
import AssignmentDeviceOverview from '@/sections/assignment/assignment-device-overview'
import { useState } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import { formatCurrency } from '@/utils/format'

export default function AssignmentView() {
  const [calculated, setCalculated] = useState({})
  const [coldWaterBilling, setColdWaterBilling] = useState(false)
  const [additionalCostBilling, setAdditionalCostBilling] = useState(false)
  const [smokeAlarm, setSmokeAlarm] = useState(false)

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={4}>
        <AssignmentProcessStep0
          setCalculated={setCalculated}
          setColdWaterBilling={setColdWaterBilling}
          setAdditionalCostBilling={setAdditionalCostBilling}
          setSmokeAlarm={setSmokeAlarm}
        />
      </Grid>

      <Grid item xs={12} md={8}>
        <Stack
          direction={{ md: 'row', sm: 'column' }}
          gap={3}
          flexWrap={'wrap'}
          justifyContent={'space-between'}
          width={'100%'}
        >
          <AssignmentCostOverview
            title={trans('assignment.cost_overview.title')}
            calculated={calculated}
            chart={{
              series: [
                {
                  label: trans('assignment.cost_overview.measurement_service'),
                  value: calculated?.serviceGross ?? 0
                },
                { label: trans('assignment.cost_overview.device_rental'), value: calculated?.devicesGross ?? 0 }
              ]
            }}
          />

          <Card sx={{ width: { lg: '48%', md: '100%' } }}>
            <Stack direction={'column'} justifyContent={'space-between'} height={'100%'}>
              <div>
                <CardHeader title={trans('assignment.requested_service.title')} sx={{ mb: 1 }} />
                <Box sx={{ px: 3, mb: 3 }}>
                  <Typography variant="body2" sx={{ mb: 1 }}>
                    {trans('assignment.requested_service.heating_bill')}
                  </Typography>
                  <Typography variant="body2" sx={{ mb: 1 }}>
                    {trans('assignment.requested_service.remote_readout')}
                  </Typography>
                  <Typography variant="body2" sx={{ mb: 1 }}>
                    {trans('assignment.requested_service.device_rental')}
                  </Typography>
                  <Typography variant="body2" sx={{ mb: 1 }}>
                    {trans('assignment.requested_service.consumption_information')}
                  </Typography>
                  <Typography variant="body2" sx={{ mb: 1 }}>
                    {trans('assignment.requested_service.installation_service')}
                  </Typography>
                  {coldWaterBilling && (
                    <Typography variant="body2" sx={{ mb: 1 }}>
                      {trans('assignment.requested_service.cold_water_billing')}
                    </Typography>
                  )}
                  {additionalCostBilling && (
                    <Typography variant="body2" sx={{ mb: 1 }}>
                      {trans('assignment.requested_service.additional_cost_billing')}
                    </Typography>
                  )}
                  {smokeAlarm && (
                    <Typography variant="body2" sx={{ mb: 1 }}>
                      {trans('assignment.requested_service.smoke_alarm')}
                    </Typography>
                  )}
                </Box>
              </div>
              <div>
                <Divider sx={{ borderStyle: 'dashed' }} />

                <Stack direction={'row'} sx={{ px: 3, my: 3 }} justifyContent={'space-between'}>
                  <Typography variant="h6">{trans('assignment.requested_service.annual_amount')}</Typography>
                  <Typography variant="h6">{formatCurrency(calculated?.alloverGross ?? 0)}</Typography>
                </Stack>
              </div>
            </Stack>
          </Card>
        </Stack>

        <Stack direction={{ md: 'row', sm: 'column' }} gap={3} sx={{ mt: 2, flexWrap: 'nowrap' }}>
          <AssignmentDeviceOverview
            total={calculated?.hcaDeviceCount ?? 0}
            title={trans('assignment.heat_cost_allocator.title')}
            note={trans('assignment.heat_cost_allocator.note')}
            sx={{ width: '100%' }}
          />

          <AssignmentDeviceOverview
            total={calculated?.heatingDeviceCount ?? 0}
            title={trans('assignment.heat_meter.title')}
            note={trans('assignment.heat_meter.note')}
            sx={{ width: '100%' }}
          />
        </Stack>

        <Stack direction={{ md: 'row', sm: 'column' }} gap={3} sx={{ mt: 2, flexWrap: 'nowrap' }}>
          <AssignmentDeviceOverview
            total={calculated?.waterDeviceCount ?? 0}
            title={trans('assignment.water_meter.title')}
            note={trans('assignment.water_meter.note')}
            sx={{ width: '100%' }}
          />

          <AssignmentDeviceOverview
            total={calculated?.smokeDeviceCount ?? 0}
            title={trans('assignment.smoke_alarm.title')}
            note={trans('assignment.smoke_alarm.note')}
            sx={{ width: '100%' }}
          />
        </Stack>
      </Grid>
    </Grid>
  )
}
