import { useForm } from '@/hooks/use-form'
import { DialogActions, DialogTitle, Stack } from '@mui/material'
import DialogContent from '@mui/material/DialogContent'
import { FormProvider } from '@/context/use-form-context'
import { ToastCtl } from '@/utils/toast-ctl'
import { router, usePage } from '@inertiajs/react'
import { trans } from '@/utils/translation'
import InertiaDatePicker from '@/components/form/inertia-date-picker'
import InertiaNumericField from '@/components/form/inertia-numeric-field'
import CustomButton from '@/components/custom-button'
import { useModalContext } from '@/context/use-modal-context'
import { LoadingButton } from '@mui/lab'
import useSubmit from '@/hooks/use-submit'
import InertiaAutocomplete from '@/components/form/inertia-autocomplete'
import FuelCostTypes from '@/constants/FuelCostDeliveryTypes'
import FuelCostDeliveryTypes from '@/constants/FuelCostDeliveryTypes'
import useSingletonAxios from '@/hooks/use-singleton-axios'
import { handleErrorResponse } from '@/utils/misc'
import PropTypes from 'prop-types'

export default function BillingFuelCostModal({ billingFuelCost, billingId }) {
  const { closeModal } = useModalContext()
  const { billing } = usePage().props
  const request = useSingletonAxios()

  const storageable = billing.property.heatingSettings?.fuelCostType?.storageable

  const methods = useForm({
    url: {
      method: billingFuelCost?.id ? 'PUT' : 'POST',
      location: billingFuelCost?.id ? `/api/billing_fuel_cost/${billingFuelCost.id}` : `/api/billing_fuel_cost/create`
    },
    defaultValues: {
      date: billingFuelCost?.date,
      deliveryType: billingFuelCost?.deliveryType,
      amount: billingFuelCost?.amount,
      taxRate: billingFuelCost?.taxRate || 19,
      grossAmount: billingFuelCost?.grossAmount,
      netAmount: billingFuelCost?.netAmount,
      energy: billingFuelCost?.energy || billing?.property?.heatingSettings?.fuelCostType?.energy || 1,
      emissionFactor:
        billingFuelCost?.emission || billing?.property?.heatingSettings?.fuelCostType?.emissionFactor || 0.203,
      carbonEmission: billingFuelCost?.carbonEmission || 0.0,
      carbonEmissionCost: billingFuelCost?.carbonEmissionCost,
      billing: billingId
    }
  })
  const { submit, submitting, data, setData, setOverrideFieldValues } = methods

  const calculateBrutto = (netto) => {
    if (data.grossAmount <= 0 || !netto) return

    if (parseInt(data.grossAmount) === 0) {
      setData('grossAmount', netto)
      setOverrideFieldValues('grossAmount', netto)
      return
    }
    setData('grossAmount', netto * (1 + data.taxRate / 100))
    setOverrideFieldValues({ ['grossAmount']: netto * (1 + data.taxRate / 100) })
  }

  const calculateNetto = (brutto) => {
    if (data.netAmount <= 0 || !brutto) return

    if (parseInt(data.netAmount) === 0) {
      setData('netAmount', brutto)
      setOverrideFieldValues('netAmount', brutto)
      return
    }
    setData('netAmount', brutto / (1 + data.taxRate / 100))
    setOverrideFieldValues({ ['netAmount']: brutto / (1 + data.taxRate / 100) })
  }

  const handleSubmit = () => {
    submit(
      () => {
        router.reload()
        ToastCtl.show(trans('general.save_success'), 'success')
        closeModal('billingFuelCostModal')
      },
      (e) => handleErrorResponse(e)
    )
  }

  useSubmit(() => handleSubmit())

  return (
    <>
      <DialogTitle> {trans('billing.detail.fuel_cost.modal.title')} </DialogTitle>
      <DialogContent dividers sx={{ py: 1 }}>
        <FormProvider {...methods} sx={{ px: 2, mx: 8 }}>
          <form onSubmit={handleSubmit}>
            <Stack direction={'column'} spacing={2}>
              <InertiaAutocomplete
                name={'deliveryType'}
                input={{
                  label: trans('billing.detail.fuel_cost.modal.type'),
                  required: true
                }}
                items={Object.values(FuelCostDeliveryTypes).map((type) => ({
                  id: type,
                  label: trans(`billing.detail.fuel_cost.modal.${type}`),
                  disabled: storageable
                    ? (billing.fuelCosts.length === 0 && type !== FuelCostTypes.INITIAL) ||
                      (billing.fuelCosts.length > 0 && type === FuelCostTypes.INITIAL)
                    : type !== FuelCostTypes.PURCHASE
                }))}
                callback={(value) => {
                  if (value === FuelCostTypes.INITIAL) {
                    setData('date', billing?.begin)
                    setOverrideFieldValues({ ['date']: billing?.begin })
                  }
                  if (value === FuelCostTypes.FINAL) {
                    setData('date', billing?.end)
                    setOverrideFieldValues({ ['date']: billing?.end })
                  }
                }}
              />
              <InertiaDatePicker
                name={'date'}
                input={{
                  label: trans('billing.detail.fuel_cost.modal.date')
                }}
              />
              <InertiaNumericField
                name={'amount'}
                input={{
                  label: trans('billing.detail.fuel_cost.modal.amount'),
                  suffix: ` ${billing?.property?.heatingSettings?.fuelCostType?.measurementUnit}`
                }}
                callback={(value) => {
                  setData('carbonEmission', value * data.emissionFactor * data.energy)
                  setOverrideFieldValues({ ['carbonEmission']: value * data.emissionFactor * data.energy })
                  if (data.deliveryType === 'FINAL') {
                    request({
                      method: 'POST',
                      url: `/billings/${billing.id}/fuel-costs`,
                      data: { fuelCost: { ...data, amount: value, id: billingFuelCost?.id } }
                    })
                      .then((response) => {
                        setData('grossAmount', response.data.calculatedCosts?.calculatedRestFuelCostGross)
                        setData('netAmount', response.data.calculatedCosts?.calculatedRestFuelCostNet)
                        setOverrideFieldValues({
                          ['netAmount']: response.data.calculatedCosts?.calculatedRestFuelCostNet,
                          ['grossAmount']: response.data.calculatedCosts?.calculatedRestFuelCostGross
                        })
                      })
                      .catch(() => {
                        ToastCtl.show(trans('billing.detail.fuel_cost.modal.calculation_failed'), 'error')
                      })
                  }
                }}
              />
              {data.deliveryType !== 'FINAL' && (
                <InertiaAutocomplete
                  name={'taxRate'}
                  input={{ label: trans('billing.detail.fuel_cost.modal.tax_rate'), suffix: ' %' }}
                  items={[
                    { id: -1, label: 'Andere MwSt. Satz' },
                    { id: 0, label: '0 %' },
                    { id: 7, label: '7 %' },
                    { id: 19, label: '19 %' }
                  ]}
                  callback={() => {
                    calculateNetto(data.grossAmount)
                    calculateBrutto(data.netAmount)
                  }}
                />
              )}
              <InertiaNumericField
                name={'grossAmount'}
                input={{
                  label: trans('billing.detail.fuel_cost.modal.brutto'),
                  fixedDecimalScale: true,
                  disabled: data.deliveryType === 'FINAL',
                  allowNegative: true
                }}
                callback={(value) => calculateNetto(value)}
              />
              <InertiaNumericField
                name={'netAmount'}
                input={{
                  label: trans('billing.detail.fuel_cost.modal.netto'),
                  fixedDecimalScale: true,
                  disabled: data.deliveryType === 'FINAL',
                  allowNegative: true
                }}
                callback={(value) => calculateBrutto(value)}
              />
              <InertiaNumericField
                name={'energy'}
                input={{
                  label: trans('billing.detail.fuel_cost.modal.energy'),
                  suffix: ` kWh/${billing?.property?.heatingSettings?.fuelCostType?.measurementUnit || 'kWh'}`,
                  fixedDecimalScale: true
                }}
                callback={(value) => {
                  setData('carbonEmission', data.amount * data.emissionFactor * value)
                  setOverrideFieldValues({ ['carbonEmission']: data.amount * data.emissionFactor * value })
                }}
              />
              <InertiaNumericField
                name={'emissionFactor'}
                input={{
                  label: trans('billing.detail.fuel_cost.modal.emission_factor'),
                  suffix: ' kWh',
                  decimalScale: 3
                }}
                callback={(value) => {
                  setData('carbonEmission', data.amount * value * data.energy)
                  setOverrideFieldValues({ ['carbonEmission']: data.amount * value * data.energy })
                }}
              />
              <InertiaNumericField
                name={'carbonEmission'}
                input={{
                  label: trans('billing.detail.fuel_cost.modal.co2_emission'),
                  suffix: ' kg',
                  fixedDecimalScale: true
                }}
              />
              <InertiaNumericField
                name={'carbonEmissionCost'}
                input={{
                  label: trans('billing.detail.fuel_cost.modal.co2_cost'),
                  fixedDecimalScale: true
                }}
              />
            </Stack>
          </form>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <CustomButton onClick={() => closeModal('billingFuelCostModal')}> {trans('button.cancel')} </CustomButton>
        <LoadingButton loading={submitting} type="submit" variant="contained" color="primary" onClick={handleSubmit}>
          {trans('button.confirm')}
        </LoadingButton>
      </DialogActions>
    </>
  )
}

BillingFuelCostModal.propTypes = {
  billingFuelCost: PropTypes.object,
  billingId: PropTypes.string
}
