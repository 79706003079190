import { useForm as useInertiaForm } from '@inertiajs/react'
import { useState } from 'react'
import { cloneDeep, debounce, set } from 'lodash'
import { set as setFp } from 'lodash/fp'
import axios from 'axios'
import useSingletonAxios from '@/hooks/use-singleton-axios'
import { handleErrorResponse } from '@/utils/misc'

export const useForm = (...args) => {
  let { url, defaultValues } = args[0]

  const [overrideFieldValues, setOverrideFieldValues] = useState(defaultValues)
  const [submitting, setSubmitting] = useState(false)
  const [onChange, setOnChange] = useState(null)
  const form = useInertiaForm(defaultValues)
  const request = useSingletonAxios()

  const setData = (key, value, triggerValidation = true, triggerChange = true) => {
    form.setData(function (data) {
      return setFp(key, value, data)
    })

    if (triggerValidation) {
      validate(key, value)
        .then(() => form.clearErrors(key))
        .catch((e) => {
          parseErrorResponse(e?.response?.data?.violations, key)
        })
    }

    if (triggerChange) {
      setOnChange(new Date())
    }
  }

  const parseErrorResponse = (errors, name = '') => {
    if (!errors) {
      return
    }

    if (name) {
      const violation = errors.find((violation) => violation.propertyPath === name)
      if (violation) {
        form.setError(name, violation.message ?? violation.title)
        return
      }

      form.clearErrors(name)
      return
    }

    for (const violation of errors) {
      form.setError(violation.propertyPath, violation.message ?? violation.title)
    }
  }

  const convertArrayToDotSyntax = (key) => {
    key = key.replaceAll('][', '.')
    key = key.replaceAll('[', '.')
    return key.replaceAll(']', '')
  }

  const validate = (name, value) => {
    const data = cloneDeep(form.data)
    if (name && name.length > 0) {
      name = convertArrayToDotSyntax(name)
    }

    if (typeof value !== 'undefined') {
      set(data, name, value)
    }

    return request({
      method: url.method,
      url: url.location,
      data: data,
      headers: { Precognition: true, ...url.headers }
    }).catch((e) => {
      if (axios.isCancel(e)) return

      throw e
    })
  }

  const submit = (onSuccess, onFailure = null, options) => {
    setSubmitting(true)

    const config = {
      method: url.method,
      url: url.location,
      headers: url.headers,
      data: form.data
    }

    if (options) {
      config.headers = options?.headers || config.headers
      config.method = options?.method || config.method
      config.url = options?.url || config.url
      config.data = { ...config.data, ...options.data }
    }

    return request(config)
      .then((response) => {
        onSuccess(response)
      })
      .catch((error) => {
        form.clearErrors()
        error?.response?.data?.violations?.map((violation) => {
          form.setError(violation.propertyPath, violation.message)
        })

        handleErrorResponse(error, onFailure)
      })
      .finally(() => setSubmitting(false))
  }

  const setDataDebounce = debounce((key, value, triggerValidation = true) => {
    setData(key, value, triggerValidation)
  }, 250)

  return {
    ...form,
    onChange,
    submit,
    submitting,
    validate,
    setData,
    setDataDebounce,
    setOverrideFieldValues,
    overrideFieldValues
  }
}
