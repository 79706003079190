import SimpleLayout from '@/layouts/header-simple'
import { AssignmentProcessView } from '../../sections/assignment/steps/view'
import { Head } from '@inertiajs/react'
import { trans } from '@/utils/translation'

const AssignmentSteps = () => {
  return (
    <>
      <Head>
        <title>{trans('general.assignment')}</title>
      </Head>
      <AssignmentProcessView />
    </>
  )
}
AssignmentSteps.layout = (page) => <SimpleLayout>{page}</SimpleLayout>

export default AssignmentSteps
