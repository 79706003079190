import { createContext, useContext } from 'react'
import PropTypes from 'prop-types'

const HookFormContext = createContext(null)

export const useFormContext = () => useContext(HookFormContext)

export const FormProvider = ({ children, ...data }) => {
  return <HookFormContext.Provider value={data}>{children}</HookFormContext.Provider>
}

FormProvider.propTypes = {
  children: PropTypes.node
}
