import { DialogActions, DialogTitle, Grid } from '@mui/material'
import { trans } from '@/utils/translation'
import DialogContent from '@mui/material/DialogContent'
import CustomButton from '@/components/custom-button'
import { LoadingButton } from '@mui/lab'
import { useModalContext } from '@/context/use-modal-context'
import { FormProvider } from '@/context/use-form-context'
import { useForm } from '@/hooks/use-form'
import { router } from '@inertiajs/react'
import { ToastCtl } from '@/utils/toast-ctl'
import useSubmit from '@/hooks/use-submit'
import InertiaTextfield from '@/components/form/inertia-textfield'
import InertiaTextField from '@/components/form/inertia-textfield'
import PropTypes from 'prop-types'

export default function ContactModal({ contact }) {
  const { closeModal } = useModalContext()

  const methods = useForm({
    url: {
      method: 'PUT',
      location: `/api/contacts/${contact.id}/edit`
    },
    defaultValues: {
      name: contact?.name,
      phone: contact?.phone,
      email: contact?.email,
      address: {
        street: contact?.address?.street,
        streetNumber: contact?.address?.streetNumber,
        postcode: contact?.address?.postcode,
        city: contact?.address?.city
      }
    }
  })

  const { submit, submitting } = methods

  const handleSubmit = () => {
    submit(() => {
      router.reload()
      ToastCtl.show(trans('general.save_success'), 'success')
      closeModal('contactEdit')
    })
  }

  useSubmit(() => handleSubmit())
  return (
    <>
      <DialogTitle>{trans('contact.edit')}</DialogTitle>
      <DialogContent dividers sx={{ py: 1 }}>
        <FormProvider {...methods} sx={{ px: 2, mx: 8 }}>
          <form onSubmit={handleSubmit}>
            <Grid container direction={'row'} spacing={2}>
              <Grid item xs={12}>
                <InertiaTextfield
                  name={'name'}
                  input={{
                    label: trans('field.name'),
                    required: true
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <InertiaTextfield
                  name={'phone'}
                  input={{
                    label: trans('field.phone'),
                    required: true
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <InertiaTextfield
                  name={'email'}
                  input={{
                    label: trans('field.email')
                  }}
                />
              </Grid>
              <Grid item xs={12} md={8}>
                <InertiaTextField
                  name={'address.street'}
                  input={{
                    label: trans('field.street'),
                    required: false
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <InertiaTextField
                  name={'address.streetNumber'}
                  input={{
                    label: trans('field.street_number'),
                    required: false
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <InertiaTextField
                  name={'address.postcode'}
                  input={{
                    label: trans('field.zip'),
                    required: false
                  }}
                />
              </Grid>
              <Grid item xs={12} md={8}>
                <InertiaTextField
                  name={'address.city'}
                  input={{
                    label: trans('field.city'),
                    required: false
                  }}
                />
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <CustomButton onClick={() => closeModal('contactEdit')}>{trans('button.cancel')}</CustomButton>
        <LoadingButton
          loading={submitting}
          variant={'contained'}
          onClick={() => {
            handleSubmit()
          }}
        >
          {trans('button.done')}
        </LoadingButton>
      </DialogActions>
    </>
  )
}

ContactModal.propTypes = {
  contact: PropTypes.object
}
