import dayjs from 'dayjs'
import { trans } from '@/utils/translation'
import DeviceTypes from '@/constants/DeviceTypes'

require('dayjs/locale/de')

export const formatCurrency = (amount) => {
  const currencyFormatter = new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',

    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  })

  return currencyFormatter.format(amount)
}

export const formatNumber = (number, fraction = 2) => {
  const numberFormatter = new Intl.NumberFormat('de-DE', {
    minimumFractionDigits: fraction,
    maximumFractionDigits: fraction
  })

  return numberFormatter.format(number)
}

export const formatPercent = (number, fraction = 2) => {
  const percentFormatter = new Intl.NumberFormat('de-DE', {
    style: 'percent',
    minimumFractionDigits: fraction,
    maximumFractionDigits: fraction
  })

  return percentFormatter.format(number)
}

export const getDecimalByDeviceType = (type) => {
  if (type === DeviceTypes.HCA || type === DeviceTypes.HEAT || type === DeviceTypes.ELECTRICITY) {
    return 0
  }

  return 3
}

export const getUnitByDeviceType = (type) => {
  if (type === DeviceTypes.HCA) {
    return 'Einheiten'
  }

  if (type === DeviceTypes.COLD_WATER || type === DeviceTypes.WARM_WATER) {
    return 'm³'
  }

  if (type === DeviceTypes.SMOKE) {
    return ''
  }

  return 'kWh'
}

export const formatDeviceValue = (number, type) => {
  if (type === DeviceTypes.SMOKE) {
    if (number === null) {
      return '-'
    }

    return number >= 1 ? 'i.O.' : 'Fehler'
  }

  return number !== null
    ? `${formatNumber(number, getDecimalByDeviceType(type))} ${getUnitByDeviceType(type) ?? ''}`
    : `- ${getUnitByDeviceType(type)}`
}

export const formatDate = (date, format = 'DD.MM.YYYY') => {
  return dayjs(date).locale('de').format(format)
}

export const formatDateAnonymized = (date) => {
  if (dayjs(date).isAfter(dayjs().subtract(7, 'day'))) {
    return trans('user.logged_in_last_week')
  }

  if (dayjs(date).isAfter(dayjs().subtract(1, 'month'))) {
    return trans('user.logged_in_last_month')
  }

  if (dayjs(date).isAfter(dayjs().subtract(12, 'month'))) {
    return trans('user.logged_in_last_year')
  }

  return trans('user.logged_in_long_ago')
}

export const formatFloor = (floor) => {
  if (floor < 0) {
    return `${Math.abs(floor)}. ${trans('apartment.floor.UG')}`
  }

  if (floor === 0) {
    return trans('apartment.floor.EG')
  }

  if (floor === 999) {
    return trans('apartment.floor.DG')
  }

  return `${floor}. ${trans('apartment.floor.OG')}`
}

export const formatDeviceLocation = (device) => {
  if (device.location === 'GENERAL_AREA') {
    return trans('device.type.GENERAL_AREA')
  }

  return device.location
}

export const formatSize = (inputValue) => {
  if (!inputValue) return '-'

  if (inputValue === 0) return '0 Bytes'

  const units = ['bytes', 'Kb', 'Mb', 'Gb', 'Tb', 'Pb', 'Eb', 'Zb', 'Yb']

  const decimal = 2

  const baseValue = 1024

  const number = Number(inputValue)

  const index = Math.floor(Math.log(number) / Math.log(baseValue))

  return `${parseFloat((number / baseValue ** index).toFixed(decimal))} ${units[index]}`
}
