import { ToastCtl } from '@/utils/toast-ctl'
import { trans } from '@/utils/translation'
import { router } from '@inertiajs/react'
import { useModalContext } from '@/context/use-modal-context'
import { useForm } from '@/hooks/use-form'
import useSubmit from '@/hooks/use-submit'
import { DialogActions, DialogTitle } from '@mui/material'
import DialogContent from '@mui/material/DialogContent'
import { FormProvider } from '@/context/use-form-context'
import { LoadingButton } from '@mui/lab'
import CustomButton from '@/components/custom-button'
import PropTypes from 'prop-types'
import Typography from '@mui/material/Typography'
import InertiaTextField from '@/components/form/inertia-textfield'
import InertiaDatePicker from '@/components/form/inertia-date-picker'

export default function AppointmentCancelModal({ appointment }) {
  const { closeModal } = useModalContext()

  const methods = useForm({
    url: {
      method: 'PUT',
      location: `/api/appointments/${appointment.id}/decline`
    },
    defaultValues: {
      declinedReason: appointment?.declinedReason ?? '',
      availableDate: appointment?.availableDate ?? ''
    }
  })

  const { submit, submitting } = methods

  const handleSubmit = () => {
    submit(() => {
      router.reload()
      ToastCtl.show(trans('general.save_success'), 'success')
      closeModal('cancelAppointment')
    })
  }

  useSubmit(() => handleSubmit())

  return (
    <>
      <DialogTitle>{trans('appointment.delete.title')}</DialogTitle>
      <Typography variant={'body2'} sx={{ mx: 3, mb: 1 }}>
        {trans('appointment.delete.note')}
      </Typography>
      <DialogContent dividers sx={{ py: 1 }}>
        <FormProvider {...methods} sx={{ px: 2, mx: 8 }}>
          <form onSubmit={handleSubmit}>
            <InertiaTextField
              name={'declinedReason'}
              input={{
                label: trans('appointment.delete.reason'),
                multiline: true,
                maxRows: 4,
                rows: 2
              }}
              sx={{ mb: 3 }}
            />
            <Typography variant="body2" sx={{ mt: 2, mb: 1 }}>
              {trans('appointment.delete.when_available')}
            </Typography>
            <InertiaDatePicker
              name={'availableDate'}
              input={{
                label: trans('appointment.delete.available_date')
              }}
              sx={{ my: 2 }}
            />
          </form>
        </FormProvider>
      </DialogContent>
      <DialogActions sx={{ mt: 2 }}>
        <CustomButton
          onClick={() => {
            closeModal('cancelAppointment')
          }}
        >
          {trans('button.cancel')}
        </CustomButton>
        <LoadingButton
          loading={submitting}
          variant={'contained'}
          onClick={() => {
            handleSubmit()
          }}
        >
          {trans('appointment.cancel')}
        </LoadingButton>
      </DialogActions>
    </>
  )
}

AppointmentCancelModal.propTypes = {
  appointment: PropTypes.object
}
