import PropTypes from 'prop-types'
import { ConfirmDialog } from '@/components/custom-dialog'
import { trans } from '@/utils/translation'
import Button from '@mui/material/Button'
import { ToastCtl } from '@/utils/toast-ctl'
import { router } from '@inertiajs/react'
import { handleErrorResponse } from '@/utils/misc'
import useSingletonAxios from '@/hooks/use-singleton-axios'

export default function TableConfirmDelete({ url, confirmRows, setValue, setRefresh }) {
  const request = useSingletonAxios()

  return (
    <>
      <ConfirmDialog
        open={confirmRows.value}
        onClose={confirmRows.onFalse}
        title={trans('button.delete')}
        content={<>{trans('general.confirm_delete')}</>}
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              request({
                method: 'DELETE',
                url: url
              })
                .then(() => {
                  confirmRows.onFalse()
                  ToastCtl.show(trans('general.delete_success'), 'success')

                  setValue(null)
                  setRefresh ? setRefresh(new Date()) : router.reload()
                })
                .catch((e) => {
                  confirmRows.onFalse()

                  handleErrorResponse(e, () => {
                    if (e?.response?.data?.violations?.[0]?.message) {
                      ToastCtl.show(e.response.data.violations[0].message, 'error')
                    } else {
                      ToastCtl.show(trans('general.delete_error'), 'error')
                    }
                  })
                })
            }}
          >
            {trans('button.delete')}
          </Button>
        }
      />
    </>
  )
}

TableConfirmDelete.propTypes = {
  url: PropTypes.string,
  confirmRows: PropTypes.object,
  setValue: PropTypes.func,
  setRefresh: PropTypes.func
}
