import { useMemo } from 'react'
import { trans } from '@/utils/translation'
import { Card, Stack } from '@mui/material'
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table'
import { defaultTableOptions, handleErrorResponse } from '@/utils/misc'
import Typography from '@mui/material/Typography'
import CustomButton from '@/components/custom-button'
import MenuItem from '@mui/material/MenuItem'
import Iconify from '@/components/iconify'
import CreateModal from '@/sections/property-owner/create-modal'
import { useModalContext } from '@/context/use-modal-context'
import { router, usePage } from '@inertiajs/react'
import { formatDate } from '@/utils/format'
import useSingletonAxios from '@/hooks/use-singleton-axios'

export default function UserContractedPropertyListTable() {
  const { user } = usePage().props
  const { openModal } = useModalContext()
  const request = useSingletonAxios()

  const columns = useMemo(
    () => [
      {
        accessorKey: 'property.label',
        header: trans('tenant.rented_apartments.columns.property'),
        size: 250,
        enableSorting: false,
        muiTableBodyCellProps: ({ row }) => ({
          onClick: () => router.get(`/properties/${row.original?.property?.id}/detail`),
          sx: {
            cursor: 'pointer',
            '&:hover': {
              textDecoration: 'underline'
            }
          }
        })
      },
      {
        accessorKey: 'begin',
        header: trans('general.from'),
        Cell: ({ renderedCellValue }) => formatDate(renderedCellValue),
        size: 50,
        enableSorting: false
      },
      {
        accessorKey: 'end',
        header: trans('general.to'),
        Cell: ({ renderedCellValue }) => (renderedCellValue ? formatDate(renderedCellValue) : '-'),
        size: 50,
        enableSorting: false
      }
    ],
    []
  )

  const table = useMaterialReactTable({
    ...defaultTableOptions,
    manualFiltering: false,
    manualPagination: false,
    manualSorting: false,
    renderTopToolbar: () => (
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} justifyContent={'space-between'} m={2}>
        <Typography variant="h6"> {`${trans('owner.contractor.title')} (Adminfeld)`} </Typography>
        <CustomButton
          variant="contained"
          color="primary"
          onClick={() => openModal('propertyOwnerCreate', <CreateModal defaultValues={{ user: user.id }} />)}
        >
          + {trans('property_contractor.create')}{' '}
        </CustomButton>
      </Stack>
    ),
    columns,
    data: user.contractedProperties,
    enableRowActions: true,
    renderRowActionMenuItems: ({ row }) => [
      <MenuItem key="editProperty" onClick={() => router.get(`/properties/${row.original.property.id}/detail`)}>
        <Iconify icon="solar:pen-bold" sx={{ mr: 1 }} />
        {trans('table_view.to_property')}
      </MenuItem>,
      <MenuItem
        key="editDate"
        onClick={() =>
          openModal(
            'propertyOwnerCreate',
            <CreateModal
              defaultValues={{
                id: row.original.id,
                user: user.id,
                apartments: [row.original.property.id],
                property: row.original.property.id,
                begin: row.original.begin,
                end: row.original.end
              }}
            />
          )
        }
      >
        <Iconify icon="solar:pen-bold" sx={{ mr: 1 }} />
        {trans('table_view.edit_time')}
      </MenuItem>,
      <MenuItem
        key="delete"
        sx={{ color: 'error.main' }}
        onClick={() => {
          request({
            method: 'DELETE',
            url: `/api/property_owners/${row.original.id}`
          })
            .then(() => {
              router.reload()
            })
            .catch((e) => handleErrorResponse(e))
        }}
      >
        <Iconify icon="solar:trash-bin-trash-bold" sx={{ mr: 1 }} />
        {trans('table_view.delete_time')}
      </MenuItem>
    ]
  })

  return (
    <>
      <Card>
        <MaterialReactTable table={table} />
      </Card>
    </>
  )
}
