import { Head } from '@inertiajs/react'
import { trans } from '@/utils/translation'
import DashboardLayout from '../../layouts/dashboard'
import { LogListView } from '@/sections/log/view'

const LogListPage = () => {
  return (
    <>
      <Head>
        <title>{trans('log.title')}</title>
      </Head>
      <LogListView />
    </>
  )
}
LogListPage.layout = (page) => <DashboardLayout>{page}</DashboardLayout>

export default LogListPage
