import PropTypes from 'prop-types'

import Box from '@mui/material/Box'
import { fileFormat, fileThumb } from './utils'

export default function FileThumbnail({ file }) {
  const format = fileFormat(file.name)

  return (
    <>
      <Box
        component="img"
        src={fileThumb(format)}
        sx={{
          width: 32,
          height: 32,
          flexShrink: 0
        }}
      />
    </>
  )
}

FileThumbnail.propTypes = {
  file: PropTypes.object,
  imageView: PropTypes.bool
}
