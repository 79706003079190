import IconButton from '@mui/material/IconButton'
import { closeSnackbar, enqueueSnackbar } from 'notistack'
import DeleteIcon from '@mui/icons-material/Delete'

const action = (snackbarId) => (
  <>
    <IconButton
      color="inherit"
      onClick={() => {
        closeSnackbar(snackbarId)
      }}
    >
      <DeleteIcon />
    </IconButton>
  </>
)

class ToastCtlClass {
  show(text, variant = 'success') {
    enqueueSnackbar(text, {
      variant: variant,
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right'
      },
      autoHideDuration: 5000,
      action
    })
  }
}

const ToastCtl = new ToastCtlClass()

export { ToastCtl }
