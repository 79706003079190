import { trans } from '@/utils/translation'
import { Card, Stack } from '@mui/material'
import Typography from '@mui/material/Typography'
import UserHeader from '@/sections/user/user-header'
import background from '../../../../images/Cover/watercolour-colourful.jpg'
import Tabs, { tabsClasses } from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { DocumentListView } from '@/sections/document/view'
import { usePage } from '@inertiajs/react'
import { useCallback, useState } from 'react'
import OwnerDetailPage from '@/sections/owner/owner-detail-page'
import { TenantListView } from '@/sections/tenant/view'
import InfoView from '@/components/info-view'
import VideoModal from '@/components/modal/video-modal'
import { useModalContext } from '@/context/use-modal-context'

const TABS = [
  {
    value: 'data',
    label: trans('profile.tabs.data')
  },
  {
    value: 'tenants',
    label: trans('profile.tabs.tenant')
  },
  {
    value: 'documents',
    label: trans('profile.tabs.documents')
  }
]

export default function OwnerDetailView() {
  const { user } = usePage().props
  const [currentTab, setCurrentTab] = useState('data')
  const { openModal } = useModalContext()

  const handleChangeTab = useCallback((event, newValue) => {
    setCurrentTab(newValue)
  }, [])

  return (
    <>
      <Typography variant={'h5'} gutterBottom>
        {trans('owner.header')}
      </Typography>

      <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} flexWrap={'wrap'}>
        <Card
          sx={{
            mb: 3,
            height: 290,
            minWidth: { md: 600 },
            flexGrow: 10
          }}
        >
          <UserHeader role={trans('general.owner')} coverUrl={background} />

          <Tabs
            value={currentTab}
            onChange={handleChangeTab}
            sx={{
              width: 1,
              bottom: 0,
              zIndex: 9,
              position: 'absolute',
              bgcolor: 'background.paper',
              [`& .${tabsClasses.flexContainer}`]: {
                justifyContent: {
                  xs: 'unset',
                  md: 'flex-end'
                },
                mx: { xs: 'auto', md: 1 },
                width: { xs: 'fit-content', md: 'unset' }
              }
            }}
          >
            {TABS.map((tab) => (
              <Tab key={tab.value} value={tab.value} label={tab.label} />
            ))}
          </Tabs>
        </Card>

        <Card
          sx={{
            mb: 3,
            maxHeight: 290,
            flexGrow: 1
          }}
        >
          <InfoView
            header={trans('owner.info.header')}
            description={trans('dashboard.info.description')}
            tip={trans('dashboard.info.tip')}
            button={trans('dashboard.info.button')}
            onClick={() =>
              openModal('videoModal', <VideoModal src={'05_Eigentuemer.mp4'} controls={true} autoPlay={true} />, {
                maxWidth: 'lg'
              })
            }
          />
        </Card>
      </Stack>

      {currentTab === 'data' && <OwnerDetailPage />}
      {currentTab === 'tenants' && <TenantListView defaultFilter={{ createdBy: user.id, type: 'CURRENT_TENANT' }} />}
      {currentTab === 'documents' && <DocumentListView defaultFilter={{ user: user.id }} />}
    </>
  )
}
