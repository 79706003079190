import axios from 'axios'

export const log = async (message, context, type = 'error') => {
  console.log(JSON.stringify(window.screen))
  await axios.post(
    '/logging',
    {
      type,
      message,
      context: {
        location: document.location.href,
        screen: {
          width: window.screen.width,
          height: window.screen.height
        },
        ...context
      }
    },
    {
      headers: {
        'Content-Type': 'application/json'
      }
    }
  )
}
