import { formatDate } from '@/utils/format'

export default function AppointmentMailTemplate(appointment) {
  const day = formatDate(appointment?.begin, 'dddd')
  const date = formatDate(appointment?.end, 'DD.MM.YYYY')
  const startTime = formatDate(appointment?.begin, 'HH:mm')
  const endTime = formatDate(appointment?.end, 'HH:mm')

  return `mailto:?subject= Monteur kommt am ${date}&body=Sehr geehrte Mieterin, sehr geehrte Mieter,%0D%0Dam ${day}, ${date} um ${startTime} - ${endTime} Uhr kommt ein Monteur zur Überprüfung oder Montage von Messtechnik. Bitte ermöglichen Sie dem Monteur während des gesamten angegebenen Zeitraums den Zugang zu Ihren Räumen (Wohnraum, Gewerbeeinheiten und ggf. auf den Keller/Waschräume), in denen sich die Geräte befinden.

                    %0D%0DVielen  Dank für Ihre Unterstützung!
                    %0D%0D%0D Wichtige Hinweise:%0D
1. Die Arbeiten erfolgen innerhalb des Hauses von unten nach oben und innerhalb eines Geschosses im Uhrzeigersinn.%0D
2. Aus versicherungstechnischen Gründen dürfen unsere Servicepartner keine Möbel, Heizkörperverkleidungen oder andere Gegenstände entfernen bzw. verrücken. Bitte stellen Sie den freien Zugang zu den Geräten sicher.%0D
3. Sollten Sie im genannten Zeitraum nicht anwesend sein, bitten Sie eine Person ihres Vertrauens, uns Zutritt zu Ihren Räumen zu ermöglichen.%0D
4. Gerne können Sie mit dem Monteur einen kostenpflichtigen Ersatztermin vereinbaren, den wir Ihnen gesondert in Rechnung stellen müssen. Kontaktdaten Monteur: ${appointment?.fitterContact.name}, Tel: ${appointment?.fitterContact.phone} `
}
