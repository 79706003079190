import { Card, Stack, Typography } from '@mui/material'
import { useMemo } from 'react'
import { COLUMNS } from './table/news-table-definitions'
import useRemoteTable from '@/hooks/useRemoteTable'
import { defaultTableOptions, handleErrorResponse } from '@/utils/misc'
import { trans } from '@/utils/translation'
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table'
import Button from '@mui/material/Button'
import { useModalContext } from '@/context/use-modal-context'
import NewsCreateEditModal from '@/sections/news/news-create-edit-modal'
import TableRowAction from '@/components/table/table-row-action'
import Iconify from '@/components/iconify'
import { useTheme } from '@mui/material/styles'
import useSingletonAxios from '@/hooks/use-singleton-axios'

export default function NewsListTable() {
  const path = '/news/list'
  const theme = useTheme()
  const { openModal } = useModalContext()
  const request = useSingletonAxios()

  const columns = useMemo(() => COLUMNS, [])
  const methods = useRemoteTable(path, ['title', 'header'])
  const {
    globalFilter,
    columnSorting,
    setColumnSorting,
    pagination,
    setPagination,
    data,
    isError,
    isLoading,
    rowCount,
    setRefresh
  } = methods

  const table = useMaterialReactTable({
    ...defaultTableOptions,
    enablePagination: false,
    enableSorting: false,
    muiTablePaperProps: {
      elevation: 0,
      sx: {
        borderRadius: '0'
      }
    },
    renderTopToolbar: () => (
      <>
        <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} sx={{ p: 2 }}>
          <Typography variant="h5" gutter>
            {trans('news.header')}
          </Typography>
          <Button
            variant="contained"
            color={'primary'}
            sx={{ m: 1, mr: 4 }}
            onClick={() => openModal('newsCreateEdit', <NewsCreateEditModal callback={() => setRefresh(new Date())} />)}
          >
            + {trans('news.create')}
          </Button>
        </Stack>
      </>
    ),
    enableRowActions: true,
    renderRowActions: ({ row }) => (
      <TableRowAction
        row={row}
        items={[
          {
            content: (
              <>
                <Iconify icon="solar:pen-bold" sx={{ mr: 1 }} />
                {trans('button.edit')}
              </>
            ),
            callback: () => {
              openModal(
                'newsCreateEdit',
                <NewsCreateEditModal news={row.original} callback={() => setRefresh(new Date())} />
              )
            }
          },
          {
            content: (
              <>
                <Iconify icon="solar:trash-bin-trash-bold" sx={{ mr: 1 }} color={theme.palette.error.main} />
                {trans('button.delete')}
              </>
            ),
            callback: () => {
              request({
                method: 'DELETE',
                url: `/api/news/${row.original.id}`
              })
                .then(() => {
                  setRefresh(Date.now())
                })
                .catch((e) => handleErrorResponse(e))
            }
          }
        ]}
      />
    ),
    muiTableBodyRowProps: ({ row }) => ({
      onClick: () => {
        openModal('newsCreateEdit', <NewsCreateEditModal news={row.original} callback={() => setRefresh(new Date())} />)
      },
      sx: { cursor: 'pointer' }
    }),
    muiToolbarAlertBannerProps: isError ? { severity: 'error', children: trans('table.data_load_error') } : undefined,
    columns,
    data,
    rowCount: rowCount,
    onPaginationChange: setPagination,
    onSortingChange: setColumnSorting,
    state: {
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isLoading,
      showSkeletons: isLoading,
      sorting: columnSorting
    }
  })
  return (
    <>
      <Card>
        <MaterialReactTable table={table} />
      </Card>
    </>
  )
}
