import { Card, CardHeader, Stack } from '@mui/material'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { trans } from '@/utils/translation'
import { LoadingButton } from '@mui/lab'
import { router, usePage } from '@inertiajs/react'
import { FormProvider } from '@/context/use-form-context'
import { useForm } from '@/hooks/use-form'
import { ToastCtl } from '@/utils/toast-ctl'
import useSubmit from '@/hooks/use-submit'
import InertiaToggleButton from '@/components/form/inertia-toggle-button'
import InertiaNumericToggle from '@/components/form/inertia-numeric-toggle'
import { useCallback, useEffect, useState } from 'react'
import useSingletonAxios from '@/hooks/use-singleton-axios'
import PropTypes from 'prop-types'
import { MIN_APARTMENT_COUNT_ADMIN, MIN_APARTMENT_COUNT_USER } from '@/constants/ApartmentState'
import { hasRole } from '@/utils/misc'
import Roles from '@/constants/Roles'
import { debounce } from 'lodash'

export default function AssignmentProcessStep0({
  setCalculated,
  setColdWaterBilling,
  setAdditionalCostBilling,
  setSmokeAlarm
}) {
  const { assignment, auth } = usePage().props
  const [blocked, setBlocked] = useState(false)
  const [isEnlarged, setIsEnlarged] = useState(false)

  const minApartmentCount = hasRole(auth.user, Roles.ROLE_ADMIN) ? MIN_APARTMENT_COUNT_ADMIN : MIN_APARTMENT_COUNT_USER
  useEffect(() => {
    setTimeout(() => {
      let beatCount = 0
      const maxBeats = 4
      const interval = setInterval(() => {
        setIsEnlarged((prev) => !prev)
        beatCount++
        if (beatCount >= maxBeats) {
          clearInterval(interval)
        }
      }, 400)
    }, 1000)
  }, [])

  const methods = useForm({
    url: {
      method: 'POST',
      location: `/api/assignments/create`
    },
    defaultValues: {
      assignment: assignment?.id,
      apartmentCount: assignment?.apartmentCount >= minApartmentCount ? assignment?.apartmentCount : minApartmentCount,
      deviceCountKnown: assignment?.deviceCountKnown ?? null,
      centralHeating: assignment?.centralHeating ?? false,
      waterDeviceCount: assignment?.waterDeviceCount ?? 0,
      heatingDeviceCount: assignment?.heatingDeviceCount ?? 0,
      hcaDeviceCount: assignment?.hcaDeviceCount ?? 0,
      smokeDeviceCount: assignment?.smokeDeviceCount ?? 0,
      floorHeating: assignment?.floorHeating ?? false,
      waterBillingService: assignment?.waterBillingService ?? false,
      billingService: assignment?.billingService ?? false,
      smokeService: assignment?.smokeService ?? false,
      gatewayCount: assignment?.gatewayCount ?? 0
    }
  })

  const { onChange, submit, submitting, data, setData, setOverrideFieldValues } = methods

  const handleSubmit = () => {
    submit((response) => {
      router.get(`/assignment/${response.data.id}/step-1`)
      ToastCtl.show(trans('general.save_success'), 'success')
    })
  }

  useSubmit(() => handleSubmit())
  const request = useSingletonAxios()
  const fetchPrice = useCallback(
    debounce(
      (data) => {
        request({
          method: 'POST',
          url: '/assignment/price',
          data
        })
          .then((response) => {
            if (response?.data) {
              setCalculated(response.data)
              setData('gatewayCount', response.data.gatewayCount, false, false)
              setData('heatingDeviceCount', response.data.heatingDeviceCount, false, false)
              setData('hcaDeviceCount', response.data.hcaDeviceCount, false, false)
              setData('waterDeviceCount', response.data.waterDeviceCount, false, false)
              setData('smokeDeviceCount', response.data.smokeDeviceCount, false, false)

              setOverrideFieldValues({
                heatingDeviceCount: response.data.heatingDeviceCount,
                hcaDeviceCount: response.data.hcaDeviceCount,
                waterDeviceCount: response.data.waterDeviceCount,
                smokeDeviceCount: response.data.smokeDeviceCount
              })

              setBlocked(false)
            }
          })
          .catch(() => {
            setBlocked(false)
            ToastCtl.show(trans('assignment.price_loading_error'), 'error')
          })
      },
      1000,
      {
        leading: false,
        trailing: true
      }
    ),
    []
  )

  useEffect(() => {
    setBlocked(true)
    setColdWaterBilling(data.waterBillingService)
    setAdditionalCostBilling(data.billingService)
    setSmokeAlarm(data.smokeService)
    fetchPrice(data)
  }, [onChange])

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit}>
        <Card
          sx={{
            transition: 'transform 1s ease-in-out',
            transform: isEnlarged ? 'scale(1.1)' : 'scale(1)'
          }}
        >
          <CardHeader title={trans('assignment.customize.header')} sx={{ mb: 1 }} />

          <Box sx={{ px: 3, mb: 0 }}>
            <InertiaNumericToggle
              name={'apartmentCount'}
              input={{
                label: trans('assignment.customize.apartment_count'),
                subCallback: () => {
                  if (data.apartmentCount > minApartmentCount) {
                    setOverrideFieldValues({ apartmentCount: data.apartmentCount - 1 })
                    setData('apartmentCount', data.apartmentCount - 1)
                  }

                  if (data.apartmentCount === minApartmentCount) {
                    ToastCtl.show(
                      trans('assignment.customize.apartment_count_hint', { count: minApartmentCount }),
                      'warning'
                    )
                  }
                }
              }}
            />
          </Box>

          <Box sx={{ px: 3 }}>
            <InertiaToggleButton
              name={'deviceCountKnown'}
              input={{
                label: trans('assignment.customize.device_count')
              }}
            />
          </Box>

          <Box sx={{ px: 3, mb: 1 }}>
            {data.deviceCountKnown !== null && (
              <>
                {data.deviceCountKnown === true && (
                  <Box sx={{ mb: 1 }}>
                    <Typography variant="body2">{trans('assignment.customize.measuring_device')}</Typography>
                    <Stack spacing={0.5}>
                      <InertiaNumericToggle
                        name={'heatingDeviceCount'}
                        input={{
                          label: trans('assignment.customize.heat_meter')
                        }}
                      />
                      <InertiaNumericToggle
                        name={'hcaDeviceCount'}
                        input={{
                          label: trans('assignment.customize.hca_device')
                        }}
                      />
                      <InertiaNumericToggle
                        name={'waterDeviceCount'}
                        input={{
                          label: trans('assignment.customize.water_meter')
                        }}
                      />
                    </Stack>
                  </Box>
                )}
                {data.deviceCountKnown === false && (
                  <>
                    <InertiaToggleButton
                      name={'floorHeating'}
                      input={{
                        label: trans('assignment.customize.floor_heating')
                      }}
                    />
                  </>
                )}
                <InertiaToggleButton
                  name={'centralHeating'}
                  input={{
                    label: trans('assignment.customize.central_heating')
                  }}
                />
                <InertiaToggleButton
                  name={'waterBillingService'}
                  input={{
                    label: trans('assignment.customize.water_billing_service')
                  }}
                />
                <InertiaToggleButton
                  name={'billingService'}
                  input={{
                    label: trans('assignment.customize.billing_service')
                  }}
                />
                <InertiaToggleButton
                  name={'smokeService'}
                  input={{
                    label: trans('assignment.customize.smoke_service')
                  }}
                />
                {data.deviceCountKnown === true && data.smokeService && (
                  <>
                    <InertiaNumericToggle
                      name={'smokeDeviceCount'}
                      input={{
                        label: trans('assignment.customize.smoke_device')
                      }}
                    />
                  </>
                )}
              </>
            )}

            <Stack direction={'row'} spacing={2} sx={{ mt: 2, justifyContent: 'flex-end' }}>
              <LoadingButton variant={'outlined'} onClick={() => router.get('/properties')}>
                {trans('button.back')}
              </LoadingButton>
              <LoadingButton variant={'contained'} loading={submitting || blocked} onClick={() => handleSubmit()}>
                {trans('button.next')}
              </LoadingButton>
            </Stack>
          </Box>
        </Card>
      </form>
    </FormProvider>
  )
}

AssignmentProcessStep0.propTypes = {
  setCalculated: PropTypes.func,
  setColdWaterBilling: PropTypes.func,
  setAdditionalCostBilling: PropTypes.func,
  setSmokeAlarm: PropTypes.func
}
