import { Head } from '@inertiajs/react'
import { trans } from '@/utils/translation'
import DashboardLayout from '../../layouts/dashboard'
import OwnerListView from '../../sections/owner/view/owner-list-view'

const OwnerListPage = () => {
  return (
    <>
      <Head>
        <title>{trans('general.owner')}</title>
      </Head>
      <OwnerListView defaultFilter={{ type: 'CURRENT_OWNER' }} />
    </>
  )
}
OwnerListPage.layout = (page) => <DashboardLayout>{page}</DashboardLayout>

export default OwnerListPage
