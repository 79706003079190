import PropTypes from 'prop-types'
import Stack from '@mui/material/Stack'
import { useResponsive } from '@/hooks/use-responsive'
import Logo from '@/components/logo'
import background from '../../images/assetapp_auth_background.jpg'
import ThemeProvider from '../theme'

// ----------------------------------------------------------------------

export default function AuthLayout({ children }) {
  const mdUp = useResponsive('up', 'md')

  const renderLogo = (
    <Logo
      sx={{
        zIndex: 9,
        position: 'absolute',
        m: { xs: 2, md: 5 }
      }}
    />
  )

  const renderContent = (
    <Stack
      sx={{
        width: 1,
        mx: 'auto',
        height: { xs: 'fit-content', md: '100vh' },
        my: { xs: 'auto', md: 0 },
        maxWidth: { xs: '85vw', md: 600 },
        px: { xs: 3, md: 8 },
        pt: { xs: 4, md: 20 },
        pb: { xs: 4, md: 0 },
        borderRadius: { xs: 2, md: 0 },
        backgroundColor: (theme) => theme.palette.background.paper
      }}
    >
      {children}
    </Stack>
  )

  const renderSection = (
    <Stack
      flexGrow={1}
      spacing={10}
      alignItems="center"
      justifyContent="center"
      sx={{
        background: `url(${background})`,
        backgroundSize: 'cover'
      }}
    ></Stack>
  )

  return (
    <ThemeProvider>
      <Stack
        component="main"
        direction="row"
        sx={{
          minHeight: '100vh',
          background: mdUp ? 'transparent' : `url(${background})`
        }}
      >
        {renderLogo}

        {mdUp && renderSection}

        {renderContent}
      </Stack>
    </ThemeProvider>
  )
}

AuthLayout.propTypes = {
  children: PropTypes.node,
  image: PropTypes.string,
  title: PropTypes.string
}
