import { useMemo, useState } from 'react'
import { trans } from '@/utils/translation'
import useRemoteTable from '../../hooks/useRemoteTable'
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table'
import { defaultTableOptions, hasRole } from '@/utils/misc'
import { Card, Typography } from '@mui/material'
import { router, usePage } from '@inertiajs/react'
import { COLUMNS } from './table/billing-table-definitions'
import BillingTableToolbar from './table/billing-table-toolbar'
import TableRowAction from '@/components/table/table-row-action'
import Iconify from '@/components/iconify'
import Roles from '@/constants/Roles'
import { useModalContext } from '@/context/use-modal-context'
import { useTheme } from '@mui/material/styles'
import BillingEditModal from '@/sections/billing/billing-edit-modal'
import TableConfirmDelete from '@/components/table/table-confirm-delete'
import { useBoolean } from '@/hooks/use-boolean'

export default function BillingListTable() {
  const path = '/billings/list'
  const theme = useTheme()
  const { auth } = usePage().props
  const { openModal } = useModalContext()

  const confirmRow = useBoolean()
  const [billing, setBilling] = useState(null)

  const columns = useMemo(() => COLUMNS, [])
  const methods = useRemoteTable(path, ['street_number', 'postcode_city'], {
    state: ''
  })
  const {
    globalFilter,
    columnSorting,
    setColumnSorting,
    pagination,
    setPagination,
    data,
    isError,
    isLoading,
    rowCount,
    setRefresh
  } = methods

  const table = useMaterialReactTable({
    ...defaultTableOptions,
    renderTopToolbar: () => <BillingTableToolbar {...methods} />,
    muiTablePaperProps: {
      elevation: 0,
      sx: {
        borderRadius: '0'
      }
    },
    enableRowActions: true,
    renderRowActions: ({ row }) => {
      const rowActions = [
        {
          content: (
            <>
              <Iconify icon={'eva:trash-2-outline'} color={theme.palette.error.main} />
              <Typography variant={'body'}>{trans('button.delete')}</Typography>
            </>
          ),
          callback: () => {
            confirmRow.onTrue()
            setBilling(row.original)
          }
        }
      ]

      if (hasRole(auth.user, Roles.ROLE_ADMIN)) {
        rowActions.splice(0, 0, {
          content: (
            <>
              <Iconify icon={'eva:edit-outline'} />
              <Typography variant={'body'}>{trans('button.edit')}</Typography>
            </>
          ),
          callback: () => {
            openModal('billingEdit', <BillingEditModal billing={row.original} />)
          }
        })
      }

      return <TableRowAction row={row} width={180} items={rowActions} />
    },
    muiTableBodyRowProps: ({ row }) => ({
      onClick: (e) => {
        if (!e.target.classList.contains('MuiBackdrop-root')) {
          router.get(`/billings/${row.original.id}/detail`)
        }
      },
      sx: { cursor: 'pointer' }
    }),
    muiToolbarAlertBannerProps: isError ? { severity: 'error', children: trans('table.data_load_error') } : undefined,
    columns,
    data,
    rowCount: rowCount,
    onPaginationChange: setPagination,
    onSortingChange: setColumnSorting,
    state: {
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isLoading,
      showSkeletons: isLoading,
      sorting: columnSorting
    }
  })

  return (
    <>
      <Card>
        <MaterialReactTable table={table} />
      </Card>

      <TableConfirmDelete
        url={`/api/billings/${billing?.id}`}
        confirmRows={confirmRow}
        setValue={setBilling}
        setRefresh={setRefresh}
      />
    </>
  )
}
