import DashboardLayout from '@/layouts/dashboard'
import { Head } from '@inertiajs/react'
import { trans } from '@/utils/translation'
import { ProfileView } from '../../sections/user/view'

const List = () => {
  return (
    <>
      <Head>
        <title>{trans('user.title')}</title>
      </Head>
      <ProfileView />
    </>
  )
}

List.layout = (page) => <DashboardLayout>{page}</DashboardLayout>
export default List
