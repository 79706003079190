import { Toolbar, Typography } from '@mui/material'
import { Head, router } from '@inertiajs/react'
import CustomButton from '@/components/custom-button'
import CompactLayout from '@/layouts/CompactLayout'
import { m } from 'framer-motion'
import { trans } from '@/utils/translation'
import PageNotFoundIllustration from '../../illustrations/page-not-found-illustration'
import CustomLink from '@/components/custom-link'
import MotionContainer from '@/components/animate/motion-container'
import { varBounce } from '@/components/animate/variants/bounce'

const Error403 = () => {
  return (
    <>
      <Head title={trans('error.404.header')} />
      <Toolbar
        onClick={() => {
          document.location.href = '/'
        }}
      >
        <CustomLink
          style={{
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'end',
            textDecoration: 'none'
          }}
          onClick={() => router.get('/')}
        >
          <img
            src={require('../../../images/logos/logo_small_colored.svg')}
            alt="Logo"
            style={{
              maxWidth: '50px',
              marginLeft: '15px',
              marginTop: '30px'
            }}
          />
          <Typography variant={'h5'} sx={{ ml: 1 }} style={{ color: '#000' }}>
            AssetEnergy
          </Typography>
        </CustomLink>
      </Toolbar>
      <CompactLayout>
        <MotionContainer>
          <m.div variants={varBounce().in}>
            <Typography variant="h3" sx={{ mb: 2 }}>
              {trans('error.404.title')}
            </Typography>
          </m.div>
          <m.div variants={varBounce().in}>
            <Typography sx={{ color: 'text.secondary' }}>{trans('error.404.message')}</Typography>
          </m.div>
          <m.div variants={varBounce().in}>
            <PageNotFoundIllustration
              sx={{
                height: 260,
                my: { xs: 5, sm: 10 }
              }}
            />
          </m.div>
          <CustomButton variant={'contained'} onClick={() => router.get('/')}>
            {trans('navigation.back_to_dashboard')}
          </CustomButton>
        </MotionContainer>
      </CompactLayout>
    </>
  )
}

export default Error403
