import Box from '@mui/material/Box'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import { router, usePage } from '@inertiajs/react'
import StepButton from '@mui/material/StepButton'
import { useTheme } from '@mui/material/styles'
import AppointmentStep1 from '@/sections/appointment/steps/appointment-step-1'
import AppointmentStep2 from '@/sections/appointment/steps/appointment-step-2'
import AppointmentStep3 from '@/sections/appointment/steps/appointment-step-3'

const steps = [1, 2, 3]
export default function AppointmentStepsView() {
  const { step, appointment } = usePage().props

  const theme = useTheme()
  return (
    <Box>
      <Stepper
        nonLinear
        activeStep={Number(step) - 1}
        sx={{
          justifyContent: 'space-between',
          mb: 3,
          maxWidth: '400px',
          mx: 'auto',
          '& .MuiStepLabel-root .Mui-completed': {
            color: theme.palette.success.main // circle color (COMPLETED)
          }
        }}
      >
        {steps.map((label, index) => (
          <Step
            key={label}
            completed={index < Number(step) - 1}
            onClick={() => {
              router.get(`/appointments/${appointment.id}/step-${label}`)
            }}
          >
            <StepButton color="inherit"></StepButton>
          </Step>
        ))}
      </Stepper>
      <Box>
        {
          <Box sx={{ mt: 2, mb: 1, py: 1 }}>
            {parseInt(step) === 1 && <AppointmentStep1 />}
            {parseInt(step) === 2 && <AppointmentStep2 />}
            {parseInt(step) === 3 && <AppointmentStep3 />}
          </Box>
        }
      </Box>
    </Box>
  )
}
