import React, { useMemo } from 'react'
import { trans } from '@/utils/translation'
import useRemoteTable from '../../hooks/useRemoteTable'
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table'
import { defaultTableOptions } from '@/utils/misc'
import { Card, Stack } from '@mui/material'
import { COLUMNS } from './table/mail-table-definitions'
import PropTypes from 'prop-types'

export default function MailListTable({ defaultFilter = {}, defaultSorting = [] }) {
  const path = `/mails/list`

  const columns = useMemo(() => COLUMNS, [])
  const methods = useRemoteTable(path, ['number'], defaultFilter, defaultSorting)
  const {
    globalFilter,
    columnSorting,
    setColumnSorting,
    pagination,
    setPagination,
    data,
    isError,
    isLoading,
    rowCount
  } = methods

  const table = useMaterialReactTable({
    ...defaultTableOptions,
    renderTopToolbar: () => <></>,
    renderDetailPanel: ({ row }) => (
      <Stack>
        <div dangerouslySetInnerHTML={{ __html: row.original.html }} />
      </Stack>
    ),
    muiTableBodyRowProps: ({ row }) => ({
      onClick: () => row.toggleExpanded(!row.getIsExpanded()),
      sx: { cursor: 'pointer' }
    }),
    muiTablePaperProps: {
      elevation: 0,
      sx: {
        borderRadius: '0'
      }
    },
    muiToolbarAlertBannerProps: isError ? { severity: 'error', children: trans('table.data_load_error') } : undefined,
    columns,
    data,
    rowCount: rowCount,
    onPaginationChange: setPagination,
    onSortingChange: setColumnSorting,
    state: {
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isLoading,
      showSkeletons: isLoading,
      sorting: columnSorting
    }
  })

  return (
    <>
      <Card>
        <MaterialReactTable table={table} />
      </Card>
    </>
  )
}

MailListTable.propTypes = {
  defaultFilter: PropTypes.object,
  defaultSorting: PropTypes.array
}
