import { DialogActions, DialogTitle } from '@mui/material'
import { trans } from '@/utils/translation'
import DialogContent from '@mui/material/DialogContent'
import { LoadingButton } from '@mui/lab'
import { useModalContext } from '@/context/use-modal-context'

export default function RegisterTenantModal() {
  const { closeModal } = useModalContext()

  return (
    <>
      <DialogTitle>{trans('general.attention')}</DialogTitle>
      <DialogContent dividers sx={{ py: 1 }}>
        {trans('tenant.register_hint')}
      </DialogContent>
      <DialogActions>
        <LoadingButton
          variant={'contained'}
          onClick={() => {
            closeModal('registerTenantHint')
          }}
        >
          {trans('button.close')}
        </LoadingButton>
      </DialogActions>
    </>
  )
}
