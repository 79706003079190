// Improved version of debounce, that considers the parameters as well
import { debounce, memoize } from 'lodash'
import { MRT_Localization_DE } from 'material-react-table/locales/de'
import { trans } from './translation'
import Roles from '@/constants/Roles'
import { ToastCtl } from '@/utils/toast-ctl'

export const memoizeDebounce = (func, wait = 0, options = {}) => {
  const mem = memoize(function () {
    return debounce(func, wait, options)
  }, options.resolver)
  return function () {
    mem.apply(this, arguments).apply(this, arguments)
  }
}

export const defaultTableOptions = {
  defaultColumn: {
    minSize: 20,
    maxSize: 300,
    size: 20
  },
  localization: MRT_Localization_DE,
  positionActionsColumn: 'last',
  positionExpandColumn: 'first',
  positionToolbarAlertBanner: 'bottom',
  enableGlobalFilter: false,
  enableColumnActions: false,
  displayColumnDefOptions: {
    'mrt-row-expand': {
      size: 1,
      Header: () => {
        return <></>
      },
      muiTableHeadCellProps: () => ({
        sx: (theme) => ({
          py: 2,
          maxWidth: '40px',
          backgroundColor: theme.palette.grey[200]
        })
      }),
      muiTableBodyCellProps: () => ({
        sx: {
          maxWidth: '40px'
        }
      })
    },
    'mrt-row-select': {
      size: 1,
      muiTableHeadCellProps: () => ({
        sx: (theme) => ({
          py: 2,
          maxWidth: '40px',
          backgroundColor: theme.palette.grey[200]
        })
      }),
      muiTableBodyCellProps: () => ({
        sx: {
          maxWidth: '40px'
        }
      })
    },
    'mrt-row-actions': {
      size: 60,
      header: '',
      muiTableHeadCellProps: () => ({
        sx: (theme) => ({
          py: 2,
          maxWidth: '40px',
          backgroundColor: theme.palette.grey[200]
        })
      }),
      muiTableBodyCellProps: () => ({
        sx: {
          maxWidth: '40px'
        }
      })
    }
  },
  muiTableHeadCellProps: () => ({
    sx: (theme) => ({
      backgroundColor: theme.palette.grey[200],
      py: 2
    })
  }),
  muiTablePaginationProps: {
    rowsPerPageOptions: [5, 20, 50, 100],
    showFirstButton: true,
    showLastButton: true,
    labelRowsPerPage: trans('table.items_per_page')
  },
  manualFiltering: true,
  manualPagination: true,
  manualSorting: true
}

export const avatar = (user) => {
  if (!user) {
    return ''
  }

  return require(`@/../images/animals/${user?.avatar || 'avatar_01'}.jpg`)
}

export const boolToString = (bool) => {
  return bool ? trans('general.yes') : trans('general.no')
}

export const floors = () => {
  let items = [
    { id: -6, label: `-6. ${trans('apartment.floor.UG')}` },
    { id: -5, label: `-5. ${trans('apartment.floor.UG')}` },
    { id: -4, label: `-4. ${trans('apartment.floor.UG')}` },
    { id: -3, label: `-3. ${trans('apartment.floor.UG')}` },
    { id: -2, label: `-2. ${trans('apartment.floor.UG')}` },
    { id: -1, label: `-1. ${trans('apartment.floor.UG')}` },
    { id: 0, label: trans('apartment.floor.EG') }
  ]

  for (let i = 1; i <= 50; i++) {
    items.push({ id: i, label: ` ${i}. ${trans('apartment.floor.OG')}` })
  }

  items.push({ id: 999, label: trans('apartment.floor.DG') })

  return items
}

export const hasRole = (user, role) => {
  if (user?.roles?.includes(Roles.ROLE_ADMIN)) return true

  return user?.roles?.includes(role)
}

export const handleErrorResponse = (e, onFailure = null) => {
  if (e?.response?.data?.detail === 'Persisting entities is not allowed.') {
    ToastCtl.show(trans('validation.demo_change_not_allowed'), 'warning')
    return
  }

  if (e?.response?.status === 403) {
    ToastCtl.show(trans('general.forbidden'), 'warning')
    return
  }

  if (typeof onFailure === 'function') {
    onFailure(e)
    return
  }

  ToastCtl.show(trans('validation.general_error'), 'error')
}

export const isDemo = () => {
  return process.env.DEMO_HOST === document.location.hostname
}
