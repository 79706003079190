import { useMemo } from 'react'
import { trans } from '@/utils/translation'
import useRemoteTable from '../../hooks/useRemoteTable'
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table'
import { defaultTableOptions } from '@/utils/misc'
import { Card, Stack, Table, TableCell } from '@mui/material'
import { COLUMNS } from './table/assignment-table-definitions'
import AssignmentTableToolbar from './table/assignment-table-toolbar'
import Typography from '@mui/material/Typography'
import { formatDate } from '@/utils/format'

export default function AssignmentListTable() {
  const path = '/assignments/list'

  const columns = useMemo(() => COLUMNS, [])
  const methods = useRemoteTable(
    path,
    ['street_number', 'postcode_city', 'user_name'],
    {
      state: '',
      minApartmentCount: 0,
      maxApartmentCount: 500
    },
    [{ id: 'createdAt', desc: true }]
  )
  const {
    globalFilter,
    columnSorting,
    setColumnSorting,
    pagination,
    setPagination,
    data,
    isError,
    isLoading,
    rowCount
  } = methods

  const table = useMaterialReactTable({
    ...defaultTableOptions,
    renderTopToolbar: () => <AssignmentTableToolbar {...methods} />,
    muiTablePaperProps: {
      elevation: 0,
      sx: {
        borderRadius: '0'
      }
    },
    renderDetailPanel: ({ row }) => (
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableCell>
          <Stack direction={'column'} spacing={1} sx={{ px: 3 }}>
            <Typography variant={'body1'} fontWeight={'bold'}>
              Ihre Anfrage
            </Typography>
            <Typography variant={'body2'}>
              {trans('assignment.customize.apartment_count')}
              {': '} <b>{row.original.apartmentCount}</b>
            </Typography>
            <Typography variant={'body2'}>
              Anzahl der benötigen Geräte bekannt
              {': '} <b>{row.original.deviceCountKnown ? trans('general.yes') : trans('general.no')}</b>
            </Typography>
            {row.original.deviceCountKnown && (
              <>
                <Typography variant={'body2'}>
                  {trans('assignment.customize.heat_meter')}
                  {': '} <b>{row.original.heatingDeviceCount}</b>
                </Typography>
                <Typography variant={'body2'}>
                  {trans('assignment.customize.hca_device')}
                  {': '} <b>{row.original.hcaDeviceCount}</b>
                </Typography>
                <Typography variant={'body2'}>
                  {trans('assignment.customize.water_meter')}
                  {': '} <b>{row.original.waterDeviceCount}</b>
                </Typography>
              </>
            )}
            {row.original.deviceCountKnown === false && (
              <>
                <Typography variant={'body2'}>
                  {'Fußbodenheizung: '} <b>{row.original.floorHeating ? trans('general.yes') : trans('general.no')}</b>
                </Typography>
              </>
            )}
            <Typography variant={'body2'}>
              {'Warmwasser zentral erzeugt: '}{' '}
              <b>{row.original.centralHeating ? trans('general.yes') : trans('general.no')}</b>
            </Typography>
            <Typography variant={'body2'}>
              {trans('assignment.customize.water_billing_service')}
              {': '} <b>{row.original.waterBillingService ? trans('general.yes') : trans('general.no')}</b>
            </Typography>
            <Typography variant={'body2'}>
              {trans('assignment.customize.billing_service')}
              {': '} <b>{row.original.billingService ? trans('general.yes') : trans('general.no')}</b>
            </Typography>
            <Typography variant={'body2'}>
              {trans('assignment.customize.smoke_service')} {': '}
              <b>{row.original.smokeService ? trans('general.yes') : trans('general.no')}</b>
            </Typography>
            {row.original.deviceCountKnown && row.original.smokeService && (
              <Typography variant={'body2'}>
                {trans('assignment.customize.smoke_device')}
                {': '} <b>{row.original.smokeDeviceCount}</b>
              </Typography>
            )}
          </Stack>
        </TableCell>
        <TableCell>
          <Stack direction={'column'} spacing={1} sx={{ px: 3 }}>
            <Typography variant={'body1'} fontWeight={'bold'}>
              {trans('assignment.customize.property.title')}
            </Typography>
            <Typography variant={'body2'} fontWeight={'bold'}>
              {row.original.property?.address?.street} {row.original.property?.address?.streetNumber}
            </Typography>
            <Typography variant={'body2'} fontWeight={'bold'}>
              {row.original.property?.address?.postcode} {row.original.property?.address?.city}
            </Typography>
            <Typography variant={'body2'}>
              {trans('assignment.customize.property.billing_date')} {': '}{' '}
              <b>{formatDate(row.original.billingDate, 'DD.MM')}</b>
            </Typography>
            <Typography variant={'body2'}>
              {trans('assignment.customize.property.heating_system')}
              {': '}
              <b>
                {row.original.property?.heatingSettings?.heatingSystemType
                  ? trans(`heating_system_type.${row.original.property?.heatingSettings?.heatingSystemType.label}`)
                  : '-'}
              </b>
            </Typography>
            <Typography variant={'body2'}>
              {trans('assignment.customize.property.fuel_cost_type')} {': '}{' '}
              <b>{row.original.property?.heatingSettings?.fuelCostType?.label ?? '-'}</b>
            </Typography>
          </Stack>
        </TableCell>
        <TableCell>
          <Stack direction={'column'} spacing={1} sx={{ px: 3 }}>
            <Typography variant={'body1'} fontWeight={'bold'}>
              {trans('assignment.customize.installation.title')}{' '}
            </Typography>
            <Typography variant={'body2'}>
              Messgeräte installiert:{' '}
              <b>{row.original.devicesInstalled ? trans('general.yes') : trans('general.no')}</b>
            </Typography>
            <Typography variant={'body2'}>
              Individuell: <b>{row.original.isIndividual ? trans('general.yes') : trans('general.no')}</b>
            </Typography>
            {row.original.requestedFittingDate && (
              <Typography variant={'body2'}>
                {trans('assignment.customize.installation.installation_start')}
                {': '}
                <b>{formatDate(row.original.requestedFittingDate)}</b>
              </Typography>
            )}
            {row.original.customerComment && (
              <Typography variant={'body2'}>
                Anmerkung: <b>{row.original.customerComment}</b>
              </Typography>
            )}
          </Stack>
        </TableCell>
        <TableCell>
          <Stack direction={'column'} spacing={1} sx={{ px: 3 }}>
            <Typography variant={'body1'} fontWeight={'bold'}>
              {' '}
              {trans('assignment.customize.refine_request.title')}{' '}
            </Typography>
            <Typography variant={'body2'}>
              Messgeräte installiert:{' '}
              <b>{row.original.devicesInstalled ? trans('general.yes') : trans('general.no')}</b>
            </Typography>
            <Typography variant={'body2'}>
              Messdienst beauftragt:{' '}
              <b>{row.original.activeContracting ? trans('general.yes') : trans('general.no')}</b>
            </Typography>
          </Stack>
        </TableCell>
        <TableCell>
          <Stack direction={'column'} spacing={1} sx={{ px: 3 }}>
            <Typography variant={'body1'} fontWeight={'bold'}>
              {' '}
              {trans('assignment.customize.your_data')}{' '}
            </Typography>
            {!row.original.property?.address.street && <Typography variant={'body2'}>-</Typography>}
            {row.original.property?.address?.company && (
              <Typography variant={'body2'}>
                {trans('field.company')}
                {': '} <b>{row.original.property?.address?.company}</b>
              </Typography>
            )}
            <Typography variant={'body2'} fontWeight={'bold'}>
              {row.original.property?.address?.name}
            </Typography>
            <Typography variant={'body2'} fontWeight={'bold'}>
              {row.original.property?.address?.street} {row.original.property?.address?.streetNumber}
            </Typography>
            <Typography variant={'body2'} fontWeight={'bold'}>
              {row.original.property?.address?.postcode} {row.original.property?.address?.city}
            </Typography>
            {row.original.property?.billingAddress && (
              <>
                <Typography variant={'body1'}>Abweichende Angebotsadresse: </Typography>
                {row.original.property?.billingAddress?.company && (
                  <Typography variant={'body2'}>
                    {trans('field.company')}
                    {': '}
                    <b>{row.original.property?.billingAddress?.company}</b>
                  </Typography>
                )}
                <Typography variant={'body2'} fontWeight={'bold'}>
                  {row.original.property?.billingAddress?.name}
                </Typography>
                <Typography variant={'body2'} fontWeight={'bold'}>
                  {row.original.property?.billingAddress?.street} {row.original.property?.address?.streetNumber}
                </Typography>
                <Typography variant={'body2'} fontWeight={'bold'}>
                  {row.original.property?.billingAddress?.postcode} {row.original.property?.address?.city}
                </Typography>
              </>
            )}
          </Stack>
        </TableCell>
      </Table>
    ),
    muiTableBodyRowProps: ({ row }) => ({
      onClick: () => row.toggleExpanded(!row.getIsExpanded()),
      sx: { cursor: 'pointer' }
    }),
    muiToolbarAlertBannerProps: isError ? { severity: 'error', children: trans('table.data_load_error') } : undefined,
    columns,
    data,
    rowCount: rowCount,
    onPaginationChange: setPagination,
    onSortingChange: setColumnSorting,
    state: {
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isLoading,
      showSkeletons: isLoading,
      sorting: columnSorting
    }
  })

  return (
    <>
      <Card>
        <MaterialReactTable table={table} />
      </Card>
    </>
  )
}
