import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { Stack } from '@mui/material'
import { trans } from '@/utils/translation'
import { usePage } from '@inertiajs/react'
import CustomButton from '@/components/custom-button'
import AppointmentCancelModal from '@/sections/appointment/appointment-cancel-modal'
import { useModalContext } from '@/context/use-modal-context'
import { formatDate } from '@/utils/format'

export default function AppointmentData(prop) {
  const { appointment } = usePage().props
  const { openModal } = useModalContext()

  return (
    <>
      <Box sx={{ mx: 3, my: 1 }}>
        <Typography variant={'body1'}>{trans('field.type')}:</Typography>
        <Typography variant={'body2'}>{trans(`appointment.type.${appointment?.type}`)}</Typography>
      </Box>

      <Box sx={{ mx: 3, my: 1 }}>
        <Typography variant={'body1'}>{trans('appointment.where')}:</Typography>
        <Typography variant={'body2'}>
          {appointment?.assignment?.property?.address?.street}{' '}
          {appointment?.assignment?.property?.address?.streetNumber}
        </Typography>
        <Typography variant={'body2'}>
          {appointment?.assignment?.property?.address?.postcode} {appointment?.assignment?.property?.address?.city}
        </Typography>
      </Box>

      <Box sx={{ mx: 3, my: 2 }}>
        <Typography variant={'body1'}>{trans('appointment.when')}:</Typography>
        <Typography variant={'body2'}>{formatDate(appointment?.begin)} </Typography>
        <Typography variant={'body2'}>
          {formatDate(appointment?.begin, 'HH:mm')} - {formatDate(appointment?.end, 'HH:mm')}{' '}
          {trans('appointment.time_unit')}
        </Typography>
        {prop.cancel && (
          <CustomButton
            variant={'text'}
            sx={{ borderBottom: '1px solid black', borderRadius: 'unset', p: 0 }}
            onClick={() => openModal('cancelAppointment', <AppointmentCancelModal appointment={appointment} />)}
          >
            {trans('appointment.cancel')}
          </CustomButton>
        )}
      </Box>

      <Box sx={{ mx: 3, my: 2 }}>
        <Typography variant={'body1'}>{trans('appointment.who')}:</Typography>
        <Typography variant={'body2'}>{appointment?.fitterContact?.name} </Typography>
        <Typography variant={'body2'}>
          {appointment?.fitterContact?.address?.street} {appointment?.fitterContact?.address?.streetNumber}
        </Typography>
        <Typography variant={'body2'}>
          {appointment?.fitterContact?.address?.postcode} {appointment?.fitterContact?.address?.city}
        </Typography>
        <Stack direction={'row'} spacing={1}>
          <Typography variant={'body2'}>{trans('appointment.phone')}:</Typography>
          <Typography variant={'body2'}>{appointment?.fitterContact?.phone} </Typography>
        </Stack>
        {appointment?.fitterContact?.email && (
          <Stack direction={'row'} spacing={1}>
            <Typography variant={'body2'}>{trans('appointment.email')}:</Typography>
            <Typography variant={'body2'}>{appointment?.fitterContact?.email} </Typography>
          </Stack>
        )}
      </Box>
    </>
  )
}
