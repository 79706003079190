import { Card, CardHeader, Stack } from '@mui/material'
import { trans } from '@/utils/translation'
import IconButton from '@mui/material/IconButton'
import Iconify from '@/components/iconify'
import Typography from '@mui/material/Typography'

import { usePage } from '@inertiajs/react'
import CustomLabelValue from '../../components/custom-label-value'
import { formatCurrency, formatNumber } from '@/utils/format'
import { useModalContext } from '@/context/use-modal-context'
import PropertyEditEstimationSettingsModal from '@/sections/property/property-edit-estimation-settings-modal'

export default function PropertyAnalysisEstimation() {
  const { property } = usePage().props
  const { openModal } = useModalContext()

  return (
    <Card>
      <CardHeader
        title={trans('property.analysis_estimation.title')}
        action={
          <IconButton
            onClick={() =>
              openModal('propertyEditEstimationSettings', <PropertyEditEstimationSettingsModal property={property} />)
            }
          >
            <Iconify icon="solar:pen-bold" />
          </IconButton>
        }
      />
      <Stack direction={'column'} spacing={1} sx={{ p: 3, typography: 'body2' }}>
        <Typography variant={'subtitle2'}>
          {' '}
          {trans('property.analysis_estimation.hot_cold_water_costs.title')}
        </Typography>
        <CustomLabelValue
          label={trans('property.analysis_estimation.hot_cold_water_costs.year_consumption')}
          value={`${formatNumber(property.estimationSettings.annualEnergyConsumption, 0)} kWh`}
        />
        <CustomLabelValue
          label={trans('property.analysis_estimation.hot_cold_water_costs.price_per_kwh')}
          value={`${formatCurrency(property.estimationSettings.costPerKilowattHour)} /kWh`}
        />
        <CustomLabelValue
          label={trans('property.analysis_estimation.hot_cold_water_costs.system_sensitivity_hkw')}
          value={`${formatNumber(property.estimationSettings.hcaSensitivity, 3)} Einheiten/kWh`}
        />
        <CustomLabelValue
          label={trans('property.analysis_estimation.hot_cold_water_costs.system_sensitivity_wmz')}
          value={`${formatNumber(property.estimationSettings.heatingSensitivity, 3)} kWh/kWh`}
        />
        <CustomLabelValue
          label={trans('property.analysis_estimation.hot_cold_water_costs.share_of_hot_water')}
          value={`${property.estimationSettings.warmWaterEnergyShare} %`}
        />

        <Typography sx={{ mt: 2 }} variant={'subtitle2'}>
          {' '}
          {trans('property.analysis_estimation.additional_costs.title')}
        </Typography>
        <CustomLabelValue
          label={trans('property.analysis_estimation.additional_costs.price_per_m2')}
          value={`${formatCurrency(property.estimationSettings.costPerSquareMeter)} / m²`}
        />
      </Stack>
    </Card>
  )
}
