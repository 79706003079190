import PropTypes from 'prop-types'

import Box from '@mui/material/Box'

import Main from './main'
import Header from './header'
import NavVertical from './nav-vertical'
import { useBoolean } from '@/hooks/use-boolean'
import ThemeProvider from '@/theme'

// ----------------------------------------------------------------------

export default function DashboardLayout({ children }) {
  const nav = useBoolean()

  const renderNavVertical = <NavVertical openNav={nav.value} onCloseNav={nav.onFalse} />

  return (
    <>
      <ThemeProvider>
        <Header onOpenNav={nav.onTrue} />
        <Box
          sx={{
            minHeight: 1,
            display: 'flex',
            flexDirection: { xs: 'column', lg: 'row' }
          }}
        >
          {renderNavVertical}
          <Main>{children}</Main>
        </Box>
      </ThemeProvider>
    </>
  )
}

DashboardLayout.propTypes = {
  children: PropTypes.node
}
