import { Stack } from '@mui/material'
import { trans } from '@/utils/translation'
import InertiaTextField from '@/components/form/inertia-textfield'
import InertiaSwitch from '@/components/form/inertia-switch'

export default function UserDataForm() {
  return (
    <>
      <Stack direction={'column'} spacing={2}>
        <InertiaTextField
          name={'company'}
          input={{
            label: trans('field.company')
          }}
        />
        <InertiaTextField
          name={'name'}
          input={{
            label: trans('field.name'),
            required: true
          }}
        />
        <InertiaTextField
          name={'address.street'}
          input={{
            label: trans('field.street'),
            required: true
          }}
        />
        <InertiaTextField
          name={'address.streetNumber'}
          input={{
            label: trans('field.street_number'),
            required: true
          }}
        />
        <InertiaTextField
          name={'address.postcode'}
          input={{
            label: trans('field.zip'),
            required: true
          }}
        />
        <InertiaTextField
          name={'address.city'}
          input={{
            label: trans('field.city'),
            required: true
          }}
        />
        <InertiaTextField
          name={'phone'}
          input={{
            label: trans('field.phone')
          }}
        />
        <InertiaTextField
          name={'email'}
          input={{
            label: trans('field.email')
          }}
        />
        <InertiaSwitch
          name={'isAccountActive'}
          input={{
            label: trans('user.data.assetapp_access')
          }}
        />
      </Stack>
    </>
  )
}
