import { Card, CardHeader, Stack } from '@mui/material'
import { trans } from '@/utils/translation'
import IconButton from '@mui/material/IconButton'
import Iconify from '@/components/iconify'

import { usePage } from '@inertiajs/react'
import CustomLabelValue from '../../components/custom-label-value'
import PropertyEditAdminModal from '@/sections/property/property-edit-admin-modal'
import { useModalContext } from '@/context/use-modal-context'
import Button from '@mui/material/Button'
import { ToastCtl } from '@/utils/toast-ctl'
import useSingletonAxios from '@/hooks/use-singleton-axios'

export default function PropertyAdminFunction() {
  const { property } = usePage().props
  const { openModal } = useModalContext()
  const request = useSingletonAxios()

  return (
    <>
      <Card>
        <CardHeader
          title={trans('property.admin_function.title')}
          action={
            <IconButton onClick={() => openModal('propertyEditAdmin', <PropertyEditAdminModal property={property} />)}>
              <Iconify icon="solar:pen-bold" />
            </IconButton>
          }
        />
        <Stack direction="column" justifyContent="space-between" spacing={2} sx={{ p: 3, typography: 'body2' }}>
          <CustomLabelValue
            label={trans('property.admin_function.counter_data')}
            value={trans(`device_value.fetch_interval.${property.settings.deviceValueFetchInterval}`)}
          />
          <Button
            variant={'contained'}
            onClick={() =>
              request({
                method: 'GET',
                url: `/properties/${property.id}/fetchDeviceValues`
              })
                .then(() => ToastCtl.show('Zählerdaten werden im Hintergrund importiert', 'success'))
                .catch(() => ToastCtl.show('Zählerdaten konnten nicht importiert werden', 'error'))
            }
          >
            Zählerdaten abholen
          </Button>
        </Stack>
      </Card>
    </>
  )
}
