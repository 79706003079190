import { useMemo } from 'react'
import { trans } from '@/utils/translation'
import useRemoteTable from '../../hooks/useRemoteTable'
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table'
import { defaultTableOptions, handleErrorResponse } from '@/utils/misc'
import { Card } from '@mui/material'
import { COLUMNS } from './table/device-value-table-definitions'
import DeviceValueTableToolbar from './table/device-value-table-toolbar'
import Iconify from '@/components/iconify'
import PropTypes from 'prop-types'
import { useModalContext } from '@/context/use-modal-context'
import DeviceValueEditModal from '@/sections/device-value/device-value-edit-modal'
import DeviceValueTypes from '@/constants/DeviceValueTypes'
import TableRowAction from '@/components/table/table-row-action'
import { useTheme } from '@mui/material/styles'
import { usePage } from '@inertiajs/react'
import DeviceTypes from '@/constants/DeviceTypes'
import useSingletonAxios from '@/hooks/use-singleton-axios'

export default function DeviceValueListTable({
  defaultFilter = {},
  defaultSorting = [{ id: 'reportDate', desc: true }]
}) {
  const path = `/device_values/list`
  const { openModal } = useModalContext()
  const theme = useTheme()
  const { device } = usePage().props
  const request = useSingletonAxios()

  const columns = useMemo(() => COLUMNS, [])
  const methods = useRemoteTable(path, ['number'], defaultFilter, defaultSorting)
  const {
    globalFilter,
    columnSorting,
    setColumnSorting,
    pagination,
    setPagination,
    data,
    isError,
    isLoading,
    rowCount,
    setRefresh
  } = methods

  const table = useMaterialReactTable({
    ...defaultTableOptions,
    renderTopToolbar: () => <DeviceValueTableToolbar {...methods} />,
    enableRowActions: true,
    renderRowActions: ({ row }) =>
      row.original.type === DeviceValueTypes.MANUAL && device.type !== DeviceTypes.SMOKE ? (
        <TableRowAction
          row={row}
          items={[
            {
              content: (
                <>
                  <Iconify icon="solar:pen-bold" sx={{ mr: 1 }} />
                  {trans('button.edit')}
                </>
              ),
              callback: () => {
                openModal(
                  'deviceValueEdit',
                  <DeviceValueEditModal
                    deviceValue={row.original}
                    device={device}
                    callback={() => setRefresh(new Date())}
                  />
                )
              }
            },
            {
              content: (
                <>
                  <Iconify icon="solar:trash-bin-trash-bold" sx={{ mr: 1 }} color={theme.palette.error.main} />
                  {trans('button.delete')}
                </>
              ),
              callback: () => {
                request({
                  method: 'DELETE',
                  url: `/api/device_values/${row.original.id}`
                })
                  .then(() => {
                    setRefresh(Date.now())
                  })
                  .catch((e) => handleErrorResponse(e))
              }
            }
          ]}
        />
      ) : null,
    muiTablePaperProps: {
      elevation: 0,
      sx: {
        borderRadius: '0'
      }
    },
    muiToolbarAlertBannerProps: isError ? { severity: 'error', children: trans('table.data_load_error') } : undefined,
    columns,
    data,
    rowCount: rowCount,
    onPaginationChange: setPagination,
    onSortingChange: setColumnSorting,
    state: {
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isLoading,
      showSkeletons: isLoading,
      sorting: columnSorting
    }
  })

  return (
    <>
      <Card>
        <MaterialReactTable table={table} />
      </Card>
    </>
  )
}

DeviceValueListTable.propTypes = {
  defaultFilter: PropTypes.object,
  defaultSorting: PropTypes.array
}
