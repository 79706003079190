import { Grid, Stack } from '@mui/material'
import Box from '@mui/material/Box'
import InfoView from '@/components/info-view'
import { trans } from '@/utils/translation'
import { usePage } from '@inertiajs/react'
import DashboardYearConsumption from '@/sections/dashboard-overview/dashboard-year-consumption'
import WidgetSummaryOverview from '@/sections/dashboard-overview/overview/widget-summary'
import { useTheme } from '@mui/material/styles'
import DashboardOverviewComparison from '@/sections/dashboard-overview/dashboard-overview-comparison'
import DashboardWaterConsumption from '@/sections/dashboard-overview/dashboard-water-consumption'
import DashboardHeatingEfficiency from '@/sections/dashboard-overview/dashboard-heating-efficiency'
import DashboardEnergyForecast from '@/sections/dashboard-overview/dashboard-energy-forecast'
import DashboardEnergyComparison from '@/sections/dashboard-overview/dashboard-energy-comparison'
import DashboardApartmentWidget from '@/sections/dashboard-overview/dashboard-apartment-widget'
import dayjs from 'dayjs'
import DeviceTypes from '@/constants/DeviceTypes'
import VideoModal from '@/components/modal/video-modal'
import { useModalContext } from '@/context/use-modal-context'

export default function DashboardOverviewView() {
  const {
    auth,
    apartment,
    heatAndWarmWaterEnergyUsage,
    electricityUsage,
    coldWaterUsage,
    warmWaterUsage,
    heatUsage,
    warmWaterEnergyUsage,
    heatingSettings,
    averageHeatingSum,
    averageUserCost
  } = usePage().props
  const theme = useTheme()
  const { openModal } = useModalContext()

  const video = apartment ? '11_Verbrauch_Wohnung.mp4' : '10_Verbrauch_Immobilie.mp4'

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={8}>
        <InfoView
          header={
            <Stack>
              <Box>{trans('dashboard.info.header')}</Box>
              <Box>{auth.user.name}</Box>
            </Stack>
          }
          description={trans('dashboard.info.description')}
          tip={trans('dashboard.info.tip')}
          button={trans('dashboard.info.button')}
          onClick={() =>
            openModal('videoModal', <VideoModal src={video} controls={true} autoPlay={true} />, { maxWidth: 'lg' })
          }
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <DashboardApartmentWidget />
      </Grid>

      <Grid item xs={12}>
        <DashboardYearConsumption
          title={trans('dashboard.consumption.title')}
          subheader={trans('dashboard.consumption.comparison')}
          heatUsage={heatUsage}
          warmWaterEnergyUsage={warmWaterEnergyUsage}
          heatingSettings={heatingSettings}
        />
      </Grid>

      <Grid item xs={12} md={6} xl={3}>
        <WidgetSummaryOverview
          title={trans('dashboard.consumption.heating')}
          declaration={trans('dashboard.consumption.heating_declaration')}
          empty={trans('dashboard.consumption.heating_empty')}
          usage={heatUsage}
          deviceType={DeviceTypes.HEAT}
          chart={{
            colors: [theme.palette.secondary.light, theme.palette.secondary.main]
          }}
        />
      </Grid>

      <Grid item xs={12} md={6} xl={3}>
        <WidgetSummaryOverview
          title={trans('dashboard.consumption.warm_water')}
          empty={trans('dashboard.consumption.warm_water_empty')}
          usage={warmWaterUsage}
          deviceType={DeviceTypes.WARM_WATER}
          chart={{
            type: 'bar',
            colors: [theme.palette.error.main, theme.palette.error.main]
          }}
        />
      </Grid>

      <Grid item xs={12} md={6} xl={3}>
        <WidgetSummaryOverview
          title={trans('dashboard.consumption.cold_water')}
          empty={trans('dashboard.consumption.cold_water_empty')}
          usage={coldWaterUsage}
          deviceType={DeviceTypes.COLD_WATER}
          chart={{
            type: 'bar',
            colors: [theme.palette.info.dark, theme.palette.info.dark]
          }}
        />
      </Grid>

      <Grid item xs={12} md={6} xl={3}>
        <WidgetSummaryOverview
          title={trans('dashboard.consumption.electricity')}
          empty={trans('dashboard.consumption.electricity_empty')}
          usage={electricityUsage}
          deviceType={DeviceTypes.ELECTRICITY}
          chart={{
            colors: [theme.palette.warning.light, theme.palette.warning.main]
          }}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <DashboardOverviewComparison
          title={trans('dashboard.consumption.heating_previous_month_compare')}
          empty={trans('dashboard.consumption.heating_empty')}
          value={heatUsage[dayjs().subtract(1, 'month').format('YYYY-MM')]}
          previousValue={heatUsage[dayjs().subtract(1, 'month').subtract(1, 'year').format('YYYY-MM')]}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <DashboardOverviewComparison
          title={trans('dashboard.consumption.warm_water_previous_year_compare')}
          empty={trans('dashboard.consumption.warm_water_empty')}
          value={warmWaterEnergyUsage[dayjs().subtract(1, 'month').format('YYYY-MM')]}
          previousValue={warmWaterEnergyUsage[dayjs().subtract(2, 'month').format('YYYY-MM')]}
        />
      </Grid>

      <Grid item xs={12} md={8}>
        <DashboardWaterConsumption
          title={trans('dashboard.water_consumption.title')}
          coldWaterUsage={coldWaterUsage}
          warmWaterUsage={warmWaterUsage}
        />
      </Grid>

      <Grid item xs={12} md={4}>
        <DashboardHeatingEfficiency
          usage={heatUsage}
          heatingSettings={heatingSettings}
          averageHeatingSum={averageHeatingSum}
        />
      </Grid>

      <Grid item xs={12} md={8}>
        <DashboardEnergyForecast usage={heatAndWarmWaterEnergyUsage} />
      </Grid>

      {apartment?.label && (
        <Grid item xs={12} md={4}>
          <DashboardEnergyComparison averageUserCost={averageUserCost} />
        </Grid>
      )}
    </Grid>
  )
}
