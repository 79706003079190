import PropTypes from 'prop-types'

import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import { styled, useTheme } from '@mui/material/styles'
import { formatCurrency } from '@/utils/format'
import Chart, { useChart } from '@/components/chart'
import { trans } from '@/utils/translation'
import Box from '@mui/material/Box'

// ----------------------------------------------------------------------

const CHART_HEIGHT = 400

const LEGEND_HEIGHT = 72

const StyledChart = styled(Chart)(({ theme }) => ({
  height: CHART_HEIGHT,
  '& .apexcharts-canvas, .apexcharts-inner, svg, foreignObject': {
    height: `100% !important`
  },
  '& .apexcharts-legend': {
    height: LEGEND_HEIGHT,
    borderTop: `dashed 1px ${theme.palette.divider}`,
    top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`
  }
}))

// ----------------------------------------------------------------------

export default function AssignmentCostOverview({ title, subheader, chart, ...other }) {
  const theme = useTheme()

  const { colors, series, options } = chart

  const chartSeries = series.map((i) => i.value)

  const chartOptions = useChart({
    chart: {
      sparkline: {
        enabled: true
      }
    },
    colors,
    labels: series.map((i) => i.label),
    stroke: { colors: [theme.palette.background.paper] },
    legend: {
      offsetY: 0,
      floating: true,
      position: 'bottom',
      horizontalAlign: 'center'
    },
    tooltip: {
      fillSeriesColor: false,
      y: {
        formatter: (value) => formatCurrency(value),
        title: {
          formatter: (seriesName) => `${seriesName}`
        }
      }
    },
    plotOptions: {
      pie: {
        donut: {
          size: '90%',
          labels: {
            show: true,
            value: {
              fontSize: '14px',
              fontWeight: 400,
              offsetY: 4
            },
            total: {
              fontSize: '22px',
              color: theme.palette.text.primary,
              show: true,
              showAlways: true,
              label: formatCurrency(
                other?.calculated?.apartmentCount > 0
                  ? other.calculated.alloverGross / other.calculated.apartmentCount
                  : 0
              ),
              formatter: () => {
                return trans('assignment.customize.apartment_cost')
              }
            }
          }
        }
      }
    },
    ...options
  })

  return (
    <Card sx={{ width: { lg: '48%', md: '100%' }, position: 'relative' }}>
      <CardHeader title={title} subheader={subheader} sx={{ mb: 5 }} />

      <Box
        sx={{
          bottom: '80px',
          right: '2rem',
          display: 'flex',
          flexDirection: 'column',
          fontSize: '10px',
          position: 'absolute'
        }}
      >
        <span>*{trans('assignment.customize.price_vat_hint')}</span>
        <span>**{trans('assignment.customize.price_cost_hint')}</span>
      </Box>
      <StyledChart dir="ltr" type="donut" series={chartSeries} options={chartOptions} width="100%" height={280} />
    </Card>
  )
}

AssignmentCostOverview.propTypes = {
  chart: PropTypes.object,
  subheader: PropTypes.string,
  title: PropTypes.string
}
