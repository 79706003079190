import Typography from '@mui/material/Typography'
import { Card } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import CustomButton from '@/components/custom-button'
import PropTypes from 'prop-types'

export default function InfoView(props) {
  const theme = useTheme()

  return (
    <Card
      sx={{
        px: { xs: 2 },
        py: { xs: 2, sm: 6 },
        height: '100%',
        backgroundColor: theme.palette.secondary.lighter
      }}
    >
      <Typography variant={'h5'} mb={1} mx={4} color={theme.palette.secondary.darker}>
        {props.header}
      </Typography>
      <Typography variant={'body2'} mx={4} mb={2} color={theme.palette.secondary.darker}>
        {props.description} <br />
        {props.tip}
      </Typography>
      <CustomButton
        variant="contained"
        sx={{ mx: 4, backgroundColor: theme.palette.secondary.main }}
        onClick={props.onClick}
      >
        {props.button}
      </CustomButton>
    </Card>
  )
}

InfoView.propTypes = {
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  description: PropTypes.string,
  tip: PropTypes.string,
  button: PropTypes.string,
  onClick: PropTypes.func
}
