import { useEffect, useMemo, useState } from 'react'
import { trans } from '@/utils/translation'
import useRemoteTable from '../../hooks/useRemoteTable'
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table'
import { defaultTableOptions, hasRole } from '@/utils/misc'
import { Card, Checkbox, Stack, Typography } from '@mui/material'
import { COLUMNS } from './table/document-table-definitions'
import DocumentTableToolbar from './table/document-table-toolbar'
import PropTypes from 'prop-types'
import TableRowAction from '@/components/table/table-row-action'
import Iconify from '@/components/iconify'
import { useTheme } from '@mui/material/styles'
import { useBoolean } from '@/hooks/use-boolean'
import TableConfirmDelete from '@/components/table/table-confirm-delete'
import DocumentUpdateModal from '@/sections/document/document-update-modal'
import { useModalContext } from '@/context/use-modal-context'
import DocumentManagerDetails from '@/sections/document/document-manager-details'
import { RemoveOutlined } from '@mui/icons-material'
import IconButton from '@mui/material/IconButton'
import TableConfirmBatchDelete from '@/components/table/table-confirm-batch-delete'
import { usePage } from '@inertiajs/react'
import Roles from '@/constants/Roles'

export default function DocumentListTable({
  defaultFilter,
  defaultSorting = [{ id: 'uploaded_on', desc: true }],
  defaultPagination = { pageSize: 10, pageIndex: 0 },
  ...options
}) {
  const path = '/documents/list'
  const theme = useTheme()
  const confirmRow = useBoolean()
  const confirmRows = useBoolean()
  const [file, setFile] = useState(null)
  const { openModal } = useModalContext()
  const [item, setItem] = useState(null)
  const details = useBoolean()
  const { auth } = usePage().props

  const columns = useMemo(() => COLUMNS, [])
  const methods = useRemoteTable(path, ['name'], defaultFilter, defaultSorting, defaultPagination)
  const {
    globalFilter,
    columnSorting,
    setColumnSorting,
    pagination,
    setPagination,
    data,
    isError,
    isLoading,
    rowCount,
    setRowSelection,
    rowSelection,
    setRefresh
  } = methods

  // Clear selection on paginate
  useEffect(() => {
    setRowSelection([])
  }, [pagination])

  // Update sidebar item on data change
  useEffect(() => {
    if (item?.id) {
      setItem(data.filter((data) => data.id === item.id)[0])
    }
  }, [data])

  const items = (row) => {
    const items = [
      {
        content: (
          <>
            <Iconify icon={'eva:eye-outline'} />
            <Typography variant={'body'}>{trans('button.show')}</Typography>
          </>
        ),
        callback: () => {
          window.open(`/documents/${row.original.id}/view`, '_blank')
        }
      },
      {
        content: (
          <>
            <Iconify icon={'eva:download-outline'} />
            <Typography variant={'body'}>{trans('button.download')}</Typography>
          </>
        ),
        callback: () => {
          document.location.href = `/documents/${row.original.id}/download`
        }
      }
    ]

    if (row.original.createdBy?.id === auth.user.id || hasRole(auth.user, Roles.ROLE_ADMIN)) {
      items.push(
        {
          content: (
            <>
              <Iconify icon={'eva:share-outline'} />
              <Typography variant={'body'}>{trans('button.share')}</Typography>
            </>
          ),
          callback: () => {
            openModal('documentUpdate', <DocumentUpdateModal documentId={row.original.id} setRefresh={setRefresh} />)
          }
        },
        {
          content: (
            <>
              <Iconify icon={'eva:trash-2-outline'} color={theme.palette.error.main} />
              <Typography variant={'body'}>{trans('button.delete')}</Typography>
            </>
          ),
          callback: () => {
            setFile(row.original)
            confirmRow.onTrue()
          }
        }
      )
    }

    return items
  }

  const table = useMaterialReactTable({
    ...defaultTableOptions,
    enableRowActions: true,
    enableRowSelection: true,
    enableMultiRowSelection: true,
    renderRowActions: ({ row }) => <TableRowAction row={row} width={180} items={items(row)} />,
    renderTopToolbar: () => <DocumentTableToolbar {...methods} />,
    muiTablePaperProps: {
      elevation: 0,
      sx: {
        borderRadius: '0'
      }
    },
    muiTableBodyRowProps: ({ row }) => ({
      onClick: (e) => {
        // Open file if not on main document list
        if (document.location.pathname !== '/documents' && !e.target.classList.contains('MuiBackdrop-root')) {
          window.open(`/documents/${row.original.id}/view`, '_blank')
          return
        }

        if (!e.target.classList.contains('MuiBackdrop-root')) {
          setItem(row.original)
          details.onTrue()
        }
      },
      sx: { cursor: 'pointer' }
    }),
    positionToolbarAlertBanner: 'head-overlay',
    muiToolbarAlertBannerProps: isError ? { severity: 'error' } : { severity: 'warning' },
    renderToolbarAlertBannerContent: () => {
      if (isError) {
        return trans('table.data_load_error')
      }

      return (
        <Stack direction={'row'} sx={{ px: 1 }} alignItems={'center'} justifyContent={'space-between'}>
          <Stack direction={'row'} alignItems={'center'}>
            <Checkbox
              sx={{ mr: 3 }}
              checkedIcon={
                <RemoveOutlined
                  sx={{
                    background: theme.palette.primary.main,
                    color: theme.palette.common.white,
                    borderRadius: '5px'
                  }}
                />
              }
              defaultChecked
              onClick={() => setRowSelection([])}
            />
            <Typography fontSize={'small'}>
              {Object.values(rowSelection).length} {trans('table.selected')}
            </Typography>
          </Stack>
          <IconButton onClick={confirmRows.onTrue}>
            <Iconify icon={'eva:trash-2-outline'} color={theme.palette.error.main} />
          </IconButton>
        </Stack>
      )
    },
    columns,
    data,
    rowCount: rowCount,
    onPaginationChange: setPagination,
    onSortingChange: setColumnSorting,
    onRowSelectionChange: setRowSelection,
    state: {
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isLoading,
      showSkeletons: isLoading,
      sorting: columnSorting,
      rowSelection
    },
    ...options
  })

  return (
    <>
      <Card>
        <MaterialReactTable table={table} />
      </Card>

      <TableConfirmDelete
        url={`/documents/${file?.id}`}
        confirmRows={confirmRow}
        setValue={setFile}
        setRefresh={setRefresh}
      />

      <TableConfirmBatchDelete
        url={`/documents/delete`}
        data={Object.keys(rowSelection).map((row, index) => data[index].id)}
        confirmRows={confirmRows}
        setRowSelection={setRowSelection}
        setRefresh={setRefresh}
      />

      {item && (
        <DocumentManagerDetails item={item} open={details.value} close={details.onFalse} setRefresh={setRefresh} />
      )}
    </>
  )
}

DocumentListTable.propTypes = {
  defaultFilter: PropTypes.object,
  defaultSorting: PropTypes.array,
  defaultPagination: PropTypes.object
}
