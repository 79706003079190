import { Head } from '@inertiajs/react'
import { trans } from '@/utils/translation'
import AuthLayout from '../../layouts/auth'
import CustomLink from '@/components/custom-link'

const Register = () => {
  return (
    <>
      <Head>
        <title>{trans('register.header')}</title>
      </Head>
      <p>Bitte bestätigen Sie ihre E-Mail Adresse, um die Registrierung abzuschließen.</p>
      <p>
        Wenn Sie keine E-Mail erhalten, überprüfen Sie bitte Ihren Spam-Ordner oder{' '}
        <CustomLink href="/register">versuchen Sie es erneut</CustomLink>.
      </p>
    </>
  )
}

Register.layout = (page) => <AuthLayout>{page}</AuthLayout>

export default Register
