import { useState } from 'react'
import { Stack, Typography } from '@mui/material'
import { useFormContext } from '@/context/use-form-context'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import UploadIllustration from '@/illustrations/upload-illustration'
import { alpha } from '@mui/material/styles'
import { useDropzone } from 'react-dropzone'
import Paper from '@mui/material/Paper'
import { trans } from '@/utils/translation'
import FilePreview from '@/components/file-thumbnail/file-preview'
import { ToastCtl } from '@/utils/toast-ctl'

const InertiaFileDragDrop = ({ name, input }) => {
  const { setData, errors, clearErrors } = useFormContext()
  const { multiple, disabled } = input
  const [files, setFiles] = useState([])

  const onRemove = (removedFile) => {
    const filesAfterRemove = files.filter((file) => file.name !== removedFile.name)
    setData(name, filesAfterRemove)
    setFiles(filesAfterRemove)
  }

  const onUpload = (acceptedFiles) => {
    if (!acceptedFiles.length) {
      return
    }

    if (!multiple) {
      setData(name, [acceptedFiles[0]])
      setFiles([acceptedFiles[0]])
    } else {
      setData(name, [...files, ...acceptedFiles])
      setFiles([...files, ...acceptedFiles])
    }

    clearErrors(name)
  }

  const { getRootProps, getInputProps, isDragActive, fileRejections } = useDropzone({
    onDrop: onUpload,
    multiple,
    disabled,
    accept: {
      'application/pdf': ['.pdf'],
      'image/png': ['.png'],
      'image/gif': ['.gif'],
      'image/jpg': ['.jpg', '.jpeg']
    }
  })

  const renderFileRejections = fileRejections?.length > 0 && (
    <Paper
      variant="outlined"
      sx={{
        py: 1,
        px: 2,
        mt: 3,
        textAlign: 'left',
        borderStyle: 'dashed',
        borderColor: 'error.main',
        bgcolor: (theme) => alpha(theme.palette.error.main, 0.08)
      }}
    >
      {fileRejections.map(({ file, errors }) => {
        const { name } = file

        return (
          <Box key={name} sx={{ my: 1 }}>
            <Typography variant="subtitle2" noWrap>
              {name}
            </Typography>

            {errors.map((error) => (
              <Box key={error.code} component="span" sx={{ typography: 'caption' }}>
                - {error.message}
              </Box>
            ))}
          </Box>
        )
      })}
    </Paper>
  )

  const renderPlaceholder = (
    <Stack
      {...getRootProps()}
      spacing={3}
      alignItems="center"
      justifyContent="center"
      flexWrap="wrap"
      sx={{
        flexShrink: 0,
        width: '100%',
        p: 5,
        outline: 'none',
        borderRadius: 1,
        cursor: 'pointer',
        overflow: 'hidden',
        position: 'relative',
        bgcolor: (theme) => alpha(theme.palette.grey[500], 0.08),
        border: (theme) => `1px dashed ${alpha(theme.palette.grey[500], 0.2)}`,
        transition: (theme) => theme.transitions.create(['opacity', 'padding']),
        '&:hover': {
          opacity: 0.72
        },
        ...(isDragActive && {
          opacity: 0.72
        }),
        ...(disabled && {
          opacity: 0.48,
          pointerEvents: 'none'
        })
      }}
    >
      <UploadIllustration sx={{ width: 1, maxWidth: 200 }} />
      <Stack spacing={1} sx={{ alignItems: 'center', justifyContent: 'center' }}>
        <Typography variant="h6">{trans('field.drag_n_drop.title')}</Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          {trans('field.drag_n_drop.description_pre')}
          <Box
            component="span"
            sx={{
              mx: 0.5,
              color: 'primary.main',
              textDecoration: 'underline'
            }}
          >
            {trans('field.drag_n_drop.description')}
          </Box>
          {trans('field.drag_n_drop.description_post')}
        </Typography>
      </Stack>
    </Stack>
  )

  return (
    <label htmlFor={`upload-${name}`} style={{ display: 'flex', gap: '1rem', alignItems: 'flex-start' }}>
      <input
        {...getInputProps()}
        style={{ display: 'none' }}
        id={`upload-${name}`}
        name={name}
        type="file"
        onChange={(e) => {
          if (getInputProps()?.accept && !getInputProps().accept.includes(e.target.files[0].type)) {
            ToastCtl.show('Dateityp nicht erlaubt', 'error')
            return
          }

          onUpload(e.target.files)
        }}
      />
      <Stack direction={'column'} spacing={2} sx={{ width: '100%', flexShrink: 0 }}>
        {renderPlaceholder}
        {files.map((file) => (
          <FilePreview key={file?.name} label={''} file={file} onRemove={() => onRemove(file)} />
        ))}
        <span className="validation-error">{errors[name]}</span>

        {renderFileRejections}
      </Stack>
    </label>
  )
}

InertiaFileDragDrop.propTypes = {
  name: PropTypes.string,
  input: PropTypes.object
}

export default InertiaFileDragDrop
