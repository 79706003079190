import DashboardLayout from '@/layouts/dashboard'
import { DashboardOverviewView } from '@/sections/dashboard-overview/view'
import { Head } from '@inertiajs/react'
import { trans } from '@/utils/translation'

const DashboardOverview = () => {
  return (
    <>
      <Head>
        <title>{trans('dashboard.title')}</title>
      </Head>
      <DashboardOverviewView />
    </>
  )
}

DashboardOverview.layout = (page) => <DashboardLayout>{page}</DashboardLayout>

export default DashboardOverview
