import { router } from '@inertiajs/react'
import Link from '@mui/material/Link'
import { unset } from 'lodash'
import PropTypes from 'prop-types'

const CustomLink = ({ children, ...options }) => {
  const href = options.href
  options = { ...options, sx: { ...options.sx, cursor: 'pointer' } }
  unset(options, 'href')

  return (
    <Link
      sx={{ cursor: 'pointer' }}
      color={'inherit'}
      underline={'always'}
      {...options}
      onClick={() => router.get(href)}
    >
      {children}
    </Link>
  )
}

CustomLink.propTypes = {
  children: PropTypes.node.isRequired,
  href: PropTypes.string.isRequired
}

export default CustomLink
