import { Head } from '@inertiajs/react'
import { trans } from '@/utils/translation'
import DashboardLayout from '../../layouts/dashboard'
import { ApartmentListView } from '../../sections/apartment/view'

const ApartmentListPage = () => {
  return (
    <>
      <Head>
        <title>{trans('apartment.title')}</title>
      </Head>
      <ApartmentListView />
    </>
  )
}
ApartmentListPage.layout = (page) => <DashboardLayout>{page}</DashboardLayout>

export default ApartmentListPage
