import { trans } from '@/utils/translation'
import { formatDate, formatDeviceValue } from '@/utils/format'

export const COLUMNS = [
  {
    accessorKey: 'reportDate',
    header: trans('field.device_value.report_date'),
    Cell: ({ renderedCellValue }) => (renderedCellValue ? formatDate(renderedCellValue, 'DD.MM.YYYY HH:mm') : '-'),
    size: 100,
    enableSorting: true
  },
  {
    accessorKey: 'type',
    header: trans('field.device_value.type'),
    Cell: ({ renderedCellValue }) => trans(`device_value.type.${renderedCellValue?.toUpperCase()}`),
    size: 150,
    enableSorting: false
  },
  {
    accessorKey: 'value',
    header: trans('field.device_value.value'),
    Cell: ({ renderedCellValue, row }) =>
      formatDeviceValue(renderedCellValue, row.original.deviceType, row.original.unit),
    size: 150,
    enableSorting: true
  }
]
