import { Head } from '@inertiajs/react'
import DashboardLayout from '@/layouts/dashboard'
import { trans } from '@/utils/translation'
import { NewsListView } from '@/sections/news/view'

const NewsList = () => {
  return (
    <>
      <Head>
        <title>{trans('menu.news')}</title>
      </Head>
      <NewsListView />
    </>
  )
}
NewsList.layout = (page) => <DashboardLayout>{page}</DashboardLayout>
export default NewsList
