import { DialogActions, DialogTitle, Stack, Typography } from '@mui/material'
import { trans } from '@/utils/translation'
import DialogContent from '@mui/material/DialogContent'
import CustomButton from '@/components/custom-button'
import { LoadingButton } from '@mui/lab'
import { useModalContext } from '@/context/use-modal-context'
import { useForm } from '@/hooks/use-form'
import { router } from '@inertiajs/react'
import { ToastCtl } from '@/utils/toast-ctl'
import useSubmit from '@/hooks/use-submit'
import { FormProvider } from '@/context/use-form-context'
import InertiaSwitch from '@/components/form/inertia-switch'
import InertiaRemoteAutocomplete from '@/components/form/inertia-remote-autocomplete'
import InertiaDatePicker from '@/components/form/inertia-date-picker'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import UserRemoteCreateSelect from '@/components/user-remote-create-select'

export default function CreateModal({ defaultValues }) {
  const { openModal, closeModal } = useModalContext()

  const methods = useForm({
    url: {
      method: defaultValues?.id ? 'PUT' : 'POST',
      location: defaultValues?.id ? `/api/apartment_tenants/${defaultValues.id}` : `/api/apartment_tenants/create`
    },
    defaultValues: {
      isEmpty: false,
      user: null,
      property: null,
      apartments: null,
      begin: dayjs().startOf('month'),
      ...defaultValues
    }
  })

  const { submit, data, setData, setOverrideFieldValues, submitting } = methods

  const handleSubmit = (options) => {
    submit(
      () => {
        router.reload()
        ToastCtl.show(trans('general.save_success'), 'success')
        closeModal('apartmentTenantCreate')
      },
      (e) => {
        // Do not show default api platform errors in modal
        if (e?.response?.data?.['@type'] === 'ConstraintViolationList') {
          return
        }

        if (!e.response?.data?.violations?.[0]) {
          ToastCtl.show(e.response?.data?.detail ?? trans('validation.general_error'), 'error')
        }

        openModal(
          'apartmentTenantCreateError',
          <>
            <DialogTitle>{trans(`general.attention`)}</DialogTitle>
            <DialogContent dividers sx={{ py: 1 }}>
              <Typography variant={'body2'}>{e.response.data.detail}</Typography>
            </DialogContent>
            <DialogActions>
              <CustomButton onClick={() => closeModal('apartmentTenantCreateError')}>
                {trans('button.cancel')}
              </CustomButton>
              <LoadingButton
                loading={submitting}
                variant={'contained'}
                onClick={() => {
                  closeModal('apartmentTenantCreateError')
                  if (e.response.data.violations.find((item) => item.code === 'NON_RECOVERABLE')) return

                  handleSubmit({
                    data: {
                      isConfirmed: true
                    }
                  })
                }}
              >
                {trans('button.confirm')}
              </LoadingButton>
            </DialogActions>
          </>
        )
      },
      options
    )
  }

  useSubmit(() => handleSubmit())

  return (
    <>
      <DialogTitle>{defaultValues?.id ? trans(`apartment_tenant.edit`) : trans(`apartment_tenant.create`)}</DialogTitle>
      <DialogContent dividers sx={{ py: 1 }}>
        <FormProvider {...methods} sx={{ px: 2, mx: 8 }}>
          <form onSubmit={handleSubmit}>
            <Stack direction={'column'} spacing={2}>
              {!defaultValues?.isEmpty && !defaultValues?.user && (
                <InertiaSwitch
                  name={'isEmpty'}
                  input={{
                    label: trans('apartment_tenant.is_empty')
                  }}
                />
              )}
              {!data.isEmpty && !defaultValues?.user && (
                <UserRemoteCreateSelect
                  label={trans('general.tenant')}
                  type={'tenant'}
                  apartment={defaultValues?.apartment}
                />
              )}
              {!defaultValues?.property && (
                <InertiaRemoteAutocomplete
                  name={'property'}
                  input={{
                    label: trans('general.property'),
                    disabled: !(data.user || data.isEmpty)
                  }}
                  url={'/api/properties?label=%s&itemsPerPage=10'}
                  callback={() => {
                    setData('apartments', [])
                    setOverrideFieldValues({
                      apartments: []
                    })
                  }}
                />
              )}
              {!defaultValues?.apartments && (
                <InertiaRemoteAutocomplete
                  name={'apartments'}
                  input={{
                    multiple: true,
                    label: trans('general.apartment'),
                    disabled: !data.property
                  }}
                  url={`/api/apartments?property.id=${data.property}&label=%s&itemsPerPage=10`}
                />
              )}
              <>
                <Stack direction={'row'} spacing={2}>
                  <InertiaDatePicker
                    name={'begin'}
                    input={{
                      label: trans('general.from')
                    }}
                  />
                  <InertiaDatePicker
                    name={'end'}
                    input={{
                      label: trans('general.to')
                    }}
                  />
                </Stack>
              </>
            </Stack>
          </form>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <CustomButton onClick={() => closeModal('apartmentTenantCreate')}>{trans('button.cancel')}</CustomButton>
        <LoadingButton
          loading={submitting}
          variant={'contained'}
          onClick={() => {
            handleSubmit()
          }}
        >
          {trans('button.done')}
        </LoadingButton>
      </DialogActions>
    </>
  )
}

CreateModal.propTypes = {
  defaultValues: PropTypes.object
}
