import Button from '@mui/material/Button'
import PropTypes from 'prop-types'

const CustomButton = ({ children, ...options }) => {
  return (
    <Button variant={'outlined'} {...options}>
      {children}
    </Button>
  )
}

CustomButton.propTypes = {
  children: PropTypes.node.isRequired
}

export default CustomButton
