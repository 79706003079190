import { useMemo } from 'react'
import { trans } from '@/utils/translation'
import useRemoteTable from '../../hooks/useRemoteTable'
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table'
import { defaultTableOptions } from '@/utils/misc'
import { Card } from '@mui/material'
import { router } from '@inertiajs/react'
import { COLUMNS } from './table/appointment-table-definitions'
import AppointmentTableToolbar from './table/appointment-table-toolbar'

export default function AppointmentListTable() {
  const path = '/appointments/list'

  const columns = useMemo(() => COLUMNS, [])
  const methods = useRemoteTable(
    path,
    ['street_number', 'postcode_city'],
    {
      state: ''
    },
    [{ id: 'begin', desc: true }]
  )
  const {
    globalFilter,
    columnSorting,
    setColumnSorting,
    pagination,
    setPagination,
    data,
    isError,
    isLoading,
    rowCount
  } = methods

  const table = useMaterialReactTable({
    ...defaultTableOptions,
    renderTopToolbar: () => <AppointmentTableToolbar {...methods} />,
    muiTablePaperProps: {
      elevation: 0,
      sx: {
        borderRadius: '0'
      }
    },
    muiTableBodyRowProps: ({ row }) => ({
      onClick: () => router.get(`/appointments/${row.original.id}/detail`),
      sx: { cursor: 'pointer' }
    }),
    muiToolbarAlertBannerProps: isError ? { severity: 'error', children: trans('table.data_load_error') } : undefined,
    columns,
    data,
    rowCount: rowCount,
    onPaginationChange: setPagination,
    onSortingChange: setColumnSorting,
    state: {
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isLoading,
      showSkeletons: isLoading,
      sorting: columnSorting
    }
  })

  return (
    <>
      <Card>
        <MaterialReactTable table={table} />
      </Card>
    </>
  )
}
