import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import { useTheme } from '@mui/material/styles'
import Chart, { useChart } from '@/components/chart'
import CustomLink from '@/components/custom-link'
import Tooltip from '@mui/material/Tooltip'
import { trans } from '@/utils/translation'
import Iconify from '@/components/iconify'
import Divider from '@mui/material/Divider'
import { useCallback, useState } from 'react'
import CustomPopover, { usePopover } from '@/components/custom-popover'
import ButtonBase from '@mui/material/ButtonBase'
import MenuItem from '@mui/material/MenuItem'

export default function BillableProperties({ title, footer, chart, ...other }) {
  const theme = useTheme()

  const [year, setYear] = useState(new Date().getFullYear() - 1)

  const [visibleSerie, setVisibleSerie] = useState(chart.series.filter((i) => i.year === year)[0])

  const totalCount = () => visibleSerie.data.filter((i) => i.label === 'properties')[0].value
  const calcBillableCount = (series) =>
    series.data.filter((i) => i.label === 'properties')[0].value -
    series.data.filter((i) => i.label === 'billings')[0].value

  const [billableCount, setBillableCount] = useState(calcBillableCount(visibleSerie))
  const [billablePercentage, setBillablePercentage] = useState(
    (visibleSerie.data.filter((i) => i.label === 'billings')[0].value / totalCount()) * 100
  )

  const popover = usePopover()

  const { colors = [theme.palette.secondary.main, theme.palette.warning.main], series, options } = chart

  const titleTip = (
    <>
      {title}
      <Tooltip title={trans('dashboard.property.declaration')}>
        <Iconify icon={'bi:info-circle'} sx={{ ml: 1, width: '14px', height: '14px' }} />
      </Tooltip>
    </>
  )

  const chartOptions = useChart({
    legend: {
      show: false
    },
    grid: {
      padding: { top: -32, bottom: -32 }
    },
    fill: {
      type: 'gradient',
      gradient: {
        colorStops: [
          { offset: 0, color: colors[0], opacity: 1 },
          { offset: 100, color: colors[1], opacity: 1 }
        ]
      }
    },
    plotOptions: {
      radialBar: {
        hollow: { size: '68%' },
        dataLabels: {
          value: {
            offsetY: 8
          },
          total: {
            label: trans('dashboard.property.billable_properties'),
            color: theme.palette.text.disabled,
            fontSize: theme.typography.caption.fontSize,
            fontWeight: theme.typography.caption.fontWeight,
            formatter: () => `noch ${parseInt(billableCount)}`
          }
        }
      }
    },
    ...options
  })

  const handleChangeSeries = useCallback(
    (newValue) => {
      popover.onClose()

      const newVisibleSerie = chart.series.filter((i) => i.year === newValue)[0]

      setYear(newValue)
      setVisibleSerie(newVisibleSerie)
      setBillableCount(calcBillableCount(newVisibleSerie))
      setBillablePercentage((newVisibleSerie.data.filter((i) => i.label === 'billings')[0].value / totalCount()) * 100)
    },
    [popover]
  )

  return (
    <>
      <Card {...other}>
        <CardHeader
          title={titleTip}
          sx={{ mb: 8 }}
          action={
            <ButtonBase
              onClick={popover.onOpen}
              sx={{
                pl: 1,
                py: 0.5,
                pr: 0.5,
                borderRadius: 1,
                typography: 'subtitle2',
                bgcolor: 'background.neutral'
              }}
            >
              {year}

              <Iconify
                width={16}
                icon={popover.open ? 'eva:arrow-ios-upward-fill' : 'eva:arrow-ios-downward-fill'}
                sx={{ ml: 0.5 }}
              />
            </ButtonBase>
          }
        />
        {series.map((item) => (
          <Box key={item.year}>
            {item.year === year && (
              <Chart
                dir="ltr"
                type="radialBar"
                options={chartOptions}
                width="100%"
                height={310}
                series={[billablePercentage]}
                sx={{
                  mb: 8
                }}
              />
            )}
          </Box>
        ))}

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Box m={2}>
          <CustomLink sx={{ float: 'right', textDecoration: 'none', mb: 2 }} href={'/billings'}>
            {footer}
          </CustomLink>
        </Box>
      </Card>

      <CustomPopover open={popover.open} onClose={popover.onClose} sx={{ width: 140 }}>
        {series.map((option) => (
          <MenuItem key={option.year} selected={option.year === year} onClick={() => handleChangeSeries(option.year)}>
            {option.year}
          </MenuItem>
        ))}
      </CustomPopover>
    </>
  )
}

BillableProperties.propTypes = {
  chart: PropTypes.object,
  subheader: PropTypes.string,
  title: PropTypes.string,
  footer: PropTypes.string
}
