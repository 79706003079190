import { Typography } from '@mui/material'
import { trans } from '@/utils/translation'
import TenantListTable from '../tenant-list-table'

export default function TenantListView(props) {
  return (
    <>
      <Typography variant="h5" gutterBottom>
        {trans('tenant.header')}
      </Typography>
      <TenantListTable {...props} />
    </>
  )
}
