import Typography from '@mui/material/Typography'
import { Card } from '@mui/material'
import { trans } from '@/utils/translation'
import Stack from '@mui/material/Stack'
import Iconify from '@/components/iconify'
import { formatPercent } from '@/utils/format'
import { useTheme } from '@mui/material/styles'
import ProgressItem from '@/sections/dashboard-overview/components/progress-item'
import Image from '@/components/image'
import { normalizeValues } from '@/utils/chart'
import EmptyItem from '@/sections/dashboard-overview/components/empty-item'
import PropTypes from 'prop-types'

export default function DashboardEnergyComparison({ averageUserCost }) {
  const theme = useTheme()
  const value = averageUserCost?.energyUsage
  const previousValue = averageUserCost?.defaultEnergyUsage

  const percent = (value - previousValue) / previousValue
  const img = percent < 0 ? 'character_4.png' : 'character_6.png'

  const [valueNormalized, previousValueNormalized] = normalizeValues(value, previousValue)

  return (
    <>
      {value !== undefined ? (
        <Card sx={{ p: 3 }}>
          <Typography variant={'subtitle1'}>{trans('dashboard.comparison.title')}</Typography>

          {isNaN(percent) ? (
            <Typography component="div" variant="subtitle2" sx={{ mt: 1 }}>
              {trans('dashboard.comparison.no_data')}
            </Typography>
          ) : (
            <Stack direction="row" alignItems="center" sx={{ mt: 2, mb: 1 }}>
              <Iconify
                width={24}
                icon={
                  percent < 0 ? 'solar:double-alt-arrow-down-bold-duotone' : 'solar:double-alt-arrow-up-bold-duotone'
                }
                sx={{
                  mr: 1,
                  color: 'error.main',
                  ...(percent < 0 && {
                    color: 'success.main'
                  })
                }}
              />
              <Typography component="div" variant="subtitle2">
                {percent > 0 && '+'}
                {formatPercent(percent, 1)} {percent > 0 && trans('dashboard.comparison.more')}{' '}
                {percent < 0 && trans('dashboard.comparison.less')}
              </Typography>
            </Stack>
          )}

          <Stack direction={'row'} justifyContent={'space-between'} sx={{ mt: 2 }}>
            <Image src={require(`../../../images/illustrations/${img}`)} width={'30%'} />

            <Stack directon={'column'} width={'65%'} spacing={2}>
              <ProgressItem
                progress={valueNormalized}
                value={value}
                color={theme.palette.warning.main}
                sx={{ display: 'flex', justifyContent: 'space-between' }}
              />
              <ProgressItem
                progress={previousValueNormalized}
                value={previousValue}
                color={theme.palette.grey[400]}
                sx={{ display: 'flex', justifyContent: 'space-between' }}
              />
            </Stack>
          </Stack>
        </Card>
      ) : (
        <EmptyItem message={trans('dashboard.consumption.heating_empty')} />
      )}
    </>
  )
}

DashboardEnergyComparison.propTypes = {
  averageUserCost: PropTypes.object
}
