import { DialogActions, DialogTitle } from '@mui/material'
import { trans } from '@/utils/translation'
import DialogContent from '@mui/material/DialogContent'
import CustomButton from '@/components/custom-button'
import { LoadingButton } from '@mui/lab'
import { useModalContext } from '@/context/use-modal-context'
import { useForm } from '@/hooks/use-form'
import { router } from '@inertiajs/react'
import { ToastCtl } from '@/utils/toast-ctl'
import useSubmit from '@/hooks/use-submit'
import { FormProvider } from '@/context/use-form-context'
import PropTypes from 'prop-types'
import UserDataForm from '@/sections/user/form/user-data-form'

export default function UserCreateModal({ type = 'tenant', callback = null, apartment }) {
  const { closeModal } = useModalContext()

  const methods = useForm({
    url: {
      method: 'POST',
      location: `/api/users/create`
    },
    defaultValues: {
      company: '',
      name: '',
      address: {
        street: apartment?.address?.street ?? '',
        streetNumber: apartment?.address?.streetNumber ?? '',
        postcode: apartment?.address?.postcode ?? '',
        city: apartment?.address?.city ?? ''
      },
      phone: '',
      email: '',
      type: type,
      isAccountActive: false
    }
  })

  const { submit, submitting } = methods

  const handleSubmit = () => {
    submit((response) => {
      callback ? callback(response) : router.get(`/${type}s/${response.data.id}/detail`)
      ToastCtl.show(trans('general.save_success'), 'success')
      closeModal('userCreate')
    })
  }

  useSubmit(() => handleSubmit())

  return (
    <>
      <DialogTitle>{trans(`${type}.create`)}</DialogTitle>
      <DialogContent dividers sx={{ py: 1 }}>
        <FormProvider {...methods} sx={{ px: 2, mx: 8 }}>
          <form onSubmit={handleSubmit}>
            <UserDataForm />
          </form>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <CustomButton
          onClick={() => {
            callback ? callback() : null
            closeModal('userCreate')
          }}
        >
          {trans('button.cancel')}
        </CustomButton>
        <LoadingButton
          loading={submitting}
          variant={'contained'}
          onClick={() => {
            handleSubmit()
          }}
        >
          {trans('button.done')}
        </LoadingButton>
      </DialogActions>
    </>
  )
}

UserCreateModal.propTypes = {
  type: PropTypes.string,
  callback: PropTypes.func,
  apartment: PropTypes.object
}
