import DashboardLayout from '@/layouts/dashboard'
import { DashboardView } from '@/sections/dashboard/view'
import { Head } from '@inertiajs/react'
import { trans } from '@/utils/translation'

const DashboardList = () => {
  return (
    <>
      <Head>
        <title>{trans('dashboard.title')}</title>
      </Head>
      <DashboardView />
    </>
  )
}

DashboardList.layout = (page) => <DashboardLayout>{page}</DashboardLayout>

export default DashboardList
