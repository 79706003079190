import { alpha } from '@mui/material/styles'

// ----------------------------------------------------------------------

// SETUP COLORS

export const grey = {
  0: '#FFFFFF',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24'
}

export const primary = {
  main: '#1f1f1f',
  lighter: '#5b5b5b',
  light: '#4B4B4B',
  dark: '#151515',
  darker: '#101010',
  contrastText: '#FFFFFF'
}

export const secondary = {
  main: '#ffa500',
  lighter: '#fdf5e3',
  light: '#FFD466',
  dark: '#B76900',
  darker: '#7A3D00',
  contrastText: '#FFFFFF'
}

export const info = {
  lighter: '#CAFDF5',
  light: '#61F3F3',
  main: '#00B8D9',
  dark: '#006C9C',
  darker: '#003768',
  contrastText: '#FFFFFF'
}

export const success = {
  main: '#5EC26A', // '#76a93a',
  lighter: '#F1FAD9',
  light: '#C4E58A',
  dark: '#46791D',
  darker: '#24510B',
  contrastText: '#ffffff'
}

export const warning = {
  main: '#ef7900',
  lighter: '#FEEFCB',
  light: '#FABE64',
  dark: '#AC4600',
  darker: '#722300',
  contrastText: grey[800]
}

export const error = {
  lighter: '#FFE9D5',
  light: '#FFAC82',
  main: '#FF5630',
  dark: '#B71D18',
  darker: '#7A0916',
  contrastText: '#FFFFFF'
}

export const common = {
  black: '#000000',
  white: '#FFFFFF'
}

export const action = {
  hover: alpha(grey[500], 0.08),
  selected: alpha(grey[500], 0.16),
  disabled: alpha(grey[500], 0.8),
  disabledBackground: alpha(grey[500], 0.24),
  focus: alpha(grey[500], 0.24),
  hoverOpacity: 0.08,
  disabledOpacity: 0.48
}

const base = {
  primary,
  secondary,
  info,
  success,
  warning,
  error,
  grey,
  common,
  divider: alpha(grey[500], 0.2),
  action
}

// ----------------------------------------------------------------------

export function palette(mode) {
  const light = {
    ...base,
    mode: 'light',
    text: {
      primary: grey[800],
      secondary: grey[600],
      disabled: grey[500]
    },
    background: {
      paper: '#FFFFFF',
      default: '#FFFFFF',
      neutral: grey[200]
    },
    action: {
      ...base.action,
      active: grey[600]
    }
  }

  const dark = {
    ...base,
    mode: 'dark',
    text: {
      primary: '#FFFFFF',
      secondary: grey[500],
      disabled: grey[600]
    },
    background: {
      paper: grey[800],
      default: grey[900],
      neutral: alpha(grey[500], 0.12)
    },
    action: {
      ...base.action,
      active: grey[500]
    }
  }

  return mode === 'light' ? light : dark
}
