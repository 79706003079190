import CustomPopover, { usePopover } from '../../../components/custom-popover'
import { Chip, FormControl, InputLabel, OutlinedInput, Select, Stack, Typography } from '@mui/material'
import { trans } from '@/utils/translation'
import Iconify from '../../../components/iconify'
import IconButton from '@mui/material/IconButton'
import MenuItem from '@mui/material/MenuItem'
import { alpha } from '@mui/material/styles'
import Tabs from '@mui/material/Tabs'
import { STATUS_OPTIONS } from './assignment-table-definitions'
import Tab from '@mui/material/Tab'
import Label from '../../../components/label'
import { useStateCounts } from '@/hooks/use-state-counts'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import AssignmentStep from '@/constants/AssignmentStep'
import { Clear } from '@mui/icons-material'
import ListFilterTextfield from '@/components/form/list-filter-textfield'
import TextField from '@mui/material/TextField'

export default function AssignmentTableToolbar({
  columnFilters,
  setColumnFilters,
  globalFilter,
  setGlobalFilter,
  resetAll
}) {
  const popover = usePopover()
  const counts = useStateCounts(`/assignments/count?filters=${JSON.stringify(columnFilters)}`, columnFilters)

  return (
    <>
      <Stack
        flexDirection={'row'}
        flexWrap={'wrap'}
        alignItems={'center'}
        justifyContent={'space-between'}
        sx={{
          boxShadow: (theme) => `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`
        }}
      >
        <Tabs
          value={columnFilters.state ?? ''}
          onChange={(e, value) => {
            setColumnFilters({ ...columnFilters, state: value })
          }}
          sx={{
            px: 2.5
          }}
        >
          {Object.entries(STATUS_OPTIONS).map(([key, { color, label }]) => (
            <Tab
              key={key}
              iconPosition="end"
              value={key}
              label={label}
              icon={
                <Label variant={((key === null || key === columnFilters.state) && 'filled') || 'soft'} color={color}>
                  {counts[key] ?? counts['ALL']}
                </Label>
              }
            />
          ))}
        </Tabs>
      </Stack>
      <Stack>
        <Stack
          direction={'row'}
          alignItems={'center'}
          flexWrap={'wrap'}
          spacing={2}
          flexGrow={1}
          sx={{
            p: 2
          }}
        >
          <Stack
            direction={'row'}
            alignItems={'center'}
            spacing={2}
            sx={{
              flexGrow: 1
            }}
          >
            <FormControl
              sx={{
                flexShrink: 1,
                width: { xs: 1, md: 300 }
              }}
            >
              <InputLabel>{trans('general.step')}</InputLabel>
              <Select
                multiple={false}
                value={columnFilters?.step ?? ''}
                onChange={(e) =>
                  setColumnFilters({
                    ...columnFilters,
                    step: e.target.value
                  })
                }
                input={<OutlinedInput label={trans('general.step')} />}
                renderValue={(selected) => (
                  <Box
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      gap: 0.5
                    }}
                  >
                    <Chip
                      key={selected}
                      label={trans(`assignment.step.${selected}`)}
                      variant="light"
                      color="primary"
                      size="small"
                    />
                  </Box>
                )}
                IconComponent={
                  columnFilters?.step
                    ? () => (
                        <IconButton
                          size="small"
                          onClick={() => {
                            setColumnFilters({
                              ...columnFilters,
                              step: ''
                            })
                          }}
                        >
                          <Clear />
                        </IconButton>
                      )
                    : undefined
                }
              >
                {Object.values({
                  EMAIL_NOT_CONFIRMED: 'EMAIL_NOT_CONFIRMED',
                  EMAIL_CONFIRMED: 'EMAIL_CONFIRMED',
                  ...AssignmentStep
                })
                  .filter((item) => item !== AssignmentStep.ONBOARDING_OPEN)
                  .map((step) => (
                    <MenuItem key={step} value={step}>
                      {trans(`assignment.step.${step}`)}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <FormControl
              sx={{
                flexShrink: 1,
                width: { xs: 1, md: 200 }
              }}
            >
              <Stack direction="row" spacing={2} alignItems="center">
                <TextField
                  label="min"
                  type="number"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  sx={{ width: '90px' }}
                  value={columnFilters.minApartmentCount ?? 0}
                  onChange={(e) => {
                    setColumnFilters({
                      ...columnFilters,
                      minApartmentCount: e.target.value
                    })
                  }}
                />
                <Typography>-</Typography>
                <TextField
                  label="max"
                  type="number"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  sx={{ width: '90px' }}
                  value={columnFilters.maxApartmentCount ?? 100}
                  onChange={(e) => {
                    setColumnFilters({
                      ...columnFilters,
                      maxApartmentCount: e.target.value
                    })
                  }}
                />
              </Stack>
            </FormControl>
            <FormControl sx={{ flexGrow: 1 }}>
              <ListFilterTextfield
                placeholder={trans('assignment.search_placeholder')}
                globalFilter={globalFilter}
                setGlobalFilter={setGlobalFilter}
              />
            </FormControl>
            <IconButton onClick={popover.onOpen}>
              <Iconify icon="eva:more-vertical-fill" />
            </IconButton>
          </Stack>
        </Stack>

        <CustomPopover open={popover.open} onClose={popover.onClose} arrow="right-top" sx={{ width: 270 }}>
          <MenuItem
            onClick={() => {
              resetAll()
              popover.onClose()
            }}
          >
            <Iconify icon={'carbon:reset'} sx={{ width: 24, height: 24, mr: '16px' }} />
            {trans('table.reset_filter')}
          </MenuItem>
        </CustomPopover>
      </Stack>
    </>
  )
}

AssignmentTableToolbar.propTypes = {
  columnFilters: PropTypes.object,
  setColumnFilters: PropTypes.func,
  globalFilter: PropTypes.string,
  setGlobalFilter: PropTypes.func,
  refresh: PropTypes.number,
  resetAll: PropTypes.func
}
