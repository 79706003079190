import { AssignmentView } from '@/sections/assignment/view'
import SimpleLayout from '@/layouts/header-simple'
import { Head } from '@inertiajs/react'
import { trans } from '@/utils/translation'

const Assignment = () => {
  return (
    <>
      <Head>
        <title>{trans('general.assignment')}</title>
      </Head>
      <AssignmentView />
    </>
  )
}
Assignment.layout = (page) => <SimpleLayout>{page}</SimpleLayout>

export default Assignment
