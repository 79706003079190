import { useEffect, useMemo, useState } from 'react'
import { useFormContext } from '@/context/use-form-context'
import TextField from '@mui/material/TextField'
import { Tooltip } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import { InfoOutlined, LinkOutlined, Lock } from '@mui/icons-material'
import Autocomplete from '@mui/material/Autocomplete'
import { get } from 'lodash'
import { router } from '@inertiajs/react'

const InertiaAutocomplete = ({ name, input, items, callback, ...options }) => {
  const { data, setDataDebounce, overrideFieldValues, errors, clearErrors } = useFormContext()
  const { multiple, disabled, tooltip, required, link } = input

  let value = get(data, name) || null
  if (multiple) {
    value = value ? value.map((item) => item.id || item) : []
  }

  const [fieldValue, setFieldValue] = useState(value)

  useEffect(() => {
    let override = get(overrideFieldValues, name)
    if (typeof override !== 'undefined') {
      if (multiple) {
        override = override ? override.map((item) => item.id || item) : []
      }

      setFieldValue(override)
    }
  }, [get(overrideFieldValues, name)])

  // Fetch dependent select on mount
  useEffect(() => {
    if (get(data, name)) {
      callback ? callback(get(data, name)) : null
    }
  }, [])

  return useMemo(() => {
    return (
      <Autocomplete
        clearOnEscape
        autoComplete
        autoHighlight
        multiple={multiple ?? false}
        disabled={disabled ?? false}
        options={items}
        name={name}
        value={fieldValue}
        getOptionLabel={(value) => {
          const parsedValue = value.hasOwnProperty('id') ? value?.id : value
          return items.filter((item) => item.id == parsedValue)[0]?.label || value?.label || ''
        }}
        getOptionDisabled={(option) => option.disabled}
        isOptionEqualToValue={(option, value) => {
          if (typeof value === 'undefined') {
            return false
          }

          if (Array.isArray(value)) {
            return value.indexOf(option.id) > -1
          }

          const parsedValue = value.hasOwnProperty('id') ? value?.id : value
          return option.id == parsedValue
        }}
        onChange={(e, v) => {
          // MUI Multiselect doesn't provide an array if multiple=true and the selected options count == 0
          let values = multiple ? [v?.id] : v?.id
          if (Array.isArray(v)) {
            values = v.map((item) => item?.id || item)
          }

          if (typeof values === 'undefined') {
            values = null
          }

          if (values) {
            clearErrors(name)
          }

          setFieldValue(values)
          setDataDebounce(name, values)
          callback ? callback(values) : null
        }}
        renderOption={(props, option) => (
          <li {...props} key={`${name}-${option.id}`}>
            {option.label}
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            error={!!get(errors, name)}
            helperText={get(errors, name)}
            InputProps={{
              ...params.InputProps,
              autoComplete: 'new-password',
              endAdornment: (
                <>
                  {tooltip && (
                    <Tooltip title={tooltip}>
                      <IconButton>
                        <InfoOutlined />
                      </IconButton>
                    </Tooltip>
                  )}
                  {disabled && (
                    <Tooltip title={'Bearbeitung deaktiviert'}>
                      <IconButton>
                        <Lock />
                      </IconButton>
                    </Tooltip>
                  )}
                  {link && (
                    <Tooltip title={'Zum Datensatz'}>
                      <IconButton onClick={() => router.get(link)}>
                        <LinkOutlined />
                      </IconButton>
                    </Tooltip>
                  )}
                  {params.InputProps.endAdornment}
                </>
              ),
              style: input.innerstyle
            }}
            required={required}
            sx={{
              width: '100%',
              '& .MuiAutocomplete-inputRoot': {
                '& .MuiAutocomplete-input': {
                  minWidth: '0 !important'
                }
              },
              ...input.sx
            }}
            {...input}
          />
        )}
        {...options}
      />
    )
  }, [get(errors, name), items, fieldValue, callback, disabled])
}
export default InertiaAutocomplete
