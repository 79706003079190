import PropTypes from 'prop-types'

import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Avatar from '@mui/material/Avatar'
import ListItemText from '@mui/material/ListItemText'
import { alpha, useTheme } from '@mui/material/styles'
import { bgGradient } from '@/theme/css'
import Typography from '@mui/material/Typography'
import { trans } from '@/utils/translation'
import { usePage } from '@inertiajs/react'
import UserStateLabel from './user-state-label'
import { formatDateAnonymized } from '@/utils/format'
import { avatar } from '@/utils/misc'

export default function UserHeader({ role, coverUrl }) {
  const { user } = usePage().props
  const theme = useTheme()

  return (
    <Box
      sx={{
        ...bgGradient({
          color: alpha(theme.palette.primary.dark, 0.4),
          imgUrl: coverUrl
        }),
        height: 1,
        color: 'common.white'
      }}
    >
      <Stack direction={{ xs: 'column', md: 'row' }} justifyContent={'space-between'} p={2} mx={{ md: 2 }}>
        {/* <Iconify icon="solar:camera-add-bold" width={32} /> */}
        <div></div>
        <Stack direction={'column'} alignItems={'end'}>
          <UserStateLabel variant={'filled'} state={user.state} />
          <Typography variant={'caption'}>
            {trans('profile.last_online')}: <br />
            {user.lastLogin ? formatDateAnonymized(user.lastLogin) : trans('user.not_logged_in_yet')}
          </Typography>
        </Stack>
      </Stack>

      <Stack
        direction={{ xs: 'column', md: 'row' }}
        sx={{
          left: { md: 24 },
          bottom: { md: 24 },
          zIndex: { md: 10 },
          position: { md: 'absolute' }
        }}
      >
        <Avatar
          alt={user.name}
          src={avatar(user)}
          sx={{
            mx: 'auto',
            width: { xs: 64, md: 128 },
            height: { xs: 64, md: 128 },
            border: `solid 2px ${theme.palette.common.white}`
          }}
        >
          {user.name}
        </Avatar>

        <ListItemText
          sx={{
            mt: 3,
            ml: { md: 3 },
            textAlign: { xs: 'center', md: 'unset' }
          }}
          primary={user.name}
          secondary={role}
          primaryTypographyProps={{
            typography: 'h4'
          }}
          secondaryTypographyProps={{
            mt: 0.5,
            color: 'inherit',
            component: 'span',
            typography: 'body2',
            sx: { opacity: 0.48 }
          }}
        />
      </Stack>
    </Box>
  )
}

UserHeader.propTypes = {
  coverUrl: PropTypes.string,
  role: PropTypes.string
}
