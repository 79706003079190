import { trans } from '@/utils/translation'
import { Card, CardHeader, Stack } from '@mui/material'
import InertiaTextField from '@/components/form/inertia-textfield'
import { FormProvider } from '@/context/use-form-context'
import InertiaDatePicker from '@/components/form/inertia-date-picker'
import Typography from '@mui/material/Typography'
import { useForm } from '@/hooks/use-form'
import Box from '@mui/material/Box'
import { LoadingButton } from '@mui/lab'
import { router, usePage } from '@inertiajs/react'
import useSubmit from '@/hooks/use-submit'
import { ToastCtl } from '@/utils/toast-ctl'
import InertiaToggleButton from '@/components/form/inertia-toggle-button'
import dayjs from 'dayjs'

export default function AssignmentProcessStep2() {
  const { assignment } = usePage().props

  const minDate = dayjs().add(6, 'weeks')
  const methods = useForm({
    url: {
      method: 'PUT',
      location: `/api/assignments/${assignment.id}/step-2`
    },
    defaultValues: {
      devicesInstalled: assignment?.devicesInstalled ?? false,
      isIndividual: assignment?.isIndividual ?? false,
      requestedFittingDate: assignment?.requestedFittingDate ?? minDate,
      phone: assignment?.property?.contactPerson?.phone ?? '',
      customerComment: assignment?.customerComment ?? ''
    }
  })

  const { submit, submitting, data } = methods

  const handleSubmit = () => {
    submit((response) => {
      router.get(`/assignment/${response.data.id}/step-3`)
      ToastCtl.show(trans('general.save_success'), 'success')
    })
  }

  useSubmit(() => handleSubmit())

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit}>
        <Box sx={{ maxWidth: '400px', mx: 'auto' }}>
          <Card>
            <CardHeader title={trans('assignment.customize.installation.title')} />
            <Stack direction={'column'} spacing={2} sx={{ mx: 3, mt: 1 }}>
              <Stack spacing={0.5}>
                <InertiaToggleButton
                  name={'devicesInstalled'}
                  input={{
                    label: trans('assignment.customize.installation.is_installed')
                  }}
                />
                <InertiaToggleButton
                  name={'isIndividual'}
                  input={{
                    label: trans('assignment.customize.installation.when_installed')
                  }}
                  left={{
                    label: trans('assignment.customize.installation.individual')
                  }}
                  right={{
                    label: trans('assignment.customize.installation.date')
                  }}
                />
              </Stack>

              {!data.isIndividual && (
                <InertiaDatePicker
                  name={'requestedFittingDate'}
                  input={{
                    label: trans('assignment.customize.installation.installation_start')
                  }}
                  minDate={minDate}
                />
              )}

              <Typography variant="body2">{trans('assignment.customize.installation.how_to_contact')}</Typography>
              <InertiaTextField name={'phone'} input={{ label: trans('assignment.customize.installation.phone') }} />

              <Typography variant="body2">{trans('assignment.customize.installation.notes.frage')}</Typography>
              <InertiaTextField
                name={'customerComment'}
                input={{
                  label: trans('assignment.customize.installation.notes.tip'),
                  multiline: true,
                  rows: 2
                }}
              />
            </Stack>

            <Box sx={{ display: 'flex', flexDirection: 'row', py: 2, mx: 3, justifyContent: 'space-between' }}>
              <LoadingButton variant={'outlined'} onClick={() => router.get(`/assignment/${assignment.id}/step-1`)}>
                {trans('button.back')}
              </LoadingButton>
              <LoadingButton loading={submitting} variant={'contained'} onClick={handleSubmit}>
                {trans('button.next')}
              </LoadingButton>
            </Box>
          </Card>
        </Box>
      </form>
    </FormProvider>
  )
}
