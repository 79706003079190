import { Card, CardContent, Typography } from '@mui/material'
import PropTypes from 'prop-types'

const Content = ({ content }) => {
  return (
    <Card sx={{ m: 4, boxShadow: 2, borderRadius: 3 }}>
      <CardContent sx={{ m: 2, borderRadius: 3 }}>
        <Typography variant={'h4'} sx={{ mb: 3 }}>
          {content.header}
        </Typography>
        <Typography variant={'body1'}>
          <div dangerouslySetInnerHTML={{ __html: content.body }}></div>
        </Typography>
      </CardContent>
    </Card>
  )
}

Content.propTypes = {
  content: PropTypes.object
}

export default Content
