import { trans } from '@/utils/translation'
import { Card, CardHeader, Stack } from '@mui/material'
import Typography from '@mui/material/Typography'
import InertiaAutocomplete from '@/components/form/inertia-autocomplete'
import { LoadingButton } from '@mui/lab'
import Box from '@mui/material/Box'
import { FormProvider } from '@/context/use-form-context'
import { router, usePage } from '@inertiajs/react'
import { useForm } from '@/hooks/use-form'
import { ToastCtl } from '@/utils/toast-ctl'
import InertiaFileUpload from '@/components/form/inertia-file-upload'
import InertiaToggleButton from '@/components/form/inertia-toggle-button'

export const fileTypes = [
  {
    label: trans('assignment.customize.refine_request.document.last_heating_bill'),
    id: 'lastHeatingBillDocument'
  },
  {
    label: trans('assignment.customize.refine_request.document.device_contract'),
    id: 'deviceContractDocument'
  },
  {
    label: trans('assignment.customize.refine_request.document.last_meter_protocol'),
    id: 'lastMeterProtocolDocument'
  },
  {
    label: trans('assignment.customize.refine_request.document.declaration_of_partition'),
    id: 'declarationOfPartitionDocument'
  },
  { label: trans('assignment.customize.refine_request.document.floor_plan'), id: 'floorPlanDocument' },
  { label: trans('assignment.customize.refine_request.document.tenant_list'), id: 'tenantListDocument' },
  { label: trans('assignment.customize.refine_request.document.other'), id: 'otherDocument' }
]

export default function AssignmentProcessStep3() {
  const { assignment } = usePage().props

  const methods = useForm({
    url: {
      method: 'PUT',
      location: `/api/assignments/${assignment.id}/step-3`,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    },
    defaultValues: {
      activeContracting: assignment?.activeContracting ?? false,
      lastHeatingBillDocument: new File([], assignment?.lastHeatingBillDocument?.name),
      deviceContractDocument: new File([], assignment?.deviceContractDocument?.name),
      lastMeterProtocolDocument: new File([], assignment?.lastMeterProtocolDocument?.name),
      declarationOfPartitionDocument: new File([], assignment?.declarationOfPartitionDocument?.name),
      floorPlanDocument: new File([], assignment?.floorPlanDocument?.name),
      tenantListDocument: new File([], assignment?.tenantListDocument?.name),
      otherDocument: new File([], assignment?.otherDocument?.name)
    }
  })

  const { submit, submitting, data } = methods

  const handleSubmit = () => {
    submit((response) => {
      router.get(`/assignment/${response.data.id}/step-4`)
      ToastCtl.show(trans('general.save_success'), 'success')
    })
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit}>
        <Box sx={{ maxWidth: '400px', mx: 'auto' }}>
          <Card>
            <CardHeader title={trans('assignment.customize.refine_request.title')} />
            <Stack direction={'column'} spacing={2} sx={{ mx: 3, mt: 1 }}>
              <Stack>
                <InertiaToggleButton
                  name={'activeContracting'}
                  input={{
                    label: trans('assignment.customize.refine_request.is_commissioned')
                  }}
                />
              </Stack>

              <Typography variant="body2">
                {trans('assignment.customize.refine_request.document.upload_document')}
              </Typography>

              <InertiaAutocomplete
                name={'fileSelection'}
                items={fileTypes}
                input={{
                  label: trans('assignment.customize.refine_request.document.upload')
                }}
                callback={(value) => {
                  if (value) {
                    document.getElementById(`upload-${value}`).click()
                  }
                }}
              />
              <Stack spacing={2}>
                {fileTypes.map((fileType) => (
                  <Box key={fileType.id} sx={{ display: data[fileType.id]?.name !== 'undefined' ? 'block' : 'none' }}>
                    <InertiaFileUpload
                      name={fileType.id}
                      input={{
                        label: fileType.label,
                        accept: 'image/png,image/jpeg,image/gif,application/pdf'
                      }}
                      filename={data[fileType.id]?.name}
                    />
                  </Box>
                ))}
              </Stack>
            </Stack>

            <Box sx={{ display: 'flex', flexDirection: 'row', py: 2, mx: 3, justifyContent: 'space-between' }}>
              <LoadingButton variant={'outlined'} onClick={() => router.get(`/assignment/${assignment.id}/step-2`)}>
                {trans('button.back')}
              </LoadingButton>
              <LoadingButton loading={submitting} variant={'contained'} onClick={handleSubmit}>
                {trans('button.next')}
              </LoadingButton>
            </Box>
          </Card>
        </Box>
      </form>
    </FormProvider>
  )
}
