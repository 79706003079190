import { DialogActions, DialogTitle, Grid } from '@mui/material'
import { trans } from '@/utils/translation'
import DialogContent from '@mui/material/DialogContent'
import CustomButton from '@/components/custom-button'
import { LoadingButton } from '@mui/lab'
import { useModalContext } from '@/context/use-modal-context'
import { useForm } from '@/hooks/use-form'
import { router } from '@inertiajs/react'
import { ToastCtl } from '@/utils/toast-ctl'
import useSubmit from '@/hooks/use-submit'
import { FormProvider } from '@/context/use-form-context'
import dayjs from 'dayjs'
import InertiaDatePicker from '@/components/form/inertia-date-picker'
import InertiaNumericField from '@/components/form/inertia-numeric-field'
import InertiaTextfield from '@/components/form/inertia-textfield'
import PropTypes from 'prop-types'
import InertiaAutocomplete from '@/components/form/inertia-autocomplete'
import { floors } from '@/utils/misc'

export default function ApartmentCreateModal({ propertyId, apartment }) {
  const { closeModal } = useModalContext()

  const methods = useForm({
    url: {
      method: apartment?.id ? 'PUT' : 'POST',
      location: apartment?.id ? `/api/apartments/${apartment.id}/edit` : `/api/apartments`
    },
    defaultValues: {
      apartment: apartment?.id,
      property: propertyId,
      begin: apartment?.begin ?? dayjs().startOf('year').toDate(),
      end: apartment?.end,
      number: apartment?.number,
      label: apartment?.label,
      location: apartment?.location,
      floor: apartment?.floor,
      ownershipShare: 0,
      size: null,
      heatingSize: null,
      warmWaterSize: null,
      additionalCostSize: null,
      commercialSize: 0,
      personCount: 1
    }
  })

  const { setData, setOverrideFieldValues, submit, submitting } = methods

  const handleSubmit = () => {
    submit((response) => {
      router.get(`/apartments/${response.data.id}/detail`)
      ToastCtl.show(trans('general.save_success'), 'success')
      closeModal('apartmentCreate')
    })
  }

  useSubmit(() => handleSubmit())

  return (
    <>
      <DialogTitle>{trans(apartment ? 'apartment.edit' : 'apartment.create')}</DialogTitle>
      <DialogContent dividers sx={{ py: 1 }}>
        <FormProvider {...methods} sx={{ px: 2, mx: 8 }}>
          <form onSubmit={handleSubmit}>
            <Grid container direction={'row'} spacing={2}>
              <Grid item md={6} xs={12}>
                <InertiaDatePicker
                  name={'begin'}
                  input={{
                    label: trans('general.from'),
                    required: true
                  }}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <InertiaDatePicker
                  name={'end'}
                  input={{
                    label: trans('general.to')
                  }}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <InertiaNumericField
                  name={'number'}
                  input={{
                    label: trans('field.apartment.number'),
                    required: true,
                    suffix: '',
                    decimalScale: 0,
                    thousandSeparator: ''
                  }}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <InertiaTextfield
                  name={'label'}
                  input={{
                    label: trans('field.apartment.label'),
                    required: true
                  }}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <InertiaTextfield
                  name={'location'}
                  input={{
                    label: trans('field.apartment.location'),
                    required: false
                  }}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <InertiaAutocomplete
                  name={'floor'}
                  input={{
                    label: trans('field.apartment.floor'),
                    required: true
                  }}
                  items={floors()}
                />
              </Grid>
              {!apartment?.id && (
                <>
                  <Grid item md={6} xs={12}>
                    <InertiaNumericField
                      name={'ownershipShare'}
                      input={{
                        label: trans('field.apartment.ownership_share'),
                        required: true,
                        suffix: '',
                        decimalScale: 0,
                        thousandSeparator: ''
                      }}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <InertiaNumericField
                      name={'size'}
                      input={{
                        label: trans('field.apartment.size'),
                        required: true,
                        suffix: ' m²',
                        decimalScale: 3
                      }}
                      callback={(value) => {
                        if (value) {
                          setData('heatingSize', value)
                          setData('warmWaterSize', value)
                          setData('additionalCostSize', value)

                          setOverrideFieldValues({
                            heatingSize: value,
                            warmWaterSize: value,
                            additionalCostSize: value
                          })
                        }
                      }}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <InertiaNumericField
                      name={'heatingSize'}
                      input={{
                        label: trans('field.apartment.heating_size'),
                        required: true,
                        suffix: ' m²',
                        decimalScale: 3
                      }}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <InertiaNumericField
                      name={'warmWaterSize'}
                      input={{
                        label: trans('field.apartment.warm_water_size'),
                        required: true,
                        suffix: ' m²',
                        decimalScale: 3
                      }}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <InertiaNumericField
                      name={'additionalCostSize'}
                      input={{
                        label: trans('field.apartment.additional_cost_size'),
                        required: true,
                        suffix: ' m²',
                        decimalScale: 3
                      }}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <InertiaNumericField
                      name={'commercialSize'}
                      input={{
                        label: trans('field.apartment.commercial_size'),
                        required: true,
                        suffix: ' m²',
                        decimalScale: 3
                      }}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <InertiaNumericField
                      name={'personCount'}
                      input={{
                        label: trans('field.apartment.person_count'),
                        required: true,
                        suffix: '',
                        decimalScale: 0
                      }}
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </form>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <CustomButton onClick={() => closeModal('apartmentCreate')}>{trans('button.cancel')}</CustomButton>
        <LoadingButton
          loading={submitting}
          variant={'contained'}
          onClick={() => {
            handleSubmit()
          }}
        >
          {trans('button.done')}
        </LoadingButton>
      </DialogActions>
    </>
  )
}

ApartmentCreateModal.propTypes = {
  propertyId: PropTypes.string,
  apartment: PropTypes.object
}
