import PropTypes from 'prop-types'
import { forwardRef } from 'react'

import Box from '@mui/material/Box'
import Link from '@mui/material/Link'
import assetAppLogo from '../../../images/logos/assetapp_logo.png'

// ----------------------------------------------------------------------

const Logo = forwardRef(({ disabledLink = false, sx }) => {
  const logo = <Box component="img" src={assetAppLogo} sx={{ width: 200, cursor: 'pointer', ...sx }} />

  if (disabledLink) {
    return logo
  }

  return (
    <Link href="/" sx={{ display: 'contents' }}>
      {logo}
    </Link>
  )
})

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object
}

export default Logo
