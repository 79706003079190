import { trans } from '@/utils/translation'
import { TenantDetailView } from '@/sections/tenant/view'
import DashboardLayout from '@/layouts/dashboard'
import { Head } from '@inertiajs/react'

const TenantDetailPage = () => {
  return (
    <>
      <Head>
        <title>{trans('tenant.title')}</title>
      </Head>
      <TenantDetailView />
    </>
  )
}

TenantDetailPage.layout = (page) => <DashboardLayout>{page}</DashboardLayout>

export default TenantDetailPage
