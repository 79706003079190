import { Card, Grid } from '@mui/material'
import { FormProvider } from '@/context/use-form-context'
import InertiaTextField from '@/components/form/inertia-textfield'
import { useForm } from '@/hooks/use-form'
import useSubmit from '@/hooks/use-submit'
import { LoadingButton } from '@mui/lab'
import { router, usePage } from '@inertiajs/react'
import { ToastCtl } from '@/utils/toast-ctl'
import { trans } from '@/utils/translation'
import Box from '@mui/material/Box'

const UserProfileForm = () => {
  const { auth } = usePage().props

  const methods = useForm({
    url: {
      method: 'PUT',
      location: `/api/users/${auth.user.id}/edit-profile-data`
    },
    defaultValues: {
      company: auth.user.company,
      name: auth.user.name,
      phone: auth.user.phone,
      street: auth.user.address?.street,
      streetNumber: auth.user.address?.streetNumber,
      postcode: auth.user.address?.postcode,
      city: auth.user.address?.city
    }
  })

  const { submit, submitting } = methods

  const handleSubmit = () => {
    submit(() => {
      router.reload()
      ToastCtl.show(trans('general.save_success'), 'success')
    })
  }

  useSubmit(() => handleSubmit())

  return (
    <>
      <FormProvider {...methods} sx={{ px: 2, mx: 8 }}>
        <form onSubmit={handleSubmit}>
          <Grid>
            <Card sx={{ p: 3 }}>
              <Box
                rowGap={3}
                columnGap={2}
                display="grid"
                gridTemplateColumns={{
                  xs: 'repeat(1, 1fr)',
                  sm: 'repeat(2, 1fr)'
                }}
              >
                <InertiaTextField
                  name={'company'}
                  input={{
                    label: trans('field.company')
                  }}
                />
                <InertiaTextField
                  name={'name'}
                  input={{
                    label: trans('field.name'),
                    required: true
                  }}
                />
                <InertiaTextField
                  name={'phone'}
                  input={{
                    label: trans('field.phone'),
                    required: true
                  }}
                />
                <InertiaTextField
                  name={'street'}
                  input={{
                    label: trans('field.street'),
                    required: true
                  }}
                />
                <InertiaTextField
                  name={'streetNumber'}
                  input={{
                    label: trans('field.street_number'),
                    required: true
                  }}
                />
                <InertiaTextField
                  name={'postcode'}
                  input={{
                    label: trans('field.zip'),
                    required: true
                  }}
                />
                <InertiaTextField
                  name={'city'}
                  input={{
                    label: trans('field.city'),
                    required: true
                  }}
                />
              </Box>

              <LoadingButton
                variant={'contained'}
                loading={submitting}
                onClick={() => handleSubmit()}
                sx={{ mt: 3, float: 'right' }}
              >
                {trans('button.save')}
              </LoadingButton>
            </Card>
          </Grid>
        </form>
      </FormProvider>
    </>
  )
}

export default UserProfileForm
