import PropTypes from 'prop-types'
import { forwardRef } from 'react'

import Box from '@mui/material/Box'
import Link from '@mui/material/Link'
import { styled } from '@mui/material/styles'
import ListItemButton from '@mui/material/ListItemButton'
import { router } from '@inertiajs/react'

// ----------------------------------------------------------------------

const NavItem = forwardRef(({ title, path, icon, open, active, ...other }, ref) => {
  const renderContent = (
    <StyledNavItem ref={ref} disableGutters open={open} active={active} {...other}>
      {icon && (
        <Box component="span" className="icon">
          {icon}
        </Box>
      )}
      {title && (
        <Box component="span" sx={{ flex: '1 1 auto', minWidth: 0 }}>
          <Box component="span" className="label">
            {title}
          </Box>
        </Box>
      )}
    </StyledNavItem>
  )
  return (
    <Link href={'#'} onClick={() => router.get(path)} color="inherit" underline="none">
      {renderContent}
    </Link>
  )
})

NavItem.propTypes = {
  open: PropTypes.bool,
  active: PropTypes.bool,
  path: PropTypes.string,
  icon: PropTypes.element,
  title: PropTypes.string
}

export default NavItem

// ----------------------------------------------------------------------

const StyledNavItem = styled(ListItemButton, {
  shouldForwardProp: (prop) => prop !== 'active'
})(({ active, open, theme }) => {
  const opened = open && !active

  const noWrapStyles = {
    width: '100%',
    maxWidth: '100%',
    display: 'block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  }

  const baseStyles = {
    item: {
      marginBottom: 4,
      borderRadius: 8,
      color: theme.palette.text.secondary,
      padding: theme.spacing(0.5, 1, 0.5, 1.5)
    },
    icon: {
      width: 24,
      height: 24,
      flexShrink: 0,
      marginRight: theme.spacing(2)
    },
    label: {
      ...noWrapStyles,
      ...theme.typography.body2,
      textTransform: 'capitalize',
      fontWeight: theme.typography[active ? 'fontWeightSemiBold' : 'fontWeightMedium']
    },
    arrow: {
      flexShrink: 0,
      marginLeft: theme.spacing(0.75)
    }
  }

  return {
    // Root item
    ...{
      ...baseStyles.item,
      padding: theme.spacing(0.5, 1, 0.5, 1.5),
      minHeight: 40,
      transition: theme.transitions.create(['all'], {
        duration: theme.transitions.duration.shorter
      }),
      '&:hover': {
        backgroundColor: theme.palette.action.hover
      },
      '& .icon': {
        ...baseStyles.icon
      },
      '& .label': {
        ...baseStyles.label
      },
      ...(active && {
        color: theme.palette.secondary.main,
        fontWeight: theme.typography.fontWeightSemiBold,
        backgroundColor: theme.palette.secondary.lighter
      }),
      ...(opened && {
        opacity: 0.64
      })
    }
  }
})
