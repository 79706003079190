import { trans } from '@/utils/translation'
import { Card, CardHeader, Stack } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import Iconify from '@/components/iconify'

import { usePage } from '@inertiajs/react'
import CustomLabelValue from '../../components/custom-label-value'
import { boolToString } from '@/utils/misc'
import { useModalContext } from '@/context/use-modal-context'
import PropertyEditCarbonEmissionModal from '@/sections/property/property-edit-carbon-emission-modal'

export default function PropertyCo2Tax() {
  const { property } = usePage().props
  const { openModal } = useModalContext()

  return (
    <>
      <Card>
        <CardHeader
          title={trans('property.co2_tax.title')}
          action={
            <IconButton
              onClick={() =>
                openModal('propertyEditCarbonEmission', <PropertyEditCarbonEmissionModal property={property} />)
              }
            >
              <Iconify icon="solar:pen-bold" />
            </IconButton>
          }
        />
        <Stack direction={'column'} spacing={1} sx={{ p: 3 }}>
          <CustomLabelValue
            label={trans('property.co2_tax.consideration')}
            value={boolToString(property.carbonSettings.enableCarbonEmissionTax)}
          />
          <CustomLabelValue
            label={trans('property.co2_tax.commercial_use')}
            value={boolToString(property.carbonSettings.isCommercialUse)}
          />
          <CustomLabelValue
            label={trans('property.co2_tax.renovation_requirement')}
            value={boolToString(property.carbonSettings.restructuringRequirements)}
          />
          <CustomLabelValue
            label={trans('property.co2_tax.no_co2_deduction')}
            value={boolToString(property.carbonSettings.noTotalCarbonEmissionDeduction)}
          />
          <CustomLabelValue
            label={trans('property.co2_tax.monument_protection')}
            value={boolToString(property.carbonSettings.monumentProtection)}
          />
        </Stack>
      </Card>
    </>
  )
}
