import DashboardLayout from '@/layouts/dashboard'
import { trans } from '@/utils/translation'
import { Head } from '@inertiajs/react'
import { ManagerDetailView } from '@/sections/manager/view'

const ManagerDetailPage = () => {
  return (
    <>
      <Head>
        <title>{trans('general.manager')}</title>
      </Head>
      <ManagerDetailView />
    </>
  )
}
ManagerDetailPage.layout = (page) => <DashboardLayout>{page}</DashboardLayout>
export default ManagerDetailPage
