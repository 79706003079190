import { Stack } from '@mui/material'
import DashboardLayout from '@/layouts/dashboard'
import { Head } from '@inertiajs/react'
import TaskListView from '@/sections/task/view/task-list-view'
import { trans } from '@/utils/translation'

const TaskListPage = () => {
  return (
    <>
      <Head>
        <title>{trans('task.title')}</title>
      </Head>
      <Stack sx={{ width: '100%' }}>
        <TaskListView />
      </Stack>
    </>
  )
}

TaskListPage.layout = (page) => <DashboardLayout>{page}</DashboardLayout>

export default TaskListPage
