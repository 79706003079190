import { Alert, InputAdornment, Stack, Typography } from '@mui/material'
import { FormProvider } from '@/context/use-form-context'
import InertiaTextField from '@/components/form/inertia-textfield'
import { Head, usePage } from '@inertiajs/react'
import * as yup from 'yup'
import { ToastCtl } from '@/utils/toast-ctl'
import AuthLayout from '../../layouts/auth'
import { useBoolean } from '@/hooks/use-boolean'
import { trans } from '@/utils/translation'
import IconButton from '@mui/material/IconButton'
import Iconify from '../../components/iconify'
import CustomLink from '../../components/custom-link'
import { LoadingButton } from '@mui/lab'
import { useLocalForm } from '@/hooks/use-local-form'

const ResetPassword = () => {
  const { error } = usePage().props
  const password = useBoolean()
  const passwordConfirm = useBoolean()
  const schema = yup
    .object()
    .shape({
      password: yup
        .string()
        .required(trans('validation.password.required'))
        .min(8, trans('validation.password.min'))
        .matches(/^(?=.*[0-9])(?=.*[a-zA-Z]).{8,128}$/, 'Passwort muss aus Zahlen & Buchstaben bestehen'),
      password_confirm: yup.string().required(trans('validation.password_confirm.required'))
    })
    .test('passwords-match', trans('validation.password_confirm.match'), function (data) {
      return data.password === data.password_confirm
    })

  const methods = useLocalForm({
    defaultValues: {
      password: '',
      password_confirm: ''
    },
    validationSchema: schema
  })

  const { data, post, processing } = methods

  const submit = () => {
    schema
      .validate(data)
      .then(() => {
        post('/reset', data)
      })
      .catch((validationError) => {
        ToastCtl.show(validationError.message, 'error')
      })
  }

  return (
    <>
      <Head>
        <title>{trans('reset_password.header')}</title>
      </Head>
      <FormProvider {...methods} sx={{ px: 2, mx: 8 }}>
        <Typography variant={'h5'} component={'h1'} sx={{ mb: 2, fontWeight: 500, fontSize: '1.75rem' }}>
          {' '}
          {trans('reset_password.title')}
        </Typography>
        <Typography variant="body2" sx={{ my: 1 }}>
          {trans('reset_password.description')}
        </Typography>
        <form onSubmit={submit}>
          {error && (
            <Alert sx={{ mb: 2 }} severity={'error'} variant={'filled'} color={'error'}>
              {error}
            </Alert>
          )}
          <Stack direction={'column'} spacing={2}>
            <InertiaTextField
              name={'password'}
              input={{
                label: trans('field.new_password'),
                InputProps: {
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={password.onToggle} edge="end">
                        <Iconify icon={password.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'} />
                      </IconButton>
                    </InputAdornment>
                  )
                },
                type: password.value ? 'text' : 'password'
              }}
            />
            <InertiaTextField
              name={'password_confirm'}
              input={{
                label: trans('field.password_confirm'),
                InputProps: {
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={passwordConfirm.onToggle} edge="end">
                        <Iconify icon={passwordConfirm.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'} />
                      </IconButton>
                    </InputAdornment>
                  )
                },
                helperText: (
                  <Stack component="span" direction="row" alignItems="start" spacing={1}>
                    <Iconify icon="eva:info-fill" width={16} sx={{ mr: 0.5 }} /> {trans('reset_password.description')}
                  </Stack>
                ),
                type: passwordConfirm.value ? 'text' : 'password'
              }}
            />
            <LoadingButton loading={processing} onClick={submit} variant={'contained'}>
              {trans('button.reset_password')}
            </LoadingButton>
            <Stack direction={'row'} justifyContent={'center'} gap={'.5rem'}>
              <CustomLink variant="body2" color="inherit" href="/login">
                {trans('navigation.back_to_login')}
              </CustomLink>
            </Stack>
          </Stack>
        </form>
      </FormProvider>
    </>
  )
}

ResetPassword.layout = (page) => <AuthLayout>{page}</AuthLayout>

export default ResetPassword
