import { Alert, Stack, Typography } from '@mui/material'
import { FormProvider } from '@/context/use-form-context'
import CustomTextField from '@/components/form/inertia-textfield'
import { Head, usePage } from '@inertiajs/react'
import useSubmit from '@/hooks/use-submit'
import * as yup from 'yup'
import AuthLayout from '../../layouts/auth'
import { ToastCtl } from '@/utils/toast-ctl'
import CustomLink from '../../components/custom-link'
import { LoadingButton } from '@mui/lab'
import { trans } from '@/utils/translation'
import { useLocalForm } from '@/hooks/use-local-form'

const ForgotPassword = () => {
  const { error } = usePage().props
  const schema = yup.object().shape({
    email: yup.string().email(trans('validation.email.required')).required(trans('validation.email.required'))
  })
  const methods = useLocalForm({
    defaultValues: {
      email: ''
    },
    validationSchema: schema
  })

  const { data, processing, post } = methods

  const submit = () => {
    schema
      .validate(data)
      .then(() => {
        post('/forgot-password', data)
      })
      .catch((validationError) => {
        ToastCtl.show(validationError.message, 'error')
      })
  }

  useSubmit(() => submit())

  return (
    <>
      <Head>
        <title>{trans('forgot_password.header')}</title>
      </Head>
      <FormProvider {...methods} sx={{ px: 2, mx: 8 }}>
        <Typography variant={'h4'} component={'h1'} sx={{ mb: 2, fontWeight: 500, fontSize: '1.75rem' }}>
          {trans('forgot_password.title')}
        </Typography>
        <Typography variant="body2" sx={{ my: 1 }}>
          {trans('forgot_password.description')}{' '}
        </Typography>
        <form onSubmit={submit}>
          {error && (
            <Alert sx={{ mb: 2 }} severity={'error'} variant={'filled'} color={'error'}>
              {error}
            </Alert>
          )}
          <Stack direction={'column'} spacing={2}>
            <CustomTextField
              name={'email'}
              input={{
                label: trans('field.email')
              }}
            />
            <LoadingButton loading={processing} onClick={submit} variant={'contained'}>
              {trans('forgot_password.send_reset_link')}
            </LoadingButton>
            <Stack direction={'row'} justifyContent={'center'} gap={'.5rem'}>
              <CustomLink href="/login">{trans('navigation.back_to_login')}</CustomLink>
            </Stack>
          </Stack>
        </form>
      </FormProvider>
    </>
  )
}

ForgotPassword.layout = (page) => <AuthLayout>{page}</AuthLayout>

export default ForgotPassword
