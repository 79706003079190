import { Card, CardHeader, Stack } from '@mui/material'
import Divider from '@mui/material/Divider'
import Box from '@mui/material/Box'
import { trans } from '@/utils/translation'
import IconButton from '@mui/material/IconButton'
import Iconify from '@/components/iconify'

import Typography from '@mui/material/Typography'
import { usePage } from '@inertiajs/react'
import CustomLabelValue from '../../components/custom-label-value'
import PropertyEditModal from '@/sections/property/property-edit-modal'
import { useModalContext } from '@/context/use-modal-context'
import { formatDate, formatNumber } from '@/utils/format'

export default function PropertyData() {
  const { property } = usePage().props
  const { openModal } = useModalContext()

  const renderDetails = (
    <Card sx={{ py: 3, textAlign: 'center', typography: 'h5' }}>
      <Stack direction="row" divider={<Divider orientation="vertical" flexItem sx={{ borderStyle: 'dashed' }} />}>
        <Stack width={1}>
          {formatNumber(property.size, 3)} m²
          <Box component="span" sx={{ color: 'text.secondary', typography: 'body2' }}>
            {trans('property.details.size')}
          </Box>
        </Stack>

        <Stack width={1}>
          {property.rentedPercentage} %
          <Box component="span" sx={{ color: 'text.secondary', typography: 'body2' }}>
            {trans('property.details.rented')}
          </Box>
        </Stack>
      </Stack>
    </Card>
  )

  const renderObjectData = (
    <Card>
      <CardHeader
        title={trans('property.object_data.title')}
        action={
          <IconButton onClick={() => openModal('propertyEdit', <PropertyEditModal property={property} />)}>
            <Iconify icon="solar:pen-bold" />
          </IconButton>
        }
      />
      <Stack direction={'column'} spacing={1} sx={{ p: 3, typography: 'body2' }}>
        <Typography variant={'subtitle2'} sx={{ fontWeight: 600 }}>
          {' '}
          {trans('property.object_data.basic_data.title')}
        </Typography>
        <CustomLabelValue
          label={trans('property.object_data.basic_data.unit_number')}
          value={property.apartments.length}
        />
        <CustomLabelValue
          label={trans('property.object_data.basic_data.is_weg')}
          value={property.isWEG ? trans('general.yes') : trans('general.no')}
        />
        <CustomLabelValue
          label={trans('property.object_data.basic_data.construction_year')}
          value={property.constructionYear ? formatDate(property.constructionYear, 'YYYY') : '-'}
        />

        <Typography sx={{ mt: 2, fontWeight: 500 }} variant={'subtitle2'}>
          {' '}
          {trans('property.object_data.sizes.title')}
        </Typography>
        <CustomLabelValue
          label={trans('property.object_data.sizes.constructed_area')}
          value={`${formatNumber(property.size, 3)} m²`}
        />
        <CustomLabelValue
          label={trans('property.object_data.sizes.heating')}
          value={`${formatNumber(property.heatingSize, 3)} m²`}
        />
        <CustomLabelValue
          label={trans('property.object_data.sizes.wwater')}
          value={`${formatNumber(property.warmWaterSize, 3)} m²`}
        />
        <CustomLabelValue
          label={trans('property.object_data.sizes.additional_cost')}
          value={`${formatNumber(property.additionalCostSize, 3)} m²`}
        />
        <CustomLabelValue
          label={trans('property.object_data.sizes.commercial')}
          value={`${formatNumber(property.commercialSize, 3)} m²`}
        />
      </Stack>
    </Card>
  )

  return (
    <>
      <Stack spacing={3}>
        {renderDetails}

        {renderObjectData}
      </Stack>
    </>
  )
}
