import { Grid, Stack } from '@mui/material'
import UserData from '@/sections/user/user-data'
import UserAdminFunction from '@/sections/user/user-admin-function'
import OwnerAuthorization from '@/sections/owner/owner-authorization'
import UserOwnedApartmentsListTable from '@/sections/user/period/user-owned-apartment-list-table'
import UserRentedApartmentsListTable from '@/sections/user/period/user-rented-apartment-list-table'
import UserOwnedPropertyListTable from '@/sections/user/period/user-owned-property-list-table'
import UserContractedPropertyListTable from '@/sections/user/period/user-contracted-property-list-table'
import { hasRole } from '@/utils/misc'
import Roles from '@/constants/Roles'
import { usePage } from '@inertiajs/react'

export default function OwnerDetailPage() {
  const { auth } = usePage().props

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Stack direction={{ md: 'column' }} gap={3}>
            <UserData />
            <OwnerAuthorization />
            {hasRole(auth.user, Roles.ROLE_ADMIN) && <UserAdminFunction />}
          </Stack>
        </Grid>

        <Grid item xs={12} md={8}>
          <Stack direction={{ md: 'column' }} gap={3}>
            <UserOwnedPropertyListTable />
            <UserOwnedApartmentsListTable />
            <UserRentedApartmentsListTable />
            {hasRole(auth.user, Roles.ROLE_ADMIN) && <UserContractedPropertyListTable />}
          </Stack>
        </Grid>
      </Grid>
    </>
  )
}
