import Typography from '@mui/material/Typography'
import { Card, CardHeader } from '@mui/material'
import Stack from '@mui/material/Stack'
import Iconify from '@/components/iconify'
import { formatNumber, formatPercent, getDecimalByDeviceType, getUnitByDeviceType } from '@/utils/format'
import { trans } from '@/utils/translation'
import { useTheme } from '@mui/material/styles'
import Chart from '@/components/chart'
import PropTypes from 'prop-types'
import Tooltip from '@mui/material/Tooltip'
import Box from '@mui/material/Box'
import { filterValuesByYear, sumValuesByYear } from '@/utils/chart'
import dayjs from 'dayjs'
import DeviceTypes from '@/constants/DeviceTypes'
import EmptyItem from '@/sections/dashboard-overview/components/empty-item'

export default function DashboardEnergyForecast({ usage }) {
  const theme = useTheme()

  const header = (
    <>
      {trans('dashboard.forecast.title')}
      <Tooltip title={trans('dashboard.forecast.declaration')}>
        <Iconify icon={'bi:info-circle'} sx={{ ml: 1, width: '14px', height: '14px' }} />
      </Tooltip>
    </>
  )

  const missingValues = filterValuesByYear(usage, dayjs().year() - 1).some((value) => value === null)

  const lastYearUsage = sumValuesByYear(usage, dayjs().year() - 1)
  const currentYearUsage = sumValuesByYear(usage, dayjs().year())
  const percent =
    currentYearUsage > 0 && lastYearUsage > 0 && !missingValues
      ? (currentYearUsage - lastYearUsage) / lastYearUsage
      : null

  const colors = [theme.palette.primary.light, theme.palette.primary.main]
  const chartOptions = {
    colors: [theme.palette.primary.light, theme.palette.primary.main].map((color) => color[1]),
    fill: {
      type: 'gradient',
      gradient: {
        colorStops: [
          { offset: 0, color: colors[0], opacity: 1 },
          { offset: 100, color: colors[1], opacity: 1 }
        ]
      }
    },
    stroke: {
      curve: 'smooth'
    },
    yaxis: {
      show: false,
      min: currentYearUsage > 0 ? (currentYearUsage / 24) * -1 : -100
    },
    chart: {
      sparkline: {
        enabled: true
      }
    },
    plotOptions: {
      bar: {
        columnWidth: '68%',
        borderRadius: 2
      }
    },
    series: [{ name: '', data: filterValuesByYear(usage, dayjs().year()).map((item) => (item == null ? 0 : item)) }],
    tooltip: {
      x: { show: false },
      y: {
        formatter: (value) => {
          if (!value) {
            return 0
          }

          return `${value.toLocaleString('de-DE', { maximumFractionDigits: getDecimalByDeviceType(DeviceTypes.HEAT) })} ${getUnitByDeviceType(DeviceTypes.HEAT)}`
        }
      },
      marker: { show: false }
    },
    forecastDataPoints: {
      count: 12 - dayjs().month()
    }
  }

  return (
    <>
      {Object.values(usage)?.length > 0 ? (
        <Card sx={{ backgroundColor: theme.palette.secondary.lighter }}>
          <CardHeader title={header} />
          <Box sx={{ px: 3, pb: 4 }}>
            <Stack direction={'row'} alignItems={'baseline'} spacing={1} sx={{ mt: 2 }}>
              <Typography variant={'h3'}>
                {formatNumber(currentYearUsage, getDecimalByDeviceType(DeviceTypes.HEAT))}
              </Typography>
              <Typography variant={'h6'}>{getUnitByDeviceType(DeviceTypes.HEAT)}</Typography>
            </Stack>
            <Stack direction="row" alignItems="center" sx={{ mt: 1, mb: 1 }}>
              <Iconify
                width={24}
                icon={
                  percent < 0 ? 'solar:double-alt-arrow-down-bold-duotone' : 'solar:double-alt-arrow-up-bold-duotone'
                }
                sx={{
                  mr: 1,
                  color: 'success.main',
                  ...(percent > 0 && {
                    color: 'error.main'
                  })
                }}
              />

              <Typography component="div" variant="subtitle2">
                {percent && (
                  <>
                    {percent > 0 && '+'}
                    {formatPercent(percent, 1)} {trans('dashboard.forecast.percent')}
                  </>
                )}
                {!percent && '-'}
              </Typography>
            </Stack>

            <Chart dir="ltr" type="line" series={chartOptions.series} options={chartOptions} height={128} />
          </Box>
        </Card>
      ) : (
        <EmptyItem message={trans('dashboard.consumption.energy_empty')} />
      )}
    </>
  )
}

DashboardEnergyForecast.propTypes = {
  usage: PropTypes.object
}
