import { Head } from '@inertiajs/react'
import { trans } from '@/utils/translation'
import DashboardLayout from '../../layouts/dashboard'
import { ApartmentDetailView } from '@/sections/apartment/view'

const DeviceListPage = () => {
  return (
    <>
      <Head>
        <title>{trans('apartment.title')}</title>
      </Head>
      <ApartmentDetailView />
    </>
  )
}
DeviceListPage.layout = (page) => <DashboardLayout>{page}</DashboardLayout>

export default DeviceListPage
