import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Stack from '@mui/material/Stack'
import { useTheme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { formatPercent } from '@/utils/format'
import Chart from '@/components/chart'
import Iconify from '@/components/iconify'
import { trans } from '@/utils/translation'

export default function WidgetSummary({ title, percent, total, chart, sx, ...other }) {
  const theme = useTheme()

  const { colors = [theme.palette.primary.light, theme.palette.primary.main], series, options } = chart

  const chartOptions = {
    colors: colors.map((colr) => colr[1]),
    fill: {
      type: 'gradient',
      gradient: {
        colorStops: [
          { offset: 0, color: colors[0], opacity: 1 },
          { offset: 100, color: colors[1], opacity: 1 }
        ]
      }
    },
    chart: {
      sparkline: {
        enabled: true
      }
    },
    plotOptions: {
      bar: {
        columnWidth: '68%',
        borderRadius: 2
      }
    },
    tooltip: {
      enabled: false
    },
    ...options
  }

  return (
    <Card sx={{ display: 'flex', alignItems: 'center', p: 3, height: '100%', ...sx }} {...other}>
      <Box sx={{ flexGrow: 1 }}>
        <Typography variant="subtitle2" sx={{ mb: 1 }}>
          {title}
        </Typography>

        <Typography variant="h3">{total}</Typography>

        <Stack direction="row" alignItems="center" sx={{ mt: 2, mb: 1 }}>
          <Iconify
            width={24}
            icon={percent < 0 ? 'solar:double-alt-arrow-down-bold-duotone' : 'solar:double-alt-arrow-up-bold-duotone'}
            sx={{
              mr: 1,
              color: 'success.main',
              ...(percent < 0 && {
                color: 'error.main'
              })
            }}
          />

          <Typography component="div" variant="subtitle2">
            {percent > 0 && '+'}
            {isNaN(percent) ? formatPercent(0) : formatPercent(percent)} {trans('dashboard.percent')}
          </Typography>
        </Stack>
      </Box>

      <Chart dir="ltr" type="bar" series={[{ data: series }]} options={chartOptions} width={60} height={36} />
    </Card>
  )
}

WidgetSummary.propTypes = {
  chart: PropTypes.object,
  percent: PropTypes.number,
  sx: PropTypes.object,
  title: PropTypes.string,
  total: PropTypes.string
}
