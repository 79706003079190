import { DialogActions, DialogTitle, Stack } from '@mui/material'
import { trans } from '@/utils/translation'
import DialogContent from '@mui/material/DialogContent'
import CustomButton from '@/components/custom-button'
import { LoadingButton } from '@mui/lab'
import { useModalContext } from '@/context/use-modal-context'
import { useForm } from '@/hooks/use-form'
import { router } from '@inertiajs/react'
import { ToastCtl } from '@/utils/toast-ctl'
import useSubmit from '@/hooks/use-submit'
import { FormProvider } from '@/context/use-form-context'
import PropTypes from 'prop-types'
import InertiaRemoteAutocomplete from '@/components/form/inertia-remote-autocomplete'
import InertiaAutocomplete from '@/components/form/inertia-autocomplete'
import InertiaSwitch from '@/components/form/inertia-switch'

export default function DocumentUpdateModal({ documentId, setRefresh }) {
  const { closeModal } = useModalContext()

  const methods = useForm({
    url: {
      method: 'PATCH',
      location: `/documents/${documentId}/update`
    },
    defaultValues: {
      type: null,
      users: [],
      apartment: null,
      property: null,
      notify: false
    }
  })

  const { data, setData, setOverrideFieldValues, submit, submitting } = methods

  const handleSubmit = () => {
    submit(
      () => {
        router.reload()
        ToastCtl.show(trans('general.save_success'), 'success')
        closeModal('documentUpdate')
        setRefresh(new Date())
      },
      () => ToastCtl.show(trans('general.missing_permissions'), 'error')
    )
  }

  useSubmit(() => handleSubmit())

  return (
    <>
      <DialogTitle>{trans('document.share')}</DialogTitle>
      <DialogContent dividers sx={{ py: 1 }}>
        <FormProvider {...methods} sx={{ px: 2, mx: 8 }}>
          <form onSubmit={handleSubmit}>
            <Stack direction={'column'} spacing={2}>
              <InertiaAutocomplete
                name={'type'}
                input={{
                  label: trans('document.share'),
                  required: true
                }}
                items={[
                  { id: 'person', label: 'einzelnen Personen' },
                  { id: 'apartment', label: 'dem Mieter und Eigentümer einer Einheit' },
                  { id: 'property', label: 'Allen Mietern und Eigentümern einer Liegenschaft' }
                ]}
              />
              {data.type === 'person' && (
                <InertiaRemoteAutocomplete
                  name={'users'}
                  input={{
                    label: trans('general.user'),
                    multiple: true,
                    required: true,
                    optionLabel: (item) => `${item.name} ${item.email ? `(${item.email})` : ''}`
                  }}
                  url={'/api/users?name=%s&itemsPerPage=10'}
                />
              )}
              {(data.type === 'apartment' || data.type === 'property') && (
                <InertiaRemoteAutocomplete
                  name={'property'}
                  input={{
                    label: trans('general.property'),
                    required: true
                  }}
                  url={'/api/properties?label=%s&itemsPerPage=10'}
                  callback={() => {
                    setData('apartments', [])
                    setOverrideFieldValues({
                      apartments: []
                    })
                  }}
                />
              )}
              {data.property && data.type === 'apartment' && (
                <InertiaRemoteAutocomplete
                  name={'apartment'}
                  input={{
                    label: trans('general.apartment'),
                    required: true
                  }}
                  url={`/api/apartments?property.id=${data.property}&label=%s&itemsPerPage=10`}
                />
              )}
              <InertiaSwitch
                name={'notify'}
                input={{
                  label: trans('document.notify')
                }}
              />
            </Stack>
          </form>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <CustomButton onClick={() => closeModal('documentUpdate')}>{trans('button.cancel')}</CustomButton>
        <LoadingButton
          loading={submitting}
          variant={'contained'}
          onClick={() => {
            handleSubmit()
          }}
        >
          {trans('button.done')}
        </LoadingButton>
      </DialogActions>
    </>
  )
}

DocumentUpdateModal.propTypes = {
  documentId: PropTypes.string,
  setRefresh: PropTypes.func
}
