import { Typography } from '@mui/material'
import { trans } from '@/utils/translation'
import AssignmentListTable from '@/sections/assignment/assignment-list-table'

export default function AssignmentListView() {
  return (
    <>
      <Typography variant="h5" gutterBottom>
        {trans('assignment.header')}
      </Typography>
      <AssignmentListTable />
    </>
  )
}
