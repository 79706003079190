import { useMemo, useState } from 'react'
import { trans } from '@/utils/translation'
import { Card, Stack } from '@mui/material'
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table'
import { defaultTableOptions } from '@/utils/misc'
import Typography from '@mui/material/Typography'
import CustomButton from '@/components/custom-button'
import MenuItem from '@mui/material/MenuItem'
import Iconify from '@/components/iconify'
import { useModalContext } from '@/context/use-modal-context'
import { router, usePage } from '@inertiajs/react'
import { formatDate } from '@/utils/format'
import CreateModal from '@/sections/property-contractor/create-modal'
import { useBoolean } from '@/hooks/use-boolean'
import TableConfirmDelete from '@/components/table/table-confirm-delete'
import Roles from '@/constants/Roles'

export default function PropertyContractorListTable() {
  const { property } = usePage().props
  const { openModal } = useModalContext()
  const confirmRow = useBoolean()
  const [contractedProperty, setContractedProperty] = useState(null)

  const columns = useMemo(
    () => [
      {
        accessorFn: (row) => `${row.user.name} (${trans(`role.${row.user.roles[0]}`)})`,
        header: trans('general.contractor'),
        Cell: ({ renderedCellValue }) => renderedCellValue,
        size: 250,
        enableSorting: false
      },
      {
        accessorKey: 'begin',
        header: trans('general.from'),
        Cell: ({ renderedCellValue }) => formatDate(renderedCellValue),
        size: 50,
        enableSorting: false
      },
      {
        accessorKey: 'end',
        header: trans('general.to'),
        Cell: ({ renderedCellValue }) => (renderedCellValue ? formatDate(renderedCellValue) : '-'),
        size: 50,
        enableSorting: false
      }
    ],
    []
  )

  const editRoute = (role) => {
    switch (role) {
      case Roles.ROLE_PROPERTY_OWNER:
        return '/owners'
      case Roles.ROLE_PROPERTY_ADMIN:
        return '/managers'
      default:
        return null
    }
  }

  const table = useMaterialReactTable({
    ...defaultTableOptions,
    renderTopToolbar: () => (
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} justifyContent={'space-between'} m={2}>
        <Typography variant="h6"> {trans('property.contractors.title')} </Typography>
        <CustomButton
          variant="contained"
          color="primary"
          onClick={() =>
            openModal('propertyContractorCreate', <CreateModal defaultValues={{ property: property.id }} />)
          }
        >
          + {trans('property_contractor.create')}{' '}
        </CustomButton>
      </Stack>
    ),
    columns,
    data: property.contractors,
    enableRowActions: true,
    renderRowActionMenuItems: ({ row }) => [
      <MenuItem
        key="editUser"
        disabled={row.original.user.roles[0] === Roles.ROLE_ADMIN}
        onClick={() => router.get(`${editRoute(row.original.user.roles[0])}/${row.original.user?.id}/detail`)}
      >
        <Iconify icon="solar:pen-bold" sx={{ mr: 1 }} />
        {trans('table_view.edit_user')}
      </MenuItem>,
      <MenuItem
        key="editDate"
        onClick={() =>
          openModal(
            'propertyContractorCreate',
            <CreateModal
              defaultValues={{
                id: row.original.id,
                user: row.original.user?.id,
                property: property.id,
                begin: row.original.begin,
                end: row.original.end
              }}
            />
          )
        }
      >
        <Iconify icon="solar:pen-bold" sx={{ mr: 1 }} />
        {trans('table_view.edit_time')}
      </MenuItem>,
      <MenuItem
        key="delete"
        sx={{ color: 'error.main' }}
        onClick={() => {
          setContractedProperty(row.original)
          confirmRow.onTrue()
        }}
      >
        <Iconify icon="solar:trash-bin-trash-bold" sx={{ mr: 1 }} />
        {trans('table_view.delete_time')}
      </MenuItem>
    ]
  })

  return (
    <>
      <Card>
        <MaterialReactTable table={table} />
      </Card>
      <TableConfirmDelete
        url={`/api/property_contractors/${contractedProperty?.id}`}
        confirmRows={confirmRow}
        setValue={setContractedProperty}
      />
    </>
  )
}
