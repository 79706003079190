import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Stack from '@mui/material/Stack'
import { useTheme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { formatDeviceValue, formatPercent } from '@/utils/format'
import Chart, { useChart } from '@/components/chart'
import Iconify from '@/components/iconify'
import CustomPopover, { usePopover } from '@/components/custom-popover'
import { useCallback, useEffect, useRef, useState } from 'react'
import ButtonBase from '@mui/material/ButtonBase'
import CardHeader from '@mui/material/CardHeader'
import MenuItem from '@mui/material/MenuItem'
import Tooltip from '@mui/material/Tooltip'
import dayjs from 'dayjs'
import { trans } from '@/utils/translation'
import { filterValuesByYear, sumValuesByYear } from '@/utils/chart'

export default function WidgetSummaryOverview({ title, declaration, empty, usage, deviceType, chart, sx, ...other }) {
  const theme = useTheme()
  const chartRef = useRef()

  const header = (
    <>
      {title}
      {declaration && (
        <Tooltip title={declaration}>
          <Iconify icon={'bi:info-circle'} sx={{ ml: 1, width: '14px', height: '14px' }} />
        </Tooltip>
      )}
    </>
  )

  const popover = usePopover()

  const [seriesData, setSeriesData] = useState('last_month')
  const { colors = [theme.palette.primary.light, theme.palette.primary.main], type = 'line', options } = chart
  const series = [
    {
      name: 'last_month',
      data: filterValuesByYear(usage, dayjs().year())
    },
    {
      name: 'last_year',
      data: filterValuesByYear(usage, dayjs().subtract(1, 'year').format('YYYY'))
    }
  ]

  const getPercentage = (current, previous) => {
    if (!previous || !current) {
      return null
    }

    return (current - previous) / previous
  }

  const totalValue = (type = null) => {
    if (type === 'last_year') {
      return sumValuesByYear(usage, dayjs().subtract(1, 'year').format('YYYY'))
    }

    return usage[dayjs().subtract(1, 'month').format('YYYY-MM')]
  }

  const compareValue = (type = null) => {
    if (type === 'last_year') {
      return sumValuesByYear(usage, dayjs().subtract(2, 'year').format('YYYY'))
    }

    return usage[dayjs().subtract(2, 'month').format('YYYY-MM')]
  }

  const [total, setTotal] = useState(totalValue())
  const [percent, setPercent] = useState(getPercentage(totalValue(), compareValue()))

  const chartOptions = useChart({
    colors: [colors[1]],
    legend: {
      show: false
    },
    fill: {
      type: 'gradient',
      gradient: {
        colorStops: [
          { offset: 0, color: colors[0], opacity: 1 },
          { offset: 100, color: colors[1], opacity: 1 }
        ]
      }
    },
    chart: {
      animations: {
        enabled: true
      },
      sparkline: {
        enabled: true
      }
    },
    tooltip: {
      enabled: false
    },
    ...options
  })

  const handleChangeSeries = useCallback(
    (newValue) => {
      popover.onClose()
      setSeriesData(newValue)
      setPercent(getPercentage(totalValue(newValue), compareValue(newValue)))
      setTotal(totalValue(newValue))

      chartRef?.current?.chart?.toggleSeries('last_year')
      chartRef?.current?.chart?.toggleSeries('last_month')
    },
    [popover]
  )

  useEffect(() => {
    chartRef?.current?.chart?.toggleSeries('last_year')
  }, [])

  return (
    <>
      {Object.values(usage)?.length > 0 ? (
        <>
          <Card sx={{ height: '100%' }} {...other}>
            <CardHeader
              title={header}
              action={
                <ButtonBase
                  onClick={popover.onOpen}
                  sx={{
                    pl: 1,
                    py: 0.5,
                    pr: 0.5,
                    borderRadius: 1,
                    typography: 'caption',
                    bgcolor: 'background.neutral'
                  }}
                >
                  {trans(`dashboard.consumption.${seriesData}`)}

                  <Iconify
                    width={16}
                    icon={popover.open ? 'eva:arrow-ios-upward-fill' : 'eva:arrow-ios-downward-fill'}
                    sx={{ ml: 0.5 }}
                  />
                </ButtonBase>
              }
            />

            <Box sx={{ display: 'flex', justifyContent: 'space-between', mx: 3, mt: 2, ...sx }}>
              <Stack direction="row" alignItems="center">
                <Iconify
                  width={24}
                  icon={
                    percent < 0 ? 'solar:double-alt-arrow-down-bold-duotone' : 'solar:double-alt-arrow-up-bold-duotone'
                  }
                  sx={{
                    mr: 1,
                    color: 'success.main',
                    ...(percent > 0 && {
                      color: 'error.main'
                    })
                  }}
                />

                <Typography component="div" variant="subtitle2">
                  {percent && (
                    <>
                      {percent > 0 && '+'}
                      {formatPercent(percent, 1)}
                    </>
                  )}
                  {!percent && '-'}
                </Typography>
              </Stack>
              <Chart
                ref={chartRef}
                dir="ltr"
                type={type}
                series={series}
                options={chartOptions}
                width={60}
                height={36}
              />
            </Box>
            {total != null && (
              <Typography variant="h4" sx={{ mx: 3, mb: 2, mt: 1 }}>
                {formatDeviceValue(total, deviceType)}
              </Typography>
            )}
            {total === null && (
              <Typography variant="body2" sx={{ mx: 3, mb: 2, mt: 1 }}>
                {trans('dashboard.consumption.data_empty')}
              </Typography>
            )}
          </Card>
          <CustomPopover open={popover.open} onClose={popover.onClose} sx={{ width: 140 }}>
            {series.map((option) => (
              <MenuItem
                key={option.name}
                selected={option.name === seriesData}
                onClick={() => handleChangeSeries(option.name)}
                disabled={totalValue(option.name) === null}
              >
                {trans(`dashboard.consumption.${option.name}`)}
              </MenuItem>
            ))}
          </CustomPopover>
        </>
      ) : (
        <Card sx={{ height: '100%', backgroundColor: 'background.neutral' }} {...other}>
          <Box sx={{ p: 6, ...sx }}>
            <Typography variant="subtitle1" sx={{ textAlign: 'center' }}>
              {empty}
            </Typography>
          </Box>
        </Card>
      )}
    </>
  )
}

WidgetSummaryOverview.propTypes = {
  chart: PropTypes.object,
  percent: PropTypes.number,
  sx: PropTypes.object,
  title: PropTypes.string,
  empty: PropTypes.string,
  total: PropTypes.string,
  declaration: PropTypes.string,
  usage: PropTypes.object,
  deviceType: PropTypes.string
}
