import { trans } from '@/utils/translation'
import { Card, CardContent, CardHeader, Stack } from '@mui/material'
import Box from '@mui/material/Box'
import { router, usePage } from '@inertiajs/react'
import Button from '@mui/material/Button'
import poster from '@/../images/poster/Startbild.jpg'
import CustomVideo from '@/components/custom-video'

export default function AssignmentProcessSuccess() {
  const { assignment, isNewCustomer } = usePage().props

  const video = isNewCustomer ? '02_Anfrage_Neukunde.mp4' : '03_Anfrage_Bestandskunde.mp4'

  return (
    <Box sx={{ maxWidth: '900px', mx: 'auto' }}>
      <Card sx={{ mx: 3 }}>
        <CardHeader
          title={trans('assignment.success')}
          titleTypographyProps={{ textAlign: 'center' }}
          sx={{
            mb: 2
          }}
        />
        <Box sx={{ borderRadius: 3, mx: 2, overflow: 'hidden' }}>
          <CustomVideo src={video} poster={poster} />
        </Box>
        <CardContent sx={{ display: 'flex', justifyContent: 'center' }}>
          <Stack direction={'row'} spacing={2}>
            <Button
              color={'primary'}
              variant={'contained'}
              onClick={() => router.get(`/properties/${assignment.property.id}/detail/`)}
            >
              {trans('assignment.link_property')}
            </Button>
            <Button
              color={'primary'}
              variant={'contained'}
              onClick={() => router.get(`/properties/${assignment.property.id}/complete/step-0`)}
            >
              {trans('assignment.link_property_complete')}
            </Button>
          </Stack>
        </CardContent>
      </Card>
    </Box>
  )
}
