import Box from '@mui/material/Box'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepButton from '@mui/material/StepButton'
import AssignmentProcessStep1 from '@/sections/assignment/steps/assignment-process-step-1'
import { useTheme } from '@mui/material/styles'
import AssignmentProcessStep2 from '@/sections/assignment/steps/assignment-process-step-2'
import AssignmentProcessStep3 from '@/sections/assignment/steps/assignment-process-step-3'
import { router, usePage } from '@inertiajs/react'
import { AssignmentView } from '@/sections/assignment/view'
import AssignmentProcessStep4 from '@/sections/assignment/steps/assignment-process-step-4'
import AssignmentProcessSuccess from '@/sections/assignment/steps/assignment-process-success'

const steps = [1, 2, 3, 4]

export default function AssignmentProcessView() {
  const { step, assignment } = usePage().props
  const theme = useTheme()

  const renderOverview = <AssignmentView />

  const renderSteps = (
    <Box>
      <Stepper
        nonLinear
        activeStep={Number(step) - 1}
        sx={{
          justifyContent: 'space-between',
          mb: 3,
          mx: 'auto',
          maxWidth: '400px',
          '& .MuiStepLabel-root .Mui-completed': {
            color: theme.palette.success.main // circle color (COMPLETED)
          }
        }}
      >
        {steps.map((label, index) => (
          <Step
            key={label}
            completed={index < Number(step) - 1}
            disabled={index > Number(step) - 1}
            onClick={() => {
              if (index > Number(step) - 1) return

              router.get(`/assignment/${assignment.id}/step-${label}`)
            }}
          >
            <StepButton color="inherit"></StepButton>
          </Step>
        ))}
      </Stepper>
      <div>
        {
          <Box sx={{ mt: 2, mb: 1, py: 1 }}>
            {parseInt(step) === 1 && <AssignmentProcessStep1 />}
            {parseInt(step) === 2 && <AssignmentProcessStep2 />}
            {parseInt(step) === 3 && <AssignmentProcessStep3 />}
            {parseInt(step) === 4 && <AssignmentProcessStep4 />}
            {parseInt(step) === 5 && <AssignmentProcessSuccess />}
          </Box>
        }
      </div>
    </Box>
  )

  return (
    <>
      {parseInt(step) === 0 && renderOverview}
      {parseInt(step) !== 0 && renderSteps}
    </>
  )
}
