import CustomPopover, { usePopover } from '../../../components/custom-popover'
import { FormControl, Stack } from '@mui/material'
import { trans } from '@/utils/translation'
import Iconify from '../../../components/iconify'
import IconButton from '@mui/material/IconButton'
import MenuItem from '@mui/material/MenuItem'
import { alpha } from '@mui/material/styles'
import Tabs from '@mui/material/Tabs'
import { STATUS_OPTIONS } from './appointment-table-definitions'
import Tab from '@mui/material/Tab'
import Label from '../../../components/label'
import { useStateCounts } from '@/hooks/use-state-counts'
import PropTypes from 'prop-types'
import AppointmentState from '@/constants/AppointmentState'
import ListFilterTextfield from '@/components/form/list-filter-textfield'

export default function AppointmentTableToolbar({
  columnFilters,
  setColumnFilters,
  globalFilter,
  setGlobalFilter,
  resetAll
}) {
  const popover = usePopover()
  const counts = useStateCounts(`/appointments/count?filters=${JSON.stringify(columnFilters)}`, columnFilters)

  return (
    <>
      <Stack
        flexDirection={'row'}
        flexWrap={'wrap'}
        alignItems={'center'}
        justifyContent={'space-between'}
        sx={{
          boxShadow: (theme) => `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`
        }}
      >
        <Tabs
          value={columnFilters.state ?? ''}
          onChange={(e, value) => {
            setColumnFilters({ ...columnFilters, state: value })
          }}
          sx={{
            px: 2.5
          }}
        >
          {Object.entries(STATUS_OPTIONS).map(
            ([key, { color, label }]) =>
              key !== AppointmentState.AUTO_CONFIRMED && (
                <Tab
                  key={key}
                  iconPosition="end"
                  value={key}
                  label={label}
                  icon={
                    <Label
                      variant={((key === null || key === columnFilters.state) && 'filled') || 'soft'}
                      color={color}
                    >
                      {counts[key] ?? counts['ALL']}
                    </Label>
                  }
                />
              )
          )}
        </Tabs>
      </Stack>
      <Stack>
        <Stack
          direction={'row'}
          alignItems={'center'}
          flexWrap={'wrap'}
          spacing={2}
          flexGrow={1}
          sx={{
            p: 2
          }}
        >
          <Stack
            direction={'row'}
            alignItems={'center'}
            sx={{
              flexGrow: 1,
              width: { xs: 1, md: 200 }
            }}
          >
            <FormControl sx={{ flexGrow: 1 }}>
              <ListFilterTextfield
                placeholder={trans('appointment.search_placeholder')}
                globalFilter={globalFilter}
                setGlobalFilter={setGlobalFilter}
              />
            </FormControl>
            <IconButton onClick={popover.onOpen}>
              <Iconify icon="eva:more-vertical-fill" />
            </IconButton>
          </Stack>
        </Stack>

        <CustomPopover open={popover.open} onClose={popover.onClose} arrow="right-top" sx={{ width: 270 }}>
          <MenuItem
            onClick={() => {
              resetAll()
              popover.onClose()
            }}
          >
            <Iconify icon={'carbon:reset'} sx={{ width: 24, height: 24, mr: '16px' }} />
            {trans('table.reset_filter')}
          </MenuItem>
        </CustomPopover>
      </Stack>
    </>
  )
}

AppointmentTableToolbar.propTypes = {
  columnFilters: PropTypes.object,
  setColumnFilters: PropTypes.func,
  globalFilter: PropTypes.string,
  setGlobalFilter: PropTypes.func,
  refresh: PropTypes.number,
  resetAll: PropTypes.func
}
