import Typography from '@mui/material/Typography'
import { Stack } from '@mui/material'
import { useFormContext } from '@/context/use-form-context'
import PropTypes from 'prop-types'
import CustomButton from '@/components/custom-button'
import InertiaNumericField from '@/components/form/inertia-numeric-field'

export default function InertiaNumericToggle({ name, input }) {
  const { data, setData, setOverrideFieldValues, errors } = useFormContext()
  const { label, addCallback, subCallback, allowNegative } = input

  return (
    <>
      <Typography variant="body2">{label}</Typography>
      <Stack direction={'row'} spacing={2} alignItems={'baseline'}>
        <CustomButton
          variant="outlined"
          sx={{ mt: 0 }}
          onClick={() => {
            if (subCallback) {
              subCallback()
            } else {
              if (allowNegative || data[name] > 0) {
                setOverrideFieldValues({ [name]: data[name] - 1 })
                setData(name, data[name] - 1)
              }
            }
          }}
        >
          -
        </CustomButton>
        <Stack sx={{ width: '58px', maxHeight: '40px', margin: 0 }}>
          <InertiaNumericField
            name={name}
            input={{
              label: '',
              suffix: '',
              decimalScale: 0,
              size: 'small',
              helperText: null,
              sx: {
                '& input': {
                  textAlign: 'center'
                }
              }
            }}
          />
        </Stack>
        <CustomButton
          variant="outlined"
          sx={{ mt: 0, mr: 1 }}
          onClick={() => {
            if (addCallback) {
              addCallback()
            } else {
              setOverrideFieldValues({ [name]: data[name] + 1 })
              setData(name, data[name] + 1)
            }
          }}
        >
          +
        </CustomButton>
      </Stack>
      <span className="validation-error">{errors[name]}</span>
    </>
  )
}

InertiaNumericToggle.propTypes = {
  name: PropTypes.string.isRequired,
  input: PropTypes.object.isRequired
}
