import { useMemo, useState } from 'react'
import { trans } from '@/utils/translation'
import useRemoteTable from '../../hooks/useRemoteTable'
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table'
import { defaultTableOptions } from '@/utils/misc'
import { Card, Typography } from '@mui/material'
import { router, usePage } from '@inertiajs/react'
import { COLUMNS, TENANT_COLUMNS } from './table/apartment-table-definitions'
import ApartmentTableToolbar from './table/apartment-table-toolbar'
import PropTypes from 'prop-types'
import Roles from '@/constants/Roles'
import Iconify from '@/components/iconify'
import TableRowAction from '@/components/table/table-row-action'
import { useBoolean } from '@/hooks/use-boolean'
import TableConfirmDelete from '@/components/table/table-confirm-delete'
import { useTheme } from '@mui/material/styles'

export default function ApartmentListTable({ defaultFilter, defaultSorting }) {
  const path = '/apartments/list'
  const { auth } = usePage().props
  const theme = useTheme()
  const [apartment, setApartment] = useState(null)
  const confirmRows = useBoolean()

  const columns = useMemo(() => (defaultFilter?.property ? COLUMNS : TENANT_COLUMNS), [])
  const methods = useRemoteTable(path, ['label'], defaultFilter, defaultSorting)
  const {
    globalFilter,
    columnSorting,
    setColumnSorting,
    pagination,
    setPagination,
    data,
    isError,
    isLoading,
    rowCount,
    refresh,
    setRefresh
  } = methods

  const table = useMaterialReactTable({
    ...defaultTableOptions,
    renderTopToolbar: () => <ApartmentTableToolbar {...methods} />,
    muiTablePaperProps: {
      elevation: 0,
      sx: {
        borderRadius: '0'
      }
    },
    enableRowActions: true,
    renderRowActions: ({ row }) => {
      if (
        (row.original.property.state === 'OPEN' || row.original.property.state === 'PROGRESS') &&
        row.original.property.assignments.length === 1 &&
        auth.user?.roles?.includes(Roles.ROLE_ADMIN)
      ) {
        return (
          <TableRowAction
            row={row}
            items={[
              {
                content: (
                  <>
                    <Iconify icon={'eva:trash-2-outline'} color={theme.palette.error.main} />
                    <Typography variant={'body'}>{trans('button.delete')}</Typography>
                  </>
                ),
                callback: () => {
                  setApartment(row.original)
                  confirmRows.onTrue()
                }
              }
            ]}
          />
        )
      }
    },
    muiTableBodyRowProps: ({ row }) => ({
      onClick: () => {
        auth.user?.roles?.includes(Roles.ROLE_TENANT)
          ? router.get(`/dashboard/apartment/${row.original.id}`)
          : router.get(`/apartments/${row.original.id}/detail`)
      },
      sx: { cursor: 'pointer' }
    }),
    muiToolbarAlertBannerProps: isError ? { severity: 'error', children: trans('table.data_load_error') } : undefined,
    columns,
    data,
    rowCount: rowCount,
    onPaginationChange: setPagination,
    onSortingChange: setColumnSorting,
    state: {
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isLoading,
      showSkeletons: isLoading,
      sorting: columnSorting,
      refresh
    }
  })

  return (
    <>
      <Card>
        <MaterialReactTable table={table} />
      </Card>

      <TableConfirmDelete
        url={`/api/apartments/${apartment?.id}`}
        confirmRows={confirmRows}
        setValue={setApartment}
        setRefresh={setRefresh}
      />
    </>
  )
}

ApartmentListTable.propTypes = {
  defaultFilter: PropTypes.object,
  defaultSorting: PropTypes.array
}
