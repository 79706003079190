import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import { usePage } from '@inertiajs/react'
import BillingState from '@/constants/BillingState'

export default function OverlayCard({ children }) {
  const { billing } = usePage().props
  const editForbidden = billing.state === BillingState.DONE || billing.state === BillingState.SUBMITTED

  return (
    <Box sx={{ position: 'relative' }}>
      <Box
        sx={{
          borderRadius: '16px',
          background: '#8a8a8a3d',
          position: 'absolute',
          width: '100%',
          height: '100%',
          zIndex: editForbidden ? 100 : -1,
          pointerEvents: editForbidden ? 'none' : 'auto'
        }}
      ></Box>
      {children}
    </Box>
  )
}

OverlayCard.propTypes = {
  children: PropTypes.node.isRequired
}
