import { useMemo } from 'react'
import { trans } from '@/utils/translation'
import useRemoteTable from '../../hooks/useRemoteTable'
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table'
import { defaultTableOptions } from '@/utils/misc'
import { Card, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { router } from '@inertiajs/react'
import { formatDate, formatDeviceValue } from '@/utils/format'
import Paper from '@mui/material/Paper'
import { COLUMNS } from './table/device-table-definitions'
import DeviceTableToolbar from './table/device-table-toolbar'
import PropTypes from 'prop-types'

export default function DeviceListTable({ defaultFilter, defaultSorting, context }) {
  const path = '/devices/list'

  const columns = useMemo(() => COLUMNS, [])
  const methods = useRemoteTable(path, ['number'], defaultFilter, defaultSorting)
  const {
    globalFilter,
    columnSorting,
    setColumnSorting,
    pagination,
    setPagination,
    data,
    isError,
    isLoading,
    rowCount
  } = methods

  const table = useMaterialReactTable({
    ...defaultTableOptions,
    renderTopToolbar: () => <DeviceTableToolbar {...methods} context={context} />,
    renderDetailPanel: ({ row }) =>
      row.original.deviceValues.length > 0 ? (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>{trans('field.device.report_date')}</TableCell>
                <TableCell>{trans('field.device.type')}</TableCell>
                <TableCell>{trans('field.device.value')}</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {row.original.deviceValues.map((deviceValue) => (
                <TableRow
                  key={deviceValue.id}
                  sx={{
                    '&:last-child td, &:last-child th': {
                      border: 0
                    }
                  }}
                >
                  <TableCell>{formatDate(deviceValue.reportDate, 'DD.MM.YYYY HH:mm')}</TableCell>
                  <TableCell>{trans(`device_value.type.${deviceValue.type}`)}</TableCell>
                  <TableCell>{formatDeviceValue(deviceValue.value, row.original.type, deviceValue.unit)}</TableCell>
                  <TableCell sx={{ width: '25%' }}></TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        false
      ),
    muiTablePaperProps: {
      elevation: 0,
      sx: {
        borderRadius: '0'
      }
    },
    muiTableBodyRowProps: ({ row }) => ({
      onClick: () => router.get(`/devices/${row.original.id}/detail`),
      sx: { cursor: 'pointer' }
    }),
    muiToolbarAlertBannerProps: isError ? { severity: 'error', children: trans('table.data_load_error') } : undefined,
    columns,
    data,
    rowCount: rowCount,
    onPaginationChange: setPagination,
    onSortingChange: setColumnSorting,
    state: {
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isLoading,
      showSkeletons: isLoading,
      sorting: columnSorting
    }
  })

  return (
    <>
      <Card>
        <MaterialReactTable table={table} />
      </Card>
    </>
  )
}

DeviceListTable.propTypes = {
  defaultFilter: PropTypes.object,
  defaultSorting: PropTypes.array,
  context: PropTypes.object
}
