import { Card, CardHeader, Grid } from '@mui/material'
import AppointmentData from '@/sections/appointment/appointment-data'
import Box from '@mui/material/Box'
import { trans } from '@/utils/translation'
import CustomButton from '@/components/custom-button'
import AppointmentMailTemplate from '@/sections/appointment/appointment-mail-template'
import { usePage } from '@inertiajs/react'

export default function AppointmentDetailPage() {
  const { appointment } = usePage().props
  const mailTemplate = AppointmentMailTemplate(appointment)

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4}>
        <Card>
          <Box sx={{ my: 3 }}>
            <AppointmentData cancel={false} />
          </Box>
        </Card>
      </Grid>
      <Grid item xs={12} md={4}>
        <Card sx={{ pb: 3 }}>
          <CardHeader title={trans('appointment.features')} />
          <CustomButton
            variant={'contained'}
            sx={{ mx: 3, my: 1 }}
            disabled={appointment?.document === null}
            onClick={() => window.open(`/documents/${appointment.document.id}/view`, '_blank')}
          >
            {trans('appointment.print.button')}
          </CustomButton>
          <CustomButton variant={'contained'} sx={{ mx: 3, my: 1 }} href={mailTemplate}>
            {trans('appointment.send_email.button')}
          </CustomButton>
        </Card>
      </Grid>
    </Grid>
  )
}
