import { useEffect, useMemo, useState } from 'react'
import TextField from '@mui/material/TextField'
import { Tooltip } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import { InfoOutlined, Lock } from '@mui/icons-material'
import { get } from 'lodash'
import { useFormContext } from '@/context/use-form-context'

const InertiaTextfield = ({ input, name }) => {
  const { data, setDataDebounce, overrideFieldValues, errors, setData } = useFormContext()
  const { required, tooltip, disabled, type } = input

  const [fieldValue, setFieldValue] = useState(get(data, name) || '')

  useEffect(() => {
    const override = get(overrideFieldValues, name)
    if (typeof override !== 'undefined') {
      setFieldValue(override || '')
    }
  }, [get(overrideFieldValues, name)])

  return useMemo(() => {
    return (
      <TextField
        value={fieldValue}
        onChange={(e) => {
          setFieldValue(e.target.value)
          setDataDebounce(name, e.target.value)
          setData(name, e.target.value)
        }}
        error={!!get(errors, name)}
        helperText={get(errors, name)}
        name={name}
        InputProps={{
          endAdornment: (
            <>
              {tooltip ? (
                <Tooltip title={tooltip}>
                  <IconButton>
                    <InfoOutlined />
                  </IconButton>
                </Tooltip>
              ) : (
                ''
              )}
              {disabled ? (
                <Tooltip title={'Bearbeitung deaktiviert'}>
                  <IconButton size={'small'} sx={{ py: 0 }}>
                    <Lock />
                  </IconButton>
                </Tooltip>
              ) : (
                ''
              )}
            </>
          )
        }}
        required={required}
        sx={{
          width: '100%',
          ...input.sx
        }}
        {...input}
      />
    )
  }, [get(errors, name), get(data, name), fieldValue, disabled, type])
}
export default InertiaTextfield
