import { createContext, useContext } from 'react'
import PropTypes from 'prop-types'

const ModalContext = createContext({
  openModal: () => {},
  closeModal: () => {},
  closeAllModals: () => {}
})

export const useModalContext = () => useContext(ModalContext)

export const ModalProvider = (props) => {
  const { children, ...data } = props
  return <ModalContext.Provider value={data.value}>{children}</ModalContext.Provider>
}

ModalProvider.propTypes = {
  children: PropTypes.node
}
