import Typography from '@mui/material/Typography'
import { Card, CardContent, CardHeader } from '@mui/material'
import PropTypes from 'prop-types'

export default function AssignmentDeviceOverview({ total, title, note, ...other }) {
  return (
    <Card {...other}>
      <CardHeader title={total} titleTypographyProps={{ variant: 'h3' }} />
      <CardContent sx={{ pt: 0 }}>
        <Typography variant="h6" sx={{ mb: 1 }}>
          {title}
        </Typography>
        <Typography variant="body2">{note}</Typography>
      </CardContent>
    </Card>
  )
}

AssignmentDeviceOverview.propTypes = {
  total: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  note: PropTypes.string.isRequired
}
