import Label from '../../components/label'
import { memo } from 'react'
import { STATUS_OPTIONS } from './table/billing-table-definitions'
import PropTypes from 'prop-types'
import { Stack } from '@mui/material'

function BillingStateLabel({ state }) {
  const colorVariant = STATUS_OPTIONS[state]?.color || 'default'
  const translation = STATUS_OPTIONS[state]?.label

  return (
    <Stack sx={{ background: '#fff', borderRadius: '6px', display: 'inherit' }}>
      <Label variant="soft" color={colorVariant}>
        {translation}
      </Label>
    </Stack>
  )
}

BillingStateLabel.propTypes = {
  state: PropTypes.string
}

export default memo(BillingStateLabel)
