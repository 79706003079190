import DashboardLayout from '@/layouts/dashboard'
import { Head } from '@inertiajs/react'
import { trans } from '@/utils/translation'
import AssignmentListView from '@/sections/assignment/view/assignment-list-view'

const AssignmentListPage = () => {
  return (
    <>
      <Head>
        <title>{trans('assignment.title')}</title>
      </Head>
      <AssignmentListView />
    </>
  )
}

AssignmentListPage.layout = (page) => <DashboardLayout>{page}</DashboardLayout>
export default AssignmentListPage
