import CustomPopover, { usePopover } from '../../../components/custom-popover'
import { Checkbox, Chip, FormControl, InputLabel, OutlinedInput, Select, Stack } from '@mui/material'
import { trans } from '@/utils/translation'
import Iconify from '../../../components/iconify'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import { Clear } from '@mui/icons-material'
import MenuItem from '@mui/material/MenuItem'
import DeviceTypes from '../../../constants/DeviceTypes'
import { alpha } from '@mui/material/styles'
import Tabs from '@mui/material/Tabs'
import { STATUS_OPTIONS } from './device-table-definitions'
import Tab from '@mui/material/Tab'
import Label from '../../../components/label'
import Button from '@mui/material/Button'
import { useStateCounts } from '@/hooks/use-state-counts'
import PropTypes from 'prop-types'
import { useModalContext } from '@/context/use-modal-context'
import DeviceCreateEditModal from '@/sections/device/device-create-edit-modal'
import DeviceExportModal from '@/sections/device/device-export-modal'
import ListFilterTextfield from '@/components/form/list-filter-textfield'

export default function DeviceTableToolbar({
  columnFilters,
  setColumnFilters,
  globalFilter,
  setGlobalFilter,
  resetAll,
  url,
  context
}) {
  const popover = usePopover()
  const { openModal } = useModalContext()
  const counts = useStateCounts(`/devices/count?filters=${JSON.stringify(columnFilters)}`, columnFilters)

  return (
    <>
      <Stack
        flexDirection={'row'}
        flexWrap={'wrap'}
        alignItems={'center'}
        justifyContent={'space-between'}
        sx={{
          boxShadow: (theme) => `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`
        }}
      >
        <Tabs
          value={columnFilters.state ?? ''}
          onChange={(e, value) => {
            setColumnFilters({ ...columnFilters, state: value })
          }}
          sx={{
            px: 2.5
          }}
        >
          {Object.entries(STATUS_OPTIONS).map(([key, { color, label }]) => (
            <Tab
              key={key}
              iconPosition="end"
              value={key}
              label={label}
              icon={
                <Label variant={((key === null || key === columnFilters.state) && 'filled') || 'soft'} color={color}>
                  {counts[key] ?? counts['ALL']}
                </Label>
              }
            />
          ))}
        </Tabs>
        <Button
          variant="contained"
          color={'primary'}
          sx={{ m: 1, mr: 4 }}
          onClick={() =>
            openModal(
              'deviceCreate',
              <DeviceCreateEditModal
                redirectToDetail={true}
                device={{
                  apartment: context?.apartment,
                  property: context?.property ?? context?.apartment?.property
                }}
              />
            )
          }
        >
          + {trans('device.create')}
        </Button>
      </Stack>
      <Stack>
        <Stack
          direction={'row'}
          alignItems={'center'}
          flexWrap={'wrap'}
          spacing={2}
          flexGrow={1}
          sx={{
            p: 2
          }}
        >
          <FormControl
            sx={{
              flexShrink: 1,
              width: { xs: 1, md: 200 }
            }}
          >
            <InputLabel>{trans('field.device.type')}</InputLabel>
            <Select
              multiple={true}
              value={columnFilters?.types ?? []}
              onChange={(e) =>
                setColumnFilters({
                  ...columnFilters,
                  types: e.target.value
                })
              }
              input={<OutlinedInput label={trans('field.device.type')} />}
              renderValue={(selected) => (
                <Box
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: 0.5
                  }}
                >
                  {selected.map((value) => (
                    <Chip
                      key={value}
                      label={trans(`device.type.${value}`)}
                      variant="light"
                      color="primary"
                      size="small"
                    />
                  ))}
                </Box>
              )}
            >
              {Object.values(DeviceTypes).map((type) => (
                <MenuItem key={type} value={type}>
                  <Checkbox
                    disableRipple
                    size="small"
                    value={type}
                    checked={columnFilters?.types?.includes(type) ?? false}
                  />
                  {trans(`device.type.${type}`)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl
            sx={{
              flexShrink: 1,
              width: { xs: 1, md: 250 }
            }}
          >
            <InputLabel>{trans('device.origin')}</InputLabel>
            <Select
              value={columnFilters?.origin ?? ''}
              onChange={(e) =>
                setColumnFilters({
                  ...columnFilters,
                  origin: e.target.value
                })
              }
              input={<OutlinedInput label={trans('device.origin')} />}
              renderValue={(value) => (
                <Box
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: 0.5
                  }}
                >
                  <Chip
                    key={value}
                    label={trans(`device.creator.${value}`)}
                    variant="light"
                    color="primary"
                    size="small"
                  />
                </Box>
              )}
              IconComponent={
                columnFilters?.origin
                  ? () => (
                      <IconButton
                        size="small"
                        onClick={() => {
                          setColumnFilters({
                            ...columnFilters,
                            origin: ''
                          })
                        }}
                      >
                        <Clear />
                      </IconButton>
                    )
                  : undefined
              }
            >
              {['IS_MANUAL', 'IS_AUTO'].map((origin) => (
                <MenuItem key={origin} value={origin}>
                  {trans(`device.creator.${origin}`)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Stack
            direction={'row'}
            alignItems={'center'}
            sx={{
              flexGrow: 1,
              width: { xs: 1, md: 200 }
            }}
          >
            <FormControl sx={{ flexGrow: 1 }}>
              <ListFilterTextfield
                placeholder={trans('device.search_placeholder')}
                globalFilter={globalFilter}
                setGlobalFilter={setGlobalFilter}
              />
            </FormControl>
            <IconButton onClick={popover.onOpen}>
              <Iconify icon="eva:more-vertical-fill" />
            </IconButton>
          </Stack>
        </Stack>

        <CustomPopover open={popover.open} onClose={popover.onClose} arrow="right-top" sx={{ width: 220 }}>
          <MenuItem
            onClick={() => {
              resetAll()
              popover.onClose()
            }}
          >
            <Iconify icon={'carbon:reset'} sx={{ width: 24, height: 24, mr: '16px' }} />
            {trans('table.reset_filter')}
          </MenuItem>
          <MenuItem
            onClick={() => {
              openModal('deviceCreate', <DeviceCreateEditModal redirectToDetail={true} />)
              popover.onClose()
            }}
          >
            <Iconify icon={'carbon:meter'} sx={{ width: 24, height: 24, mr: '16px' }} />
            {trans('device.create')}
          </MenuItem>

          <MenuItem
            onClick={() => {
              openModal('deviceExport', <DeviceExportModal url={url} />)

              popover.onClose()
            }}
          >
            <Iconify icon={'carbon:download'} sx={{ width: 24, height: 24, mr: '16px' }} />
            {trans('button.csv_export')}
          </MenuItem>
        </CustomPopover>
      </Stack>
    </>
  )
}

DeviceTableToolbar.propTypes = {
  columnFilters: PropTypes.object,
  setColumnFilters: PropTypes.func,
  globalFilter: PropTypes.string,
  setGlobalFilter: PropTypes.func,
  refresh: PropTypes.number,
  resetAll: PropTypes.func,
  url: PropTypes.object,
  context: PropTypes.object
}
