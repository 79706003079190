import { Card, Grid, Stack } from '@mui/material'
import { FormProvider } from '@/context/use-form-context'
import { LoadingButton } from '@mui/lab'
import { trans } from '@/utils/translation'
import InertiaSwitch from '@/components/form/inertia-switch'
import { router, usePage } from '@inertiajs/react'
import { useForm } from '@/hooks/use-form'
import { ToastCtl } from '@/utils/toast-ctl'
import useSubmit from '@/hooks/use-submit'
import Box from '@mui/material/Box'

const UserProfileNotificationForm = [
  {
    items: [
      {
        id: 'notifyViaEmail',
        label: trans('user.notifications.email')
      },
      {
        id: 'newsletter',
        label: trans('user.notifications.newsletter')
      }
    ]
  }
]

const Notifications = () => {
  const { auth } = usePage().props
  const methods = useForm({
    url: {
      method: 'PUT',
      location: `/api/users/${auth.user.id}/edit-notifications`
    },
    defaultValues: {
      notifyViaEmail: auth.user.settings.notifyViaEmail,
      newsletter: auth.user.settings.newsletter
    }
  })

  const { submit, submitting } = methods

  const handleSubmit = () => {
    submit(() => {
      router.reload()
      ToastCtl.show(trans('general.save_success'), 'success')
    })
  }

  useSubmit(() => handleSubmit())

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit}>
          <Stack component={Card} spacing={3} sx={{ p: 3 }}>
            {UserProfileNotificationForm.map((notification, index) => (
              <Grid container spacing={3} sx={{ p: 3 }} key={index}>
                <Grid item xs={12}>
                  <Stack spacing={1}>
                    {notification.items.map((item) => (
                      <Box key={item.id}>
                        <InertiaSwitch
                          input={{
                            label: item.label
                          }}
                          name={item.id}
                        />
                      </Box>
                    ))}
                  </Stack>
                </Grid>
              </Grid>
            ))}
            <LoadingButton variant={'contained'} sx={{ ml: 'auto', mr: 3 }} loading={submitting} onClick={handleSubmit}>
              {trans('button.save')}
            </LoadingButton>
          </Stack>
        </form>
      </FormProvider>
    </>
  )
}

export default Notifications
