import { formatDate } from '@/utils/format'
import { trans } from '@/utils/translation'

export const COLUMNS = [
  {
    accessorFn: (row) => row.subject,
    header: 'Betreff',
    size: 350,
    enableSorting: false
  },
  {
    accessorFn: (row) => row.sender.join(', '),
    header: 'Von',
    size: 100,
    enableSorting: false
  },
  {
    accessorFn: (row) => row.recipient.join(', '),
    header: 'An',
    size: 100,
    enableSorting: false
  },
  {
    accessorFn: (row) => formatDate(row.sentAt, 'DD.MM.YYYY HH:mm:ss'),
    header: 'Gesendet um',
    size: 100,
    enableSorting: false
  },
  {
    accessorFn: (row) => trans(`state.mail_message.${row.state}`),
    header: 'Status',
    size: 100,
    enableSorting: false
  }
]
