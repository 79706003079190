import { Card, Stack, Typography } from '@mui/material'
import { trans } from '@/utils/translation'
import { router, usePage } from '@inertiajs/react'
import { alpha, useTheme } from '@mui/material/styles'
import { useCallback, useState } from 'react'
import Box from '@mui/material/Box'
import ApartmentStateLabel from '../apartment-state-label'
import Tabs, { tabsClasses } from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { bgGradient } from '@/theme/css'
import Button from '@mui/material/Button'
import ApartmentDetailData from '../apartment-detail-data'
import { DocumentListView } from '@/sections/document/view'
import { DeviceListView } from '@/sections/device/view'
import { EnergySavingsLeaf } from '@mui/icons-material'
import InfoView from '@/components/info-view'
import VideoModal from '@/components/modal/video-modal'
import { useModalContext } from '@/context/use-modal-context'

const TABS = [
  {
    value: 'data',
    label: trans('apartment.tabs.data')
  },
  {
    value: 'documents',
    label: trans('apartment.tabs.documents')
  },
  {
    value: 'devices',
    label: trans('apartment.tabs.devices')
  }
]

export default function ApartmentDetailView() {
  const { apartment } = usePage().props
  const { openModal } = useModalContext()

  const theme = useTheme()
  const [currentTab, setCurrentTab] = useState('data')

  const handleChangeTab = useCallback((event, newValue) => {
    setCurrentTab(newValue)
  }, [])

  return (
    <>
      <Typography variant={'h5'} gutterBottom>
        {trans('apartment.header')}
      </Typography>

      <Stack direction={'row'} spacing={2} flexWrap={'wrap'}>
        <Card
          sx={{
            mb: 3,
            height: 290,
            minWidth: { xs: '100%', md: '650px', lg: '800px', xl: '850px' },
            flexGrow: 10
          }}
        >
          <Box
            sx={{
              height: 1,
              color: 'common.white',
              ...bgGradient({
                direction: 'to right',
                color: alpha(theme.palette.primary.lighter, 0.1),
                imgUrl: require('../../../../images/Cover/Wohnung.jpg'),
                startColor: alpha(theme.palette.primary.darker, 0.4)
              })
            }}
          >
            <Stack direction={'column'} justifyContent={'space-between'} height={'80%'}>
              <Stack direction={{ xs: 'column', md: 'row' }} alignItems={'end'} justifyContent={'space-between'} p={4}>
                {/* <Iconify icon="solar:camera-add-bold" width={32} /> */}
                <div></div>
                <ApartmentStateLabel variant={'filled'} state={apartment.state} />
              </Stack>
              <Stack direction={'column'} px={4}>
                <Typography variant={'h6'}>{apartment?.label}</Typography>
                <Typography variant={'body2'} mb={2}>
                  {apartment?.address?.street} {apartment?.address?.streetNumber}, {apartment?.address?.postcode}{' '}
                  {apartment?.address?.city}
                </Typography>
                <Button
                  startIcon={<EnergySavingsLeaf />}
                  variant={'contained'}
                  sx={{ maxWidth: '220px' }}
                  color={'success'}
                  mt={2}
                  onClick={() => router.get(`/dashboard/apartment/${apartment.id}`)}
                >
                  {trans('button.current_consumption')}
                </Button>
              </Stack>
            </Stack>
          </Box>
          <Tabs
            value={currentTab}
            onChange={handleChangeTab}
            sx={{
              width: 1,
              bottom: 0,
              zIndex: 9,
              px: 2,
              position: 'absolute',
              bgcolor: 'background.paper',
              [`& .${tabsClasses.flexContainer}`]: {
                pr: { md: 3 },
                justifyContent: {
                  sm: 'center',
                  md: 'flex-end'
                }
              }
            }}
          >
            {TABS.map((tab) => (
              <Tab key={tab.value} value={tab.value} icon={tab.icon} label={tab.label} />
            ))}
          </Tabs>
        </Card>
        <Card
          sx={{
            mb: 3,
            maxHeight: 290,
            flexGrow: 1
          }}
        >
          <InfoView
            header={trans('apartment.info.header')}
            description={trans('dashboard.info.description')}
            tip={trans('dashboard.info.tip')}
            button={trans('dashboard.info.button')}
            onClick={() =>
              openModal('videoModal', <VideoModal src={'06_Einheit.mp4'} controls={true} autoPlay={true} />, {
                maxWidth: 'lg'
              })
            }
          />
        </Card>
      </Stack>

      {currentTab === 'data' && <ApartmentDetailData />}
      {currentTab === 'documents' && (
        <DocumentListView defaultFilter={{ apartment: apartment.id }} context={{ apartment }} />
      )}
      {currentTab === 'devices' && (
        <DeviceListView defaultFilter={{ apartment: apartment.id }} context={{ apartment }} />
      )}
    </>
  )
}
