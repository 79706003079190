import Typography from '@mui/material/Typography'
import { Stack } from '@mui/material'
import PropTypes from 'prop-types'

export default function CustomLabelValue({ label, value }) {
  return (
    <Stack direction="row" justifyContent="space-between">
      <Typography variant={'body2'}>{label}</Typography>
      <Typography variant={'body2'}>{value}</Typography>
    </Stack>
  )
}

CustomLabelValue.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired
}
