import { DialogActions, DialogTitle, FormControlLabel, Grid, Radio, RadioGroup, Stack } from '@mui/material'
import { trans } from '@/utils/translation'
import DialogContent from '@mui/material/DialogContent'
import CustomButton from '@/components/custom-button'
import { LoadingButton } from '@mui/lab'
import { useModalContext } from '@/context/use-modal-context'
import { ToastCtl } from '@/utils/toast-ctl'
import useSubmit from '@/hooks/use-submit'
import { FormProvider } from '@/context/use-form-context'
import dayjs from 'dayjs'
import InertiaDatePicker from '@/components/form/inertia-date-picker'
import { useLocalForm } from '@/hooks/use-local-form'
import * as yup from 'yup'
import PropTypes from 'prop-types'

export default function DeviceExportModal({ url, defaultValues }) {
  const { closeModal } = useModalContext()
  const schema = yup
    .object()
    .shape({
      exportType: yup.string().nullable(),
      begin: yup.date().required(),
      end: yup.date().required()
    })
    .test('date', trans('validation.period_invalid'), async (value) => {
      if (!value.exportType) {
        return true
      }

      return value.begin < value.end
    })

  const methods = useLocalForm({
    defaultValues: {
      exportType: '',
      begin: dayjs().startOf('year').endOf('day'),
      end: dayjs().endOf('year'),
      ...defaultValues
    },
    validationSchema: schema
  })

  const { data, setData } = methods

  const handleSubmit = () => {
    schema
      .validate(data)
      .then(() => {
        ToastCtl.show(trans('general.data_export_hint'), 'info')

        url.pathname = url.pathname.replace('list', 'export')
        if (data.exportType !== '') {
          url.pathname = url.pathname.replace('/devices/', '/device_values/')

          const filters = JSON.parse(url.searchParams.get('filters') || '{}')
          filters.begin = data.begin
          filters.end = data.end
          url.searchParams.set('filters', JSON.stringify(filters))
        }
        document.location.href = url.href

        closeModal('deviceExport')
      })
      .catch((validationError) => {
        ToastCtl.show(validationError.message, 'error')
      })
  }

  useSubmit(() => handleSubmit())

  return (
    <>
      <DialogTitle>{trans(`device.export.header`)}</DialogTitle>
      <DialogContent dividers sx={{ py: 1 }}>
        <FormProvider {...methods} sx={{ px: 2, mx: 8 }}>
          <form onSubmit={handleSubmit}>
            <Stack spacing={2}>
              <RadioGroup spacing={2} row sx={{ flexWrap: 'nowrap' }}>
                {!defaultValues?.exportType && (
                  <FormControlLabel
                    onClick={() => setData('exportType', '')}
                    sx={{
                      py: 2,
                      ml: 0,
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      flexWrap: 'nowrap',
                      border: '1px solid rgba(33, 43, 54, 0.08)',
                      borderRadius: '12px',
                      ...(data.exportType === '' && { border: '1px solid black' })
                    }}
                    control={<Radio sx={{ display: 'none' }} />}
                    label={trans('device.export.current_values')}
                  />
                )}
                <FormControlLabel
                  onClick={() => setData('exportType', 'specific')}
                  sx={{
                    py: 2,
                    ml: 0,
                    mr: 0,
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexWrap: 'nowrap',
                    border: '1px solid rgba(33, 43, 54, 0.08)',
                    borderRadius: '12px',
                    ...(data.exportType !== '' && { border: '1px solid black' })
                  }}
                  control={<Radio sx={{ display: 'none' }} />}
                  label={trans('device.export.specific_values')}
                />
              </RadioGroup>
              {data.exportType !== '' && (
                <Grid container direction={'row'} spacing={2}>
                  <Grid item md={6} xs={12}>
                    <InertiaDatePicker
                      name={'begin'}
                      input={{
                        label: trans('general.from'),
                        required: true
                      }}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <InertiaDatePicker
                      name={'end'}
                      input={{
                        label: trans('general.to'),
                        required: true
                      }}
                    />
                  </Grid>
                </Grid>
              )}
            </Stack>
          </form>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <CustomButton onClick={() => closeModal('deviceExport')}>{trans('button.cancel')}</CustomButton>
        <LoadingButton
          variant={'contained'}
          onClick={() => {
            handleSubmit()
          }}
        >
          {trans('button.done')}
        </LoadingButton>
      </DialogActions>
    </>
  )
}

DeviceExportModal.propTypes = {
  url: PropTypes.object,
  defaultValues: PropTypes.object
}
