import { useMemo } from 'react'
import { trans } from '@/utils/translation'
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table'
import { defaultTableOptions } from '@/utils/misc'
import { usePage } from '@inertiajs/react'
import { formatCurrency, formatPercent } from '@/utils/format'
import CollapseCard from '@/components/collapse-card'
import OverlayCard from '@/sections/billing/components/overlay-card'
import PropTypes from 'prop-types'

export default function BillingControlTable({ expanded, onExpand }) {
  const { billing, previousBilling } = usePage().props

  const calculateChangePercentage = (preValue, currentValue) => {
    if (preValue === 0 || currentValue === 0) {
      return '-'
    }

    return formatPercent(currentValue / preValue)
  }

  const data = [
    {
      type: trans('billing.detail.control.data.property_costs'),
      lastYearValue: formatCurrency(previousBilling?.costsSum ?? 0),
      currentYearValue: formatCurrency(billing.costsSum),
      change: calculateChangePercentage(previousBilling?.costsSum ?? 0, billing.costsSum)
    },
    {
      type: trans('billing.detail.control.data.fuel_costs'),
      lastYearValue: formatCurrency(previousBilling?.fuelCostSum?.calculatedUsageCostGross ?? 0),
      currentYearValue: formatCurrency(billing.fuelCostSum?.calculatedUsageCostGross),
      change: calculateChangePercentage(
        previousBilling?.fuelCostSum?.calculatedUsageCostGross ?? 0,
        billing.fuelCostSum?.calculatedUsageCostGross
      )
    },
    {
      type: trans('billing.detail.control.data.heating_costs'),
      lastYearValue: formatCurrency(previousBilling?.calculatedHeatingCostGross ?? 0),
      currentYearValue: formatCurrency(billing.calculatedHeatingCostGross),
      change: calculateChangePercentage(
        previousBilling?.calculatedHeatingCostGross ?? 0,
        billing.calculatedHeatingCostGross
      )
    },
    {
      type: trans('billing.detail.control.data.additional_costs'),
      lastYearValue: formatCurrency(previousBilling?.calculatedAdditionalCostGross ?? 0),
      currentYearValue: formatCurrency(billing.calculatedAdditionalCostGross),
      change: calculateChangePercentage(
        previousBilling?.calculatedAdditionalCostGross ?? 0,
        billing.calculatedAdditionalCostGross
      )
    },
    {
      type: trans('billing.detail.control.data.prepayment'),
      lastYearValue: formatCurrency(previousBilling?.calculatedPrepayment ?? 0),
      currentYearValue: formatCurrency(billing.calculatedPrepayment),
      change: calculateChangePercentage(previousBilling?.calculatedPrepayment ?? 0, billing.calculatedPrepayment)
    }
  ]

  const columns = useMemo(
    () => [
      {
        accessorKey: 'type',
        header: trans('billing.detail.control.columns.cost_type'),
        size: 250,
        enableSorting: false
      },
      {
        accessorKey: 'lastYearValue',
        header: trans('billing.detail.control.columns.last_year_value'),
        size: 150,
        enableSorting: false
      },
      {
        accessorKey: 'currentYearValue',
        header: trans('billing.detail.control.columns.current_year_value'),
        size: 100,
        enableSorting: false
      },
      {
        accessorKey: 'change',
        header: trans('billing.detail.control.columns.change'),
        size: 100,
        enableSorting: false
      }
    ],
    []
  )

  const table = useMaterialReactTable({
    ...defaultTableOptions,
    renderTopToolbar: () => <></>,
    columns,
    data: data
  })

  return (
    <>
      <OverlayCard>
        <CollapseCard title={trans('billing.detail.control.title')} expand={expanded} onExpand={onExpand}>
          <MaterialReactTable table={table} />
        </CollapseCard>
      </OverlayCard>
    </>
  )
}

BillingControlTable.propTypes = {
  expanded: PropTypes.bool,
  onExpand: PropTypes.func
}
