import { Alert, Grid, InputAdornment, Stack, Typography } from '@mui/material'
import { FormProvider } from '@/context/use-form-context'
import InertiaTextfield from '@/components/form/inertia-textfield'
import { Head, router, usePage } from '@inertiajs/react'
import useSubmit from '@/hooks/use-submit'
import CustomButton from '../../components/custom-button'
import * as yup from 'yup'
import { trans } from '@/utils/translation'
import AuthLayout from '../../layouts/auth'
import IconButton from '@mui/material/IconButton'
import Iconify from '../../components/iconify'
import { useBoolean } from '@/hooks/use-boolean'
import CustomLink from '../../components/custom-link'
import { LoadingButton } from '@mui/lab'
import { ToastCtl } from '@/utils/toast-ctl'
import { useLocalForm } from '@/hooks/use-local-form'

const Login = () => {
  const { error, email } = usePage().props
  const password = useBoolean()
  const schema = yup.object().shape({
    username: yup.string().email(trans('validation.email.required')).required(trans('validation.email.required')),
    password: yup.string().required(trans('validation.password.required'))
  })

  const methods = useLocalForm({
    defaultValues: {
      username: email,
      password: ''
    },
    validationSchema: schema
  })

  const { data, processing, post } = methods

  const submit = () => {
    schema
      .validate(data)
      .then(() => {
        post('/login-check', data)
      })
      .catch((validationError) => {
        ToastCtl.show(validationError.message, 'error')
      })
  }

  useSubmit(() => submit())

  return (
    <>
      <Head title={trans('login.title')} />
      <FormProvider {...methods} sx={{ px: 2, mx: 8 }}>
        <Typography variant={'h4'} component={'h1'} sx={{ mb: 2 }}>
          {trans('login.header')}
        </Typography>
        <form onSubmit={submit}>
          {error && (
            <Alert sx={{ mb: 2 }} severity={'error'} variant={'filled'} color={'error'}>
              {trans(`login_errors.${error}`)}
            </Alert>
          )}
          <Stack direction={'column'} spacing={2}>
            <InertiaTextfield
              name={'username'}
              input={{
                label: trans('field.email')
              }}
            />
            <Stack style={{ position: 'relative' }}>
              <InertiaTextfield
                name={'password'}
                input={{
                  label: trans('field.password'),
                  InputProps: {
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={password.onToggle} edge="end">
                          <Iconify icon={password.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'} />
                        </IconButton>
                      </InputAdornment>
                    )
                  },
                  type: password.value ? 'text' : 'password'
                }}
              />
            </Stack>

            <CustomLink href={'/forgot-password'} variant="body2" sx={{ alignSelf: 'flex-end' }}>
              Passwort vergessen?
            </CustomLink>

            <LoadingButton loading={processing} onClick={submit} variant={'contained'}>
              {trans('button.login')}
            </LoadingButton>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <CustomButton
                  sx={{ width: '100%' }}
                  onClick={() => (document.location.href = 'https://assetenergy.de/assetapp-testen/')}
                >
                  {trans('button.test')}
                </CustomButton>
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomButton sx={{ width: '100%' }} onClick={() => router.get('/register')}>
                  {trans('button.offer')}
                </CustomButton>
              </Grid>
            </Grid>
          </Stack>
        </form>
      </FormProvider>
    </>
  )
}

Login.layout = (page) => <AuthLayout>{page}</AuthLayout>

export default Login
