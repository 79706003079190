import { DialogActions, DialogTitle } from '@mui/material'
import { trans } from '@/utils/translation'
import DialogContent from '@mui/material/DialogContent'
import CustomButton from '@/components/custom-button'
import { LoadingButton } from '@mui/lab'
import { useModalContext } from '@/context/use-modal-context'
import { useForm } from '@/hooks/use-form'
import { router } from '@inertiajs/react'
import { ToastCtl } from '@/utils/toast-ctl'
import useSubmit from '@/hooks/use-submit'
import { FormProvider } from '@/context/use-form-context'
import PropTypes from 'prop-types'
import DeviceValueForm from '@/sections/device-value/form/device-value-form'

export default function DeviceValueEditModal({ deviceValue, device, callback }) {
  const { closeModal } = useModalContext()

  const methods = useForm({
    url: {
      method: 'PUT',
      location: `/api/device_values/${deviceValue.id}`
    },
    defaultValues: {
      device: deviceValue.deviceId,
      deviceValueType: deviceValue.type,
      reportDate: deviceValue.reportDate,
      value: deviceValue.value
    }
  })

  const { submit, submitting } = methods

  const handleSubmit = () => {
    submit(() => {
      callback ? callback() : router.reload()
      ToastCtl.show(trans('general.save_success'), 'success')
      closeModal('deviceValueEdit')
    })
  }

  useSubmit(() => handleSubmit())

  return (
    <>
      <DialogTitle>{trans(`device_value.edit`)}</DialogTitle>
      <DialogContent dividers sx={{ py: 1 }}>
        <FormProvider {...methods} sx={{ px: 2, mx: 8 }}>
          <form onSubmit={handleSubmit}>
            <DeviceValueForm device={device} />
          </form>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <CustomButton onClick={() => closeModal('deviceValueEdit')}>{trans('button.cancel')}</CustomButton>
        <LoadingButton
          loading={submitting}
          variant={'contained'}
          onClick={() => {
            handleSubmit()
          }}
        >
          {trans('button.done')}
        </LoadingButton>
      </DialogActions>
    </>
  )
}

DeviceValueEditModal.propTypes = {
  deviceValue: PropTypes.object,
  device: PropTypes.object,
  callback: PropTypes.func
}
