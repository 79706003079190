import Typography from '@mui/material/Typography'
import { Card, CardContent, CardHeader, Stack } from '@mui/material'
import { trans } from '@/utils/translation'
import { formatNumber, getDecimalByDeviceType, getUnitByDeviceType } from '@/utils/format'
import DeviceTypes from '@/constants/DeviceTypes'
import EmptyItem from '@/sections/dashboard-overview/components/empty-item'
import PropTypes from 'prop-types'

export default function DashboardHeatingEfficiency({ heatingSettings, averageHeatingSum }) {
  const heatingCosts = averageHeatingSum?.value
  const heatingEfficiencyLow = averageHeatingSum?.low
  const heatingEfficiencyHigh = averageHeatingSum?.high

  let background, labelColor, consumption
  if (heatingCosts <= heatingEfficiencyLow) {
    background = 'linear-gradient(rgba(253, 245, 227,1) 40%, rgba(94, 194, 106,1) 100%);'
    labelColor = 'rgb(94, 194, 106)'
    consumption = trans('dashboard.heating_consumption.low')
  } else if (heatingCosts > heatingEfficiencyLow && heatingCosts <= heatingEfficiencyHigh) {
    background = 'linear-gradient(rgba(253, 245, 227,1) 40%, rgba(223, 163, 49) 100%);'
    labelColor = 'rgb(223, 163, 49)'
    consumption = trans('dashboard.heating_consumption.normal')
  } else {
    background = 'linear-gradient(rgba(253, 245, 227,1) 40%, rgba(236, 98, 64,1) 100%);'
    labelColor = 'rgb(236, 98, 64)'
    consumption = trans('dashboard.heating_consumption.high')
  }

  return (
    <>
      {heatingCosts !== undefined ? (
        <Card sx={{ background, height: '100%' }}>
          <CardHeader title={trans('dashboard.heating_consumption.title')} />
          <CardContent>
            <Typography
              variant={'body1'}
              sx={{
                background: labelColor,
                color: 'common.white',
                borderRadius: '8px',
                width: 'fit-content',
                px: 2,
                mb: 2
              }}
            >
              {consumption}
            </Typography>

            <Typography variant={'h6'}>
              {formatNumber(heatingCosts, getDecimalByDeviceType(DeviceTypes.HEAT))}{' '}
              {getUnitByDeviceType(DeviceTypes.HEAT)} / m²
            </Typography>
            <Stack direction={'column'} spacing={1} sx={{ mt: 2 }}>
              <Typography variant={'body2'}>
                {trans('dashboard.heating_consumption.heating')}:{' '}
                {trans(`heating_system_type.${heatingSettings.heatingSystemType.label}`)}
              </Typography>
              <Typography variant={'body2'}>
                {trans('dashboard.heating_consumption.low')}: {heatingEfficiencyLow}{' '}
                {getUnitByDeviceType(DeviceTypes.HEAT)} / m²
              </Typography>
              <Typography variant={'body2'}>
                {trans('dashboard.heating_consumption.high')}: {heatingEfficiencyHigh}{' '}
                {getUnitByDeviceType(DeviceTypes.HEAT)} / m²
              </Typography>
            </Stack>
          </CardContent>
        </Card>
      ) : (
        <EmptyItem message={trans('dashboard.consumption.heating_empty')} />
      )}
    </>
  )
}

DashboardHeatingEfficiency.propTypes = {
  heatingSettings: PropTypes.object,
  averageHeatingSum: PropTypes.object
}
