import { useMemo } from 'react'
import { FormControlLabel, Switch } from '@mui/material'
import { useFormContext } from '@/context/use-form-context'
import { get } from 'lodash'
import IconButton from '@mui/material/IconButton'
import { InfoOutlined } from '@mui/icons-material'
import Tooltip from '@mui/material/Tooltip'
import Box from '@mui/material/Box'

const InertiaSwitch = ({ name, input, callback }) => {
  const { data, setData, errors } = useFormContext()
  const { label, tooltip, disabled } = input

  return useMemo(() => {
    return (
      <Box sx={{ display: 'flex', flexWrap: 'wrap', width: '100%' }}>
        <FormControlLabel
          control={
            <Switch
              {...input}
              checked={!!get(data, name)}
              onChange={() => {
                const value = !get(data, name)
                setData(name, value)
                callback ? callback(value) : null
              }}
              size="medium"
            />
          }
          labelPlacement="start"
          sx={{
            m: 0,
            width: 1,
            justifyContent: 'space-between'
          }}
          label={
            <>
              {label}
              {tooltip && (
                <Tooltip title={tooltip}>
                  <IconButton>
                    <InfoOutlined />
                  </IconButton>
                </Tooltip>
              )}
            </>
          }
        />
        <span className="validation-error">{errors[name]}</span>
      </Box>
    )
  }, [get(errors, name), get(data, name), disabled])
}

export default InertiaSwitch
