import { Stack } from '@mui/material'
import { trans } from '@/utils/translation'
import Box from '@mui/material/Box'
import InertiaDatePicker from '@/components/form/inertia-date-picker'
import InertiaNumericField from '@/components/form/inertia-numeric-field'
import PropTypes from 'prop-types'
import { getDecimalByDeviceType, getUnitByDeviceType } from '@/utils/format'

export default function DeviceValueForm({ device }) {
  return (
    <>
      <Stack spacing={2}>
        <Stack direction={'row'} spacing={2}>
          <Box sx={{ width: '50%' }}>
            <InertiaDatePicker
              name={'reportDate'}
              input={{
                label: trans('field.device.report_date'),
                required: true
              }}
            />
          </Box>
          <InertiaNumericField
            name={'value'}
            input={{
              label: trans('field.device_value.value'),
              required: true,
              suffix: ` ${getUnitByDeviceType(device.type)}`,
              decimalScale: getDecimalByDeviceType(device.type)
            }}
            sx={{ width: '50%' }}
          />
        </Stack>
      </Stack>
    </>
  )
}

DeviceValueForm.propTypes = {
  device: PropTypes.object
}
