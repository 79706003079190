import { DialogActions, DialogTitle, Grid } from '@mui/material'
import { trans } from '@/utils/translation'
import DialogContent from '@mui/material/DialogContent'
import CustomButton from '@/components/custom-button'
import { LoadingButton } from '@mui/lab'
import { useModalContext } from '@/context/use-modal-context'
import { useForm } from '@/hooks/use-form'
import { router } from '@inertiajs/react'
import { ToastCtl } from '@/utils/toast-ctl'
import useSubmit from '@/hooks/use-submit'
import { FormProvider } from '@/context/use-form-context'
import PropTypes from 'prop-types'
import InertiaNumericField from '@/components/form/inertia-numeric-field'

export default function PropertyEditEstimationSettingsModal({ property }) {
  const { closeModal } = useModalContext()

  const methods = useForm({
    url: {
      method: 'PUT',
      location: `/api/properties/${property.id}/edit-estimation-settings`
    },
    defaultValues: {
      annualEnergyConsumption: property?.estimationSettings?.annualEnergyConsumption,
      costPerKilowattHour: property?.estimationSettings?.costPerKilowattHour,
      heatingSensitivity: property?.estimationSettings?.heatingSensitivity,
      hcaSensitivity: property?.estimationSettings?.hcaSensitivity,
      warmWaterEnergyShare: property?.estimationSettings?.warmWaterEnergyShare,
      costPerSquareMeter: property?.estimationSettings?.costPerSquareMeter
    }
  })

  const { submit, submitting } = methods

  const handleSubmit = () => {
    submit(() => {
      router.reload()
      ToastCtl.show(trans('general.save_success'), 'success')
      closeModal('propertyEditEstimationSettings')
    })
  }

  useSubmit(() => handleSubmit())

  return (
    <>
      <DialogTitle>{trans('property.analysis_estimation.edit')}</DialogTitle>
      <DialogContent dividers sx={{ py: 1 }}>
        <FormProvider {...methods} sx={{ px: 2, mx: 8 }}>
          <form onSubmit={handleSubmit}>
            <Grid container direction={'row'} spacing={2}>
              <Grid item xs={12}>
                <InertiaNumericField
                  name={'annualEnergyConsumption'}
                  input={{
                    label: trans('property.analysis_estimation.hot_cold_water_costs.year_consumption'),
                    required: true,
                    suffix: ' kWh',
                    decimalScale: 0
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <InertiaNumericField
                  name={'costPerKilowattHour'}
                  input={{
                    label: trans('property.analysis_estimation.hot_cold_water_costs.price_per_kwh'),
                    required: true,
                    suffix: ' €/kWh',
                    decimalScale: 2
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <InertiaNumericField
                  name={'hcaSensitivity'}
                  input={{
                    label: trans('property.analysis_estimation.hot_cold_water_costs.system_sensitivity_hkw'),
                    required: true,
                    suffix: ' Einheiten/kWh',
                    decimalScale: 3
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <InertiaNumericField
                  name={'heatingSensitivity'}
                  input={{
                    label: trans('property.analysis_estimation.hot_cold_water_costs.system_sensitivity_wmz'),
                    required: true,
                    suffix: ' kWh/kWh',
                    decimalScale: 3
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <InertiaNumericField
                  name={'warmWaterEnergyShare'}
                  input={{
                    label: trans('property.analysis_estimation.hot_cold_water_costs.share_of_hot_water'),
                    required: true,
                    suffix: ' %',
                    decimalScale: 0
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <InertiaNumericField
                  name={'costPerSquareMeter'}
                  input={{
                    label: trans('property.analysis_estimation.additional_costs.price_per_m2'),
                    required: true,
                    suffix: ' €/m²',
                    decimalScale: 2
                  }}
                />
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <CustomButton onClick={() => closeModal('propertyEditEstimationSettings')}>
          {trans('button.cancel')}
        </CustomButton>
        <LoadingButton
          loading={submitting}
          variant={'contained'}
          onClick={() => {
            handleSubmit()
          }}
        >
          {trans('button.done')}
        </LoadingButton>
      </DialogActions>
    </>
  )
}

PropertyEditEstimationSettingsModal.propTypes = {
  property: PropTypes.object
}
