import { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

const CustomVideo = ({ src, poster, ...other }) => {
  const video = useRef()

  function autoplayVideo() {
    setTimeout(() => {
      this.controls = true
      this.play()

      video.current.removeEventListener('click', autoplayVideo)
    }, 250)
  }

  useEffect(() => {
    video.current.addEventListener('click', autoplayVideo)
  }, [])

  return (
    <video
      ref={video}
      src={`/video_stream/${src}`}
      width={'100%'}
      poster={poster}
      style={{ cursor: 'pointer' }}
      {...other}
    />
  )
}

CustomVideo.propTypes = {
  src: PropTypes.string.isRequired,
  poster: PropTypes.string.isRequired
}

export default CustomVideo
