import CustomPopover, { usePopover } from '../../../components/custom-popover'
import { FormControl, Stack } from '@mui/material'
import { trans } from '@/utils/translation'
import Iconify from '../../../components/iconify'
import IconButton from '@mui/material/IconButton'
import MenuItem from '@mui/material/MenuItem'
import { alpha } from '@mui/material/styles'
import Tabs from '@mui/material/Tabs'
import { STATUS_OPTIONS } from './property-table-definitions'
import Tab from '@mui/material/Tab'
import Label from '../../../components/label'
import Button from '@mui/material/Button'
import { useStateCounts } from '@/hooks/use-state-counts'
import PropTypes from 'prop-types'
import { router } from '@inertiajs/react'
import ListFilterTextfield from '@/components/form/list-filter-textfield'
import { ToastCtl } from '@/utils/toast-ctl'
import { isDemo } from '@/utils/misc'

export default function PropertyTableToolbar({
  columnFilters,
  setColumnFilters,
  globalFilter,
  setGlobalFilter,
  resetAll,
  url
}) {
  const popover = usePopover()
  const counts = useStateCounts(`/properties/count?filters=${JSON.stringify(columnFilters)}`, columnFilters)

  return (
    <>
      <Stack
        flexDirection={'row'}
        flexWrap={'wrap'}
        alignItems={'center'}
        justifyContent={'space-between'}
        sx={{
          boxShadow: (theme) => `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`
        }}
      >
        <Tabs
          value={columnFilters.state ?? ''}
          onChange={(e, value) => {
            setColumnFilters({ ...columnFilters, state: value })
          }}
          sx={{
            px: 2.5
          }}
        >
          {Object.entries(STATUS_OPTIONS).map(([key, { color, label }]) => (
            <Tab
              key={key}
              iconPosition="end"
              value={key}
              label={label}
              icon={
                <Label variant={((key === null || key === columnFilters.state) && 'filled') || 'soft'} color={color}>
                  {counts[key] ?? counts['ALL']}
                </Label>
              }
            />
          ))}
        </Tabs>
        {!isDemo() && (
          <Button variant="contained" color={'primary'} sx={{ m: 1, mr: 4 }} onClick={() => router.get('/assignment')}>
            + {trans('property.create')}
          </Button>
        )}
      </Stack>
      <Stack>
        <Stack
          direction={'row'}
          alignItems={'center'}
          spacing={2}
          flexGrow={1}
          sx={{
            p: 2
          }}
        >
          <FormControl sx={{ flexGrow: 1 }}>
            <ListFilterTextfield
              placeholder={trans('property.search_placeholder')}
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
            />
          </FormControl>
          <IconButton onClick={popover.onOpen}>
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>
        </Stack>

        <CustomPopover open={popover.open} onClose={popover.onClose} arrow="right-top" sx={{ width: 220 }}>
          <MenuItem
            onClick={() => {
              resetAll()
              popover.onClose()
            }}
          >
            <Iconify icon={'carbon:reset'} sx={{ width: 24, height: 24, mr: '16px' }} />
            {trans('table.reset_filter')}
          </MenuItem>
          <MenuItem
            onClick={() => {
              router.get('/assignment')
              popover.onClose()
            }}
          >
            <Iconify icon={'carbon:building'} sx={{ width: 24, height: 24, mr: '16px' }} />
            {trans('property.create')}
          </MenuItem>

          <MenuItem
            onClick={() => {
              ToastCtl.show(trans('general.data_export_hint'), 'info')

              url.pathname = url.pathname.replace('list', 'export')
              document.location.href = url.href

              popover.onClose()
            }}
          >
            <Iconify icon={'carbon:download'} sx={{ width: 24, height: 24, mr: '16px' }} />
            {trans('button.csv_export')}
          </MenuItem>
        </CustomPopover>
      </Stack>
    </>
  )
}

PropertyTableToolbar.propTypes = {
  columnFilters: PropTypes.object,
  setColumnFilters: PropTypes.func,
  globalFilter: PropTypes.string,
  setGlobalFilter: PropTypes.func,
  refresh: PropTypes.number,
  resetAll: PropTypes.func,
  url: PropTypes.object
}
