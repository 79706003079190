import { DialogActions, DialogTitle, Stack } from '@mui/material'
import { trans } from '@/utils/translation'
import DialogContent from '@mui/material/DialogContent'
import CustomButton from '@/components/custom-button'
import { LoadingButton } from '@mui/lab'
import { useModalContext } from '@/context/use-modal-context'
import { useForm } from '@/hooks/use-form'
import { router } from '@inertiajs/react'
import { ToastCtl } from '@/utils/toast-ctl'
import useSubmit from '@/hooks/use-submit'
import { FormProvider } from '@/context/use-form-context'
import InertiaDatePicker from '@/components/form/inertia-date-picker'
import PropTypes from 'prop-types'
import InertiaAutocomplete from '@/components/form/inertia-autocomplete'
import BillingState from '@/constants/BillingState'
import { handleErrorResponse } from '@/utils/misc'

export default function BillingEditModal({ billing }) {
  const { closeModal } = useModalContext()

  const methods = useForm({
    url: {
      method: 'PUT',
      location: `/api/billings/${billing.id}/edit`
    },
    defaultValues: {
      state: billing.state,
      begin: billing.begin,
      end: billing.end
    }
  })

  const { submit, submitting } = methods

  const handleSubmit = () => {
    submit(
      (response) => {
        router.get(`/billings/${response.data.id}/detail`)
        ToastCtl.show(trans('general.save_success'), 'success')
        closeModal('billingEdit')
      },
      (e) => handleErrorResponse(e)
    )
  }

  useSubmit(() => handleSubmit())

  return (
    <>
      <DialogTitle>{trans(`billing.edit`)}</DialogTitle>
      <DialogContent dividers sx={{ py: 1 }}>
        <FormProvider {...methods} sx={{ px: 2, mx: 8 }}>
          <form onSubmit={handleSubmit}>
            <Stack spacing={2}>
              <Stack direction={'row'} spacing={2}>
                <InertiaDatePicker
                  name={'begin'}
                  input={{
                    label: trans('general.from'),
                    required: true
                  }}
                />
                <InertiaDatePicker
                  name={'end'}
                  input={{
                    label: trans('general.to'),
                    required: true
                  }}
                />
              </Stack>
              <InertiaAutocomplete
                name={'state'}
                input={{
                  label: trans('field.state'),
                  required: true
                }}
                items={Object.values(BillingState).map((state) => ({
                  id: state,
                  label: trans(`state.billing.${state}`)
                }))}
              />
            </Stack>
          </form>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <CustomButton onClick={() => closeModal('billingEdit')}>{trans('button.cancel')}</CustomButton>
        <LoadingButton
          loading={submitting}
          variant={'contained'}
          onClick={() => {
            handleSubmit()
          }}
        >
          {trans('button.done')}
        </LoadingButton>
      </DialogActions>
    </>
  )
}

BillingEditModal.propTypes = {
  billing: PropTypes.object.isRequired
}
